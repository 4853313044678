import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgBestandpruefen({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M10.727 8.43c.374-.302.904-.42 1.487-.43.374-.004.675.04.869.09l.187.063c.498.238 1.074.654 1.074 1.427 0 .873-.46 1.25-1.607 2.02-.65.436-1.126.93-1.432 1.494a3.47 3.47 0 00-.41 1.686 1 1 0 002 0c0-.281.05-.514.168-.732.12-.221.345-.49.788-.788l.078-.052c1.033-.691 2.415-1.616 2.415-3.628 0-2.017-1.583-2.932-2.214-3.232-.52-.248-1.243-.356-1.943-.347h-.002c-.771.011-1.832.162-2.712.87a4.063 4.063 0 00-1.248 1.651 3.391 3.391 0 00-.184.62c-.015.082-.031.203-.031.203L8 9.458a1 1 0 001.998.107l.007-.046c.01-.052.032-.141.077-.252a2.07 2.07 0 01.645-.838zM13.5 18a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 10.001 18.001A9 9 0 0012 3z"
			/>
		</svg>
	);
}

const MemoSvgBestandpruefen = React.memo(SvgBestandpruefen);
export default MemoSvgBestandpruefen;
