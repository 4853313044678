import { trigram } from "n-gram";

/**
 * Calculate the similarity between two strings.
 *
 * Based on: https://stackoverflow.com/a/43158586
 */
export function similarity(a: string, b: string): number {
	if (a.trim() && a === b) {
		return 1;
	}

	const trigramsA = generateTrigrams(a);
	const trigramsB = generateTrigrams(b);

	const intersection = trigramsA.filter((tgm) => trigramsB.includes(tgm));
	const union = new Set([...trigramsA, ...trigramsB]);

	return union.size === 0 ? 0 : intersection.length / union.size;
}

/**
 * Adjusted similarity functions that scores higher when comparing strings of very different length.
 * Especially useful in autosuggest scenarios.
 *
 * Based on: https://stackoverflow.com/a/54102020
 */
export function substringSimilarity(a: string, b: string): number {
	if (a.trim() && a === b) {
		return 1;
	}

	const trigramsA = generateTrigrams(a);
	const trigramsB = generateTrigrams(b);

	const intersection = trigramsA.filter((tgm) => trigramsB.includes(tgm));
	const maxCommonLength = Math.min(trigramsA.length, trigramsB.length);

	const baseScore =
		maxCommonLength === 0 ? 0 : intersection.length / maxCommonLength;

	return baseScore + (1.0 - baseScore) * similarity(a, b);
}

export function generateTrigrams(input: string) {
	const prepared = prepareString(input);
	// We also remove any word ending trigrams with 2 spaces caused by the previous step.
	return trigram(prepared).filter((tgm) => !tgm.endsWith("  "));
}

function prepareString(input: string) {
	const trimmed = input.trim();
	if (!trimmed) {
		return "";
	}
	// step 1: replace any multi spaces with single spaces and then replace any single spaces with double ones.
	const step1 = trimmed.replace(/\s+/g, " ").replace(/\s/g, "  ");
	// step 2: normalize
	const step2 = step1.toLocaleLowerCase();
	// step 3: add 2 blank spaces at the beginning and one at the end.
	return `  ${step2} `;
}
