import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgLieferant({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.833 4C2.188 4 1 5.427 1 7v8c0 1.573 1.188 3 2.833 3h.338a3.001 3.001 0 005.658 0h4.342a3.001 3.001 0 005.658 0H21a2 2 0 002-2v-3.336a3 3 0 00-1.232-2.424l-3.652-2.664A3 3 0 0016.348 7H14c0-1.573-1.188-3-2.833-3H3.833zM19.83 16H21v-3.336a1 1 0 00-.41-.808l-3.653-2.664A1 1 0 0016.348 9H14v7h.17a3.001 3.001 0 015.66 0zM7 14a3.001 3.001 0 00-2.83 2h-.337C3.453 16 3 15.636 3 15V7c0-.636.453-1 .833-1h7.334c.38 0 .833.364.833 1v9H9.83A3.001 3.001 0 007 14zm0 2a1 1 0 100 2 1 1 0 000-2zm9 1a1 1 0 112 0 1 1 0 01-2 0z"
			/>
		</svg>
	);
}

const MemoSvgLieferant = React.memo(SvgLieferant);
export default MemoSvgLieferant;
