import { AlertSolid } from "@app/icons/components";
import { Close, Save } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import { useAbility } from "@app/modules/authorization/AuthorizationProvider";
import {
	DataTable,
	DataTableCell,
	DataTableCheckbox,
	DataTableForm,
	DataTableInputCell,
	DataTableRow,
} from "@app/modules/data-table/DataTable";
import {
	DataTableActionButton,
	DataTableActionsCell,
	DataTableDestructiveButton,
} from "@app/modules/data-table/DataTableActionsCell";
import { useDataTable } from "@app/modules/data-table/useDataTable";
import { useTenantFilter } from "@app/modules/entity/entity-utils";
import { useEntityForm } from "@app/modules/entity/hooks";
import { required } from "@app/modules/form/structs";
import { useMutation } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import { DateInputLegacy } from "@app/modules/input-fields/date/DateInputLegacy";
import { DateFilterModeSelect } from "@app/modules/layout/DateFilterModeSelect";
import { PageLayout } from "@app/modules/layout/PageLayout";
import { SectionCard } from "@app/modules/layout/SectionCard";
import { LocalDate } from "@app/modules/localdate/localdate";
import { ProductGroupSelectField } from "@app/modules/product-group/ProductGroupSelect";
import { makeProductGroupSuggestion } from "@app/modules/search/local/entity-suggestions";
import { useDateFilter } from "@app/modules/search/local/hooks";
import { useLocalSearchScope } from "@app/modules/search/local/utils";
import { quickhash } from "@app/modules/utils/hash";
import { AddProductionSiteCalendarButton } from "@app/modules/work-calendar/AddProductionSiteCalendarButton";
import { ProductionSiteComboboxField } from "@app/modules/work-calendar/ProductionSiteCombobox";
import { DeleteProductionSiteCalendarDocument } from "@app/modules/work-calendar/queries/delete-production-site-calendar.mutation.generated";
import { FullWorkCalendarFilterSuggestionsDocument } from "@app/modules/work-calendar/queries/full-work-calendar-filter-suggestions.query.generated";
import type { FullWorkCalendarsQuery } from "@app/modules/work-calendar/queries/full-work-calendars.query.generated";
import { FullWorkCalendarsDocument } from "@app/modules/work-calendar/queries/full-work-calendars.query.generated";
import { UpdateWorkCalendarDocument } from "@app/modules/work-calendar/queries/update-work-calendar.mutation.generated";
import { productGroupsForDismantlingBoolExp } from "@app/modules/work-calendar/work-calendar-utils";
import clsx from "clsx";
import { useEffect } from "react";
import type { Infer } from "superstruct";
import { boolean, object, string } from "superstruct";

const SCOPE_KEY = "work-calendar";

export function ProductionSiteProductGroupCalendar() {
	const t = useTranslate("common");
	const { cannot } = useAbility();

	const { date, setDate, mode, setMode } = useDateFilter({
		scopeKey: SCOPE_KEY,
		defaultDate: LocalDate.now().toDateString(),
		defaultMode: "from",
		path: "day",
	});

	const isReadOnly = cannot("update", "work_calendar");

	const searchScope = useLocalSearchScope(
		() => ({
			scopeKey: SCOPE_KEY,
			queryDocument: FullWorkCalendarFilterSuggestionsDocument,
			fields: [
				{
					key: "productGroup",
					getSuggestions: ({ groupSuggestions }) =>
						groupSuggestions.map(
							makeProductGroupSuggestion(
								"productionSiteProductGroupCalendar.productGroupId",
							),
						),
				},
			],
		}),
		[],
	);
	const dataTable = useDataTable({
		query: FullWorkCalendarsDocument,
		queryVariables: {
			where: { ...useTenantFilter() },
		},
		searchScope,
		columns: [
			{
				accessor: "",
				label: "",
			},
			{
				accessor: "day",
				label: t("work-calendar.table-header.day"),
			},
			{
				accessor: "workCalendar.weekday",
				label: t("work-calendar.table-header.weekday"),
			},
			{
				accessor: "workCalendar.holiday",
				label: t("work-calendar.table-header.holiday"),
				align: "center",
			},
			{
				accessor: "productionSiteProductGroupCalendar.productionSiteId",
				label: t("work-calendar.table-header.production-site"),
			},
			{
				accessor: "productionSiteProductGroupCalendar.productGroupId",
				label: t("work-calendar.table-header.product-group"),
			},
			{
				accessor: "",
				label: t("table.actions"),
				align: "right",
				hidden: isReadOnly,
			},
		],
		initialSort: [{ accessor: "day", direction: "asc" }],
		pageSize: 25,
	});

	return (
		<PageLayout title={t("work-calendar.title")}>
			<SectionCard>
				<SectionCard.Header
					variant="main"
					// This header contains both a text title and a date filter - no `variant` of `SectionCard.Header` currently supports this.
					// TODO: add a new `SectionCard.Header` `variant` to avoid passing empty title to `main` variant
					title=""
					tags={
						<>
							<span className="text-header-18 my-auto">
								{t("work-calendar.title")}
							</span>
							<DateFilterModeSelect value={mode} onChange={setMode} />
							<DateInputLegacy value={date} onChange={setDate} name="day" />
						</>
					}
					buttons={<AddProductionSiteCalendarButton />}
				/>
				<DataTable instance={dataTable}>
					{dataTable.visibleRows.map((fullCalendar, index, self) => (
						<CalendarRow
							fullCalendar={fullCalendar}
							key={quickhash([
								fullCalendar.workCalendar?.id,
								fullCalendar.productionSiteProductGroupCalendar?.id,
							])}
							readOnly={isReadOnly}
							previousDay={self[index - 1]?.day ?? undefined}
						/>
					))}
				</DataTable>
			</SectionCard>
		</PageLayout>
	);
}

interface CalendarRowProps {
	fullCalendar: FullWorkCalendarsQuery["entities"][number];
	readOnly?: boolean;
	previousDay: string | undefined;
}

function CalendarRow({
	fullCalendar,
	readOnly,
	previousDay,
}: CalendarRowProps) {
	const t = useTranslate("common");

	const [{ fetching: isSubmitting }, update] = useMutation(
		UpdateWorkCalendarDocument,
	);
	const [{ fetching: isDeleting }, deleteCalendar] = useMutation(
		DeleteProductionSiteCalendarDocument,
	);

	const { workCalendar, productionSiteProductGroupCalendar, day } =
		fullCalendar;

	const productionCalendarId = productionSiteProductGroupCalendar?.id;
	const hasProduction = Boolean(productionCalendarId);
	const isUnusualDay =
		hasProduction && (!workCalendar?.weekday || workCalendar?.holiday);

	const formId = `work-calendar-${workCalendar?.id}`;
	const {
		register,
		handleSubmit,
		formState,
		reset,
		getValues,
		control,
		setValue,
	} = useEntityForm({
		schema: makeCalendarSchema(hasProduction),
		defaultValues: {
			holiday: workCalendar?.holiday ?? false,
			productGroupId: productionSiteProductGroupCalendar?.productGroupId ?? "",
			productionSiteId:
				productionSiteProductGroupCalendar?.productionSiteId ?? "",
		},
	});

	useEffect(
		function updateHolidayAcrossRows() {
			setValue("holiday", workCalendar?.holiday ?? false);
		},
		[workCalendar?.holiday, setValue],
	);

	const isValidEntry = workCalendar && day;

	if (!isValidEntry) {
		return null;
	}

	const onSubmit = async (values: CalendarValues) => {
		if (readOnly) {
			return;
		}

		const { error } = await update({
			day,
			workCalendarData: { holiday: values.holiday },
			hasProductionSiteCalendar: hasProduction,
			productionSiteCalendarId: productionSiteProductGroupCalendar?.id ?? "",
			productionSiteCalendarData: {
				productGroupId: values.productGroupId,
				productionSiteId: values.productionSiteId,
			},
		});

		if (!error) {
			reset(values);
		}
	};

	// This work around it necessary because checkboxes don't support the `readOnly` attribute.
	// We only need it for schema fields that are boolean.
	const registerWithReadOnly = (name: keyof Pick<CalendarValues, "holiday">) =>
		readOnly ? { checked: getValues()[name] } : register(name);

	const date = LocalDate.fromDateString(day);
	const isFirstMonday = date.isFirstDayOfWeek() && day !== previousDay;

	return (
		<DataTableRow
			className={clsx(isFirstMonday && "border-t-4 border-t-brand-200")}
		>
			<DataTableCell className="w-0">
				<Icon
					icon={AlertSolid}
					size="16"
					title={t("work-calendar.unusual-day")}
					className={clsx("text-yellow-800", !isUnusualDay && "invisible")}
				/>
			</DataTableCell>
			<DataTableCell>
				{date.toLocalizedDateString()}
				<DataTableForm id={formId} onSubmit={handleSubmit(onSubmit)} />
			</DataTableCell>
			<DataTableCell>
				{workCalendar.weekday ? t("words.yes") : t("words.no")}
			</DataTableCell>
			<DataTableCell>
				<DataTableCheckbox
					form={formId}
					className="justify-around"
					type="checkbox"
					{...registerWithReadOnly("holiday")}
				/>
			</DataTableCell>
			<DataTableInputCell>
				{hasProduction && (
					<ProductionSiteComboboxField
						control={control}
						name="productionSiteId"
						isSmall
					/>
				)}
			</DataTableInputCell>
			<DataTableInputCell>
				{hasProduction && (
					<ProductGroupSelectField
						control={control}
						name="productGroupId"
						showSharedEntities
						queryVariables={{ where: productGroupsForDismantlingBoolExp }}
						isSmall
					/>
				)}
			</DataTableInputCell>
			{!readOnly && (
				<DataTableActionsCell>
					<DataTableActionButton
						icon={Close}
						onClick={() => reset()}
						disabled={isSubmitting}
						title={t("actions.cancel")}
						isHidden={!formState.isDirty}
					/>
					<DataTableActionButton
						icon={Save}
						type="submit"
						isFetching={isSubmitting}
						form={formId}
						title={t("actions.save")}
						isHidden={!formState.isDirty}
					/>
					{productionCalendarId && !formState.isDirty && (
						<DataTableDestructiveButton
							onClick={() => {
								deleteCalendar({ id: productionCalendarId });
							}}
							disabled={isDeleting}
							title={t("actions.delete")}
						/>
					)}
				</DataTableActionsCell>
			)}
		</DataTableRow>
	);
}

function makeCalendarSchema(hasProduction: boolean) {
	if (hasProduction) {
		return object({
			holiday: boolean(),
			productGroupId: required(string()),
			productionSiteId: required(string()),
		});
	}
	return object({
		holiday: boolean(),
		productGroupId: string(),
		productionSiteId: string(),
	});
}

type CalendarValues = Infer<ReturnType<typeof makeCalendarSchema>>;
