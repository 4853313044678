import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTrendDown({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.613 20.724l.094-.084 6-6a1 1 0 00-1.32-1.497l-.094.083L13 17.518V5a1 1 0 00-1.993-.117L11 5v12.518l-4.293-4.292a1 1 0 00-1.32-.083l-.094.083a1 1 0 00-.083 1.32l.083.094 6 6a1 1 0 001.32.084z"
			/>
		</svg>
	);
}

const MemoSvgTrendDown = React.memo(SvgTrendDown);
export default MemoSvgTrendDown;
