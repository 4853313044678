import type {
	DateFilterMode,
	DateValueType,
} from "@app/modules/layout/DateFilterModeSelect";
import { LocalDate } from "@app/modules/localdate/localdate";
import { logger } from "@app/modules/logger/logger";
import type { LocalSearchContextProps } from "@app/modules/search/local/LocalSearchProvider";
import { LocalSearchContext } from "@app/modules/search/local/LocalSearchProvider";
import type {
	LocalSearchRangeFilter,
	LocalSearchScope,
	LocalSearchScopeKey,
} from "@app/modules/search/local/types";
import type { UseLocalSearchFiltersOptions } from "@app/modules/search/local/useLocalSearchFilters";
import {
	DATE_FILTER_KEY,
	makeLocalSearchRangeFilter,
	makeLocalSearchValueFilter,
	useLocalSearchScope,
} from "@app/modules/search/local/utils";
import { useContext, useEffect, useMemo } from "react";

export interface UseLocalSearchReturn
	extends Omit<
		LocalSearchContextProps,
		"activeSearchScope" | "setActiveSearchScope"
	> {
	isScopeReady: boolean;
	activeSearchScopeKey: string | undefined;
}

export function useLocalSearchFilters(
	scopeKey: LocalSearchScopeKey | undefined,
): UseLocalSearchReturn {
	const {
		setActiveSearchScope: _,
		activeSearchScope,
		...rest
	} = useContext(LocalSearchContext);

	const activeSearchScopeKey = activeSearchScope?.scopeKey;
	const isScopeReady = activeSearchScope?.scopeKey === scopeKey;
	const base = isScopeReady ? rest : emptyUseLocalSearchBase;

	return { ...base, activeSearchScopeKey, isScopeReady };
}

const emptyUseLocalSearchBase: Omit<
	UseLocalSearchReturn,
	"activeSearchScopeKey" | "isScopeReady"
> = {
	queryFilters: {},
	searchFilters: [],
	hasFilter: () => false,
	findFilterByKey: () => undefined,
	addSearchFilters: warnAboutEmptyScopeMutation(undefined),
	removeSearchFilter: warnAboutEmptyScopeMutation(undefined),
	removeSearchFilterByKey: warnAboutEmptyScopeMutation(undefined),
	setSearchFilters: warnAboutEmptyScopeMutation(undefined),
};

function warnAboutEmptyScopeMutation<T>(returnValue: T) {
	return (...args: unknown[]) => {
		logger.warn(
			"You are trying to modify search filters without a matching active scope. This is likely a bug. Make sure your scope is active before calling this function.",
			...args,
		);
		return returnValue;
	};
}

export interface UseRegisterLocalSearchScopeOptions
	extends Partial<UseLocalSearchFiltersOptions> {
	pause?: boolean;
}

export function useRegisterLocalSearchScope(
	scope?: LocalSearchScope,
	{
		pause = false,
		shouldUpdateHistoryOnChange = false,
	}: UseRegisterLocalSearchScopeOptions = {},
) {
	const { setActiveSearchScope } = useContext(LocalSearchContext);

	useEffect(() => {
		if (!pause) {
			setActiveSearchScope(scope, { shouldUpdateHistoryOnChange });
		}
	}, [scope, setActiveSearchScope, pause, shouldUpdateHistoryOnChange]);

	useEffect(
		() => () => {
			if (!pause) {
				setActiveSearchScope(undefined, { shouldUpdateHistoryOnChange });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
}

/**
 * An empty search scope enables search related features (e.g. filtering, restore filters)
 * without the requiring local search.
 */
export function useEmptySearchScope(scopeKey: LocalSearchScopeKey) {
	return useLocalSearchScope(
		() => ({ scopeKey, queryDocument: null }),
		[scopeKey],
	);
}

interface UseDateFilterProps {
	defaultDate: string;
	defaultMode?: DateFilterMode;
	path: string;
	dateType?: DateValueType;
	scopeKey: LocalSearchScopeKey;
}

export function useDateFilter({
	scopeKey,
	defaultDate,
	defaultMode = "at",
	path,
	dateType = "date",
}: UseDateFilterProps) {
	const { addSearchFilters, activeSearchScopeKey, findFilterByKey } =
		useLocalSearchFilters(scopeKey);

	const dateFilter = findFilterByKey(DATE_FILTER_KEY, "Value");

	const mode = dateFilter?.operator
		? dateModesByOperator[dateFilter.operator as DateModeOperator] ??
		  defaultMode
		: defaultMode;

	const filterValue =
		dateFilter?.value && typeof dateFilter.value === "string"
			? dateFilter.value
			: toFilterValue(defaultDate, mode, dateType);

	const date = fromFilterValue(filterValue, dateType);

	const setDate = (newDate: string) => {
		const newFilterValue = toFilterValue(newDate, mode, dateType);
		addSearchFilters([makeDateFilter(path, newFilterValue, mode)]);
	};

	const setMode = (newMode: DateFilterMode) => {
		const newFilterValue = toFilterValue(date, newMode, dateType);
		addSearchFilters([makeDateFilter(path, newFilterValue, newMode)]);
	};

	useEffect(
		function initializeDateFilter() {
			if (
				dateFilter ||
				!activeSearchScopeKey ||
				activeSearchScopeKey !== scopeKey
			) {
				return;
			}
			addSearchFilters([makeDateFilter(path, filterValue, mode)]);
		},
		[
			addSearchFilters,
			dateFilter,
			filterValue,
			mode,
			path,
			scopeKey,
			activeSearchScopeKey,
		],
	);

	return {
		mode,
		date,
		setMode,
		setDate,
	};
}

function toFilterValue(
	date: string,
	mode: DateFilterMode,
	dateType: DateValueType,
) {
	if (dateType === "date") {
		return date;
	}

	switch (mode) {
		case "from":
			return LocalDate.fromDateString(date).startOf("day").toUtcString();
		case "to":
			return LocalDate.fromDateString(date).endOf("day").toUtcString();
		default:
			// Proper support for "at" mode requires extra work as it needs to represented as 2 filters. { and: [{ gte: ""}, {lte: ""}]}
			// If you need it, consider `useDateRangeFilter`
			return date;
	}
}

function fromFilterValue(filterValue: string, dateType: DateValueType) {
	if (dateType === "timestamp") {
		return LocalDate.fromUtcString(filterValue).toDateString();
	}
	return filterValue;
}

type DateModeOperator = "_eq" | "_gte" | "_lte";

const operatorsByDateMode: Record<DateFilterMode, DateModeOperator> = {
	at: "_eq",
	from: "_gte",
	to: "_lte",
};

const dateModesByOperator: Record<DateModeOperator, DateFilterMode> = {
	_eq: "at",
	_gte: "from",
	_lte: "to",
};

function makeDateFilter(path: string, date: string, mode: DateFilterMode) {
	return makeLocalSearchValueFilter(path, date, {
		customFilterKey: DATE_FILTER_KEY,
		visible: false,
		operator: operatorsByDateMode[mode],
	});
}

interface UseDateRangeFilterProps {
	scopeKey: LocalSearchScopeKey;
	path: string;
	defaultValue: { start: string; end: string };
	dateType?: DateValueType;
}

export function useDateRangeFilter({
	scopeKey,
	path,
	defaultValue,
	dateType = "date",
}: UseDateRangeFilterProps) {
	const { addSearchFilters, activeSearchScopeKey, findFilterByKey } =
		useLocalSearchFilters(scopeKey);

	const filter = findFilterByKey(DATE_FILTER_KEY, "Range");

	const filterValues = useMemo(
		() => ({
			from: filter?.from ?? toFilterValue(defaultValue.start, "from", dateType),
			to: filter?.to ?? toFilterValue(defaultValue.end, "to", dateType),
		}),
		[dateType, defaultValue.end, defaultValue.start, filter?.from, filter?.to],
	);

	const dateRange = useMemo(
		() => ({
			start: fromFilterValue(filterValues.from, dateType),
			end: fromFilterValue(filterValues.to, dateType),
		}),
		[dateType, filterValues.from, filterValues.to],
	);

	const setDateRange = ({
		start,
		end,
	}: {
		start: string | undefined;
		end: string | undefined;
	}) => {
		addSearchFilters([
			makeDateRangeFilter(path, {
				from: start && toFilterValue(start, "from", dateType),
				to: end && toFilterValue(end, "to", dateType),
			}),
		]);
	};

	useEffect(
		function initializeDateFilter() {
			if (
				filter ||
				!activeSearchScopeKey ||
				activeSearchScopeKey !== scopeKey
			) {
				return;
			}

			addSearchFilters([makeDateRangeFilter(path, filterValues)]);
		},
		[
			activeSearchScopeKey,
			addSearchFilters,
			filter,
			filterValues,
			path,
			scopeKey,
		],
	);

	return {
		dateRange,
		setDateRange,
	};
}

function makeDateRangeFilter(
	path: string,
	{ from, to }: Pick<LocalSearchRangeFilter, "from" | "to">,
) {
	return makeLocalSearchRangeFilter({
		path,
		visible: false,
		from,
		to,
		key: DATE_FILTER_KEY,
		fromOperator: "_gte",
		toOperator: "_lte",
	});
}
