/* eslint no-constant-condition: ["error", { "checkLoops": false }] */
import { getTerminationString, parse } from "@app/modules/hardware/parser";
import type {
	USBHardwareDevice,
	USBMessage,
	VendorProductId,
} from "@app/modules/hardware/types";
import type { USBDeviceConfiguration } from "@app/modules/hardware/usb-configurations";
import { logger } from "@app/modules/logger/logger";

export function getVendorProductId(device: USBDevice): VendorProductId {
	return `${device.vendorId}:${device.productId}`;
}

export function getDeviceKey(device: USBDevice) {
	return `${getVendorProductId(device)}:${device.serialNumber}`;
}

export function createUSBHardwareDevice(device: USBDevice): USBHardwareDevice {
	return {
		type: "usb",
		device,
		key: getDeviceKey(device),
		vendorProductId: getVendorProductId(device),
	};
}

export async function pairUSBDevice(): Promise<USBHardwareDevice | undefined> {
	try {
		const device = await navigator.usb?.requestDevice({ filters: [] });
		if (device) {
			return createUSBHardwareDevice(device);
		}
	} catch (exception) {
		logger.info("HARDWARE", "user cancelled device pairing.");
	}
	return undefined;
}

export async function getPairedUSBDevices(): Promise<USBHardwareDevice[]> {
	const devices = (await navigator.usb?.getDevices()) ?? [];
	return devices.map(createUSBHardwareDevice);
}

export async function claimInterface(
	{ key, device }: USBHardwareDevice,
	config: USBDeviceConfiguration,
) {
	try {
		await device.open();
		await device.selectConfiguration(config.configurationNumber);
		await device.claimInterface(config.interfaceNumber);
		logger.info("HARDWARE", "device claimed", key);
	} catch (error) {
		logger.error(error, "HARDWARE: device could not be claimed", {
			deviceKey: key,
		});
	}
}

export async function releaseInterface(
	{ key, device }: USBHardwareDevice,
	config: USBDeviceConfiguration,
) {
	try {
		await device.releaseInterface(config.interfaceNumber);
		await device.close();
		logger.info("HARDWARE", "device released", key);
	} catch (error) {
		logger.error(error, "HARDWARE: device could not be released", {
			deviceKey: key,
		});
	}
}

async function readFromDevice(
	{ device }: USBHardwareDevice,
	config: USBDeviceConfiguration,
) {
	// we get the data byte by byte despite the buffer size of 64 byte
	const result = await device.transferIn(config.transferInEndpoint, 64);
	const decoder = new TextDecoder();
	const message = decoder.decode(result.data);
	return message;
}

export function listenForUSBMessages(
	device: USBHardwareDevice,
	config: USBDeviceConfiguration,
	onMessage: (message: USBMessage) => void,
) {
	let keepReading = true;
	const termination = getTerminationString(config.instructionSet);

	const readContinuously = async () => {
		while (keepReading) {
			try {
				let message = "";
				while (true) {
					// eslint-disable-next-line no-await-in-loop
					const next = await readFromDevice(device, config);
					const newMessage = message + next;
					if (newMessage.endsWith(termination)) {
						message = newMessage.substring(
							0,
							newMessage.length - termination.length,
						);
						break;
					} else {
						message = newMessage;
					}
				}
				if (message) {
					const parsed = parse(config.instructionSet, message);
					onMessage({
						raw: message,
						device,
						type: "usb",
						parsed,
					});
				}
			} catch (error) {
				logger.error(error, "HARDWARE: stopped listening due to error", {
					deviceKey: device.key,
				});
				break;
			}
		}
	};

	const readPromise = readContinuously();

	const stopReading = async () => {
		keepReading = false;
		await releaseInterface(device, config);
		await readPromise;
	};

	return stopReading;
}
