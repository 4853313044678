import type {
	HardwareInstruction,
	ParsedMessage,
} from "@app/modules/hardware/types";

const TERMINATION_STRING = String.fromCharCode(10);

const instrRegex = /\+ +(\d+[.]\d+)+ +([\w]+)/;

// The scale we have is super basic and only supports sending weight.
function parse(instr: HardwareInstruction): ParsedMessage {
	const match = instr.match(instrRegex);
	if (!match) {
		return { type: "unknown" };
	}
	const [_, weight, unit] = match;
	if (!weight || !unit || unit !== "kg") {
		return { type: "unknown" };
	}
	return {
		type: "weight",
		unit,
		weight,
	};
}

export const pesola = {
	parse,
	TERMINATION_STRING,
};
