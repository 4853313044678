import type { Currencies_Enum } from "@app/modules/graphql-api-types.generated";

export interface CurrencyFormat {
	label: string;
	decimalDigits: number;
}

const formats: Record<Currencies_Enum, CurrencyFormat> = {
	CHF: {
		label: "CHF",
		decimalDigits: 2,
	},
	EUR: {
		label: "€",
		decimalDigits: 2,
	},
};

export function getCurrencyFormat(currency: Currencies_Enum) {
	return formats[currency];
}
