export type DeviceType = "usb" | "serial";
export type VendorProductId = `${string}:${string}`;
export type InstructionSet =
	| "SICS"
	| "pesola-tmp"
	| "datalogic"
	| "SysTec"
	| "bizerbast"
	| "unknown";
export type HardwareInstruction = string;
export type HardwareCommand = "get-weight" | "set-to-zero" | "reset" | "tare";

export interface UnknownMessage {
	type: "unknown";
}

export const unknownMessage: UnknownMessage = {
	type: "unknown",
};

export interface ErrorMessage {
	type: "error";
	code: string;
	msg: string;
}

export interface WeightMessage {
	type: "weight";
	weight: string;
	unit: "kg";
}

export interface BarcodeMessage {
	type: "barcode";
	code: string;
}

export type ParsedMessage = UnknownMessage | WeightMessage | BarcodeMessage;

export type ParsedMessageType = ParsedMessage["type"];

export interface USBHardwareDevice {
	type: "usb";
	key: string;
	vendorProductId: VendorProductId;
	device: USBDevice;
}

export interface SerialDevice {
	type: "serial";
	key: string;
	vendorProductId: VendorProductId;
	port: SerialPort;
}

export type HardwareDevice = USBHardwareDevice | SerialDevice;

export interface USBMessage {
	type: "usb";
	raw: string;
	device: USBHardwareDevice;
	parsed: ParsedMessage;
}

export interface SerialMessage {
	type: "serial";
	raw: string;
	device: SerialDevice;
	parsed: ParsedMessage;
}

export type HardwareMessage = USBMessage | SerialMessage;
