import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPendenzen({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.707 17.293a1 1 0 01.083 1.32l-.083.094-3 3a1 1 0 01-1.32.083l-.094-.083-2-2a1 1 0 011.32-1.497l.094.083L3 19.585l2.293-2.292a1 1 0 011.414 0zM23 19a1 1 0 01.117 1.993L23 21H11a1 1 0 01-.117-1.993L11 19h12zM6.707 10.293a1 1 0 01.083 1.32l-.083.094-3 3a1 1 0 01-1.32.083l-.094-.083-2-2a1 1 0 011.32-1.497l.094.083L3 12.585l2.293-2.292a1 1 0 011.414 0zM23 12a1 1 0 01.117 1.993L23 14H11a1 1 0 01-.117-1.993L11 12h12zM6.707 3.293a1 1 0 01.083 1.32l-.083.094-3 3a1 1 0 01-1.32.083l-.094-.083-2-2a1 1 0 011.32-1.497l.094.083L3 5.585l2.293-2.292a1 1 0 011.414 0zM23 5a1 1 0 01.117 1.993L23 7H11a1 1 0 01-.117-1.993L11 5h12z"
			/>
		</svg>
	);
}

const MemoSvgPendenzen = React.memo(SvgPendenzen);
export default MemoSvgPendenzen;
