import { organizeBy, organizeById } from "@app/modules/utils/organizeBy";
import { useMemo } from "react";

/**
 * These computations are memoized by referential equality.
 * Passing down a new instance of the same list on every
 * call will cause negative performance impact.
 */

/**
 * Returns normalized result of the given list by id.
 */
export function useNormalizedResult<T extends { id: string }>(items: T[]) {
	return useMemo(
		() => ({
			all: items,
			byId: organizeById(items),
		}),
		[items],
	);
}

/**
 * Returns normalized result of the given list by a custom key.
 */
export function useNormalizedResultByKey<
	K extends string,
	T extends { [key in K]: string },
>(key: K, items: T[]) {
	return useMemo(
		() => ({
			all: items,
			byKey: organizeBy(items, key),
		}),
		[items, key],
	);
}
