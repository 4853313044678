import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgLocked({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2.25c-1.01 0-1.97.429-2.668 1.174A4.061 4.061 0 008.25 6.2V10H7.1c-1.16 0-2.1.87-2.1 1.941v7.118C5 20.13 5.94 21 7.1 21h9.8c1.16 0 2.1-.87 2.1-1.941V11.94C19 10.87 18.06 10 16.9 10h-1.15V6.2a4.061 4.061 0 00-1.082-2.776A3.658 3.658 0 0012 2.25zM14.25 10V6.2c0-.665-.248-1.293-.676-1.75A2.159 2.159 0 0012 3.75c-.58 0-1.148.246-1.574.7A2.562 2.562 0 009.75 6.2V10h4.5z"
			/>
		</svg>
	);
}

const MemoSvgLocked = React.memo(SvgLocked);
export default MemoSvgLocked;
