import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOptions({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M3 12a2 2 0 104 0 2 2 0 00-4 0zM12 14a2 2 0 110-4 2 2 0 010 4zM19 14a2 2 0 110-4 2 2 0 010 4z" />
		</svg>
	);
}

const MemoSvgOptions = React.memo(SvgOptions);
export default MemoSvgOptions;
