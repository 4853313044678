import type { UiActionTarget } from "@app/modules/ui-actions/types";
import { storage } from "@app/modules/utils/local-storage";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export interface HardwareUserConfig {
	productKey?: string;
	targetAssignment?: UiActionTarget;
}

type HardwareUserConfigMap = Dictionary<string, HardwareUserConfig>;

const HardwareUserConfigContext = createContext({
	getConfig: (_deviceKey: string) =>
		undefined as HardwareUserConfig | undefined,
	updateConfig: (
		_deviceKey: string,
		_config: Partial<HardwareUserConfig>,
	) => {},
	clearConfig: () => {},
	findDeviceKeyByAssignment: (_assignment: UiActionTarget) =>
		undefined as string | undefined,
});

export interface DeviceProductMappingProviderProps {
	children?: ReactNode;
}

export function HardwareUserConfigProvider({
	children,
}: DeviceProductMappingProviderProps) {
	const [configs, setConfigs] =
		useState<HardwareUserConfigMap>(restoreFromStorage);

	useEffect(() => {
		saveToStorage(configs);
	}, [configs]);

	const value = useMemo(
		() => ({
			getConfig: (deviceKey: string) => configs[deviceKey],
			updateConfig: (deviceKey: string, config: HardwareUserConfig) => {
				setConfigs((cfgs) => ({
					...cfgs,
					[deviceKey]: { ...cfgs[deviceKey], ...config },
				}));
			},
			clearConfig: clearStorage,
			findDeviceKeyByAssignment: (assignment: UiActionTarget) =>
				Object.entries(configs).find(
					([, cfg]) => cfg?.targetAssignment === assignment,
				)?.[0],
		}),
		[configs],
	);

	return (
		<HardwareUserConfigContext.Provider value={value}>
			{children}
		</HardwareUserConfigContext.Provider>
	);
}

const STORAGE_KEY = "hardware-user-config";

function restoreFromStorage(): HardwareUserConfigMap {
	const restored = storage.get<HardwareUserConfigMap>(STORAGE_KEY);
	return restored ?? {};
}

function saveToStorage(map: HardwareUserConfigMap) {
	storage.set(STORAGE_KEY, map);
}

function clearStorage() {
	storage.remove(STORAGE_KEY);
}

export function useHardwareUserConfig() {
	return useContext(HardwareUserConfigContext);
}
