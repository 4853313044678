import { useAppState } from "@app/modules/app-state/context";
import { logger } from "@app/modules/logger/logger";
import type { SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";
import { makeSubject, pipe, subscribe } from "wonka";

// Experimental function to easily create a shared state without the need of a context.
// Inspired by https://formidable.com/blog/2021/stores-no-context-api/
// TODO: extract to a generic, common place.
export function createSharedState<T>(defaultValue: T) {
	const { source, next } = makeSubject<T>();

	const lastValueRef = { current: defaultValue };
	pipe(
		source,
		subscribe((a) => {
			lastValueRef.current = a;
		}),
	);

	return {
		useSharedState: () => {
			const [value, setStateValue] = useState(defaultValue);

			const setValue = useCallback((action: SetStateAction<T>) => {
				const newValue =
					// Why is this `instanceof` workaround necessary here, but not in `local-storage.tsx`?
					// https://github.com/microsoft/TypeScript/issues/37663#issuecomment-759728342
					action instanceof Function ? action(lastValueRef.current) : action;
				next(newValue);
			}, []);

			useEffect(() => {
				const { unsubscribe } = pipe(source, subscribe(setStateValue));
				return unsubscribe;
			}, []);

			return [value, setValue] as const;
		},
	};
}

const sharedPauseState = createSharedState<boolean>(false);

export function useSharedPausedState() {
	return sharedPauseState.useSharedState();
}

// Only pause requests after 3 minutes of inactivity.
const PAUSE_DELAY_IN_MS = 3 * 60 * 1000;

export function usePauseOnVisibilityChangeEffect() {
	const { visibilityState } = useAppState();
	const [_, setIsPaused] = useSharedPausedState();
	useEffect(() => {
		if (visibilityState === "visible") {
			setIsPaused(false);
			return () => {};
		}

		const ref = setTimeout(() => {
			logger.info("Pausing requests due to inactivity.");
			setIsPaused(true);
		}, PAUSE_DELAY_IN_MS);
		return () => {
			clearTimeout(ref);
		};
	}, [setIsPaused, visibilityState]);
}
