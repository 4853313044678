import { Search } from "@app/icons/components";
import { Icon } from "@app/icons/Icon";
import { useAppState } from "@app/modules/app-state/public/context";
import { useQuery } from "@app/modules/graphql/queries";
import { NoopDocument } from "@app/modules/graphql/queries/noop.query";
import { Hotkey } from "@app/modules/hotkey/Hotkey";
import { useTranslate } from "@app/modules/i18n/context";
import {
	OptionItem,
	Options,
	useOptionsPositioning,
} from "@app/modules/input-fields/Options";
import { TagItem } from "@app/modules/layout/TagItem";
import { useNavigate } from "@app/modules/routes/routes";
import { GlobalSearchDocument } from "@app/modules/search/global-search.query.generated";
import { LocalSearchContext } from "@app/modules/search/local/LocalSearchProvider";
import type {
	LocalSearchFilter,
	LocalSearchSuggestion,
} from "@app/modules/search/local/types";
import { getLocalSearchFilterKey } from "@app/modules/search/local/useLocalSearchFilters";
import { getSearchIcon } from "@app/modules/search/search-icons";
import type { Suggestion } from "@app/modules/search/suggestion-utils";
import {
	getSuggestionPath,
	responseToSuggestion,
} from "@app/modules/search/suggestion-utils";
import { Caption12Caps } from "@app/modules/typography";
import clsx from "clsx";
import { useCombobox, useMultipleSelection } from "downshift";
import type { ChangeEvent } from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

export interface SearchInputProps {
	className?: string;
	id?: string;
}

function SearchInput({ className, id = "search-input" }: SearchInputProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const { tenant } = useAppState();

	const {
		searchFilters,
		activeSearchScope,
		addSearchFilters,
		removeSearchFilter,
		setSearchFilters,
	} = useContext(LocalSearchContext);

	const isLocalModeAvailable = Boolean(
		activeSearchScope?.queryDocument !== undefined,
	);
	const defaultMode = isLocalModeAvailable ? "local" : "global";
	const [mode, setMode] = useState<"global" | "local">("global");
	const isLocal = isLocalModeAvailable && mode === "local";
	const isGlobal = !isLocalModeAvailable || mode === "global";

	useEffect(() => {
		setMode(defaultMode);
	}, [defaultMode]);

	const [inputValue, setInputValue] = useState("");

	const navigate = useNavigate();

	const globalSuggestions = useGlobalSuggestions(inputValue, {
		pause: isLocal,
	});

	const [localRes] = useQuery({
		query: activeSearchScope?.queryDocument || NoopDocument,
		variables: activeSearchScope?.queryVariables?.(inputValue) || {
			term: `${inputValue}%`,
			search: inputValue,
			searchAsBigint: Number.isNaN(parseInt(inputValue, 10))
				? undefined
				: inputValue,
			tenant,
			// applying filters here would reduce the potential options, but prevent adding OR filters of the same type
		},
		requestPolicy: "cache-and-network",
		pause: isGlobal,
	});

	const scopeKey = activeSearchScope?.scopeKey;

	const lastValidResult = useRef([scopeKey, localRes.data]);

	// if the scopeKey changes, but the result doesn't, we don't consider it a valid result
	const isValidLocalResult =
		scopeKey === lastValidResult.current[0] ||
		localRes.data !== lastValidResult.current[1];

	if (isValidLocalResult) {
		lastValidResult.current = [scopeKey, localRes.data];
	}

	let localSuggestions: LocalSearchSuggestion[] = useMemo(() => [], []);

	if (activeSearchScope?.fields && localRes.data && isValidLocalResult) {
		localSuggestions = activeSearchScope.fields.reduce<LocalSearchSuggestion[]>(
			(acc, field) => {
				if (!localRes.data) {
					return acc;
				}

				const suggestions: LocalSearchSuggestion[] = field.getSuggestions(
					localRes.data,
					inputValue,
				);

				return acc.concat(suggestions);
			},
			[],
		);
	}

	const { getSelectedItemProps, getDropdownProps, selectedItems, activeIndex } =
		useMultipleSelection<LocalSearchFilter>({
			selectedItems: searchFilters,
			onSelectedItemsChange: (changes) => {
				setSearchFilters(changes.selectedItems);
			},
		});

	const t = useTranslate("common");

	const {
		isOpen,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps,
	} = useCombobox<Suggestion | LocalSearchSuggestion>({
		id,
		inputValue,
		defaultHighlightedIndex: 0, // after selection, highlight the first item.
		selectedItem: null,
		items: isGlobal ? globalSuggestions : localSuggestions,
		onStateChange: ({ type, selectedItem }) => {
			switch (type) {
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
				case useCombobox.stateChangeTypes.InputBlur: {
					if (!selectedItem) {
						break;
					}

					setInputValue("");

					if (isLocal) {
						// Without the timeout we end up in an infinite loop.
						// For the infinite loop to happen a few preconditions are required:
						//   - the user uses touch input
						//   - the user presses one of option that is not highlighted
						//   - this problem was not present with the previous version of react-router
						// At this point we suspect a bug in `Downshift` that is triggered by the react-router upgrade.
						// There is a new major version of `Downshift` available. Revisit this part when upgrading.
						setTimeout(() => {
							addSearchFilters([
								(selectedItem as LocalSearchSuggestion).filter,
							]);
						}, 0);
						break;
					}

					const selectedSuggestion: Suggestion = selectedItem as Suggestion;
					const path = getSuggestionPath(selectedSuggestion);
					if (!path) {
						break;
					}

					navigate(path);
					break;
				}
				default:
					break;
			}
		},
		scrollIntoView: (item) => {
			item.scrollIntoView();
		},
	});

	const renderSuggestions = (
		item: Suggestion | LocalSearchSuggestion,
		index: number,
	) => (
		<OptionItem
			testid={item.label}
			key={item.key}
			icon={
				item.icon &&
				(typeof item.icon === "string" ? getSearchIcon(item.icon) : item.icon)
			}
			isHighlighted={highlightedIndex === index}
			domainId={item.domainId}
			tenant={item.tenant}
			tags={item.tags}
			{...getItemProps({ item, index })}
		>
			{item.label}
		</OptionItem>
	);
	const { isFocused, focus, unfocus } = useFocusHandling(activeIndex >= 0);

	const { referenceRef, optionsProps } = useOptionsPositioning({ isOpen });

	return (
		<Hotkey
			action="FOCUS_SEARCH"
			handler={() => inputRef.current?.focus()}
			containerRef={referenceRef}
			className={clsx(
				className,
				"flex flex-nowrap gap-8 flex-1 relative pl-48 bg-white h-40 my-auto",
				isFocused
					? "pr-144 border-brand-700 ring-1 ring-inset ring-offset-brand-700 ring-brand-700 rounded opacity-100"
					: "pr-96 rounded-full bg-opacity-25",
			)}
		>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label
				{...getLabelProps()}
				className="cursor-pointer"
				aria-label="toggle menu"
			>
				<Icon
					icon={Search}
					size="24"
					className={clsx(
						"absolute top-8 left-12 z-10",
						isFocused ? "text-grey-900" : "text-white",
					)}
				/>
			</label>
			<div className="self-center">
				<Hotkey
					className="flex"
					action="TOGGLE_SEARCH_MODE"
					handler={() => {
						if (isLocal) {
							setMode("global");
						} else if (isGlobal && isLocalModeAvailable) {
							setMode("local");
						}
					}}
				>
					<button
						type="button"
						className={clsx(
							toggleButtonCommons.base,
							isGlobal && !isFocused && toggleButtonCommons.active,
							isGlobal && isFocused && toggleButtonCommons.activeFocused,
							// FIXME(Jorge Marques): The button's click handler
							//  does not fire at all when the following line is left uncommented
							!isFocused && !isGlobal && toggleButtonCommons.hidden,
							isFocused && !isGlobal && "text-grey-700",
						)}
						onClick={() => {
							inputRef.current?.focus();
							setMode("global");
						}}
					>
						<Caption12Caps>{t("header.search.global")}</Caption12Caps>
					</button>
					{isLocalModeAvailable && (
						<button
							type="button"
							className={clsx(
								toggleButtonCommons.base,
								isLocal && !isFocused && toggleButtonCommons.active,
								isLocal && isFocused && toggleButtonCommons.activeFocused,
								// FIXME(Jorge Marques): The button's click handler
								//  does not fire at all when the following line is left uncommented
								!isFocused && !isLocal && toggleButtonCommons.hidden,
								isFocused && !isLocal && "text-grey-700",
							)}
							onClick={() => {
								inputRef.current?.focus();
								setMode("local");
							}}
						>
							<Caption12Caps>{t("header.search.local")}</Caption12Caps>
						</button>
					)}
				</Hotkey>
			</div>
			{selectedItems.map((selectedItem, index) => {
				if (!selectedItem.visible) {
					return null;
				}

				return (
					<TagItem
						key={getLocalSearchFilterKey(selectedItem)}
						color={isFocused ? "gray" : "white"}
						icon={selectedItem.icon && getSearchIcon(selectedItem.icon)}
						onCloseClick={(e) => {
							e.stopPropagation();
							removeSearchFilter(selectedItem);
						}}
						className="self-center"
						{...getSelectedItemProps({ selectedItem, index })}
					>
						{selectedItem.label}
					</TagItem>
				);
			})}
			<div className="flex-1 py-8" {...getComboboxProps()}>
				<input
					className={clsx(
						"w-full bg-transparent text-14 font-400 placeholder-grey-500 focus:outline-none text-white focus:text-black",
					)}
					{...getInputProps(
						getDropdownProps({
							ref: inputRef,
							name: "search",
							preventKeyAction: isOpen,
							onChange: ({ target }: ChangeEvent<HTMLInputElement>) => {
								setInputValue(target.value);
							},
							onBlur: unfocus,
							onFocus: focus,
						}),
					)}
				/>
			</div>

			<Options
				{...getMenuProps()}
				{...optionsProps}
				className={isOpen ? "border-t" : ""}
			>
				{isOpen &&
					(isGlobal
						? globalSuggestions.map(renderSuggestions)
						: localSuggestions.map(renderSuggestions))}
			</Options>
		</Hotkey>
	);
}

/* class={ */
const toggleButtonCommons = {
	base: "rounded px-8",
	hidden: "bg-transparent text-white",
	active: "bg-white text-brand-900",
	activeFocused: "bg-grey-700 text-white",
};

/* } */

function useGlobalSuggestions(search: string, { pause }: { pause?: boolean }) {
	const t = useTranslate("common");
	const { tenant } = useAppState();
	const [globalRes] = useQuery({
		query: GlobalSearchDocument,
		variables: { search, tenant: tenant ?? "" },
		requestPolicy: "cache-and-network",
		pause: pause || !tenant || !search,
	});

	const querySuggestions =
		globalRes.data?.global_search
			.map(responseToSuggestion.bind(undefined, t))
			.filter(Boolean) ?? [];

	return querySuggestions;
}

// todo: this logic could be improved with some inspiration from https://github.com/adobe/react-spectrum/blob/main/packages/@react-aria/interactions/src/useFocusWithin.ts#L63-L76
function useFocusHandling(hasActiveItems: boolean) {
	const [isFocused, setFocus] = useState(false);
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
	const hasActiveItemsRef = useRef(hasActiveItems);

	const { focus, unfocus } = useMemo(
		() => ({
			unfocus: () => {
				// We unfocus with a delay.
				// Any focus that happens before the timeout ends will prevent the focus from being set to false.
				// This prevents the search input from flashing when moving focus between search buttons and active filters.
				timeoutRef.current = setTimeout(() => {
					setFocus(false);
				}, 100);
			},
			focus: () => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
				setFocus(true);
			},
		}),
		[],
	);

	// We always use the previous value of `hasActiveItems`.
	// This is because it changes before the input field is focussed when moving from tag items to the input field.
	useEffect(() => {
		hasActiveItemsRef.current = hasActiveItems;
	}, [hasActiveItems]);

	return {
		isFocused: isFocused || hasActiveItemsRef.current,
		focus,
		unfocus,
	};
}

export { SearchInput };
