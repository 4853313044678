import { Plus } from "@app/icons/components/action";
import { Button } from "@app/modules/Button";
import { Card } from "@app/modules/cards/Card";
import type { SupplierRelatedProductsCardProps as SupplierOrderRelatedProductsCardProps } from "@app/modules/cards/card-props";
import {
	InventoryForProductIdsDocument,
	SupplierOrderRelatedProductsDocument,
} from "@app/modules/cards/queries/supplier-order-related-products-card.query.generated";
import {
	DataTable,
	DataTableCell,
	DataTableRow,
} from "@app/modules/data-table/DataTable";
import { DataTablePagination } from "@app/modules/data-table/DataTablePagination";
import { useDataTable } from "@app/modules/data-table/useDataTable";
import { EntityIdTagItemLink } from "@app/modules/entity/EntityIdTagItem";
import { Supplier_Order_States_Enum } from "@app/modules/graphql-api-types.generated";
import { useQuery, useSubscription } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import { ProductQuantityInline } from "@app/modules/product-unit/ProductQuantityInputLegacy";
import { SupplierOrderStateDocument } from "@app/modules/purchasing/supplier-order/queries/supplier-order-state.subscription.generated";
import type { AddSupplierOrderItemAction } from "@app/modules/ui-actions/types";
import { useUiActionsContext } from "@app/modules/ui-actions/UiActionsProvider";
import { useNormalizedResultByKey } from "@app/modules/utils/useNormalizedResult";

export function SupplierOrderRelatedProductsCard({
	supplierOrderId,
	supplierId,
	supplierOrderProductIds,
}: SupplierOrderRelatedProductsCardProps) {
	const t = useTranslate("common");

	const { dispatch } = useUiActionsContext();

	const handleAddProduct = (
		supplierOrderItem: AddSupplierOrderItemAction["payload"],
	) => {
		dispatch({
			type: "add-supplier-order-item",
			payload: { ...supplierOrderItem },
		});
	};

	const { pagination, ...dataTable } = useDataTable({
		name: "so-related-products",
		query: SupplierOrderRelatedProductsDocument,
		queryVariables: {
			supplierId,
			productIdBlacklist: supplierOrderProductIds,
		},
		columns: [
			{
				accessor: "name",
				label: t("cards.supplier-order-related-products.table.head.name"),
				sortable: true,
			},
			{
				accessor: "",
				label: t(
					"cards.supplier-order-related-products.table.head.stock-location",
				),
				sortable: false,
			},
			{
				accessor: "",
				label: t("cards.supplier-order-related-products.table.head.inventory"),
				sortable: false,
				align: "right",
			},
		] as const,
		pageSize: 10,
		hasSort: true,
		initialSort: [{ accessor: "name", direction: "desc" }],
	});
	const productIds = dataTable.visibleRows.map(({ id }) => id);

	const [{ data: inventoryData }] = useQuery({
		query: InventoryForProductIdsDocument,
		variables: { productIds },
		pause: productIds.length < 1,
	});
	const inventoryByProductId = useNormalizedResultByKey(
		"productId",
		inventoryData?.list_inventory_by_location ?? [],
	);

	const [{ data: supplierOrderStateData }] = useSubscription({
		query: SupplierOrderStateDocument,
		variables: { id: supplierOrderId },
	});
	const state = supplierOrderStateData?.supplierOrderByPk?.state;

	const soiByProduct = useNormalizedResultByKey(
		"productId",
		dataTable.visibleRows
			.flatMap(({ supplierOrderItems }) => supplierOrderItems)
			.filter(Boolean),
	);

	if (dataTable.visibleRows.length === 0) {
		return null;
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title>
					{t("cards.supplier-order-related-products.titles.products")}
				</Card.Title>
				{pagination && (
					<div className="ml-auto">
						<DataTablePagination
							variant="compact"
							totalRows={dataTable.totalRows}
							{...pagination}
						/>
					</div>
				)}
			</Card.Header>
			<Card.Body>
				<DataTable
					className="border-t border-grey-200"
					instance={dataTable}
					variant="compact"
				>
					{dataTable.visibleRows.map((row) => {
						const {
							id,
							name,
							domainId,
							productStockLocations,
							productSuppliers,
						} = row;
						const lastSOI = soiByProduct?.byKey?.[id];
						const inventory = inventoryByProductId?.byKey?.[id];

						return (
							<DataTableRow key={id}>
								<DataTableCell>
									<div className="flex flex-col text-caption-12 font-500">
										{name}
										<EntityIdTagItemLink
											className="w-min"
											entity={{ id, domainId }}
											to="product-data.products.id"
										/>
									</div>
								</DataTableCell>
								<DataTableCell>
									<div className="text-caption-12">
										{productStockLocations[0]?.stockLocation.name}
									</div>
								</DataTableCell>
								<DataTableCell>
									<div className="text-right">
										{inventory?.availableStorageQuantity && (
											<ProductQuantityInline
												className="text-caption-12"
												value={inventory?.availableStorageQuantity ?? "0"}
												unit={inventory?.storageUnit}
											/>
										)}
										{!inventory?.availableStorageQuantity && "-"}
									</div>
									<div className="text-right">
										{inventory?.availableMonetaryQuantity && (
											<ProductQuantityInline
												className="text-caption-12"
												value={inventory?.availableMonetaryQuantity ?? "0"}
												unit={inventory?.monetaryUnit}
											/>
										)}
										{!inventory?.availableMonetaryQuantity && "-"}
									</div>
								</DataTableCell>
								<DataTableCell>
									{state === Supplier_Order_States_Enum.Open && (
										<Button
											icon={Plus}
											variant="tertiary-grey"
											onClick={() =>
												handleAddProduct({
													orderUnitId:
														lastSOI?.orderUnit?.id ??
														productSuppliers?.[0]?.orderUnit?.id ??
														"",
													purchasingUnitId:
														lastSOI?.purchasingUnit?.id ??
														productSuppliers?.[0]?.purchasingUnit?.id ??
														"",
													purchasingPricePerUnit:
														lastSOI?.purchasingPricePerUnit ?? "0",
													productId: id,
													quantity: "0",
													parentSupplierOrderItemId: null,
												})
											}
										/>
									)}
								</DataTableCell>
							</DataTableRow>
						);
					})}
				</DataTable>
			</Card.Body>
		</Card>
	);
}
