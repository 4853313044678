/* eslint no-constant-condition: ["error", { "checkLoops": false }] */
import {
	compile,
	getTerminationString,
	parse,
} from "@app/modules/hardware/parser";
import type { SerialDeviceConfiguration } from "@app/modules/hardware/serial-configuration";
import type {
	HardwareCommand,
	SerialDevice,
	SerialMessage,
	VendorProductId,
} from "@app/modules/hardware/types";
import { logger } from "@app/modules/logger/logger";

export function getVendorProductId(port: SerialPort): VendorProductId {
	const { usbProductId, usbVendorId } = port.getInfo();
	// this is probably not enough to uniquely identify the device.
	return `${usbVendorId}:${usbProductId}`;
}

export function createSerialDevice(port: SerialPort): SerialDevice {
	return {
		type: "serial",
		port,
		key: getVendorProductId(port),
		vendorProductId: getVendorProductId(port),
	};
}

export async function pairSerialDevice(): Promise<SerialDevice | undefined> {
	try {
		const port = await navigator.serial?.requestPort({ filters: [] });
		if (port) {
			return createSerialDevice(port);
		}
	} catch (exception) {
		logger.info("HARDWARE", "user cancelled serial device pairing.");
	}
	return undefined;
}

export async function getPairedSerialDevices(): Promise<SerialDevice[]> {
	const ports = (await navigator.serial?.getPorts()) ?? [];
	return ports.map(createSerialDevice);
}

export function listenForSerialMessages(
	device: SerialDevice,
	config: SerialDeviceConfiguration,
	onMessage: (message: SerialMessage) => void,
) {
	const { port, key } = device;
	if (port.readable) {
		logger.info("HARDWARE", "already listening to serial device", key);
		return () => {};
	}

	logger.info("HARDWARE", "started listening to serial device", key);

	let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
	let keepReading = true;
	const termination = getTerminationString(config.instructionSet);

	const readContinuously = async () => {
		try {
			await port.open(config.options);
		} catch (error) {
			logger.error(error, "HARDWARE: could not open port", { deviceKey: key });
		}
		while (port.readable && keepReading) {
			if (!port.readable.locked) {
				reader = port.readable.getReader();
			}
			if (reader) {
				try {
					while (true) {
						let instruction = "";
						let isDone = false;
						while (true) {
							// eslint-disable-next-line no-await-in-loop
							const { value, done } = await reader.read();
							if (done) {
								isDone = true;
								break;
							}
							const decoder = new TextDecoder();
							const next = decoder.decode(value);
							const newMessage = instruction + next;

							if (newMessage.endsWith(termination)) {
								instruction = newMessage.substring(
									0,
									newMessage.length - termination.length,
								);
								break;
							} else {
								instruction = newMessage;
							}
						}
						if (instruction) {
							const parsed = parse(config.instructionSet, instruction);
							onMessage({ device, type: "serial", raw: instruction, parsed });
						}
						if (isDone) {
							break;
						}
					}
				} catch (error) {
					logger.error(
						error,
						"HARDWARE: error while reading from serial port",
						{ deviceKey: key },
					);
				} finally {
					// Allow the serial port to be closed later.
					reader?.releaseLock();
				}
			}
		}
		if (port.readable) {
			logger.info("HARDWARE", "port closed", key);
			await port.close();
		}
	};

	const readPromise = readContinuously();

	const stopReading = async () => {
		keepReading = false;
		reader?.cancel();
		await readPromise;
		logger.info("HARDWARE", "stopped listening to serial device", key);
	};

	return stopReading;
}

export async function write(
	{ key, port }: SerialDevice,
	config: SerialDeviceConfiguration,
	cmd: HardwareCommand,
) {
	const writer = port.writable?.getWriter();
	const encoder = new TextEncoder();
	if (writer) {
		try {
			const cmdString = compile(config.instructionSet, cmd);
			await writer.write(encoder.encode(cmdString));
		} catch (error) {
			logger.error(error, "HARDWARE: error while reading from serial port", {
				deviceKey: key,
			});
		} finally {
			writer.releaseLock();
		}
	}
}
