import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgProduktgroup({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 16.834c-.356 0-.712-.095-1.029-.285l-4-2.4A2 2 0 013 12.434V7.566a2 2 0 01.971-1.715l4-2.4a2 2 0 012.058 0l4 2.4A2 2 0 0115 7.566V9h4a2 2 0 012 2v8a2 2 0 01-2 2h-8a2 2 0 01-2-2v-2.166zm2-.868V19h8v-8h-4v1.434a2 2 0 01-.971 1.715L11 15.966zm-2-1.132l4-2.4V7.566l-4-2.4-4 2.4v4.868l4 2.4z"
			/>
		</svg>
	);
}

const MemoSvgProduktgroup = React.memo(SvgProduktgroup);
export default MemoSvgProduktgroup;
