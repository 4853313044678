import { useAppState } from "@app/modules/app-state/context";
import { Languages_Enum } from "@app/modules/graphql-api-types.generated";
import { languages } from "@app/modules/graphql/sorted-enums";

export function useTenantLanguages() {
	const { tenantDoc } = useAppState();

	const mainLanguage = tenantDoc?.mainLanguage ?? Languages_Enum.DeCh;
	const sortedLanguages = [
		mainLanguage,
		...languages.filter(
			(lang) => lang !== mainLanguage && tenantDoc?.languages.includes(lang),
		),
	];

	return {
		mainLanguage: tenantDoc?.mainLanguage ?? Languages_Enum.DeCh,
		languages: sortedLanguages,
	};
}

const languageCodes: Record<Languages_Enum, string> = {
	de_CH: "DE",
	en: "EN",
	fr_CH: "FR",
	it_CH: "IT",
};

export function langToCode(language: Languages_Enum) {
	return languageCodes[language];
}
