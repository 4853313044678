import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgLocation({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 14a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 100-14 7 7 0 000 14z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 15a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1z"
			/>
		</svg>
	);
}

const MemoSvgLocation = React.memo(SvgLocation);
export default MemoSvgLocation;
