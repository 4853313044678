import { Trash } from "@app/icons/components/action";
import type { ButtonProps } from "@app/modules/Button";
import { Button } from "@app/modules/Button";
import { useConfirmationDialog } from "@app/modules/confirmation-dialog/confirmation-dialog-provider";
import type { MouseEventHandler } from "react";
import React from "react";

export interface DestructiveButtonProps
	extends Omit<ButtonProps, "variant" | "icon" | "iconSize"> {
	skipConfirmation?: boolean;
	confirmButtonLabel?: string;
	description?: string;
}

export function DestructiveButton({
	skipConfirmation,
	onClick,
	confirmButtonLabel,
	description = "",
	...props
}: DestructiveButtonProps) {
	const handleClick = useDestructiveClickHandler({
		onClick,
		skipConfirmation,
		confirmButtonLabel,
		description,
	});

	return (
		<Button
			variant="destructive"
			icon={Trash}
			{...props}
			onClick={handleClick}
		/>
	);
}

interface UseDestructiveClickHandlerOptions {
	skipConfirmation?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	confirmButtonLabel?: string;
	description?: string;
}

export function useDestructiveClickHandler({
	skipConfirmation = false,
	onClick,
	confirmButtonLabel,
	description = "",
}: UseDestructiveClickHandlerOptions) {
	const { requireConfirmation } = useConfirmationDialog();

	const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
		if (skipConfirmation) {
			onClick?.(event);
		} else {
			requireConfirmation({
				variant: "destructive",
				onConfirm: onClick,
				confirmButtonLabel,
				description,
			})();
		}
	};

	return handleClick;
}
