import { UserGroup } from "@app/icons/components/nav";
import { Card } from "@app/modules/cards/Card";
import type { CustomerCardProps } from "@app/modules/cards/card-props";
import { CustomerCardDocument } from "@app/modules/cards/queries/customer-card.query.generated";
import { ContactDetails } from "@app/modules/contact/ContactDetails";
import { useQuery } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import { Textarea } from "@app/modules/input-fields/Textarea";
import { Body14 } from "@app/modules/typography";

export function CustomerCard({ customerId }: CustomerCardProps) {
	const t = useTranslate("common");
	const [{ data }] = useQuery({
		query: CustomerCardDocument,
		variables: { customerId },
		pause: !customerId,
	});
	const customer = data?.customerByPk;

	if (!customer) {
		return null;
	}

	const {
		name,
		billingAddress,
		shippingAddress,
		useDeliveryAddressForBilling,
		contacts,
	} = customer;

	return (
		<Card>
			<Card.Header>
				<Card.Icon icon={UserGroup} />
				<Card.Title>{name}</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Content className="grid grid-cols-2">
					<div className="grid gap-16">
						{shippingAddress?.id && (
							<div className="flex flex-col text-grey-900">
								<Body14 className="font-500">
									{t("cards.customer.titles.shipping-address")}
								</Body14>
								<Body14 className="font-400">{shippingAddress?.street1}</Body14>
								<Body14 className="font-400">{shippingAddress?.street2}</Body14>
								<Body14 className="font-400">
									{shippingAddress?.zip} {shippingAddress?.city}
								</Body14>
							</div>
						)}
						<div className="flex flex-col text-grey-900">
							<Body14 className="font-500">
								{t("cards.customer.titles.billing-address")}
							</Body14>
							{useDeliveryAddressForBilling && (
								<Body14 className="font-400">
									{t("cards.customer.titles.use-delivery-address")}
								</Body14>
							)}
							{!useDeliveryAddressForBilling && billingAddress?.id && (
								<>
									<Body14 className="font-400">
										{billingAddress?.street1}
									</Body14>
									<Body14 className="font-400">
										{billingAddress?.street2}
									</Body14>
									<Body14 className="font-400">
										{billingAddress?.zip} {billingAddress?.city}
									</Body14>
								</>
							)}
						</div>
					</div>
					<div className="grid gap-16">
						{contacts.map((contact) => (
							<ContactDetails
								key={contact.id}
								variant="compact"
								contact={contact}
							/>
						))}
					</div>
				</Card.Content>
				{customer.notes && (
					<Card.Content>
						<Textarea
							label={t("cards.customer.titles.customer-notes")}
							readOnly
							rows={3}
							value={customer.notes}
						/>
					</Card.Content>
				)}
			</Card.Body>
		</Card>
	);
}
