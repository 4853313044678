import { Prev, Selected, Trash } from "@app/icons/components/action";
import type { ButtonProps } from "@app/modules/Button";
import { Button } from "@app/modules/Button";
import { useTranslate } from "@app/modules/i18n/context";
import { Modal } from "@app/modules/layout/Modal";
import { Body14, Header18 } from "@app/modules/typography";
import type { MouseEventHandler, ReactNode } from "react";
import React from "react";

export type ConfirmationDialogVariant = "default" | "destructive" | "block";

export interface ConfirmationDialogProps {
	isOpen: boolean;
	variant?: ConfirmationDialogVariant;
	onConfirm?: MouseEventHandler<HTMLButtonElement>;
	onCancel?: () => void;
	title?: ReactNode;
	description?: ReactNode;
	confirmButtonLabel?: ReactNode;
	cancelButtonLabel?: ReactNode;
	isTouch?: boolean;
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
	const t = useTranslate("common");

	const { variant = "default" } = props;
	const cancelVariantProps = cancelPropsVariants[variant];
	const confirmVariantProps = confirmPropsVariants[variant];
	const confirmVariantLabel = confirmLabelVariants[variant] ?? "";

	const {
		isOpen,
		onConfirm = () => {},
		onCancel = () => {},
		title = t("actions.ask-confirmation"),
		description,
		confirmButtonLabel = t(confirmVariantLabel),
		cancelButtonLabel = t("actions.cancel"),
	} = props;

	const hasConfirm = confirmVariantProps?.variant;

	return (
		<Modal isOpen={isOpen} onCloseRequest={onCancel} hasCloseButton>
			<Header18 className="text-grey-900 font-600 pb-24">{title}</Header18>

			<Body14 className="text-grey-800 font-500">{description}</Body14>

			<div className="mt-32 grid grid-flow-col auto-cols-auto gap-24">
				<Button
					{...cancelVariantProps}
					isTouch
					onClick={onCancel}
					testid="ConfirmationDialog-cancel"
				>
					{cancelButtonLabel}
				</Button>
				{hasConfirm && (
					<Button
						{...confirmVariantProps}
						isTouch
						onClick={onConfirm}
						testid="ConfirmationDialog-confirm"
					>
						{confirmButtonLabel}
					</Button>
				)}
			</div>
		</Modal>
	);
}

const cancelPropsVariants: Record<ConfirmationDialogVariant, ButtonProps> = {
	default: {
		variant: "secondary",
		icon: Prev,
	},
	destructive: {
		variant: "primary",
		icon: Prev,
	},
	block: {
		variant: "primary",
		icon: Prev,
	},
};

const confirmPropsVariants: Partial<
	Record<ConfirmationDialogVariant, ButtonProps>
> = {
	default: {
		variant: "primary",
		icon: Selected,
	},
	destructive: {
		variant: "destructive",
		icon: Trash,
	},
};

const confirmLabelVariants: Partial<Record<ConfirmationDialogVariant, string>> =
	{
		default: "actions.confirm",
		destructive: "actions.delete",
	};
