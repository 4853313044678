import { useAppState } from "@app/modules/app-state/context";
import { injectTenantFilter } from "@app/modules/entity/entity-utils";
import { useQuery } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import type { I18nValueGetter } from "@app/modules/i18n/i18n-utils";
import { useI18nValueGetter } from "@app/modules/i18n/i18n-utils";
import type { TranslateFunction } from "@app/modules/i18n/types";
import type {
	ComboboxItem,
	ComboboxProps,
} from "@app/modules/input-fields/Combobox";
import { Combobox } from "@app/modules/input-fields/Combobox";
import { useDebounceDeepCompare } from "@app/modules/utils/debounce";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import type { ForwardedRef } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import type { DocumentInput } from "urql";

const DEFAULT_RESULT_LIMIT = 10;
const DEFAULT_RESULT_OFFSET = 0;

type ComboboxQueryVariables = {
	limit: number;
	offset: number;
	search: string;
};

type ComboboxQueryDocument<Entity> = DocumentInput<
	{ [key: string]: unknown; entities?: Entity[] },
	{ [key: string]: unknown } & ComboboxQueryVariables
>;

type ComboboxWrapperProps<Item extends ComboboxItem = ComboboxItem> = Omit<
	ComboboxProps<Item>,
	"items"
> & { showSharedEntities?: boolean };

export interface EntityComboboxProps<
	QueryDocument extends ComboboxQueryDocument<Entity>,
	Entity,
	Item extends ComboboxItem,
> extends ComboboxWrapperProps<Item> {
	queryDocument: QueryDocument;
	queryVariables?:
		| Omit<VariablesOf<QueryDocument>, keyof ComboboxQueryVariables>
		| ((
				variables: Pick<
					VariablesOf<QueryDocument>,
					keyof ComboboxQueryVariables
				>,
		  ) => VariablesOf<QueryDocument>);
	limit?: number;
	getEntities?: (data: ResultOf<QueryDocument>, search: string) => Entity[];
	valueItem?: Item;
	asComboboxItem: (
		entity: Entity,
		utils: AsComboboxItemUtils,
	) => Item | undefined;
	getStaticComboboxItems?: (search: string) => Item[];
	defaultInputValue?: string;
	pause?: boolean;
	showSharedEntities?: boolean;
	// This option can be used for non default queries where the tenant filter
	// cannot be injected automatically and must be applied manually.
	disableTenantFilter?: boolean;
}

export type EntityComboboxWrapperProps<
	Item extends ComboboxItem = ComboboxItem,
> = ComboboxWrapperProps<Item> & {
	defaultInputValue?: string;
	pause?: boolean;
};

export const EntityCombobox = forwardRef(function EntityCombobox<
	QueryDocument extends ComboboxQueryDocument<Entity>,
	Entity,
	Item extends ComboboxItem,
>(
	{
		queryDocument,
		queryVariables,
		getEntities = (data) => data?.entities ?? [],
		asComboboxItem,
		defaultInputValue,
		value,
		inputValue,
		onInputValueChange,
		getStaticComboboxItems,
		// TODO: value item support could be improved.
		// - pause suggestion queries if we have a value item
		// - only request value if necessary. Currently selecting an item will also send a request for the value item
		valueItem,
		pause = false,
		showSharedEntities = false,
		disableTenantFilter = false,
		limit = DEFAULT_RESULT_LIMIT,
		preventFlip,
		...props
	}: EntityComboboxProps<QueryDocument, Entity, Item>,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const { tenant } = useAppState();

	const isControlled = inputValue !== undefined;

	const onInputValueChangeRef = useRef(onInputValueChange);
	onInputValueChangeRef.current = onInputValueChange;

	const [search, setSearch] = useState(defaultInputValue || "");

	useEffect(() => {
		// if the value is reset from the outside we also clear the search/input value.
		// this is used in cases where the form values are reset after submission.
		if (value === "") {
			setSearch("");
			onInputValueChangeRef.current?.("");
		}
	}, [value]);

	const comboboxVariables = {
		limit,
		offset: DEFAULT_RESULT_OFFSET,
		search: isControlled ? inputValue : search,
	};

	const newVariables =
		typeof queryVariables === "function"
			? queryVariables(comboboxVariables)
			: ({
					...comboboxVariables,
					...queryVariables,
			  } as VariablesOf<QueryDocument>);

	const [variables, setVariables] = useState(newVariables);
	const [isPaused, setIsPaused] = useState<boolean>(pause);

	useDebounceDeepCompare(
		() => {
			// We need to set the variables and pause in the same cycle to keep
			// them in sync. If we don't, when pause is set to true the query will still
			// use the old variables.
			setVariables(newVariables);
			setIsPaused(pause);
		},
		// this number can be tweaked further if needed
		150,
		[newVariables, pause],
		{ leading: true },
	);

	const [{ data }] = useQuery<ResultOf<QueryDocument>>({
		query: queryDocument,
		variables:
			showSharedEntities || disableTenantFilter
				? variables
				: injectTenantFilter(variables, tenant),
		pause: isPaused,
	});

	const entities = (data && getEntities(data, search)) || [];
	const utils = useAsComboboxItemUtils();
	const entityItems = entities
		.map((entity) => asComboboxItem(entity, utils))
		.filter(Boolean);
	const items = getStaticComboboxItems
		? getStaticComboboxItems(search).concat(entityItems)
		: entityItems;

	return (
		<Combobox
			ref={ref}
			items={value && valueItem ? [valueItem] : items}
			inputValue={isControlled ? inputValue : search}
			onInputValueChange={isControlled ? onInputValueChange : setSearch}
			value={value}
			preventFlip={preventFlip}
			{...props}
		/>
	);
});

export type AsComboboxItemUtils = {
	t: TranslateFunction;
	getI18nValue: I18nValueGetter;
};

export function useAsComboboxItemUtils(): AsComboboxItemUtils {
	const t = useTranslate("common");
	const getI18nValue = useI18nValueGetter();
	return {
		t,
		getI18nValue,
	};
}

export interface UseValueItemItemPropsInput<
	Document extends DocumentInput,
	Item extends ComboboxItem,
> {
	value: string | null | undefined;
	query: Document;
	variables: VariablesOf<Document>;
	asComboboxItem: (result: ResultOf<Document>) => Item | undefined;
}

export function useComboboxValueItemProps<
	Document extends DocumentInput,
	Item extends ComboboxItem,
>({
	value,
	query,
	variables,
	asComboboxItem,
}: UseValueItemItemPropsInput<Document, Item>) {
	const [{ data }] = useQuery({ query, variables, pause: !value });
	const valueItem =
		value && data ? asComboboxItem(data as ResultOf<Document>) : undefined;
	if (!valueItem) {
		return {};
	}
	return { valueItem, pause: true };
}
