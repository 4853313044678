import { useQuery } from "@app/modules/graphql/queries";
import { AllowedTenantsDocument } from "@app/modules/tenant/queries/allowed-tenants.query.generated";
import { organizeBy } from "@app/modules/utils/organizeBy";
import { useMemo } from "react";

export function useAllowedTenants() {
	const [{ data }] = useQuery({
		query: AllowedTenantsDocument,
		variables: {},
		requestPolicy: "cache-first",
	});

	return useMemo(
		() => ({
			all: data?.tenants ?? [],
			byTenant: organizeBy(data?.tenants ?? [], "tenant"),
			hasAllowedTenants: (data?.tenants?.length ?? 0) > 0,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data?.tenants.length],
	);
}
