import { useAppState } from "@app/modules/app-state/context";
import { useEffect } from "react";

const defaultColor = "#CE4F75";

function getFaviconSvg(color: string = defaultColor) {
	return `
	<svg
  width='48'
  height='48'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  class='w-32'
>
  <path
    id='bottom-part'
    d='M41.227 10.225c4.828 3.67 6.796 9.175 6.772 14.068v.571l-.001.072a34.836 34.836 0 0 1 .002.56v1.723a32.616 32.616 0 0 1 0 .487V31.613c.042 4.858-1.878 9.16-4.92 10.543-6.31 2.863-12.802-3.224-21.595.878-4.735 2.213-16.323 7.808-20.704.93-.558-.877-.795-1.892-.776-3a7.062 7.062 0 0 1 0-.246 6.985 6.985 0 0 1 0-.243 7.002 7.002 0 0 1 0-.244 6.994 6.994 0 0 1 0-.243 7.039 7.039 0 0 1 0-.243 7.095 7.095 0 0 1 0-.247 6.977 6.977 0 0 1 0-.243 7.048 7.048 0 0 1 0-.244 7 7 0 0 1 0-.243 7.039 7.039 0 0 1 0-.243 6.996 6.996 0 0 1 0-.245 7.036 7.036 0 0 1 0-.245 7.053 7.053 0 0 1 0-.244 6.996 6.996 0 0 1 0-.243 6.992 6.992 0 0 1 0-.244 6.997 6.997 0 0 1 0-.244 7.077 7.077 0 0 1 0-.245 6.995 6.995 0 0 1 0-.243 7.038 7.038 0 0 1 0-.244 7.003 7.003 0 0 1 0-.244 6.993 6.993 0 0 1 0-.243 6.987 6.987 0 0 1 0-.244 7.003 7.003 0 0 1 0-.246 6.985 6.985 0 0 1 0-.243 7.002 7.002 0 0 1 0-.244 6.996 6.996 0 0 1 0-.237v-.114a7.016 7.016 0 0 1 0-.492v-.131a6.997 6.997 0 0 1 0-.246c-.115-6.56 9.177-16.606 14.134-20.347 6.834-5.158 17.321-10.5 27.088-3.072Z'
    fill='#8F324B'
  />
  <path
    id='front-part-tile'
    d='M14.139 13.297c6.834-5.158 17.321-10.5 27.088-3.072 9.52 7.238 7.921 21.607 1.852 24.364-6.309 2.863-12.801-3.224-21.595.878-4.734 2.213-16.322 7.807-20.703.93-3.862-6.063 7.637-18.782 13.358-23.1Z'
    fill='${color}'
  />
  <path
    id='letter-m'
    d='M31.757 25.011c-.31.19-.708.342-1.2.449a7.54 7.54 0 0 1-1.701.17c-.86 0-1.568-.075-2.13-.233-.563-.158-.924-.424-1.095-.803-.67-1.51-1.213-2.737-1.643-3.68a26.9 26.9 0 0 1-1.005-2.465h-.177c-.07 1.018-.127 1.941-.165 2.776a82.65 82.65 0 0 0-.07 2.44c-.012.79-.037 1.593-.069 2.402-.038.81-.088 1.7-.164 2.68-.31.089-.714.158-1.214.222-.5.056-1.005.088-1.504.088-1.05 0-1.872-.12-2.453-.36-.588-.24-.879-.677-.879-1.315l1.037-13.162c.19-.221.607-.461 1.252-.72.644-.26 1.555-.386 2.724-.386 1.265 0 2.257.139 2.971.423.715.285 1.24.746 1.575 1.378.214.411.448.886.695 1.423.253.53.5 1.08.752 1.65.253.569.493 1.131.733 1.688.24.562.456 1.062.645 1.51h.177a178.49 178.49 0 0 1 1.72-4.19 50.535 50.535 0 0 1 1.574-3.395c.36-.14.815-.253 1.378-.348a11.395 11.395 0 0 1 1.884-.14c1.17 0 2.124.127 2.864.374.74.253 1.169.607 1.29 1.068.094.342.195.873.303 1.587.107.714.22 1.517.34 2.415.121.891.241 1.84.355 2.838.12.999.234 1.954.341 2.877a97.92 97.92 0 0 1 .411 4.298c-.404.171-.828.297-1.27.373-.443.076-1.012.114-1.701.114-.91 0-1.669-.114-2.295-.335-.62-.221-.954-.664-1.005-1.315a98.206 98.206 0 0 1-.272-5.057 90.857 90.857 0 0 0-.158-3.768h-.177c-.24.67-.588 1.51-1.036 2.529a159.61 159.61 0 0 0-1.638 3.9Z'
    fill='#fff'
  />
  <path
    id='leaf-left'
    d='M31.839 10.712c-.35-1.589 3.376-4.277 7.006-4.46.175 0 3.618-.159 4.118 1.251.5 1.41-1.986 3.88-4.658 4.65-2.673.771-6.16-.031-6.466-1.44Z'
    fill='#2FAA02'
  />
  <path
    id='leaf-right'
    d='M32.014 11.026c-1.387-.89-.672-5.406 1.743-8.118.12-.131 2.43-2.701 3.785-2.054 1.354.648 1.382 4.123.04 6.581-1.343 2.459-4.341 4.353-5.569 3.59Z'
    fill='#32CC00'
  />
</svg>
`
		.replaceAll("\n", "")
		.replace(/\s\s+/g, " ")
		.replace(/[\r\n%#()<>?[\\\]^`{|}]/g, encodeURIComponent);
}

const domId = "meta-tenant-favicon";

export function TenantFavicon() {
	const { tenantDoc } = useAppState();
	const color = tenantDoc?.tenantColor;
	useEffect(() => {
		const svgString = getFaviconSvg(color);
		const linkElement = document.getElementById(domId) as
			| HTMLLinkElement
			| undefined;
		if (linkElement) {
			linkElement.href = `data:image/svg+xml;utf8,${svgString}`;
		}
	}, [color]);

	return null;
}
