import clsx from "clsx";
import type { CSSProperties, ReactNode } from "react";
import React from "react";

//
export interface AutoHeightProps {
	className?: string;
	children?: ReactNode;
	disabled?: boolean;
	style?: CSSProperties;
}

export function AutoHeight({
	className,
	children,
	disabled = false,
	style,
}: AutoHeightProps) {
	return (
		<div className={clsx(className, autoHeightCls(!disabled))} style={style}>
			{children}
		</div>
	);
}

export function autoHeightCls(isEnabled?: boolean) {
	return clsx("flex flex-col", isEnabled && "grow");
}
