import { PageLayout } from "@app/modules/layout/PageLayout";
import React from "react";

export interface NotFoundProps {
	className?: string;
}

function NotFound({ className }: NotFoundProps) {
	return (
		<PageLayout className={className}>
			<div className="shadow-card rounded overflow-hidden max-w-2xl mx-auto pb-24 bg-white">
				<img
					src="https://res.cloudinary.com/deep-impact-ag/image/upload/v1628670992/meatico/404.gif"
					alt="404 Not Found"
					className="w-full w-500"
				/>
			</div>
		</PageLayout>
	);
}

export { NotFound };
