import { mergeRefs } from "@app/modules/form/form-utils";
import { Input } from "@app/modules/input-fields/commons";
import { isDateString, LocalDate } from "@app/modules/localdate/localdate";
import type { DateValue } from "@internationalized/date";
import { parseDate } from "@internationalized/date";
import clsx from "clsx";
import type { ChangeEventHandler } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import type { AriaDatePickerProps } from "react-aria";
import "./date-time-input.css";

export interface AriaDateInputProps extends AriaDatePickerProps<DateValue> {
	name: string;
	form?: string;
}

export const AriaDateInput = forwardRef<HTMLInputElement, AriaDateInputProps>(
	function AriaDateInput(
		{
			id,
			name,
			form,
			defaultValue,
			value,
			isDisabled: disabled,
			isReadOnly: readOnly,
			maxValue,
			minValue,
			onChange,
			onBlur,
			onFocus,
			onKeyUp,
		}: AriaDateInputProps,
		ref,
	) {
		const [inputValue, setInputValue] = useState(value?.toString());
		const inputRef = useRef<HTMLInputElement>();

		useEffect(
			function updateInputValueFromValue() {
				if (value?.toString() !== inputValue) {
					setInputValue(value?.toString());
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[value],
		);

		const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
			if (isDateString(target.value)) {
				setInputValue(target.value);
				onChange?.(parseDate(target.value));
			}
		};

		const date = LocalDate.fromDateString(inputValue ?? "");

		return (
			<div className="flex">
				<span
					className={clsx(
						"text-body-14 w-24 flex-shrink-0",
						disabled && "text-grey-500",
					)}
				>
					{date.isValid() && `${date.format("dd")},`}
				</span>
				<Input.Input
					id={id}
					name={name}
					form={form}
					type="date"
					defaultValue={defaultValue?.toString()}
					value={inputValue}
					onChange={handleChange}
					ref={mergeRefs(ref, inputRef)}
					disabled={disabled}
					readOnly={readOnly}
					max={maxValue?.toString()}
					min={minValue?.toString()}
					onBlur={onBlur}
					onFocus={onFocus}
					onKeyUp={onKeyUp}
				/>
			</div>
		);
	},
);
