import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTouren({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.707 2.293a1 1 0 01.205 1.118l-8 17.743a1 1 0 01-1.912-.41V12H3.25a1 1 0 01-.41-1.912l17.75-8a1 1 0 011.117.205zM7.903 10h4.722A1.375 1.375 0 0114 11.375v4.718l5.003-11.096L7.903 10z"
			/>
		</svg>
	);
}

const MemoSvgTouren = React.memo(SvgTouren);
export default MemoSvgTouren;
