import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgConvert({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.29 13.501a1 1 0 010-1.414l3.19-3.19H6.762c-1.554 0-2.184.515-2.46.935-.319.483-.35 1.11-.256 1.545a1 1 0 11-1.954.425c-.173-.796-.156-2.015.54-3.07.737-1.12 2.087-1.835 4.13-1.835h8.718l-3.19-3.19a1 1 0 011.414-1.414L18.6 7.19l.707.707-.707.707-4.897 4.897a1 1 0 01-1.414 0zm7.107-.494c.095.434.064 1.062-.255 1.545-.277.42-.907.935-2.46.935H7.964l3.19-3.19a1 1 0 00-1.414-1.414L4.843 15.78l-.707.707.707.707 4.897 4.897a1 1 0 001.414-1.414l-3.19-3.19H16.682c2.043 0 3.393-.716 4.13-1.834.696-1.056.713-2.275.54-3.071a1 1 0 10-1.955.425z"
			/>
		</svg>
	);
}

const MemoSvgConvert = React.memo(SvgConvert);
export default MemoSvgConvert;
