import { logger } from "@app/modules/logger/logger";
import type { RoutesByPath } from "@app/modules/routes/types";
import JsonURL from "@jsonurl/jsonurl";

export function generateFullPaths<T extends string>(routes: RoutesByPath<T>) {
	const fullPaths: Partial<Record<T, string>> = {};

	(Object.keys(routes) as T[]).forEach((name) => {
		const segments = name.split(".");
		const fullPath = segments.reduce((path, _segment, segmentIdx) => {
			const fullSegmentName = new Array(segmentIdx + 1)
				.fill(0)
				.reduce(
					(pNames, _, idx) =>
						pNames === "" ? segments[idx] : `${pNames}.${segments[idx]}`,
					"",
				) as T;
			return `${path}/${routes[fullSegmentName].path}`;
		}, "");
		fullPaths[name] = fullPath;
	});

	return fullPaths as Record<T, string>;
}

export type SearchObjectValue =
	| string
	| number
	| Record<string, unknown>
	| unknown[]
	| undefined;
export type SearchObject = Record<string, SearchObjectValue>;

export function parseSearch(search: string): SearchObject {
	if (!search) {
		return {};
	}

	try {
		const parsed = JsonURL.parse(search.substring(1), {
			AQF: true,
			impliedObject: {},
		});
		return parsed;
	} catch {
		logger.warn("Failed to parse search", search);
		return {};
	}
}

export function stringifySearch(searchObject: SearchObject) {
	const searchParamsString = JsonURL.stringify(searchObject, {
		AQF: true,
		impliedObject: true,
		ignoreUndefinedObjectMembers: true,
	});
	return searchParamsString ? `?${searchParamsString}` : "";
}
