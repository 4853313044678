import type { UntypedControl } from "@app/modules/form/form-utils";
import { useTranslate } from "@app/modules/i18n/context";
import { InputGroup } from "@app/modules/input-fields/InputGroup";
import { Select } from "@app/modules/input-fields/Select";
import type { ProductUnitSelectOption } from "@app/modules/product-unit/product-unit-utils";
import { asSelectItem } from "@app/modules/product-unit/product-unit-utils";
import type { ProductQuantityInputLegacyProps } from "@app/modules/product-unit/ProductQuantityInputLegacy";
import { ProductQuantityInputLegacy } from "@app/modules/product-unit/ProductQuantityInputLegacy";
import { Controller, useWatch } from "react-hook-form";

export interface ProductQuantityFieldProps
	extends Omit<ProductQuantityInputLegacyProps, "unit"> {
	className?: string;
	control: UntypedControl;
	quantityFieldName: string;
	unitFieldName: string;
	unitDefaultValue?: string;
	units: ProductUnitSelectOption[];
	disabled?: boolean;
	preventFlip?: boolean;
}

export function ProductQuantityAndUnitField({
	className,
	control,
	quantityFieldName,
	unitFieldName,
	unitDefaultValue,
	units,
	disabled,
	defaultValue,
	isSmall,
	label,
	state,
	preventFlip,
	...props
}: ProductQuantityFieldProps) {
	const t = useTranslate("common");
	const hasMultipleUnits = units.length > 1;
	const unitId = useWatch({ control, name: unitFieldName });
	const selectedUnit = units.find(({ id }) => id === unitId);

	return (
		<InputGroup
			label={label}
			className={className}
			primaryInputName={quantityFieldName}
			state={state}
		>
			{hasMultipleUnits && (
				<Controller
					name={unitFieldName}
					control={control}
					defaultValue={unitDefaultValue}
					render={({ field }) => (
						<Select
							disabled={disabled}
							isSmall={isSmall}
							options={asSelectItem(t, units)}
							className="shrink-0 w-72"
							preventFlip={preventFlip}
							{...field}
						/>
					)}
				/>
			)}
			<Controller
				name={quantityFieldName}
				control={control}
				defaultValue={defaultValue}
				render={({ field }) => (
					<ProductQuantityInputLegacy
						className="flex-1"
						unit={selectedUnit}
						hasSuffix={!hasMultipleUnits}
						isSmall={isSmall}
						disabled={disabled}
						{...props}
						{...field}
					/>
				)}
			/>
		</InputGroup>
	);
}
