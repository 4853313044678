import { DropdownClosed, DropdownOpen } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { useInputId } from "@app/modules/form/form-utils";
import { useConsoleAssert } from "@app/modules/hooks/useConsoleAssert";
import { useTranslate } from "@app/modules/i18n/context";
import { useInputGroup } from "@app/modules/input-fields/InputGroup";
import {
	OptionItem,
	Options,
	useOptionsPositioning,
} from "@app/modules/input-fields/Options";
import type {
	InputSize,
	ValidationState,
} from "@app/modules/input-fields/types";
import clsx from "clsx";
import { useSelect } from "downshift";
import type { InputHTMLAttributes, ReactNode } from "react";
import { forwardRef, useEffect, useMemo, useState } from "react";
import {
	getViewState,
	Input,
	legacyGetSize,
	legacyGetValidationState,
} from "./commons";

export interface SelectItem {
	id: string | null;
	tenant?: string;
	domainId?: string;
	label: string;
	icon?: IconComponent;
	disabled?: boolean;
}

export interface SelectProps
	extends Omit<
		InputHTMLAttributes<HTMLInputElement>,
		"value" | "onChange" | "defaultValue"
	> {
	className?: string;
	label?: ReactNode;
	error?: string;
	options?: SelectItem[];
	selectedOption?: SelectItem;
	value?: string | null;
	defaultValue?: string;
	onChange?: (value: string | undefined | null) => void;
	isSmall?: boolean;
	preventFlip?: boolean;
	sizing?: InputSize;
	state?: ValidationState;
	hint?: ReactNode;
}

export type SelectWrapperProps = Omit<SelectProps, "options">;

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
	(
		{
			className,
			label,
			error,
			disabled,
			readOnly,
			isSmall,
			value = "",
			defaultValue,
			onChange,
			options,
			selectedOption,
			id,
			sizing = "md",
			state = "default",
			tabIndex = 0,
			hint,
			preventFlip,
			...inputProps
		},
		ref,
	) => {
		const [inputItems, setInputItems] = useState(options || []);
		const group = useInputGroup(inputProps.name);
		const inputId = useInputId(group.inputId ?? id);
		const validationState = legacyGetValidationState(group.state ?? state, {
			error,
		});
		const viewState = getViewState(validationState, { disabled, readOnly });
		const size = legacyGetSize(sizing, { isSmall });

		const {
			isOpen,
			getToggleButtonProps,
			getLabelProps,
			getMenuProps,
			highlightedIndex,
			getItemProps,
			selectedItem,
		} = useSelect({
			selectedItem:
				selectedOption ??
				(options ?? []).find((item) => item.id === value) ??
				null,
			onSelectedItemChange: (changes) => {
				if (changes.selectedItem) {
					onChange?.(changes.selectedItem.id);
					return;
				}
				onChange?.("");
			},
			items: inputItems,
			itemToString: (item) => item?.label ?? "",
			id: inputId,
			scrollIntoView: (item) => {
				// item can be undefined even if not specified by types
				item?.scrollIntoView();
			},
		});

		useEffect(() => {
			if (options) {
				setInputItems(options);

				if (
					!value &&
					defaultValue &&
					options.find((option) => option.id === defaultValue)
				) {
					onChange?.(defaultValue);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [options]);

		const selectName = inputProps.name ?? "empty";
		useConsoleAssert(selectName !== "empty", "Select: No name provided");

		const { referenceRef, optionsProps } = useOptionsPositioning({
			isOpen,
			preventFlip,
		});

		const control = (
			<Input.Control
				state={viewState}
				sizing={size}
				isInsideGroup={group.isInsideGroup}
				className={clsx(
					group.isInsideGroup && className,
					disabled ? "cursor-default" : "cursor-pointer",
				)}
				ref={referenceRef}
			>
				<button
					tabIndex={tabIndex}
					type="button"
					ref={ref}
					className={clsx("flex w-full", "focus-visible:outline-none")}
					data-testid={`toggle-button-${selectName}`}
					{...getToggleButtonProps({
						disabled,
						readOnly,
					})}
				>
					<Input.Input
						aria-label={selectName}
						value={selectedItem?.label ?? ""}
						className="flex-1 w-full"
						disabled={disabled}
						readOnly
						tabIndex={-1}
						{...inputProps}
					/>
					<Icon
						icon={isOpen ? DropdownOpen : DropdownClosed}
						size="16"
						className={clsx(
							"shrink-0 self-center",
							size === "sm" ? "" : "mr-8 ",
							disabled ? "text-grey-500" : "text-grey-700",
						)}
					/>
				</button>
				<Options
					{...getMenuProps()}
					{...optionsProps}
					className={clsx(isOpen && inputItems.length > 1 && "border-t")}
				>
					{isOpen &&
						inputItems.map((item, index) => (
							<OptionItem
								testid={item.label}
								key={item.id}
								icon={item.icon}
								isHighlighted={highlightedIndex === index}
								domainId={item.domainId}
								tenant={item.tenant}
								disabled={item.disabled}
								{...getItemProps({ item, index, disabled: item.disabled })}
							>
								{item.label}
							</OptionItem>
						))}
				</Options>
			</Input.Control>
		);

		if (group.isInsideGroup) {
			return control;
		}

		return (
			<div className={className}>
				<Input.Label {...getLabelProps()} testid={selectName} state={viewState}>
					{label}
				</Input.Label>
				{control}
				{(hint || error) && (
					<Input.Hint state={viewState}>{error ?? hint}</Input.Hint>
				)}
			</div>
		);
	},
);

export function useNullOption() {
	const t = useTranslate("common");
	return useMemo(
		() => ({
			label: t("form.select.no-selection"),
			id: null,
			state: undefined,
		}),
		[t],
	);
}
