import type { HotkeyAction } from "@app/modules/hotkey/actions";
import { actions } from "@app/modules/hotkey/actions";
import { useHotkeyOverlay } from "@app/modules/hotkey/HotkeyOverlayProvider";
import type { HotkeyHandler } from "@app/modules/hotkey/useHotkey";
import { useHotkey } from "@app/modules/hotkey/useHotkey";
import { Caption12Caps } from "@app/modules/typography";
import clsx from "clsx";
import type { ReactNode, Ref } from "react";
import React from "react";

export type HotkeyLabelPosition = "left" | "center" | "right";

export interface HotkeyProps {
	className?: string;
	action: HotkeyAction;
	handler?: HotkeyHandler;
	labelPosition?: HotkeyLabelPosition;
	children?: ReactNode;
	containerRef?: Ref<HTMLDivElement>;
}

function Hotkey({
	className,
	action,
	handler = () => {},
	children,
	labelPosition = "center",
	containerRef,
}: HotkeyProps) {
	const isLabelVisible = useHotkeyOverlay();
	useHotkey(action, handler);
	return (
		<div className={clsx("relative", className)} ref={containerRef}>
			<Caption12Caps
				className={clsx(
					"absolute bg-grey-900 text-white transition-all rounded px-4 -top-8",
					positionCls[labelPosition],
					!isLabelVisible && "hidden",
				)}
			>
				{actions[action]}
			</Caption12Caps>
			{children}
		</div>
	);
}

/* class={ */
const positionCls: Record<HotkeyLabelPosition, string> = {
	center: "transform left-1/2 -translate-x-1/2",
	left: "ransform left-0 -translate-x-1/2",
	right: "ransform right-0 -translate-x-1/2",
};
/* } */

export { Hotkey };
