import type { ControlledProps } from "@app/modules/form/controller";
import { useController } from "@app/modules/form/controller";
import { useInputId } from "@app/modules/form/form-utils";
import { useInputGroup } from "@app/modules/input-fields/InputGroup";
import type {
	InputSize,
	ValidationState,
} from "@app/modules/input-fields/types";
import clsx from "clsx";
import type { InputHTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import type { AnyVariables } from "urql";
import {
	getViewState,
	Input,
	inputTextCls,
	legacyGetSize,
	legacyGetValidationState,
} from "./commons";

export interface TextInputProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, "prefix"> {
	className?: string;
	inputClassName?: string;
	label?: string | ReactNode;
	prefix?: ReactNode;
	suffix?: string;
	isDirty?: boolean;
	sizing?: InputSize;
	state?: ValidationState;
	hint?: ReactNode;
	/** @deprecated Use `state` & `hint` instead */
	error?: string;
	/** @deprecated Use `sizing` instead */
	isSmall?: boolean;
	/** @deprecated Use `sizing` instead */
	isTouch?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	function TextInput(
		{
			className,
			label,
			id,
			hint,
			disabled,
			readOnly,
			prefix,
			suffix,
			inputClassName,
			sizing = "md",
			state = "default",
			isDirty,
			error,
			isSmall,
			isTouch,
			...inputProps
		},
		ref,
	) {
		const group = useInputGroup(inputProps.name);
		const inputId = useInputId(group.inputId ?? id);
		const validationState = legacyGetValidationState(group.state ?? state, {
			error,
		});
		const viewState = getViewState(validationState, { disabled, readOnly });
		const size = legacyGetSize(sizing, { isTouch, isSmall });

		const control = (
			<Input.Control
				state={viewState}
				sizing={size}
				isInsideGroup={group.isInsideGroup}
				className={clsx(group.isInsideGroup && className)}
			>
				{prefix && (
					<span
						className={clsx(
							prefixSuffixCommons(disabled),
							"pr-4",
							!label && !isSmall && "top-0",
							isSmall && "top-8",
							!isSmall && "top-12",
						)}
					>
						{prefix}
					</span>
				)}
				<Input.Input
					ref={ref}
					className={clsx(inputTextCls, inputClassName)}
					disabled={disabled}
					id={inputId}
					dirty={isDirty}
					{...inputProps}
				/>
				{suffix && (
					<span
						className={clsx(
							prefixSuffixCommons(disabled),
							"pl-4",
							!label && !isSmall && "top-0",
							isSmall && "top-8",
							!isSmall && "top-12",
						)}
					>
						{suffix}
					</span>
				)}
			</Input.Control>
		);

		if (group.isInsideGroup) {
			return control;
		}

		return (
			<div className={className}>
				<Input.Label
					htmlFor={inputId}
					testid={inputProps.name ?? inputId}
					state={viewState}
				>
					{label}
				</Input.Label>
				{control}
				{(hint || error) && (
					<Input.Hint state={viewState}>{error ?? hint}</Input.Hint>
				)}
			</div>
		);
	},
);

/* class={ */
const prefixSuffixCommons = (disabled?: boolean) =>
	clsx(
		"inline-block my-auto pointer-events-none text-caption-12",
		disabled ? "text-grey-500" : "text-grey-700",
	);
/* } */

export function TextInputField<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
>({
	control,
	name,
	defaultValue,
	constraint,
	...props
}: Omit<TextInputProps, "name" | "defaultValue"> &
	ControlledProps<Values, Name, ConstraintResult, ConstraintVariables>) {
	const { field } = useController({
		control,
		name,
		defaultValue,
		constraint,
	});
	return <TextInput {...props} {...field} />;
}
