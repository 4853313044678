import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgContainer({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={22}
			height={23}
			viewBox="0 0 22 23"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<g clipPath="url(#container_svg__clip0_2_11)">
				<path d="M18.96 13.33H2.37C1.23 13.33 0 12.31 0 10.66V5.33c0-.37.12-.71.33-.95L3.89.37C4.1.13 4.41 0 4.74 0h11.85c.33 0 .64.14.85.37l3.56 4c.21.24.33.58.33.96v5.33c0 1.65-1.23 2.67-2.37 2.67zM2 5.51v5.16c0 .49.3.67.37.67h16.59c.08 0 .37-.17.37-.67V5.51L16.21 2H5.12L2 5.51zM17.93 22.33H3.41c-1.7 0-3.07-1.25-3.07-2.78v-4.56H9.6v1c0 .42.49.78 1.07.78.58 0 1.07-.36 1.07-.78v-1H21v4.56c0 1.53-1.38 2.78-3.07 2.78zM2.34 17v2.56c0 .42.49.78 1.07.78h14.52c.58 0 1.07-.36 1.07-.78V17h-5.47c-.45 1.04-1.56 1.78-2.87 1.78S8.24 18.04 7.79 17H2.34z" />
				<path d="M10.67 9.44c-1.31 0-2.42-.74-2.87-1.78H1.34v-2H9.6v1c0 .42.49.78 1.07.78.58 0 1.07-.36 1.07-.78v-1H20v2h-6.47c-.45 1.04-1.56 1.78-2.87 1.78h.01z" />
			</g>
			<defs>
				<clipPath id="container_svg__clip0_2_11">
					<path d="M0 0h21.33v22.33H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

const MemoSvgContainer = React.memo(SvgContainer);
export default MemoSvgContainer;
