import type {
	InputSize,
	ValidationState,
	ViewState,
} from "@app/modules/input-fields/types";
import { logger } from "@app/modules/logger/logger";
import clsx from "clsx";
import type {
	InputHTMLAttributes,
	LabelHTMLAttributes,
	ReactNode,
	Ref,
} from "react";
import { forwardRef } from "react";

export interface InputLabelProps {
	children: ReactNode;
	disabled?: boolean;
	className?: string;
}

export function InputLabel({ children, disabled, className }: InputLabelProps) {
	return (
		<span
			className={clsx(
				className,
				"text-caption-12 inline-block font-500",
				disabled ? "text-grey-500" : "text-grey-700",
			)}
		>
			{children}
		</span>
	);
}

export function BlockInputLabel({
	children,
	disabled,
	className,
}: InputLabelProps) {
	return (
		<div
			className={clsx(
				className,
				"text-caption-12 font-500",
				disabled ? "text-grey-500" : "text-grey-700",
			)}
		>
			{children}
		</div>
	);
}

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
	children: ReactNode;
	testid: string;
	state: ViewState;
}

function Label({ className, children, testid, state, ...props }: LabelProps) {
	return (
		<label
			className={clsx(
				className,
				"text-caption-12 mb-4 font-500",
				labelStateCls[state],
			)}
			data-testid={`label-${testid}`}
			{...props}
		>
			{children}
		</label>
	);
}

/* class={ */
const labelStateCls: Record<ViewState, string> = {
	default: "text-grey-700",
	warning: "text-grey-700",
	error: "text-grey-700",
	disabled: "text-grey-500",
	readonly: "text-grey-700",
};
/* } */

interface ControlProps {
	className?: string;
	children: ReactNode;
	state: ViewState;
	sizing: InputSize;
	isInsideGroup: boolean;
	prefix?: string;
	suffix?: string;
}

const Control = forwardRef(function Control(
	{
		className,
		children,
		state,
		sizing,
		isInsideGroup,
		prefix,
		suffix,
	}: ControlProps,
	ref: Ref<HTMLDivElement>,
) {
	return (
		<div
			ref={ref}
			className={clsx(
				className,
				// "items-center" is not ideal. I'd rather have "items-baseline". I works better when using different size fonts (e.g. suffix/prefix).
				// We cannot make the change though as it breaks styling in all kinds of places. Something to consider in a UX rework.
				"flex gap-4 items-center",
				controlFocusCls,
				isInsideGroup && controlGroupCls,
				!isInsideGroup && controlNotGroupCls,
				controlBorderCls[state],
				controlStateCls[state],
				controlSizeCls[sizing],
			)}
		>
			{prefix && (
				<div className={clsx(prefixSuffixCls, prefixSuffixStateCls[state])}>
					{prefix}
				</div>
			)}
			{children}
			{suffix && (
				<div className={clsx(prefixSuffixCls, prefixSuffixStateCls[state])}>
					{suffix}
				</div>
			)}
		</div>
	);
});

/* class={ */
export const controlFocusCls =
	"focus-within:border-brand-700 focus-within:ring-1 focus-within:ring-inset focus-within:ring-offset-brand-700 focus-within:ring-brand-700";

export const controlBorderCls: Record<ViewState, string> = {
	default: "border border-grey-300",
	error: "border border-red-800",
	warning: "border border-yellow-800",
	disabled: "border border-grey-200",
	readonly: "border border-grey-300",
};

export const controlGroupCls =
	"first:rounded-l last:rounded-r not-first:border-l-0";

export const controlNotGroupCls = "rounded";

export const controlStateCls: Record<ViewState, string> = {
	default: "bg-white",
	error: "bg-white",
	warning: "bg-yellow-100",
	disabled: "bg-grey-200 text-grey-500",
	readonly: "bg-white",
};

export const controlSizeCls: Record<InputSize, string> = {
	sm: "py-4 px-8",
	md: "p-8",
	lg: "h-48 p-8",
};

export const reverseControlPaddingCls: Record<InputSize, string> = {
	sm: "-my-4 -mx-8",
	md: "-m-8",
	lg: "-m-8",
};

export const prefixSuffixCls = "text-caption-12 leading-24";

export const prefixSuffixStateCls: Record<ViewState, string> = {
	default: "text-grey-700",
	error: "text-grey-700",
	warning: "text-grey-700",
	disabled: "text-grey-500",
	readonly: "text-grey-500",
};
/* } */

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	dirty?: boolean;
}

const InputElement = forwardRef(function Input(
	{ className, dirty, ...props }: InputProps,
	ref: Ref<HTMLInputElement>,
) {
	return (
		<input
			ref={ref}
			className={clsx(inputReset, inputTextCls, dirty && "font-700", className)}
			type="text"
			// see for updated solutions: https://stackoverflow.com/a/30976223/1857169
			autoComplete="off"
			{...props}
		/>
	);
});

/* class={ */
export const inputReset =
	"appearance-none border-none outline-none focus:ring-0 bg-transparent p-0 w-full";
export const inputTextCls =
	"text-14 font-400 text-grey-900 placeholder-grey-500";
/* } */

interface HintProps {
	children: ReactNode;
	state: ViewState;
}

function Hint({ state, children }: HintProps) {
	return (
		<div className={clsx("text-caption-12 font-500 mt-4", hintStateCls[state])}>
			{children}
		</div>
	);
}

/* class={ */
const hintStateCls: Record<ViewState, string> = {
	default: "text-grey-700",
	warning: "text-yellow-800",
	error: "text-red-800",
	disabled: "text-grey-700",
	readonly: "text-grey-700",
};
/* } */

export const Input = {
	Label,
	Control,
	Input: InputElement,
	Hint,
};

export interface InputErrorProps {
	children: ReactNode;
}

export function InputError({ children }: InputErrorProps) {
	return (
		<div className="text-caption-12 text-red-800 font-500 mt-4">{children}</div>
	);
}

/**
 * @deprecated
 */
export function inputCls({
	error,
	disabled,
	focus,
}: {
	error?: boolean;
	disabled?: boolean;
	focus?: boolean;
} = {}) {
	/* class={ */
	return clsx(
		inputTextCls,
		"border placeholder-grey-500",
		error &&
			"border-red-800 ring-1 ring-inset ring-red-800 ring-offset-red-800",
		disabled && "bg-grey-200 border-grey-200 text-grey-500",
		focus
			? "border-brand-700 ring-1 ring-inset ring-offset-brand-700 ring-brand-700"
			: "focus-within:border-brand-700 focus-within:ring-1 focus-within:ring-inset focus-within:ring-offset-brand-700 focus-within:ring-brand-700",
		!focus && "border-grey-300",
	);
	/* } */
}

export function getViewState(
	state: ValidationState,
	{ disabled, readOnly }: { disabled?: boolean; readOnly?: boolean } = {},
): ViewState {
	if (disabled) {
		return "disabled";
	}
	if (readOnly) {
		return "readonly";
	}
	return state;
}

/**
 * @deprecated Use the `state` prop directly instead. This function only exists so we can map from the old and deprecated `error` prop to a ValidationState.
 */
export function legacyGetValidationState(
	state: ValidationState,
	{
		error,
	}: {
		error?: string;
	},
): ValidationState {
	return error ? "error" : state;
}

/**
 * @deprecated Use the "sizing" prop instead. This function only exists to map the old and deprecated `isTouch` & `isSmall` props to `InputSize`.
 */
export function legacyGetSize(
	sizing: InputSize,
	{
		isTouch,
		isSmall,
	}: {
		isTouch?: boolean;
		isSmall?: boolean;
	},
): InputSize {
	if (isSmall) {
		return "sm";
	}
	return isTouch ? "lg" : sizing || "md";
}

export function showPicker(element?: HTMLInputElement) {
	try {
		element?.showPicker?.();
	} catch (showPickerError) {
		logger.info("showPicker() failed", showPickerError);
	}
}
