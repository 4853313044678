import type { Role } from "@app/modules/app-state/roles";
import { sortRoles } from "@app/modules/app-state/roles";
import { Languages_Enum } from "@app/modules/graphql-api-types.generated";

export const UNKNOWN_STATE = "unknown";

export interface AppState {
	tenant: string | undefined;
	tenantDoc: undefined | TenantDocument;
	authenticationState:
		| typeof UNKNOWN_STATE
		| "unauthenticated"
		| "authenticated";
	areTranslationsLoaded: boolean;
	accessToken: string | undefined;
	arePermissionsLoaded: boolean;
	locale: Languages_Enum;
	user: UserState | undefined;
	activeRole: Role | undefined;
	workstationId: string | undefined;
	env: EnvState | undefined;
	latestEnv: EnvState | undefined;
	visibilityState: DocumentVisibilityState;
}

interface UserState {
	id: string;
	allowedRoles: Role[];
	allowedTenants: string;
}

interface TenantDocument {
	auth0Domain: string;
	auth0ClientId: string;
	languages: Languages_Enum[];
	mainLanguage: Languages_Enum;
	tenantShortName: string;
	tenantColor: string | undefined;
}

interface EnvState {
	stage: string;
	beVersion: string;
	feVersion: string;
}

export const defaultAppState: AppState = {
	tenant: undefined,
	tenantDoc: undefined,
	areTranslationsLoaded: false,
	authenticationState: "unknown",
	accessToken: undefined,
	arePermissionsLoaded: false,
	locale: Languages_Enum.DeCh,
	activeRole: undefined,
	user: undefined,
	workstationId: undefined,
	env: undefined,
	latestEnv: undefined,
	visibilityState: "visible",
};

export type AuthenticatedAction = {
	type: "AUTHENTICATED";
	accessToken: string;
	allowedRoles: Role[];
	allowedTenants: string;
	userId: number;
};

export type Action =
	| { type: "ENV_LOADED"; env: EnvState }
	| { type: "TENANT_LOADED"; tenant: string; tenantDoc: TenantDocument }
	| { type: "UNAUTHENTICATED" }
	| AuthenticatedAction
	| { type: "TRANSLATIONS_LOADED" }
	| { type: "LOCALE_CHANGED"; locale: Languages_Enum }
	| { type: "PERMISSIONS_LOADED" }
	| { type: "ROLE_CHANGED"; role: Role }
	| { type: "WORKSTATION_SELECTED"; workstationId: string | undefined }
	| {
			type: "VISIBILITY_STATE_CHANGED";
			visibilityState: DocumentVisibilityState;
	  };

export const reducer = (state: AppState, action: Action): AppState => {
	switch (action.type) {
		case "ENV_LOADED":
			if (state.env) {
				return {
					...state,
					latestEnv: action.env,
				};
			}
			return {
				...state,
				env: action.env,
				latestEnv: action.env,
			};
		case "TENANT_LOADED":
			return {
				...state,
				tenant: action.tenant,
				tenantDoc: action.tenantDoc,
				locale: action.tenantDoc.mainLanguage,
			};
		case "AUTHENTICATED": {
			const sortedRoles = sortRoles(action.allowedRoles);
			const role = sortedRoles.includes(state.activeRole as Role)
				? state.activeRole
				: sortedRoles[0];
			return {
				...state,
				authenticationState: "authenticated",
				accessToken: action.accessToken,
				activeRole: role,
				user: {
					id: action.userId.toString(),
					allowedRoles: sortedRoles,
					allowedTenants: action.allowedTenants,
				},
			};
		}
		case "UNAUTHENTICATED":
			return {
				...state,
				authenticationState: "unauthenticated",
			};
		case "TRANSLATIONS_LOADED":
			return {
				...state,
				areTranslationsLoaded: true,
			};
		case "LOCALE_CHANGED":
			return {
				...state,
				locale: action.locale,
			};
		case "PERMISSIONS_LOADED": {
			return {
				...state,
				arePermissionsLoaded: true,
			};
		}
		case "ROLE_CHANGED": {
			return {
				...state,
				activeRole: action.role,
			};
		}
		case "WORKSTATION_SELECTED": {
			return {
				...state,
				workstationId: action.workstationId,
			};
		}
		case "VISIBILITY_STATE_CHANGED": {
			return {
				...state,
				visibilityState: action.visibilityState,
			};
		}
		default:
			return state;
	}
};

/**
 * Checks if the app state has enough information for the application to be rendered.
 */
export function isReady({
	env,
	tenant,
	areTranslationsLoaded,
	authenticationState,
	arePermissionsLoaded,
}: AppState) {
	return (
		env &&
		tenant &&
		areTranslationsLoaded &&
		authenticationState !== "unknown" &&
		arePermissionsLoaded
	);
}
