import { Check } from "@app/icons/components";
import { Copy } from "@app/icons/components/action";
import type { IconComponent } from "@app/icons/types";
import { Button } from "@app/modules/Button";
import { useClipboard } from "@app/modules/hooks/useClipboard";
import { useState } from "react";

const ICON_CHANGE_DELAY = 3000;

export interface CopyButtonProps {
	text: string;
}
export function CopyButton({ text }: CopyButtonProps) {
	const { copyToClipboard } = useClipboard();

	const [icon, setIcon] = useState<IconComponent>(Copy);

	return (
		<Button
			variant="tertiary-grey"
			iconSize="16"
			icon={icon}
			onClick={async () => {
				const { success } = await copyToClipboard(text);
				if (!success) {
					return;
					// TODO: feedback that's not as intrusive as a toast
				}

				setIcon(Check);
				setTimeout(() => {
					setIcon(Copy);
				}, ICON_CHANGE_DELAY);
			}}
		/>
	);
}
