import type {
	InstructionSet,
	VendorProductId,
} from "@app/modules/hardware/types";

export interface USBDeviceConfiguration {
	instructionSet: InstructionSet;
	configurationNumber: number;
	interfaceNumber: number;
	transferInEndpoint: number;
	transferOutEndpoint: number;
}

// Unfortunately we cannot use Record<VendorProductId, USBDeviceConfiguration> here
const configurations: Record<string, USBDeviceConfiguration> = {
	"1529:16900": {
		instructionSet: "datalogic",
		configurationNumber: 1,
		interfaceNumber: 1,
		transferInEndpoint: 2,
		transferOutEndpoint: 2,
	},
};

export function getUSBDeviceConfiguration(vendorProductId: VendorProductId) {
	return configurations[vendorProductId];
}
