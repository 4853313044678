import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDismantling({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 1a3.5 3.5 0 011.087 6.828c.568 2.669 3.881 4.44 8.065 4.65a3.5 3.5 0 11-.013 2.002c-3.325-.15-6.288-1.174-8.14-2.933l.002 4.598A3.502 3.502 0 016 23a3.5 3.5 0 01-1-6.855v-8.29A3.502 3.502 0 016 1zm0 17a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm12.5-6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM6 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
			/>
		</svg>
	);
}

const MemoSvgDismantling = React.memo(SvgDismantling);
export default MemoSvgDismantling;
