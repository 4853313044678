import type {
	HardwareCommand,
	HardwareInstruction,
	ParsedMessage,
} from "@app/modules/hardware/types";
import { unknownMessage } from "@app/modules/hardware/types";
import { BigNumber } from "@app/modules/number/big-number";

const START_BYTE = "<";
const END_BYTE = ">";
const EMPTY_BYTE = " ";
const TERMINATION_STRING = String.fromCharCode(13, 10);

const instructions: Record<HardwareCommand, HardwareInstruction | undefined> = {
	"get-weight": "RN",
	tare: "TA",
	"set-to-zero": "SZ",
	reset: undefined,
};

function compile(cmd: HardwareCommand): HardwareInstruction | undefined {
	const instruction = instructions[cmd];
	if (!instruction) {
		return undefined;
	}
	return `${START_BYTE}${instruction}${END_BYTE}`;
}

const RESPONSE_REGEX = /<(.+)>/;
const WEIGHT_BODY_REGEX =
	/(.{2})(.{2})(.{8})(.{5})(.{4})(.)(.{8})(.{8})(.{8})(.{2})(.{2})(.)(.{3})(.{8})/;

function parse(instr: HardwareInstruction): ParsedMessage {
	const [, body] = instr.match(RESPONSE_REGEX) ?? [];
	if (body === undefined) {
		return unknownMessage;
	}

	const errorCode = body.slice(0, 2);
	if (errorCode !== "00") {
		return unknownMessage;
	}

	const matches = body.match(WEIGHT_BODY_REGEX);
	if (matches === null) {
		return unknownMessage;
	}

	const netWeight = matches[9]?.replaceAll(EMPTY_BYTE, "");
	const unit = matches[10]?.replaceAll(EMPTY_BYTE, "");
	if (!netWeight || !unit) {
		return unknownMessage;
	}

	const kgWeight = convertToKg(unit, netWeight);
	if (kgWeight === undefined) {
		return unknownMessage;
	}

	return {
		type: "weight",
		unit: "kg",
		weight: kgWeight,
	};
}

function convertToKg(unit: string, weight: string) {
	switch (unit) {
		case "kg":
			return weight;
		case "g":
			return new BigNumber(weight).shiftedBy(-3).toString();
		case "t":
			return new BigNumber(weight).shiftedBy(3).toString();
		default:
			return undefined;
	}
}

export const systec = {
	compile,
	parse,
	TERMINATION_STRING,
};
