import { logger } from "@app/modules/logger/logger";
import type { DependencyList } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export interface DebounceOptions {
	leading?: boolean;
	debug?: boolean;
}

export function debounce<Fn extends Function>(
	func: Fn,
	delay: number,
	{ leading = false, debug = false }: DebounceOptions = {},
): Fn {
	let timeout: ReturnType<typeof setTimeout>;
	let lastCall = Date.now();
	const debounced = (...args: unknown[]) => {
		clearTimeout(timeout);

		const callId = Math.floor(Math.random() * 1000);
		if (debug) {
			logger.info("DEBOUNCE", "accepted call", callId);
		}

		// After some time of inactivity we consider the the next call to be leading.
		// For now derive than time of inactivity from the delay.
		const isLeadingCall = lastCall + 5 * delay < Date.now();

		if (isLeadingCall && leading) {
			if (debug) {
				logger.info("DEBOUNCE", "executing call", callId);
			}
			func(...args);
		} else {
			timeout = setTimeout(() => {
				if (debug) {
					logger.info("DEBOUNCE", "executing call", callId);
				}
				func(...args);
			}, delay);
		}

		lastCall = Date.now();
	};

	return debounced as unknown as Fn;
}

export function useDebounce(
	fn: () => void,
	delay: number,
	deps: DependencyList,
	options?: DebounceOptions,
) {
	const fnRef = useRef(fn);
	fnRef.current = fn;
	const debouncedFn = useMemo(
		() =>
			debounce(
				() => {
					fnRef.current();
				},
				delay,
				options,
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		debouncedFn();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}

export function useDebouncedValue<T>(
	value: T,
	delay: number,
	options?: DebounceOptions,
) {
	const [debounced, setDebounced] = useState(value);
	const debouncedFn = useMemo(
		() =>
			debounce(
				(newValue: T) => {
					setDebounced(newValue);
				},
				delay,
				options,
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		debouncedFn(value);
	}, [debouncedFn, value]);

	return debounced;
}

export function useDebounceDeepCompare(
	fn: () => void,
	delay: number,
	deps: DependencyList,
	options?: DebounceOptions,
) {
	const fnRef = useRef(fn);
	fnRef.current = fn;
	const debouncedFn = useMemo(
		() =>
			debounce(
				() => {
					fnRef.current();
				},
				delay,
				options,
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useDeepCompareEffect(() => {
		debouncedFn();
	}, deps);
}
