import { Close } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import { Button } from "@app/modules/Button";
import type {
	ToastOptions,
	ToastVariant,
} from "@app/modules/notification/context";
import {
	useNotificationDispatch,
	useToastNotifications,
} from "@app/modules/notification/context";
import clsx from "clsx";
import type { MouseEvent, ReactNode } from "react";

function ToastNotifications() {
	const { clearNotification } = useNotificationDispatch();
	const notifications = useToastNotifications();
	const hasNotifications = notifications.length > 0;

	if (!hasNotifications) {
		return null;
	}

	return (
		<ToastNotificationWrapper>
			{notifications.map(({ id, variant, text, cta }) => (
				<ToastNotification
					key={id}
					variant={variant}
					onCloseRequest={() => {
						clearNotification(id);
					}}
					cta={cta}
				>
					{text}
				</ToastNotification>
			))}
		</ToastNotificationWrapper>
	);
}

interface ToastNotificationProps {
	className?: string;
	children?: ReactNode;
	onCloseRequest?: () => void;
	variant?: ToastVariant;
	cta?: ToastOptions["cta"];
}

function ToastNotification({
	className,
	children,
	onCloseRequest,
	variant = "success",
	cta,
}: ToastNotificationProps) {
	const hasCloseButton = onCloseRequest && !cta;
	return (
		<div>
			<div
				className={clsx(
					className,
					"relative inline-block my-8 p-16 rounded",
					variant === "success" && "bg-green-800",
					variant === "info" && "bg-blue-500",
					variant === "error" && "bg-red-800",
				)}
			>
				{hasCloseButton && (
					<button
						type="button"
						className="absolute right-16 top-20"
						onClick={onCloseRequest}
					>
						<Icon icon={Close} size="16" className="text-white" />
					</button>
				)}
				<span
					className={clsx("text-body-14 text-white", hasCloseButton && "pr-32")}
				>
					{children}
				</span>
				{cta && (
					<Button
						variant="secondary"
						onClick={(event: MouseEvent<HTMLButtonElement>) => {
							onCloseRequest?.();
							cta.onClick?.(event);
						}}
						className="ml-16"
					>
						{cta.text}
					</Button>
				)}
			</div>
		</div>
	);
}

interface ToastNotificationWrapperProps {
	className?: string;
	children?: ReactNode;
}

function ToastNotificationWrapper({
	className,
	children,
}: ToastNotificationWrapperProps) {
	return (
		<div className={clsx(className, "fixed bottom-8 left-16 z-50")}>
			{children}
		</div>
	);
}

export { ToastNotifications };
