import type { ControlledProps } from "@app/modules/form/controller";
import { useController } from "@app/modules/form/controller";
import type { TextInputProps } from "@app/modules/input-fields/TextInput";
import { TextInput } from "@app/modules/input-fields/TextInput";
import { THOUSAND_SEPARATOR } from "@app/modules/product-unit/product-unit-utils";
import clsx from "clsx";
import type { FocusEvent, FocusEventHandler, ReactNode } from "react";
import { forwardRef } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import type { NumericFormatProps } from "react-number-format";
import { NumericFormat } from "react-number-format";
import type { AnyVariables } from "urql";

export interface NumericInputLegacyProps
	extends Omit<
			NumericTextInputProps,
			"type" | "value" | "onChange" | "defaultValue" | "onClick"
		>,
		Omit<
			NumericFormatProps,
			"onChange" | "onClick" | "getInputRef" | "customInput"
		> {
	name: string;
	value?: string | null;
	onChange?: (value: string) => void;
	defaultValue?: string;
	autoSelect?: boolean;
	visibleScale?: number;
}

/**
 * @see `NumericInput` is preferred
 */
export const NumericInputLegacy = forwardRef<
	HTMLInputElement,
	NumericInputLegacyProps
>(function NumericInputLegacy(
	{
		className,
		inputClassName,
		onChange,
		autoSelect = true,
		visibleScale,
		onFocus,
		...props
	},
	ref,
) {
	return (
		<NumericFormat
			thousandSeparator={THOUSAND_SEPARATOR}
			getInputRef={ref}
			onValueChange={(values) => {
				// This shows a less precise version of the real value.
				// It is essentially a formatting function that only affects the visible value - could be generalised if needed.
				if (values.value !== computeVisibleValue(props?.value, visibleScale)) {
					onChange?.(values.value);
				}
			}}
			valueIsNumericString
			// TextInput props
			customInput={NumericTextInput}
			className={className}
			inputClassName={clsx("text-right", inputClassName)}
			inputMode="numeric"
			// mixed props
			{...autoSelectOnFocus({ isActive: autoSelect, onFocus })}
			{...props}
			value={computeVisibleValue(props?.value, visibleScale)}
		/>
	);
});

export interface AutoSelectOnFocusInput {
	isActive: boolean;
	onFocus?: FocusEventHandler<HTMLInputElement>;
}

export function autoSelectOnFocus({
	isActive,
	onFocus,
}: AutoSelectOnFocusInput) {
	return {
		onFocus: (e: FocusEvent<HTMLInputElement>) => {
			if (isActive) {
				(e.target as HTMLInputElement | undefined)?.select();
			}
			onFocus?.(e);
		},
	};
}

export interface NumericTextInputProps
	extends Omit<TextInputProps, "prefix" | "suffix"> {
	inputPrefix?: ReactNode;
	inputSuffix?: string;
}

export const NumericTextInput = forwardRef<
	HTMLInputElement,
	NumericTextInputProps
>(function NumericTextInput({ inputSuffix, inputPrefix, ...props }, ref) {
	return (
		<TextInput {...props} prefix={inputPrefix} suffix={inputSuffix} ref={ref} />
	);
});

function computeVisibleValue(
	value: string | undefined | null,
	visibleScale: number | undefined,
) {
	if (!value || visibleScale === undefined) {
		return value;
	}

	return (
		Math.round(parseFloat(value) * 10 ** visibleScale) /
		10 ** visibleScale
	).toString();
}

export function NumericInputLegacyField<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
>({
	control,
	name,
	defaultValue,
	constraint,
	...props
}: NumericInputLegacyProps &
	ControlledProps<Values, Name, ConstraintResult, ConstraintVariables>) {
	const { field } = useController({
		control,
		name,
		defaultValue,
		constraint,
	});
	return <NumericInputLegacy {...props} {...field} />;
}
