import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgScale({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.003 9.057c-.027.425.115.844.398 1.168l1.615 1.857a1.29 1.29 0 001.63.247l.281-.174C9.697 11.677 10.788 11 12 11c1.21 0 2.299.676 3.069 1.154l.283.174a1.29 1.29 0 001.63-.246l1.617-1.857a1.616 1.616 0 00-.148-2.277A9.596 9.596 0 0012 5.515a9.596 9.596 0 00-6.45 2.433 1.626 1.626 0 00-.547 1.11zm2.16.146l.956 1.1c.314-.19.709-.421 1.116-.617C9.949 9.344 10.902 9 12 9s2.051.344 2.765.687c.406.195.801.425 1.114.616l.959-1.1A7.59 7.59 0 0012 7.514a7.59 7.59 0 00-4.838 1.688z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.615 2h10.77A4.615 4.615 0 0122 6.615v10.77A4.615 4.615 0 0117.385 22H6.615A4.615 4.615 0 012 17.385V6.615A4.615 4.615 0 016.615 2zm10.77 2H6.615A2.615 2.615 0 004 6.615v10.77A2.615 2.615 0 006.615 20h10.77A2.615 2.615 0 0020 17.385V6.615A2.615 2.615 0 0017.385 4z"
			/>
		</svg>
	);
}

const MemoSvgScale = React.memo(SvgScale);
export default MemoSvgScale;
