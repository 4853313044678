import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTools({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.237 3.555c1.56-1.55 3.993-1.96 5.9-1.144l.008.003a1.04 1.04 0 01.318 1.688L16.08 6.485a.275.275 0 000 .384l1.05 1.051a.273.273 0 00.384 0l2.396-2.396a1.04 1.04 0 011.664.269l.006.011c.426.878.51 1.983.332 3.012-.18 1.039-.648 2.123-1.472 2.934-1.563 1.541-3.785 1.556-5.51 1.095a.268.268 0 00-.263.076l-.003.002-7.593 8.116a2.911 2.911 0 11-4.11-4.111l8.103-7.53.002-.002a.266.266 0 00.075-.267c-.489-1.74-.49-4 1.096-5.574zm3.481.464c-.76.079-1.513.4-2.072.955-.84.835-.98 2.191-.58 3.614m2.652-4.57l-1.054 1.055-.002.002a2.273 2.273 0 000 3.205l.002.002 1.053 1.054.003.003a2.273 2.273 0 003.205 0l.002-.003 1.041-1.042c-.007.06-.016.12-.027.18-.125.722-.44 1.394-.904 1.852-.835.822-2.178.964-3.59.587a2.265 2.265 0 00-2.244.645l-.002.001-7.605 8.13-.007.008a.91.91 0 11-1.286-1.286l.007-.006 8.113-7.54.001-.001a2.266 2.266 0 00.643-2.276"
			/>
		</svg>
	);
}

const MemoSvgTools = React.memo(SvgTools);
export default MemoSvgTools;
