import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgUnlocked({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2.25c-1.01 0-1.968.426-2.667 1.167A4.032 4.032 0 008.25 6.182V10H7.1c-1.16 0-2.1.87-2.1 1.941v7.118C5 20.13 5.94 21 7.1 21h9.8c1.16 0 2.1-.87 2.1-1.941V11.94C19 10.87 18.06 10 16.9 10H9.75V6.182c0-.659.247-1.282.674-1.736A2.166 2.166 0 0112 3.75c.582 0 1.15.245 1.576.696.427.454.674 1.077.674 1.736a.75.75 0 001.5 0 4.032 4.032 0 00-1.083-2.765A3.666 3.666 0 0012 2.25zm-5.367 9.42a.684.684 0 01.467-.17h9.8c.203 0 .364.075.467.17.1.093.133.19.133.271v7.118a.367.367 0 01-.133.27.684.684 0 01-.467.171H7.1a.684.684 0 01-.467-.17.367.367 0 01-.133-.271V11.94c0-.082.033-.178.133-.27z"
			/>
		</svg>
	);
}

const MemoSvgUnlocked = React.memo(SvgUnlocked);
export default MemoSvgUnlocked;
