import { useReducer } from "react";

function toggleReducer(currentValue: boolean, newValue?: boolean) {
	return newValue !== undefined ? newValue : !currentValue;
}

export function useToggle(initialValue = false) {
	return useReducer(toggleReducer, initialValue) as [
		boolean,
		(newValue?: boolean) => void,
	];
}
