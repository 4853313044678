import { DropdownClosed } from "@app/icons/components/action";
import { useTranslate } from "@app/modules/i18n/context";
import {
	DropDownAction,
	DropDownButton,
	DropDownItem,
} from "@app/modules/layout/DropDown";
import { useState } from "react";

const dateFilterModes = ["from", "at", "to"] as const;
export type DateFilterMode = (typeof dateFilterModes)[number];
export type DateValueType = "date" | "timestamp";

const availableModes: Record<DateValueType, DateFilterMode[]> = {
	date: ["from", "at", "to"],
	timestamp: ["from", "to"],
};

export interface DateFilterModeSelectProps {
	value?: string;
	onChange: (mode: DateFilterMode) => void;
	className?: string;
	dateType?: DateValueType;
}

function DateFilterModeSelect({
	value,
	onChange,
	className,
	dateType = "date",
}: DateFilterModeSelectProps) {
	const t = useTranslate("common");
	const [isOpen, setIsOpen] = useState(false);
	const toggleOpen = () => setIsOpen((oldIsOpen) => !oldIsOpen);

	return (
		<DropDownAction
			buttonTitle={value ? t(`words.date-${value}`) : ""}
			isOpen={isOpen}
			icon={DropdownClosed}
			variant="tertiary"
			iconPosition="right"
			className={className}
			buttonClassName="my-auto py-8"
			onCloseRequest={() => setIsOpen(false)}
			onClick={toggleOpen}
			align="right"
		>
			{availableModes[dateType]
				.filter((mode) => mode !== value)
				.map((mode) => (
					<DropDownItem key={mode}>
						<DropDownButton
							onClick={() => {
								onChange(mode);
								setIsOpen(false);
							}}
						>
							{t(`words.date-${mode}`)}
						</DropDownButton>
					</DropDownItem>
				))}
		</DropDownAction>
	);
}

export { DateFilterModeSelect };
