import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSettings({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.725 1H13.773a1.52 1.52 0 011.474 1.277l.296 2.11c.317.158.622.337.916.535l1.98-.797.008-.003a1.507 1.507 0 011.847.645l2.003 3.466.001.002a1.506 1.506 0 01-.363 1.932l-1.686 1.324c.012.174.02.355.02.54v.006a8.217 8.217 0 01-.02.523l1.685 1.321.012.01a1.506 1.506 0 01.35 1.923l-2.004 3.468-.005.01a1.507 1.507 0 01-1.832.64c-.004-.003-.009-.004-.013-.006l-.005-.002-1.981-.798a8.717 8.717 0 01-.913.532l-.297 2.114a1.52 1.52 0 01-1.47 1.29H9.73a1.52 1.52 0 01-1.475-1.276l-.296-2.11a8.268 8.268 0 01-.915-.535l-1.98.797a1.506 1.506 0 01-1.855-.642l-2.004-3.467v-.001a1.506 1.506 0 01.362-1.932l1.683-1.322a7.508 7.508 0 010-1.073l-1.686-1.323a1.507 1.507 0 01-.359-1.929h.001L3.21 4.78a1.507 1.507 0 011.855-.642l1.981.797c.293-.195.598-.373.913-.532l.297-2.113A1.52 1.52 0 019.725 1zM7.939 4.537v-.001zm-4.585 7.047zM10.174 3L9.92 4.812v.001a1.747 1.747 0 01-.967 1.33 6.71 6.71 0 00-.877.511 1.755 1.755 0 01-1.65.189l-.004-.002-1.698-.684L3.15 8.88l1.438 1.13a1.75 1.75 0 01.664 1.525v.006a5.39 5.39 0 000 .986 1.752 1.752 0 01-.656 1.532l-.004.003-1.444 1.134 1.574 2.723 1.698-.684a1.75 1.75 0 011.653.187l.002.002c.274.191.564.361.866.507a1.753 1.753 0 01.979 1.332v.003l.252 1.797h3.154l.255-1.813a1.748 1.748 0 01.967-1.33v-.001a6.71 6.71 0 00.878-.51 1.754 1.754 0 011.65-.19l.003.002 1.699.684 1.574-2.723-1.772-1.39a1 1 0 01-.373-.927c.04-.277.06-.557.062-.837a6.36 6.36 0 00-.022-.497 1.753 1.753 0 01.66-1.53h.002l1.444-1.134-1.574-2.723-1.698.683a1.75 1.75 0 01-1.653-.187l-.002-.001a6.277 6.277 0 00-.865-.507 1.753 1.753 0 01-.98-1.332v-.003L13.33 3h-3.154zM9.78 8.55a4 4 0 012.364-.5h.001a4.006 4.006 0 013.586 3.586v.002A4.001 4.001 0 119.78 8.551zm2.167 1.49a2 2 0 10-.393 3.98 2 2 0 00.393-3.98z"
			/>
		</svg>
	);
}

const MemoSvgSettings = React.memo(SvgSettings);
export default MemoSvgSettings;
