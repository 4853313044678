// eslint-disable-next-line no-restricted-imports
import dayjs from "dayjs";
import type { Languages_Enum } from "@app/modules/graphql-api-types.generated";

export async function setLocalDateLocale(language: Languages_Enum) {
	switch (language) {
		case "de_CH": {
			const de = await import("dayjs/locale/de-ch");
			dayjs.locale(de.default);
			break;
		}
		case "en": {
			const en = await import("dayjs/locale/en");
			dayjs.locale(en.default);
			break;
		}
		case "fr_CH": {
			const fr = await import("dayjs/locale/fr-ch");
			dayjs.locale(fr.default);
			break;
		}
		case "it_CH": {
			const it = await import("dayjs/locale/it-ch");
			dayjs.locale(it.default);
			break;
		}
		default:
	}
}
