import {
	dateString,
	email,
	message,
	min,
	required,
	requiredString,
	StructError,
	timeString,
	when,
} from "@app/modules/form/structs";
import { LocalDate } from "@app/modules/localdate/localdate";
import type { Infer } from "superstruct";
import { array, boolean, nullable, object, string } from "superstruct";

export const offerRequestSchema = object({
	recipients: array(
		object({
			email: email(),
		}),
	),
	message: nullable(string()),
	expirationDate: required(dateString()),
	expirationTime: when("expirationDate", (expirationDate) => {
		if (expirationDate === LocalDate.now().toDateString()) {
			const nowTime = LocalDate.now().toLocalTime();
			return message(
				min(required(timeString()), nowTime, { exclusive: true }),
				StructError.FutureTime,
			);
		}
		return required(timeString());
	}),
});

export type OfferRequestValues = Infer<typeof offerRequestSchema>;

export const offerItemSchema = object({
	_id: requiredString,
	orderQuantity: requiredString,
	customerNote: nullable(string()),
	deleted: boolean(),
});

export type OfferItemValues = Infer<typeof offerItemSchema>;

export const offerSchema = object({
	items: array(offerItemSchema),
	deliveryDate: dateString(),
});

export type OfferValues = Infer<typeof offerSchema>;
