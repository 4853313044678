import { quickhash } from "@app/modules/utils/hash";
import { sessionStorage } from "@app/modules/utils/session-storage";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { number, record, string } from "superstruct";

interface UseScrollRestorationOptions {
	isEnabledForPath?: (path: string) => boolean;
}

/**
 * Restores the scroll position of the element as soon as the element is fully rendered.
 *
 * EXPERIMENTAL:
 *  - Does not support elements that have highly dynamic height (e.g. infinite scroll).
 *  - Untested for elements that have a scroll bar at first render.
 */
export function useExperimentalScrollRestoration({
	isEnabledForPath,
}: UseScrollRestorationOptions = {}) {
	const location = useLocation();
	const [node, setNode] = React.useState<HTMLDivElement | null>(null);
	const isEnabled = isEnabledForPath?.(location.pathname) ?? true;
	const locationKey = quickhash(location.pathname + location.search);

	// Update stored scroll position callback
	const onScroll = useCallback(
		(event: Event) => {
			const target = event.target as HTMLDivElement;
			if (isEnabled) {
				const value = sessionStorage.get(
					"scrollPosition",
					scrollPositionsSchema,
				);
				sessionStorage.set("scrollPosition", {
					...value,
					[locationKey]: target.scrollTop,
				});
			}
		},
		[isEnabled, locationKey],
	);

	// Update stored scroll position effect
	useEffect(() => {
		if (node) {
			node.addEventListener("scroll", onScroll);
			return () => {
				node.removeEventListener("scroll", onScroll);
			};
		}
		return () => {};
	}, [node, onScroll]);

	// Scrolling effect
	useEffect(() => {
		if (isEnabled && node) {
			const positions = sessionStorage.get(
				"scrollPosition",
				scrollPositionsSchema,
			);
			const scrollTop = positions?.[locationKey];
			if (scrollTop) {
				// We use the resize observer to wait until the element is fully rendered
				const observer = new ResizeObserver((entries, obs) => {
					// We only support observing single elements
					const el = entries[0]?.target;
					// A scroll bar is present when scrollHeight !== clientHeight
					// We can consider the element fully rendered.
					if (el && el.scrollHeight !== el.clientHeight) {
						el.scrollTop = scrollTop;
						obs.disconnect();
					}
				});
				observer.observe(node);
				return () => {
					observer.disconnect();
				};
			}
		}
		return () => {};
	}, [isEnabled, locationKey, node]);

	return {
		ref: useCallback((el: HTMLDivElement | null) => {
			setNode(el);
		}, []),
	};
}

const scrollPositionsSchema = record(string(), number());
