import clsx from "clsx";
import React from "react";
import type { IconComponent, IconComponentProps } from "./types";

export interface IconProps extends IconComponentProps {
	icon: IconComponent;
	size: keyof typeof sizeCls;
	className?: string;
}

function Icon({ icon: IconComp, size, className, ...props }: IconProps) {
	return (
		<IconComp
			className={clsx(sizeCls[size], "fill-current", className)}
			{...props}
		/>
	);
}

/* class={ */
const sizeCls = {
	"12": "h-12 w-12",
	"16": "h-16 w-16",
	"20": "h-20 w-20",
	"24": "h-24 w-24",
	"32": "h-32 w-32",
	"40": "h-40 w-40",
	"56": "h-56 w-56",
};
/* } */

export { Icon };
