import type { AuthenticatedAction } from "@app/modules/app-state/app-state";
import type { TestUserInfo } from "@app/modules/devdoor/testusers";
import { storage } from "@app/modules/utils/local-storage";

const DEV_JWT_ACTION_KEY = "dev-jtw";
const DEV_JWT_ENABLED_KEY = "dev-jtw-enabled";
const DEV_JWT_META_KEY = "dev-jtw-meta";

export const getIsDevJWTActive = () =>
	storage.get(DEV_JWT_ENABLED_KEY) === "true";
export const setIsDevJWTActive = (isActive: boolean) => {
	if (isActive) {
		storage.set(DEV_JWT_ENABLED_KEY, "true");
	} else {
		storage.remove(DEV_JWT_ENABLED_KEY);
	}
};

export const getJWTMeta = () => storage.get<TestUserInfo>(DEV_JWT_META_KEY);
export const setJWTMeta = (user: TestUserInfo) => {
	storage.set(DEV_JWT_META_KEY, user);
};

export const getDevJWTAction = () =>
	storage.get<AuthenticatedAction>(DEV_JWT_ACTION_KEY);

export const loginAs = (user: TestUserInfo) => {
	setIsDevJWTActive(true);
	setJWTMeta(user);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { meta, ...rest } = user;
	storage.set(DEV_JWT_ACTION_KEY, {
		type: "AUTHENTICATED",
		...rest,
	});
};

export const logout = () => {
	setIsDevJWTActive(false);
	storage.remove(DEV_JWT_ACTION_KEY);
	storage.remove(DEV_JWT_META_KEY);
};
