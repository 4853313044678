import { useEffect } from "react";
import { unstable_useBlocker } from "react-router-dom";

export interface UsePromptOptions {
	when: boolean;
	message: string;
	onProceed?: () => void | Promise<void>;
	onCancel?: () => void | Promise<void>;
	proceedNavigationOnCancel?: boolean;
}

// Implementation based on https://github.com/remix-run/react-router/blob/0ce0e4c728129efe214521a22fb902fa652bac70/packages/react-router-dom/index.tsx#L1279-L1298
// Adapted to allow callback functions.
export function useConfirmPrompt({
	message,
	when,
	onProceed,
	onCancel,
	proceedNavigationOnCancel,
}: UsePromptOptions) {
	const blocker = unstable_useBlocker(
		({ currentLocation, nextLocation }) =>
			// We only consider pathname changes as navigation. Hash and search changes are ignored.
			when && currentLocation.pathname !== nextLocation.pathname,
	);

	useEffect(() => {
		if (blocker.state === "blocked" && !when) {
			blocker.reset();
		}
	}, [blocker, when]);

	useEffect(() => {
		(async () => {
			if (blocker.state === "blocked") {
				// eslint-disable-next-line no-alert
				const proceed = window.confirm(message);

				if (proceed) {
					await onProceed?.();
					setTimeout(blocker.proceed, 0);
					return;
				}

				await onCancel?.();
				if (proceedNavigationOnCancel) {
					// Without the `setTimeout` the blocker will not proceed correctly. The user will stay on the page with the blocker in an inconsistent state.
					setTimeout(blocker.proceed, 0);
				} else {
					blocker.reset();
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker.state]);
}
