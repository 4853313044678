import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCloudupload({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2C8.642 2 6.365 4.056 5.409 6.27 2.587 6.76 0 8.734 0 12.056c0 3.55 3.065 5.876 6.5 5.876h2.567a1 1 0 100-2H6.5c-2.615 0-4.5-1.696-4.5-3.876 0-2.164 1.782-3.65 4.228-3.881a1 1 0 00.858-.69C7.64 5.751 9.338 4 12 4c2.82 0 5.25 1.971 5.605 5.374a1 1 0 00.945.895c.819.04 1.72.322 2.393.821.647.481 1.057 1.137 1.057 2.012 0 .938-.373 1.604-.96 2.061-.615.478-1.527.77-2.623.77h-3.484a1 1 0 100 2h3.484c1.424 0 2.803-.377 3.85-1.191 1.075-.836 1.733-2.086 1.733-3.64 0-1.618-.805-2.83-1.865-3.617-.793-.59-1.74-.954-2.658-1.119C18.727 4.438 15.631 2 12 2zm3.707 9.293l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L11 11.414v9.606a1 1 0 102 0v-9.606l1.293 1.293a1 1 0 001.414-1.414z"
			/>
		</svg>
	);
}

const MemoSvgCloudupload = React.memo(SvgCloudupload);
export default MemoSvgCloudupload;
