import { UNKNOWN_STATE } from "@app/modules/app-state/app-state";
import { useAppStateDispatch } from "@app/modules/app-state/context";
import { fetchFrontendVersion } from "@app/modules/env/fetchFrontendVersion";
import { EnvironmentStateDocument } from "@app/modules/env/queries/environment-state.subscription.generated";
import { useSubscription } from "@app/modules/graphql/queries";
import { useEffect, useState } from "react";

const CHECK_VERSION_INTERVAL = 60 * 1000 * 1;

export function EnvLoader() {
	const dispatch = useAppStateDispatch();
	const [res] = useSubscription({
		query: EnvironmentStateDocument,
		variables: {},
	});

	const state = res.data?.environmentStates[0]?.state as
		| {
				stage: string;
				version: string;
		  }
		| undefined;
	const beVersion = state?.version;
	const stage = state?.stage;

	const [feVersion, setFeVersion] = useState<string | undefined>();

	useEffect(function pollFeVersion() {
		const abortController = new AbortController();

		const refreshFeVersion = () => {
			fetchFrontendVersion(abortController.signal).then((version) => {
				setFeVersion(version ?? UNKNOWN_STATE);
			});
		};

		refreshFeVersion();

		const id = setInterval(refreshFeVersion, CHECK_VERSION_INTERVAL);

		return () => {
			clearInterval(id);
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (beVersion && feVersion && stage) {
			dispatch({
				type: "ENV_LOADED",
				env: {
					stage,
					beVersion,
					feVersion,
				},
			});
		}
	}, [dispatch, feVersion, stage, beVersion]);

	return null;
}
