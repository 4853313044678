import React, { memo } from "react";
import type { TagItemColor } from "@app/modules/layout/TagItem";
import { TagItem } from "@app/modules/layout/TagItem";

export interface IdTagItemProps {
	id?: string;
	label?: string;
	color?: TagItemColor;
	className?: string;
}

export const IdTagItem = memo(function IdTagItem({
	id,
	label = "ID:",
	color = "grayLight",
	...props
}: IdTagItemProps) {
	if (!id) {
		return null;
	}

	return (
		<TagItem color={color} {...props}>
			{label} {id}
		</TagItem>
	);
});
