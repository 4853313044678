import { House } from "@app/icons/components";
import { TagItem } from "@app/modules/layout/TagItem";
import { useAllowedTenants } from "@app/modules/tenant/useAllowedTenants";
import React from "react";

export interface EntityOwnerTagItemProps {
	entity: { tenant?: string } | undefined;
	className?: string;
}

export function EntityOwnerTagItem({
	entity,
	className,
}: EntityOwnerTagItemProps) {
	const { byTenant } = useAllowedTenants();
	const tenantName = byTenant[entity?.tenant ?? ""]?.tenantName;
	if (!entity || !tenantName) {
		return null;
	}
	return (
		<TagItem color="grayLight" className={className} icon={House}>
			{tenantName}
		</TagItem>
	);
}
