import type { CustomerOrderItemUnitsFragment } from "@app/modules/customer-order/queries/customer-order-item-units.fragment.generated";
import type { CustomerOrderItemFragment } from "@app/modules/customer-order/queries/customer-order-item.fragment.generated";
import type { UntypedControl } from "@app/modules/form/form-utils";
import { Customer_Order_States_Enum } from "@app/modules/graphql-api-types.generated";
import type { TranslateFunction } from "@app/modules/i18n/types";
import { LocalDate } from "@app/modules/localdate/localdate";
import { BigNumber } from "@app/modules/number/big-number";
import {
	isZero,
	ScaledNumber,
	stimes,
} from "@app/modules/number/scaled-number";
import { computeConvertedSalesMargin } from "@app/modules/pricing/pricing-utils";
import {
	convertUnit,
	isKgUnit,
} from "@app/modules/product-unit/product-unit-utils";
import type { ProductUnitFragment } from "@app/modules/product-unit/queries/product-unit.fragment.generated";
import {
	makeLocalSearchValueFilter,
	makeLocalSearchSuggestion,
} from "@app/modules/search/local/utils";
import { useTenantConfiguration } from "@app/modules/tenant/useTenantConfiguration";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";

export function calculateCustomerOrderSalesTotal(
	orderItems: {
		orderQuantity: string;
		salesPricePerUnit?: string | null;
		salesUnit?: ProductUnitFragment;
		orderUnit?: ProductUnitFragment;
	}[],
) {
	return orderItems
		.reduce(
			(acc, { salesPricePerUnit, orderQuantity, salesUnit, orderUnit }) => {
				if (!orderQuantity || !salesPricePerUnit || !salesUnit || !orderUnit) {
					return acc;
				}
				const salesQuantity = convertUnit(orderUnit, salesUnit, orderQuantity);
				return acc.plus(stimes(salesQuantity, salesPricePerUnit));
			},
			new ScaledNumber(0),
		)
		.toFixed(0);
}

export function useGetNextDeliveryDateSuggestion() {
	const configuration = useTenantConfiguration();

	// Default offset is 1 on the BE
	if (!configuration) {
		return LocalDate.now().nextWorkingDay();
	}

	return LocalDate.now().addWorkingDays(
		configuration.customerOrderDeliveryDateOffset,
	);
}

export function getDeliveryDateForCopy(date?: string): string {
	return LocalDate.fromString(date).add(1, "d").toDateString();
}

export function makeInvoiceFilter(
	t: TranslateFunction,
	{ id, domainId }: { id: string; domainId: string },
) {
	return makeLocalSearchValueFilter(
		"customerInvoiceOrders.customerInvoiceId",
		id,
		{
			icon: "invoice",
			label: `${t("orders.labels.invoice-no")} ${domainId}`,
			shouldDeactivateDateFilter: true,
		},
	);
}

export function makeInvoiceSuggestion(
	t: TranslateFunction,
	{ id, domainId }: { id: string; domainId: string },
) {
	return makeLocalSearchSuggestion(
		"customerInvoiceOrders.customerInvoiceId",
		id,
		{
			icon: "invoice",
			label: `${t("orders.labels.invoice-no")} ${domainId}`,
			shouldDeactivateDateFilter: true,
		},
	);
}

export function makeCreditNoteFilter(
	t: TranslateFunction,
	{ id, domainId }: { id: string; domainId: string },
) {
	return makeLocalSearchValueFilter("customerOrderReturns.creditNoteId", id, {
		icon: "invoice",
		label: `${t("orders.labels.credit-note-no")} ${domainId}`,
		shouldDeactivateDateFilter: true,
	});
}

export function makeCreditNoteSuggestion(
	t: TranslateFunction,
	{ id, domainId }: { id: string; domainId: string },
) {
	return makeLocalSearchSuggestion("customerOrderReturns.creditNoteId", id, {
		icon: "invoice",
		label: `${t("orders.labels.credit-note-no")} ${domainId}`,
		shouldDeactivateDateFilter: true,
	});
}

/* class={ */
const orderCls = {
	0: "col-span-3",
	1: "col-span-2",
	2: "col-span-2",
	3: "col-span-2",
	4: "col-span-3",
	5: "col-span-2 text-right",
};

const internalOrderCls = {
	0: "col-span-4",
	1: "col-span-2",
	2: "col-span-2",
	3: "col-span-2",
	4: "col-span-2",
	5: "col-span-2",
};

const internalOrderHeadCls = {
	0: "col-span-4",
	1: "col-span-2",
	2: "col-span-2",
	3: "col-span-2",
	4: "col-span-2",
	5: "col-span-2",
};
/* } */

export function getCoisColumnCls(
	columnIndex: keyof typeof orderCls,
	isInternalOrder: boolean,
) {
	return isInternalOrder
		? internalOrderCls[columnIndex]
		: orderCls[columnIndex];
}

export function getCoisHeadColumnCls(
	columnIndex: keyof typeof orderCls,
	isInternalOrder: boolean,
) {
	return isInternalOrder
		? internalOrderHeadCls[columnIndex]
		: orderCls[columnIndex];
}

export function useCoiUnits({
	units,
	control,
}: {
	units?: CustomerOrderItemUnitsFragment["productUnits"];
	control: UntypedControl;
}) {
	const orderUnitId = useWatch({ name: "orderUnitId", control });
	const salesUnitId = useWatch({ name: "salesUnitId", control });
	const sortedUnits = useMemo(() => {
		if (!units) {
			return [];
		}
		// kg should be the first in the list
		const kgIndex = units.findIndex(isKgUnit);
		if (!kgIndex || kgIndex === -1) {
			return units;
		}
		const sorted = [...units];
		sorted.unshift(...sorted.splice(kgIndex, 1));
		return sorted;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [units?.length]);
	const orderUnits = useMemo(
		() =>
			sortedUnits.map((unit) => ({
				...unit,
				disabled: unit.productUnitSalesPriceConfigsViaOrderUnit.length === 0,
			})),
		[sortedUnits],
	);
	const salesUnits = useMemo(
		() =>
			sortedUnits.map((unit) => ({
				...unit,
				disabled: unit.productUnitSalesPriceConfigsViaSalesUnit.length === 0,
			})),
		[sortedUnits],
	);
	const orderUnit = orderUnits.find((unit) => unit.id === orderUnitId);
	const salesUnit = salesUnits.find((unit) => unit.id === salesUnitId);

	const hasSalesPriceConfig =
		orderUnit?.productUnitSalesPriceConfigsViaOrderUnit.some(
			(puspc) => puspc.salesUnitId === salesUnit?.id,
		);

	return {
		orderUnits,
		orderUnit,
		orderUnitId,
		salesUnits,
		salesUnit,
		salesUnitId,
		hasSalesPriceConfig,
	};
}

export function computeCoiSalesMargin(item: CustomerOrderItemFragment) {
	const pricing = item.currentTemporalProducts?.[0];
	const costPricePerUnit = pricing?.costPricePerUnit;
	const costPriceUnit = item.product.productUnits.find(
		({ id }) => id === pricing?.costPriceUnitId,
	);

	return item.salesPricePerUnit &&
		item.salesUnit &&
		costPricePerUnit &&
		!isZero(costPricePerUnit) &&
		costPriceUnit
		? computeConvertedSalesMargin(
				item.salesPricePerUnit,
				item.salesUnit,
				costPricePerUnit,
				costPriceUnit,
		  )
		: undefined;
}

export function canOrderTotalsChange(state: Customer_Order_States_Enum) {
	return ![
		Customer_Order_States_Enum.Delivered,
		Customer_Order_States_Enum.Completed,
		Customer_Order_States_Enum.AttachedToInvoice,
	].includes(state);
}

export function getCustomerOrderRouteName({
	isInternal,
}: {
	isInternal: boolean;
}) {
	return isInternal ? "sales.internal-orders.id" : "sales.customer-orders.id";
}

export function calculateCoiExtraTotals<
	T extends {
		customerOrderItemExtra?: {
			marginPercentage?: string | null;
			finalInvoiceWeight?: string | null;
		} | null;
	}[],
>(coi: T) {
	const results = coi.reduce(
		(acc, item, index) => {
			acc.totalMargin = acc.totalMargin.plus(
				item.customerOrderItemExtra?.marginPercentage ?? "0",
			);
			acc.totalWeight = acc.totalWeight.plus(
				item.customerOrderItemExtra?.finalInvoiceWeight ?? "0",
			);

			if (index + 1 === coi.length) {
				const nonZeroCoiCount = coi.filter(({ customerOrderItemExtra }) =>
					Boolean(customerOrderItemExtra),
				).length;

				acc.averageMargin = acc.totalMargin.dividedBy(nonZeroCoiCount || 1);
				acc.averageWeight = acc.totalWeight.dividedBy(nonZeroCoiCount || 1);
			}
			return acc;
		},
		{
			totalWeight: new ScaledNumber(0),
			totalMargin: new BigNumber(0),
			averageWeight: new ScaledNumber(0),
			averageMargin: new BigNumber(0),
		},
	);
	return results;
}
