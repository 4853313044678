import { isDev } from "@app/modules/environment";
import type { LogLevel } from "@app/modules/logger/utils";
import { logToConsole } from "@app/modules/logger/utils";

function makeLogFunction(level: LogLevel): typeof console.log {
	if (isDev) {
		return (...data: unknown[]) => logToConsole(level, "DEV", ...data);
	}
	return () => {};
}

const devlogger = {
	log: makeLogFunction("DEBUG"),
	info: makeLogFunction("INFO"),
	warn: makeLogFunction("WARN"),
	error: makeLogFunction("ERROR"),
};

export { devlogger };
