import { Check, EyeClose } from "@app/icons/components";
import { getCustomerOrderRouteName } from "@app/modules/customer-order/customer-order-utils";
import type { Notification_Types_Enum } from "@app/modules/graphql-api-types.generated";
import { useMutation } from "@app/modules/graphql/queries";
import type { TranslateFunction } from "@app/modules/i18n/types";
import { LocalDate } from "@app/modules/localdate/localdate";
import type { NotificationPanelItemFragment } from "@app/modules/notification/queries/notification-panel.query.generated";
import { UpdateNotificationDocument } from "@app/modules/notification/queries/update-notification.mutation.generated";
import { UpdateNotificationsDocument } from "@app/modules/notification/queries/update-notifications.mutation.generated";
import type { ParamsOf, RouteName } from "@app/modules/routes/routes";
import { is, object, string, type Struct } from "superstruct";

export function getNotificationTypeName(
	t: TranslateFunction,
	type: Notification_Types_Enum,
) {
	return t(`notifications.types.${type}`);
}

export function getNotificationTextParams(
	t: TranslateFunction,
	notification: NotificationPanelItemFragment,
) {
	return notificationParams[notification.type]?.(t, notification);
}

const offerStateChangeHandler = (
	t: TranslateFunction,
	notification: NotificationPanelItemFragment,
) => {
	const { customerOrder } = notification;
	if (!customerOrder) {
		return undefined;
	}

	const text = t(`notifications.texts.${notification.type}`, {
		orderDomainId: customerOrder?.domainId,
	});

	return {
		routeName: getCustomerOrderRouteName(customerOrder),
		routeParams: { id: customerOrder.id },
		text,
	} as const;
};

const notificationParams = {
	OFFER_DELIVERY_DATE_CHANGE: (
		t: TranslateFunction,
		notification: NotificationPanelItemFragment,
	) => {
		const { customerOrder, payload } = notification;
		if (
			!customerOrder ||
			notification.type !== "OFFER_DELIVERY_DATE_CHANGE" ||
			!is(payload, payloadSchemas[notification.type])
		) {
			return undefined;
		}

		const text = t(`notifications.texts.${notification.type}`, {
			orderDomainId: customerOrder?.domainId,
			fromDeliveryDate: formatDateString(payload.old_delivery_date),
			toDeliveryDate: formatDateString(payload.new_delivery_date),
		});

		return {
			routeName: getCustomerOrderRouteName(customerOrder),
			routeParams: { id: customerOrder.id },
			text,
		} as const;
	},
	EXPIRED_OFFER_ACCEPTED: offerStateChangeHandler,
	OFFER_ACCEPTED: offerStateChangeHandler,
	OFFER_REJECTED: offerStateChangeHandler,
	COI_UNDERPICKED: (
		t: TranslateFunction,
		notification: NotificationPanelItemFragment,
	) => {
		const { customerOrderItem, customerOrder, payload } = notification;
		if (
			!customerOrder ||
			!customerOrderItem ||
			notification.type !== "COI_UNDERPICKED" ||
			!is(payload, payloadSchemas[notification.type])
		) {
			return undefined;
		}

		const text = t(`notifications.texts.${notification.type}`, {
			productDomainId: customerOrderItem.product.domainId,
			orderDomainId: customerOrder.domainId,
			fulfillmentPercentage: formatPercentage(payload.fulfillment_percentage),
		});

		return {
			routeName: "operations.picking.id",
			routeParams: { id: customerOrder.id, item: customerOrderItem.id },
			text,
		} as const;
	},
} satisfies Record<
	Notification_Types_Enum,
	(
		t: TranslateFunction,
		notification: NotificationPanelItemFragment,
	) =>
		| { routeName: RouteName; routeParams: ParamsOf<RouteName>; text: string }
		| undefined
>;

const payloadSchemas = {
	OFFER_DELIVERY_DATE_CHANGE: object({
		old_delivery_date: string(),
		new_delivery_date: string(),
	}),
	EXPIRED_OFFER_ACCEPTED: object(),
	OFFER_ACCEPTED: object(),
	OFFER_REJECTED: object(),
	COI_UNDERPICKED: object({
		fulfillment_percentage: string(),
	}),
} satisfies Record<Notification_Types_Enum, Struct<any, any>>;

function formatPercentage(s: string) {
	return (parseFloat(s) * 100).toFixed(2);
}

function formatDateString(date: string) {
	return LocalDate.fromDateString(date).toLocalizedDateString();
}

export function getIsAcked(notification: NotificationPanelItemFragment) {
	return Boolean(notification.ackedAt);
}

export function getToggleIcon(isAcked: boolean) {
	return isAcked ? EyeClose : Check;
}

export function useNotificationMutations({ userId }: { userId: string }) {
	const [singleRes, updateNotification] = useMutation(
		UpdateNotificationDocument,
	);
	const [multipleRes, updateNotifications] = useMutation(
		UpdateNotificationsDocument,
	);

	const ack = (notificationId: string) =>
		updateNotification({
			id: notificationId,
			userId,
			ackedAt: LocalDate.now().toUtcString(),
		});

	const unack = (notificationId: string) =>
		updateNotification({
			id: notificationId,
			userId,
			ackedAt: null,
		});

	return {
		isFetching: multipleRes.fetching || singleRes.fetching,
		ackAll: () =>
			updateNotifications({
				where: { ackedAt: { _is_null: true } },
				set: {
					ackedByUserId: userId,
					ackedAt: LocalDate.now().toUtcString(),
				},
			}),
		toggleAck: (notificationId: string, isAcked: boolean) => {
			if (isAcked) {
				unack(notificationId);
			} else {
				ack(notificationId);
			}
		},
	};
}
