import {
	useAppState,
	useAppStateDispatch,
} from "@app/modules/app-state/context";
import { Languages_Enum } from "@app/modules/graphql-api-types.generated";
import {
	makeDateTimeFormatter,
	makeNumberFormatter,
} from "@app/modules/i18n/formatters";
import { asISOLanguageAbbreviation } from "@app/modules/i18n/i18n-utils";
import { setLocalDateLocale } from "@app/modules/i18n/localdate";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import type { Namespace } from "./namespaces";
import { namespaces } from "./namespaces";
import { makeTranslate } from "./translate";
import { loadTranslations } from "./translations";
import type { TranslateFunction, WebLocale } from "./types";

interface I18nContextValue {
	translators: Record<Namespace, TranslateFunction>;
	formatter: {
		formatDate: ReturnType<typeof makeDateTimeFormatter>;
		formatNumber: ReturnType<typeof makeNumberFormatter>;
	};
	locale: Languages_Enum;
	webLocale: WebLocale;
}

const defaultValue: I18nContextValue = {
	translators: { common: () => "" },
	formatter: {
		formatDate: () => "",
		formatNumber: () => "",
	},
	locale: Languages_Enum.DeCh,
	webLocale: "de-CH",
};

const I18nContext = createContext<I18nContextValue>(defaultValue);

export interface I18nProviderProps {
	children?: ReactNode;
}

function I18nProvider({ children }: I18nProviderProps) {
	const { locale } = useAppState();
	const dispatch = useAppStateDispatch();

	const [value, setValue] = useState<I18nContextValue>(defaultValue);

	useEffect(() => {
		if (locale) {
			(async () => {
				const translations = await loadTranslations(locale);
				const translate = makeTranslate({ translations });
				const translators: Partial<I18nContextValue["translators"]> = {};
				namespaces.forEach((ns) => {
					translators[ns] = (key, options) =>
						translate(`${ns}.${key}`, options);
				});
				const webLocale = locale.replace("_", "-") as WebLocale;
				setValue({
					locale,
					webLocale,
					translators: translators as I18nContextValue["translators"],
					formatter: {
						formatNumber: makeNumberFormatter(locale),
						formatDate: makeDateTimeFormatter(locale),
					},
				});

				await setLocalDateLocale(locale);

				// Update the document lang property
				//  Browser features and plugins rely on this property
				//  to determine locale
				document.documentElement.lang = asISOLanguageAbbreviation(locale);

				dispatch({ type: "TRANSLATIONS_LOADED" });
			})();
		}
	}, [dispatch, locale]);

	return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}

function useI18n() {
	return useContext(I18nContext);
}

function useTranslate(namespace: Namespace) {
	const { translators } = useContext(I18nContext);
	return translators[namespace];
}

function useFormatter() {
	return useContext(I18nContext).formatter;
}

export { I18nProvider, useI18n, useTranslate, useFormatter };
