// THIS FILE IS GENERATED, DO NOT EDIT!
import type * as Types from '../../graphql-api-types.generated';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EnvironmentStateSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type EnvironmentStateSubscription = { __typename: 'subscription_root', environmentStates: Array<{ __typename: 'environment_state', id: string, state?: JsonObject | null }> };


export const EnvironmentStateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"EnvironmentState"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"environmentStates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]} as unknown as DocumentNode<EnvironmentStateSubscription, EnvironmentStateSubscriptionVariables>;