import type { TagItemColor } from "@app/modules/layout/TagItem";
import { TagItem, TagItemLink } from "@app/modules/layout/TagItem";
import type { RouteName } from "@app/modules/routes/routes";

export interface EntityIdTagItemProps {
	className?: string;
	entity: { id: string; domainId?: string } | undefined | null;
}

export function EntityIdTagItem({ className, entity }: EntityIdTagItemProps) {
	if (!entity) {
		return null;
	}
	const shownId = entity.domainId || entity.id;
	return (
		<TagItem color="grayLight" className={className}>
			ID: {shownId}
		</TagItem>
	);
}

export interface EntityIdTagItemLinkProps {
	className?: string;
	entity: { id: string; domainId?: string } | undefined | null;
	to: RouteName;
	prefix?: string;
	color?: TagItemColor;
}

export function EntityIdTagItemLink({
	className,
	entity,
	to,
	prefix,
	color = "grayLight",
}: EntityIdTagItemLinkProps) {
	if (!entity) {
		return null;
	}
	const shownId = entity.domainId || entity.id;
	return (
		<TagItemLink
			color={color}
			className={className}
			to={to}
			params={{ id: entity.id }}
		>
			{prefix ?? "ID:"} {shownId}
		</TagItemLink>
	);
}
