import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgBestandunzureichend({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.826 3.076a9.074 9.074 0 012.348 0l-.258 1.983a7.074 7.074 0 00-1.832 0l-.258-1.983zm4.62.607c.732.304 1.415.701 2.034 1.177l-1.22 1.586a6.99 6.99 0 00-1.58-.915l.765-1.848zM6.52 4.86a8.992 8.992 0 012.035-1.177l.766 1.848c-.569.236-1.1.544-1.582.915L6.52 4.86zm12.62 1.66a8.993 8.993 0 011.177 2.035l-1.848.766a6.989 6.989 0 00-.915-1.582L19.14 6.52zM3.683 8.555c.304-.733.701-1.416 1.177-2.035l1.586 1.22a6.992 6.992 0 00-.915 1.58l-1.848-.765zm17.241 2.27a9.072 9.072 0 010 2.35l-1.983-.26a7.08 7.08 0 000-1.83l1.983-.26zM3 12c0-.398.026-.79.076-1.174l1.983.258a7.074 7.074 0 000 1.832l-1.983.258A9.074 9.074 0 013 12zm17.317 3.445a8.993 8.993 0 01-1.177 2.035l-1.586-1.22c.37-.481.68-1.012.915-1.58l1.848.765zM4.86 17.48a8.992 8.992 0 01-1.177-2.035l1.848-.766c.236.569.544 1.1.915 1.582L4.86 17.48zm3.695 2.837A8.993 8.993 0 016.52 19.14l1.22-1.586c.481.37 1.012.68 1.58.915l-.765 1.848zm8.925-1.177a8.993 8.993 0 01-2.035 1.177l-.766-1.848a6.988 6.988 0 001.582-.915l1.219 1.586zM12 21c-.398 0-.79-.026-1.174-.076l.258-1.983a7.08 7.08 0 001.832 0l.258 1.983c-.384.05-.776.076-1.174.076z"
			/>
		</svg>
	);
}

const MemoSvgBestandunzureichend = React.memo(SvgBestandunzureichend);
export default MemoSvgBestandunzureichend;
