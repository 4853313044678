import { useAppState } from "@app/modules/app-state/context";
import { useQuery } from "@app/modules/graphql/queries";
import { TenantConfigurationDocument } from "@app/modules/tenant/queries/tenant-configuration.query.generated";

export function useTenantConfiguration() {
	const { tenant } = useAppState();
	const [{ data }] = useQuery({
		query: TenantConfigurationDocument,
		variables: { tenant },
		pause: !tenant,
		requestPolicy: "cache-first",
	});

	return data?.tenantConfigurationByPk;
}
