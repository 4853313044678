import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDna({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.39 4.746a5.87 5.87 0 011.774-.449 1 1 0 00-.183-1.992 7.87 7.87 0 00-5.475 2.98l-.022.029-.02.03c-1.068 1.582-1.396 3.497-1.088 5.666a7.453 7.453 0 00-4.295.203l-.013.004-.013.005c-1.936.743-3.327 2.212-4.222 3.446C.937 15.906.47 17.006.408 17.171a1 1 0 001.878.69l.002-.006c.015-.037.11-.26.29-.603a11.727 11.727 0 01.934-1.491l4.755 4.755c-.284.13-.58.237-.885.318l-.015.003-.014.005c-.33.097-.669.164-1.011.2a1 1 0 10.207 1.99c.46-.049.913-.138 1.356-.268a7.43 7.43 0 004.107-2.819l.01-.013.01-.014c1.072-1.588 1.398-3.51 1.085-5.686a8.192 8.192 0 002.169.262h.036a6.812 6.812 0 002.138-.458c1.935-.744 3.325-2.211 4.22-3.445.896-1.238 1.364-2.337 1.424-2.503a1 1 0 00-1.877-.689l-.002.005c-.016.037-.11.26-.29.603-.198.377-.49.877-.874 1.41l-4.67-4.671zm-1.71 1.118a5.88 5.88 0 00-.578.628c-.139.209-.262.43-.368.664l4.696 4.694c.475-.26.908-.582 1.3-.936l-5.05-5.05zm-1.415 3.65l2.98 2.98h-.004a6.19 6.19 0 01-2.646-.563c-.224-.89-.33-1.692-.33-2.416zM9.92 19.34l-5.062-5.062a6.714 6.714 0 011.409-.965l-.101.101 4.596 4.596.072-.071a4.722 4.722 0 01-.45.846 5.43 5.43 0 01-.464.555zm1.311-3.69c-.009-.704-.117-1.482-.335-2.341a5.46 5.46 0 00-2.526-.519l2.861 2.86z"
			/>
		</svg>
	);
}

const MemoSvgDna = React.memo(SvgDna);
export default MemoSvgDna;
