import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgInfo({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 10.001 18.001A9 9 0 0012 3zm0 6.5a1 1 0 01.993.883L13 10.5V16h1.5a1 1 0 01.117 1.993L14.5 18h-5a1 1 0 01-.117-1.993L9.5 16H11v-4.5h-1a1 1 0 01-.993-.883L9 10.5a1 1 0 01.883-.993L10 9.5h2zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
			/>
		</svg>
	);
}

const MemoSvgInfo = React.memo(SvgInfo);
export default MemoSvgInfo;
