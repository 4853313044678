import type { Languages_Enum } from "@app/modules/graphql-api-types.generated";
import type { Namespace } from "./namespaces";
import type { Translations } from "./types";

type PendingNamespaceModule = Promise<{ default: Translations }>;

type PendingTranslations = Array<[Namespace, PendingNamespaceModule]>;

export async function loadTranslations(locale: Languages_Enum) {
	// dynamically generated urls are currently not possible with dynamic loading
	switch (locale) {
		case "de_CH":
			return loadNamespaces([
				["common", import("./translations/de_CH/common.json")],
			]);
		case "en":
			return loadNamespaces([
				["common", import("./translations/en/common.json")],
			]);
		case "fr_CH":
			return loadNamespaces([
				["common", import("./translations/fr_CH/common.json")],
			]);
		case "it_CH":
			return loadNamespaces([
				["common", import("./translations/it_CH/common.json")],
			]);
		default:
			return {};
	}
}

async function loadNamespaces(
	pending: PendingTranslations,
): Promise<Translations> {
	const namespaceTranslations = await Promise.all(
		pending.map(async ([ns, pend]) => {
			const translations = (await pend).default;
			return [ns, translations] as const;
		}),
	);
	const translations: Translations = {};
	namespaceTranslations.forEach(([ns, translation]) => {
		translations[ns] = translation;
	});
	return translations;
}
