import { useAppState } from "@app/modules/app-state/context";
import { useTranslate } from "@app/modules/i18n/context";
import { useAllowedTenants } from "@app/modules/tenant/useAllowedTenants";

export function useIsShared(tenant: string | undefined) {
	const appState = useAppState();
	return tenant && tenant !== appState.tenant;
}

export function useGetIsShared() {
	const appState = useAppState();
	return (tenant: string) => tenant && tenant !== appState.tenant;
}

export function useIsEntityShared(entity: { tenant?: string } | undefined) {
	const appState = useAppState();
	return Boolean(entity?.tenant) && entity?.tenant !== appState.tenant;
}

export function useGetIsEntityShared() {
	const appState = useAppState();
	return (entity: { tenant: string }) => entity.tenant !== appState.tenant;
}

export function useAllowedTenantsNames(allowedTenants: string[]) {
	const t = useTranslate("common");
	const { byTenant } = useAllowedTenants();
	const sharedTenantNames = allowedTenants
		.map((tnt) => byTenant[tnt]?.tenantName)
		.filter(Boolean)
		.join(", ");
	return sharedTenantNames || t("tenant.sharing.none");
}
