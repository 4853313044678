import hotkeys from "hotkeys-js";
import type { ReactNode } from "react";
import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

const HotkeyOverlayContext = createContext(false);

const ALT_HOLD_IN_MS = 500;

export interface HotkeyOverlayProviderProps {
	children?: ReactNode;
}

export function HotkeyOverlayProvider({
	children,
}: HotkeyOverlayProviderProps) {
	const [isVisible, setIsVisible] = useState(false);
	const keydownTimestampRef = useRef<number | null>(null);
	const hasBeenPressed = useRef(isVisible);
	hasBeenPressed.current = isVisible;

	useEffect(() => {
		hotkeys("*", { keyup: true }, (event) => {
			// if we want to support more modifier only hotkeys we need to extend the state to support per modifier state
			if (hotkeys.alt) {
				if (event.type === "keydown" && keydownTimestampRef.current === null) {
					keydownTimestampRef.current = Date.now();
				}

				if (
					!hasBeenPressed.current &&
					keydownTimestampRef.current &&
					keydownTimestampRef.current + ALT_HOLD_IN_MS <= Date.now()
				) {
					setIsVisible(true);
				}

				if (event.type === "keyup") {
					keydownTimestampRef.current = null;
					setIsVisible(false);
				}
			}
		});

		return () => {
			hotkeys.unbind("*");
		};
	}, []);

	return (
		<HotkeyOverlayContext.Provider value={isVisible}>
			{children}
		</HotkeyOverlayContext.Provider>
	);
}

export function useHotkeyOverlay() {
	return useContext(HotkeyOverlayContext);
}
