import { logger } from "@app/modules/logger/logger";

export async function fetchFrontendVersion(signal?: AbortSignal) {
	return fetch("/tag.txt", { signal })
		.then((res) => {
			if (!res.ok) {
				throw new Error(`${res.status}: Failed to fetch frontend version`);
			}
			return res.text();
		})
		.then((text) => text.trim())
		.catch((error) => {
			if (signal && !signal.aborted) {
				logger.error(error, "Could not fetch frontend version");
			}
		});
}
