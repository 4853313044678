import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgArticle({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.553 2.106a1 1 0 01.771-.052l.123.052 8.023 4.011.096.058.098.077.09.091.058.073.062.098.054.112.035.105.008.03c.02.076.029.154.03.23V17a1 1 0 01-.445.832l-.109.062-8.002 4.002a.944.944 0 01-.114.048l-.079.024-.08.017-.046.007L12 22l-.088-.004-.119-.018a.91.91 0 01-.24-.084l.117.05-.058-.022-.059-.028-8-4a1 1 0 01-.545-.77L3 17V6.992c0-.037.003-.074.007-.111l.022-.12.023-.079.026-.069.063-.124.05-.077.08-.097.09-.084.096-.07.036-.023.06-.032 8-4zM4.999 8.618v7.763l6.001 3v-7.763l-6.001-3zm14 0l-5.999 3v7.763l6-2.999-.001-7.764zM12 4.118L6.236 6.999 12 9.881 17.763 7 12 4.118z"
			/>
		</svg>
	);
}

const MemoSvgArticle = React.memo(SvgArticle);
export default MemoSvgArticle;
