import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgW2({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<g clipPath="url(#clip0)">
				<rect x="18" y="9" width="3" height="23" fill="#B0B7C3" />
				<path
					d="M2 33C2 32.4477 2.44772 32 3 32H37C37.5523 32 38 32.4477 38 33V39C38 39.5523 37.5523 40 37 40H3C2.44772 40 2 39.5523 2 39V33Z"
					fill="#F1F2F4"
				/>
				<path
					d="M14.308 0.545972C14.4787 0.210939 14.823 0 15.199 0H23.801C24.177 0 24.5213 0.21094 24.692 0.545972L28.2591 7.54597C28.5981 8.21129 28.1148 9 27.3681 9H11.6319C10.8852 9 10.4019 8.21129 10.7409 7.54597L14.308 0.545972Z"
					fill="#F1F2F4"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="40" height="40" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

const MemoSvgW2 = React.memo(SvgW2);
export default MemoSvgW2;
