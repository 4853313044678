import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgStock({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.41 2.236l.208.085 6.206 2.83c1.46.662 1.512 2.226.155 2.954l-.15.075-2.537 1.155 2.535 1.137c1.463.665 1.51 2.232.149 2.955l-.152.075-2.444 1.113 2.447 1.179c1.461.664 1.512 2.23.153 2.955l-.151.074-6.152 2.806c-.896.408-2.217.437-3.174.087l-.214-.088-6.15-2.804c-1.46-.664-1.51-2.23-.147-2.958l.152-.074 2.526-1.134-2.535-1.156c-1.46-.664-1.511-2.23-.147-2.957l.152-.075 2.53-1.136L4.14 8.18c-1.461-.664-1.512-2.229-.152-2.955l.151-.075 6.202-2.829c.87-.397 2.143-.426 3.07-.085zm1.602 13.459l-1.338.611c-.897.41-2.218.438-3.175.088l-.214-.088-1.193-.544-3.45 1.549 5.476 2.497c.39.178 1.107.198 1.57.06l.16-.06 5.498-2.507-3.334-1.606zm-5.919-5.256l-3.455 1.55 5.476 2.498c.39.177 1.107.197 1.57.059l.16-.06 5.477-2.498-3.452-1.548-1.191.545c-.897.409-2.218.438-3.175.087l-.214-.087-1.196-.546zm2.228-6.354l-.15.055-5.534 2.524 5.482 2.501c.346.158.951.191 1.406.1l.163-.04.16-.06 5.48-2.499-5.54-2.526c-.364-.166-1.035-.184-1.467-.055z"
			/>
		</svg>
	);
}

const MemoSvgStock = React.memo(SvgStock);
export default MemoSvgStock;
