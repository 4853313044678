import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDashboard({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21 13a1 1 0 01.993.883L22 14v7a1 1 0 01-.883.993L21 22h-7a1 1 0 01-.993-.883L13 21v-7a1 1 0 01.883-.993L14 13h7zm-11 0a1 1 0 01.993.883L11 14v7a1 1 0 01-.883.993L10 22H3a1 1 0 01-.993-.883L2 21v-7a1 1 0 01.883-.993L3 13h7zm10 2h-5v5h5v-5zM9 15H4v5h5v-5zM21 2a1 1 0 01.993.883L22 3v7a1 1 0 01-.883.993L21 11h-7a1 1 0 01-.993-.883L13 10V3a1 1 0 01.883-.993L14 2h7zM10 2a1 1 0 01.993.883L11 3v7a1 1 0 01-.883.993L10 11H3a1 1 0 01-.993-.883L2 10V3a1 1 0 01.883-.993L3 2h7zm10 2h-5v5h5V4zM9 4H4v5h5V4z"
			/>
		</svg>
	);
}

const MemoSvgDashboard = React.memo(SvgDashboard);
export default MemoSvgDashboard;
