import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgClouddone({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M15.984 10.394a1 1 0 10-1.53-1.288l-4.314 5.123-1.398-1.549a1 1 0 00-1.484 1.34l2.165 2.4a1 1 0 001.508-.026l5.053-6z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 3.5c-3.395 0-5.663 2.188-6.604 4.493-1.313.232-2.561.774-3.534 1.662C.712 10.704 0 12.187 0 14.033c0 3.666 3.016 6.134 6.5 6.134h11.917c1.41 0 2.8-.35 3.86-1.194 1.09-.869 1.723-2.177 1.723-3.84 0-1.71-.776-2.976-1.854-3.8-.798-.61-1.748-.972-2.662-1.136C18.752 6.1 15.679 3.5 12 3.5zM7.09 9.191C7.659 7.324 9.37 5.5 12 5.5c2.778 0 5.243 2.05 5.605 5.699a1 1 0 00.943.9c.828.043 1.722.316 2.384.822.63.482 1.068 1.181 1.068 2.212 0 1.113-.4 1.822-.97 2.276-.602.48-1.504.758-2.613.758H6.5c-2.566 0-4.5-1.75-4.5-4.134 0-1.289.48-2.234 1.21-2.9.749-.684 1.815-1.117 3.023-1.238a1 1 0 00.857-.704z"
			/>
		</svg>
	);
}

const MemoSvgClouddone = React.memo(SvgClouddone);
export default MemoSvgClouddone;
