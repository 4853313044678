import type {
	Mutation_Root,
	Mutation_RootActivateUserArgs,
	Mutation_RootDeactivateUserArgs,
	Mutation_RootDeleteCustomerBillingSettingByPkArgs,
	Mutation_RootDeleteCustomerOrderByPkArgs,
	Mutation_RootDeleteCustomerOrderItemAdjustmentByPkArgs,
	Mutation_RootDeleteCustomerOrderItemByPkArgs,
	Mutation_RootDeleteCustomerOrderPickedContainerByPkArgs,
	Mutation_RootDeleteCustomerOrderPickedContainersArgs,
	Mutation_RootDeletePickedItemByPkArgs,
	Mutation_RootDeletePickedItemsArgs,
	Mutation_RootDeleteProductRecipeIngredientsArgs,
	Mutation_RootDeleteProductS3ObjectByPkArgs,
	Mutation_RootDeleteSupplierOrderGoodsIncomeItemByPkArgs,
	Mutation_RootDeleteSupplierOrderItemByPkArgs,
	Mutation_RootDeleteTemporalProductByPkArgs,
	Mutation_RootDelete_Customer_Order_ItemArgs,
	Mutation_RootDelete_Picked_ItemsArgs,
	Mutation_RootInsertCustomerOrderItemAdjustmentArgs,
	Mutation_RootInsertCustomerOrderItemArgs,
	Mutation_RootInsertCustomerOrderReturnItemArgs,
	Mutation_RootInsertPickedItemArgs,
	Mutation_RootInsertProductSuppliersArgs,
	Mutation_RootInsertSupplierOrderGoodsIncomeItemArgs,
	Mutation_RootInsertSupplierOrderGoodsIncomeItemsArgs,
	Mutation_RootInsertSupplierOrderItemArgs,
	Mutation_RootInsertSupplierOrderReturnItemArgs,
	Mutation_RootUpdateCuttingPatternByPkArgs,
	Mutation_RootUpdateUserRolesArgs,
	Mutation_RootUpdate_Customer_Order_Items_ManyArgs,
	Mutation_RootUpdate_Cutting_Patterns_ManyArgs,
} from "@app/modules/graphql-api-types.generated";
import { Cutting_Pattern_States_Enum } from "@app/modules/graphql-api-types.generated";
import { devlogger } from "@app/modules/logger/devlogger";
import type {
	Cache,
	Data,
	ResolveInfo,
	Variables,
} from "@urql/exchange-graphcache";

type MutationResponse = Partial<Mutation_Root>;

interface TypedConfig {
	[name: string]: (
		// lets find an alternative for these "any"
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		result: Data | any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		args: Variables | any,
		cache: Cache,
		info: ResolveInfo,
	) => void;
}

const Mutation: TypedConfig = {
	insertCustomer: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["customers"]);
	},
	insertCustomerOrderItem: (
		_0,
		variables: Mutation_RootInsertCustomerOrderItemArgs,
		cache,
	) => {
		if (variables.object.customerOrderId) {
			cache.invalidate({
				__typename: "customer_orders",
				id: variables.object.customerOrderId,
			});
		}
		if (variables.object.productId) {
			cache.invalidate({
				__typename: "products",
				id: variables.object.productId,
			});
		}
	},
	deleteCustomerOrderByPk: (
		_0,
		variables: Mutation_RootDeleteCustomerOrderByPkArgs,
		cache,
	) => {
		cache.invalidate({
			__typename: "customer_orders",
			id: variables.id,
		});
	},
	deleteCustomerOrderItemByPk: (
		_0,
		variables: Mutation_RootDeleteCustomerOrderItemByPkArgs,
		cache,
	) => {
		cache.invalidate({
			__typename: "customer_order_items",
			id: variables.id,
		});
	},
	delete_customer_order_item: (
		_0,
		variables: Mutation_RootDelete_Customer_Order_ItemArgs,
		cache,
	) => {
		const id = variables.args._id;
		if (id) {
			cache.invalidate({
				__typename: "customer_order_items",
				id,
			});
		}
	},
	insertCustomerOrderReturnItem: (
		_0,
		variables: Mutation_RootInsertCustomerOrderReturnItemArgs,
		cache,
	) => {
		if (variables.object.customerOrderItemId) {
			cache.invalidate({
				__typename: "customer_order_items",
				id: variables.object.customerOrderItemId,
			});
		}
	},
	deleteCustomerOrderReturnItemByPk: (
		_0,
		variables: Mutation_RootDeleteCustomerOrderItemByPkArgs,
		cache,
	) => {
		if (variables.id) {
			cache.invalidate({
				__typename: "customer_order_return_items",
				id: variables.id,
			});
		}
	},
	deleteCustomerOrderItemAdjustmentByPk: (
		_0,
		variables: Mutation_RootDeleteCustomerOrderItemAdjustmentByPkArgs,
		cache,
	) => {
		if (variables.id) {
			cache.invalidate({
				__typename: "customer_order_item_adjustments",
				id: variables.id,
			});
		}
	},
	insertSupplierOrderItem: (
		_0,
		variables: Mutation_RootInsertSupplierOrderItemArgs,
		cache,
	) => {
		if (variables.object.supplierOrderId) {
			cache.invalidate({
				__typename: "supplier_orders",
				id: variables.object.supplierOrderId,
			});
		}
	},
	deleteSupplierOrderItemByPk: (
		_0,
		variables: Mutation_RootDeleteSupplierOrderItemByPkArgs,
		cache,
	) => {
		cache.invalidate({
			__typename: "supplier_order_items",
			id: variables.id,
		});
	},
	insertSupplierOrderGoodsIncomeItems: (
		_0,
		variables: Mutation_RootInsertSupplierOrderGoodsIncomeItemsArgs,
		cache,
	) => {
		variables.objects.forEach((object) => {
			if (object.supplierOrderItemId) {
				cache.invalidate({
					__typename: "supplier_order_items",
					id: object.supplierOrderItemId,
				});
			}
		});
	},
	insertSupplierOrderGoodsIncomeItem: (
		_0,
		variables: Mutation_RootInsertSupplierOrderGoodsIncomeItemArgs,
		cache,
	) => {
		if (variables.object.supplierOrderItemId) {
			cache.invalidate({
				__typename: "supplier_order_items",
				id: variables.object.supplierOrderItemId,
			});
		}
	},
	deleteSupplierOrderGoodsIncomeItemByPk: (
		_0,
		variables: Mutation_RootDeleteSupplierOrderGoodsIncomeItemByPkArgs,
		cache,
	) => {
		if (variables.id) {
			cache.invalidate({
				__typename: "supplier_order_goods_income_items",
				id: variables.id,
			});
		}
	},
	insertSupplierOrderReturnItem: (
		_0,
		variables: Mutation_RootInsertSupplierOrderReturnItemArgs,
		cache,
	) => {
		if (variables.object.supplierOrderItemId) {
			cache.invalidate({
				__typename: "supplier_order_items",
				id: variables.object.supplierOrderItemId,
			});
		}
	},
	deleteSupplierOrderReturnItemByPk: (
		_0,
		variables: Mutation_RootDeleteSupplierOrderItemByPkArgs,
		cache,
	) => {
		if (variables.id) {
			cache.invalidate({
				__typename: "supplier_order_return_items",
				id: variables.id,
			});
		}
	},
	order_supplied_products: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["products"]);
	},
	initialize_customer_pricing_config: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["customerPricingConfigs"]);
	},
	deleteCustomerPricingConfigByPk: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["customerPricingConfigs"]);
	},
	insertTemporalProduct: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["products_pricing"]);
		invalidateQueryFields(cache, ["temporalProducts"]);
	},
	deleteTemporalProductByPk: (
		_0,
		variables: Mutation_RootDeleteTemporalProductByPkArgs,
		cache,
	) => {
		if (variables.id) {
			cache.invalidate({
				__typename: "temporal_products",
				id: variables.id,
			});
		}
	},
	insertPickedItem: (
		_0,
		variables: Mutation_RootInsertPickedItemArgs,
		cache,
	) => {
		if (variables.object.customerOrderItemId) {
			cache.invalidate({
				__typename: "customer_order_items",
				id: variables.object.customerOrderItemId,
			});
		}
	},
	deletePickedItemByPk: (
		_0,
		variables: Mutation_RootDeletePickedItemByPkArgs,
		cache,
	) => {
		if (variables.id) {
			cache.invalidate({
				__typename: "picked_items",
				id: variables.id,
			});
		}
	},
	delete_picked_items: (
		_0,
		variables: Mutation_RootDelete_Picked_ItemsArgs,
		cache,
	) => {
		// eslint-disable-next-line no-underscore-dangle
		if (Array.isArray(variables.args._pi_ids)) {
			// eslint-disable-next-line no-underscore-dangle
			variables.args._pi_ids.forEach((id) => {
				cache.invalidate({
					__typename: "picked_items",
					id: id as any,
				});
			});
		}
	},
	deleteCustomerOrderPickedContainers: (
		_0,
		variables: Mutation_RootDeleteCustomerOrderPickedContainersArgs,
		cache,
	) => {
		const ids = variables.where.id?._in ?? [];
		ids.forEach((id) => {
			cache.invalidate({
				__typename: "customer_order_picked_container",
				id,
			});
		});
	},
	deleteCustomerOrderPickedContainerByPk: (
		_0,
		variables: Mutation_RootDeleteCustomerOrderPickedContainerByPkArgs,
		cache,
	) => {
		if (variables.id) {
			cache.invalidate({
				__typename: "customer_order_picked_container",
				id: variables.id,
			});
		}
	},
	deletePickedItems: (
		_0,
		variables: Mutation_RootDeletePickedItemsArgs,
		cache,
	) => {
		// eslint-disable-next-line no-underscore-dangle
		const orderId = variables.where.customerOrderItem?.customerOrder?.id?._eq;

		if (orderId) {
			cache.invalidate({
				__typename: "customer_orders",
				id: orderId,
			});
		}
	},
	insertCustomerOrderItemAdjustment: (
		_0,
		{ object }: Mutation_RootInsertCustomerOrderItemAdjustmentArgs,
		cache,
	) => {
		if (object.customerOrderItemId) {
			cache.invalidate({
				__typename: "customer_order_items",
				id: object.customerOrderItemId,
			});
		}
	},
	deleteProductRecipeIngredients: (
		_0,
		variables: Mutation_RootDeleteProductRecipeIngredientsArgs,
		cache,
	) => {
		// eslint-disable-next-line no-underscore-dangle
		const ingredientIds = variables.where.id?._in ?? [];

		ingredientIds.forEach((id) => {
			cache.invalidate({
				__typename: "product_recipe_ingredients",
				id,
			});
		});
	},
	create_partial_delivery: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["partialDeliveries"]);
	},
	delete_partial_delivery: (data: MutationResponse, _1, cache) => {
		invalidateQueryFields(cache, ["partialDeliveries"]);
		data?.delete_partial_delivery?.forEach((pd) => {
			pd?.pickedItems?.forEach(({ id }) => {
				cache.invalidate({ __typename: "picked_items", id });
			});
		});
	},
	deleteProductS3ObjectByPk: (
		_0,
		variables: Mutation_RootDeleteProductS3ObjectByPkArgs,
		cache,
	) => {
		cache.invalidate({
			__typename: "s3_objects",
			id: variables.s3ObjectId,
		});
		cache.invalidate({
			__typename: "product_s3_objects",
			productId: variables.productId,
			s3ObjectId: variables.s3ObjectId,
		});
	},
	insertAccount: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["accounts"]);
	},
	deleteCustomerBillingSettingByPk: (
		_0,
		variables: Mutation_RootDeleteCustomerBillingSettingByPkArgs,
		cache,
	) => {
		cache.invalidate({
			__typename: "customer_billing_settings",
			id: variables.id,
		});
	},
	...invalidateOn("insertProductStockLocations", [["productId", "products"]]),
	...invalidateOn("deleteProductStockLocations", [["productId", "products"]]),
	insertProductSuppliers: (
		_0,
		{ objects }: Mutation_RootInsertProductSuppliersArgs,
		cache,
	) => {
		const productIds = objects
			.map((object) => object.productId)
			.filter(Boolean);
		productIds.forEach((productId) => {
			cache.invalidate({
				__typename: "products",
				id: productId,
			});
		});
	},
	update_customer_order_items_many: (
		_0,
		{ updates }: Mutation_RootUpdate_Customer_Order_Items_ManyArgs,
		cache,
	) => {
		updates.forEach((update) => {
			// eslint-disable-next-line no-underscore-dangle
			if (update.where.id?._eq && update._set?.ordinalPosition !== undefined) {
				cache.invalidate({
					__typename: "customer_order_items",
					// eslint-disable-next-line no-underscore-dangle
					id: update.where.id?._eq,
				});
			}
		});
	},
	...invalidateOn("activate_price_list", [["id", "price_lists"]]),
	deleteSupplierOrderReturnedContainers: (res: MutationResponse, _1, cache) => {
		invalidateQueryFields(cache, [
			"supplierOrderReturnedContainers",
			"supplierOrderReturnedContainers_aggregate",
		]);
		invalidateRelatedEntities(
			cache,
			res.deleteSupplierOrderReturnedContainers,
			[["supplierOrderId", "supplier_orders"]],
		);
	},
	insertSupplierOrderReturnedContainers: (res: MutationResponse, _1, cache) => {
		invalidateQueryFields(cache, [
			"supplierOrderReturnedContainers",
			"supplierOrderReturnedContainers_aggregate",
		]);
		invalidateRelatedEntities(
			cache,
			res.insertSupplierOrderReturnedContainers,
			[["supplierOrderId", "supplier_orders"]],
		);
	},
	update_cutting_patterns_many: (
		_0,
		{ updates }: Mutation_RootUpdate_Cutting_Patterns_ManyArgs,
		cache,
	) => {
		updates.forEach((update) => {
			// eslint-disable-next-line no-underscore-dangle
			if (update.where.id?._eq && update._set?.priority !== undefined) {
				cache.invalidate({
					__typename: "cutting_patterns",
					// eslint-disable-next-line no-underscore-dangle
					id: update.where.id?._eq,
				});
			}
		});
	},
	updateCuttingPatternByPk: (
		_0,
		variables: Mutation_RootUpdateCuttingPatternByPkArgs,
		cache,
	) => {
		const { id } = variables.pk_columns;
		const state = variables._set?.state;
		if (!state || state !== Cutting_Pattern_States_Enum.Archived) {
			return;
		}

		cache.invalidate({
			__typename: "cutting_patterns",
			id,
		});
	},
	updateUserRoles: (_0, variables: Mutation_RootUpdateUserRolesArgs, cache) => {
		const id = variables.userId;

		cache.invalidate({
			__typename: "users",
			id,
		});
	},
	deactivateUser: (_0, variables: Mutation_RootDeactivateUserArgs, cache) => {
		const id = variables.userId;

		cache.invalidate({
			__typename: "users",
			id,
		});
	},
	activateUser: (_0, variables: Mutation_RootActivateUserArgs, cache) => {
		const id = variables.userId;

		cache.invalidate({
			__typename: "users",
			id,
		});
	},
	updateNotificationByPk: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["notifications"]);
	},
	set_exchange_rate: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["exchangeRates"]);
	},
	insertProductionSiteProductGroupCalendar: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["fullWorkCalendars"]);
	},
	deleteProductionSiteProductGroupCalendarByPk: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["fullWorkCalendars"]);
	},
	...invalidateOn("insertCustomerOrderItemFulfillmentPlans", [
		["customerOrderItemId", "customer_order_items"],
	]),
	...invalidateOn("deleteSalesTeamUsers", [["salesTeamId", "sales_teams"]]),
	...invalidateOn("insertSalesTeamUsers", [["salesTeamId", "sales_teams"]]),
	...invalidateOn("deleteCustomerGroupPricingByPk", [
		["id", "customer_group_pricing"],
	]),
	...invalidateOn("insertProductSalesChannelMembers", [
		// Because we inline this entity (see UrqlClientProvider) we have no choice
		// but to invalidate the associated product and salesChannel.
		["salesChannelId", "sales_channels"],
		["productId", "products"],
	]),
	...invalidateOn("deleteProductSalesChannelMembers", [
		["salesChannelId", "sales_channels"],
		["productId", "products"],
	]),
	...invalidateOn("deleteCustomerProductAvailabilities", [
		["id", "customer_product_availabilities"],
		["productId", "products"],
	]),
	...invalidateOn("insertCustomerProductAvailabilities", [
		["id", "customer_product_availabilities"],
		["productId", "products"],
	]),
	...invalidateOn("deleteCustomerProductAvailabilities", [
		["id", "customer_product_availabilities"],
		["productId", "products"],
	]),
	deleteProductAllowedContainers: (_0, _1, cache) => {
		invalidateQueryFields(cache, [
			"productByPk",
			"productAllowedContainersViaProduct",
		]);
	},
	insertProductAllowedContainers: (_0, _1, cache) => {
		invalidateQueryFields(cache, [
			"productByPk",
			"productAllowedContainersViaProduct",
		]);
	},
	insertProductS3Object: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["productByPk", "productS3Objects"]);
	},
	product_set_s3_object_as_product_image: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["productByPk", "productS3Objects"]);
	},
	delete_product_s3_object: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["productByPk", "productS3Objects"]);
	},
	insertLotNumber: (_0, _1, cache) => {
		invalidateQueryFields(cache, ["search_lot_numbers", "lotNumbers"]);
	},
	insertProductPackagingConfiguration: (_0, _1, cache) => {
		invalidateQueryFields(cache, [
			"productByPk",
			"customerProductAvailabilities",
		]);
	},
};

interface GenericResponse {
	returning?: Data[];
}

/**
 * Invalidates the entities specified by `fields`.
 * Does not work when using a mutation alias.
 */
function invalidateOn<M extends keyof MutationResponse>(
	mutation: M,
	fields: [string, string][],
) {
	return {
		[mutation]: (response: MutationResponse, _: unknown, cache: Cache) => {
			const mutationRes = response[mutation];
			if (!isGenericResponse(mutationRes)) {
				devlogger.warn(
					"Cannot invalidate cache with non-standard response. Please make sure to return the `returning` key or provide a custom invalidation method.",
					mutation,
					response[mutation],
				);
				return;
			}
			invalidateRelatedEntities(cache, mutationRes, fields);
		},
	};
}

function isGenericResponse(res: unknown): res is GenericResponse {
	return Array.isArray((res as GenericResponse)?.returning);
}

function invalidateRelatedEntities(
	cache: Cache,
	response: GenericResponse | undefined | null,
	fields: [string, string][],
) {
	const returning = response?.returning;

	returning?.forEach((entity) => {
		fields.forEach(([key, typename]) => {
			const value = entity[key];
			if (value && typeof value === "string") {
				cache.invalidate({ __typename: typename, id: value });
			}
		});
	});
}

function invalidateQueryFields(cache: Cache, fieldNames: string[]) {
	cache
		.inspectFields("Query")
		.filter((query) => fieldNames.includes(query.fieldName))
		.forEach((query) => {
			cache.invalidate("Query", query.fieldName, query.arguments || {});
		});
}

const Subscription = {};

export const updates = {
	Mutation,
	Subscription,
};
