// THIS FILE IS GENERATED, DO NOT EDIT!
import type * as Types from '../../../graphql-api-types.generated';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SupplierOrderStateSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['bigint'];
}>;


export type SupplierOrderStateSubscription = { __typename: 'subscription_root', supplierOrderByPk?: { __typename: 'supplier_orders', id: string, state: Types.Supplier_Order_States_Enum, currency: Types.Currencies_Enum } | null };


export const SupplierOrderStateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"SupplierOrderState"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bigint"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"supplierOrderByPk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}}]}}]}}]} as unknown as DocumentNode<SupplierOrderStateSubscription, SupplierOrderStateSubscriptionVariables>;