import { LocalDate } from "@app/modules/localdate/localdate";
import type { LogLevel } from "@app/modules/logger/utils";
import { logToConsole } from "@app/modules/logger/utils";
import { captureException, captureMessage } from "@app/modules/logrocket";

function makeLogFunction(level: LogLevel): typeof console.log {
	return (...data: unknown[]) =>
		logToConsole(level, LocalDate.now().format("HH:mm:ss.SSS"), ...data);
}

const logError = makeLogFunction("ERROR");

const logger = {
	log: makeLogFunction("DEBUG"),
	info: makeLogFunction("INFO"),
	warn: makeLogFunction("WARN"),
	error: (
		error: Error | unknown | undefined,
		message: string,
		extra?: Record<string, string>,
	) => {
		logError(...[message, extra, error].filter(Boolean));
		if (isError(error)) {
			captureException(error, { extra });
		} else {
			captureMessage(message, { extra });
		}
	},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isError(error: any): error is Error {
	return error && error?.stack && error?.message;
}

export { logger };
