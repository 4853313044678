import {
	Calendar as CalendarIcon,
	DropdownClosed,
} from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import { Button } from "@app/modules/Button";
import type { ControlledProps } from "@app/modules/form/controller";
import { useController } from "@app/modules/form/controller";
import { getViewState, Input } from "@app/modules/input-fields/commons";
import {
	Calendar,
	CalendarButton,
} from "@app/modules/input-fields/date/Calendar";
import { AriaDateInput } from "@app/modules/input-fields/date/date-commons";
import { useInputGroup } from "@app/modules/input-fields/InputGroup";
import type {
	InputBaseProps,
	InputValueProps,
	InputVisualProps,
} from "@app/modules/input-fields/types";
import { TooltipV2 } from "@app/modules/layout/TooltipV2";
import type { DateValue } from "@internationalized/date";
import { parseDate } from "@internationalized/date";
import clsx from "clsx";
import { useRef } from "react";
import type { AriaDatePickerProps } from "react-aria";
import { useDatePicker } from "react-aria";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useDatePickerState } from "react-stately";
import type { AnyVariables } from "urql";

export interface DateInputProps
	extends InputBaseProps,
		InputVisualProps,
		InputValueProps<string> {
	min?: string;
	max?: string;
}

export function DateInput({
	id,
	label,
	onChange,
	value,
	defaultValue,
	className,
	state = "default",
	sizing = "md",
	hint,
	min,
	max,
	readOnly,
	disabled,
	name,
	...inputProps
}: DateInputProps) {
	const pickerProps: AriaDatePickerProps<DateValue> = {
		id,
		defaultValue: defaultValue ? parseDate(defaultValue) : undefined,
		value: value ? parseDate(value) : undefined,
		onChange: (newDate) => {
			onChange?.(newDate.toString());
		},
		minValue: min ? parseDate(min) : undefined,
		maxValue: max ? parseDate(max) : undefined,
		isDisabled: disabled,
		isReadOnly: readOnly,
		"aria-label": name,
	};
	const pickerState = useDatePickerState(pickerProps);
	const datePickerRef = useRef(null);
	const { buttonProps, calendarProps, fieldProps, groupProps, labelProps } =
		useDatePicker(pickerProps, pickerState, datePickerRef);

	const group = useInputGroup(name);
	const validationState = group.state ?? state;
	const viewState = getViewState(validationState, { disabled, readOnly });

	const buttonCls = clsx((disabled || readOnly) && "invisible");

	const control = (
		<TooltipV2
			hasArrow={false}
			visible={pickerState.isOpen}
			content={<Calendar {...calendarProps} />}
			contentClassName="p-0 shadow-card"
		>
			<Input.Control
				state={viewState}
				sizing={sizing}
				isInsideGroup={group.isInsideGroup}
				className={clsx("gap-8 date-input", group.isInsideGroup && className)}
				{...groupProps}
			>
				<AriaDateInput {...fieldProps} {...inputProps} name={name} />
				<Button
					variant="tertiary"
					tabIndex={-1}
					onClick={() => {
						const newValue = pickerState.value?.subtract({ days: 1 });
						if (newValue) {
							pickerState.setValue(newValue);
						}
					}}
					data-testid={`${name}-calendar-prev`}
					disabled={min ? min === pickerState.value.toString() : false}
					className={buttonCls}
				>
					<Icon
						icon={DropdownClosed}
						size="16"
						className="transform rotate-90"
					/>
				</Button>
				<Button
					variant="tertiary"
					tabIndex={-1}
					onClick={() => {
						const newValue = pickerState.value?.add({ days: 1 });
						if (newValue) {
							pickerState.setValue(newValue);
						}
					}}
					data-testid={`${name}-calendar-next`}
					disabled={max ? max === pickerState.value.toString() : false}
					className={buttonCls}
				>
					<Icon
						icon={DropdownClosed}
						size="16"
						className="transform -rotate-90"
					/>
				</Button>
				<CalendarButton
					{...buttonProps}
					icon={CalendarIcon}
					className={buttonCls}
				/>
			</Input.Control>
		</TooltipV2>
	);

	if (group.isInsideGroup) {
		return control;
	}

	return (
		<div className={className}>
			<Input.Label {...labelProps} state={viewState} testid={name}>
				{label}
			</Input.Label>
			{control}
			{hint && <Input.Hint state={viewState}>{hint}</Input.Hint>}
		</div>
	);
}

export function DateInputField<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
>({
	control,
	name,
	defaultValue,
	constraint,
	...props
}: DateInputProps &
	ControlledProps<Values, Name, ConstraintResult, ConstraintVariables>) {
	const { field } = useController({
		control,
		name,
		defaultValue,
		constraint,
	});
	return <DateInput {...props} {...field} />;
}
