import { useAppState } from "@app/modules/app-state/context";

export interface TenantColorProps {
	className?: string;
}

export function TenantColorBar({ className }: TenantColorProps) {
	const { tenantDoc } = useAppState();
	if (!tenantDoc?.tenantColor) {
		return null;
	}

	return (
		<div
			className={className}
			style={{ borderBottom: `6px solid ${tenantDoc.tenantColor}` }}
		/>
	);
}
