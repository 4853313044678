import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDownload({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M13 4a1 1 0 10-2 0v10.086l-2.043-2.043a1 1 0 00-1.414 1.414l3.75 3.75a1 1 0 001.414 0l3.75-3.75a1 1 0 00-1.414-1.414L13 14.086V4z" />
			<path d="M4 15a1 1 0 10-2 0v4a3 3 0 003 3h14.007A2.994 2.994 0 0022 19v-4a1 1 0 10-2 0v4c0 .557-.445 1-.993 1H4.999A1 1 0 014 19v-4z" />
		</svg>
	);
}

const MemoSvgDownload = React.memo(SvgDownload);
export default MemoSvgDownload;
