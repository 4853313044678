import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgWirdzerlegt({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.3 1.046a1 1 0 01.685.78c1.45 8.219-3.297 13.417-7.094 17.575-.394.432-.778.852-1.144 1.263a1 1 0 01-1.454.043L8 16.414l-5.293 5.293a1 1 0 01-1.414-1.414l19-19a1 1 0 011.008-.247zM9.415 15l3.547 3.547.44-.485c3.466-3.818 7.109-7.831 6.838-13.887L9.414 15z"
			/>
		</svg>
	);
}

const MemoSvgWirdzerlegt = React.memo(SvgWirdzerlegt);
export default MemoSvgWirdzerlegt;
