import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSave({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.918 1c.606 0 1.19.217 1.648.607l.147.136 3.544 3.544c.428.428.688.994.735 1.594l.008.2v12.7a3.23 3.23 0 01-3.04 3.213l-.182.006H4.222a3.222 3.222 0 01-3.215-3.01L1 19.779V4.222a3.222 3.222 0 013.01-3.215L4.221 1h12.696zm0 2H4.222a1.222 1.222 0 00-1.214 1.084L3 4.222v15.556a1.222 1.222 0 001.084 1.214l.138.008h15.553a1.229 1.229 0 001.218-1.098l.007-.124V7.082a.539.539 0 00-.102-.317l-.056-.064L17.3 3.158a.538.538 0 00-.296-.151L16.918 3zM12 11.539a4.077 4.077 0 110 8.153 4.077 4.077 0 010-8.153zm0 2a2.077 2.077 0 100 4.153 2.077 2.077 0 000-4.153zM13.357 4c.868 0 1.566.677 1.637 1.524l.006.143v2.666c0 .865-.653 1.587-1.5 1.66l-.143.007H5.643a1.652 1.652 0 01-1.637-1.524L4 8.333V5.667c0-.865.653-1.587 1.5-1.66L5.643 4h7.714zM13 6H6v2h7V6z"
			/>
		</svg>
	);
}

const MemoSvgSave = React.memo(SvgSave);
export default MemoSvgSave;
