import clsx from "clsx";

type Size = "s" | "m" | "l";
type Color = "normal" | "dark";
type Axis = "x" | "y";

export interface DividerProps {
	className?: string;
	size?: Size;
	color?: Color;
	axis?: Axis;
}

export function Divider({
	className,
	axis = "x",
	size = "m",
	color = "normal",
}: DividerProps) {
	return (
		<div
			className={clsx(
				className,
				borderCls[axis],
				spacingCls[axis][size],
				colorCls[color],
			)}
		/>
	);
}

/* class={ */
const borderCls: Record<Axis, string> = {
	x: "border-b",
	y: "border-r",
};

const spacingCls: Record<Axis, Record<Size, string>> = {
	x: { l: "my-24", m: "my-16", s: "my-8" },
	y: { l: "mx-24", m: "mx-16", s: "mx-8" },
};

const colorCls: Record<Color, string> = {
	normal: "border-grey-200",
	dark: "border-grey-300",
};
/* } */

export function VerticalDivider(props: Omit<DividerProps, "axis">) {
	return <Divider {...props} axis="y" />;
}

export function HorizontalDivider(props: Omit<DividerProps, "axis">) {
	return <Divider {...props} axis="x" />;
}
