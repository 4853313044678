import type { ProductQuantityProps } from "@app/modules/product-unit/ProductQuantityInputLegacy";
import { ProductQuantity } from "@app/modules/product-unit/ProductQuantityInputLegacy";
import type { ProductUnitFragment } from "@app/modules/product-unit/queries/product-unit.fragment.generated";
import React from "react";

export interface ProductStockQuantityProps
	extends Omit<ProductQuantityProps, "value" | "unit"> {
	storageQuantity: string;
	storageUnit?: ProductUnitFragment;
	monetaryQuantity: string;
	monetaryUnit?: ProductUnitFragment;
}

export function ProductStockQuantity({
	storageUnit,
	storageQuantity,
	monetaryUnit,
	monetaryQuantity,
	...props
}: ProductStockQuantityProps) {
	const hasTwoUnits = storageUnit?.id !== monetaryUnit?.id;
	return (
		<>
			<ProductQuantity value={storageQuantity} unit={storageUnit} {...props} />
			{hasTwoUnits && (
				<>
					{", "}
					<ProductQuantity
						value={monetaryQuantity}
						unit={monetaryUnit}
						{...props}
					/>
				</>
			)}
		</>
	);
}
