import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgInvoiceOpen({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={16}
			height={16}
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.333 2.556h7.334c.43 0 .777.348.777.777v9.334c0 .43-.348.777-.777.777H4.333a.778.778 0 01-.777-.777V3.333c0-.43.348-.777.777-.777zM2 3.333A2.333 2.333 0 014.333 1h7.334A2.333 2.333 0 0114 3.333v9.334A2.333 2.333 0 0111.667 15H4.333A2.333 2.333 0 012 12.667V3.333zm3.778.778a.778.778 0 000 1.556h4.444a.778.778 0 100-1.556H5.778zm0 3.111a.778.778 0 100 1.556h4.444a.778.778 0 100-1.556H5.778zM5 11.112c0-.43.348-.779.778-.779h1.444a.778.778 0 110 1.556H5.778A.778.778 0 015 11.11z"
			/>
		</svg>
	);
}

const MemoSvgInvoiceOpen = React.memo(SvgInvoiceOpen);
export default MemoSvgInvoiceOpen;
