import {
	useAppState,
	useAppStateDispatch,
} from "@app/modules/app-state/context";
import type { AbilityTuple } from "@app/modules/authorization/paramaters.generated";
import { MyPermissionsDocument } from "@app/modules/authorization/queries/my-permissions.query.generated";
import type {
	AppAbility,
	CanFunction,
	CannotFunction,
	RelevantRuleForFunction,
} from "@app/modules/authorization/types";
import { useQuery } from "@app/modules/graphql/queries";
import { Ability } from "@casl/ability";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo } from "react";

interface AuthorizationContextValue {
	ability: AppAbility;
}

const AuthorizationContext = createContext<AuthorizationContextValue>({
	ability: new Ability<AbilityTuple>([]),
});

function snakeToCamel(snake: string) {
	return snake.replace(/([-_]\w)/g, (g) => g[1]?.toUpperCase() || "");
}

function snakeKeysToCamel(
	obj: Record<string, unknown>,
): Record<string, unknown> {
	const newObj: Record<string, unknown> = {};
	Object.entries(obj).forEach(([key, value]) => {
		newObj[snakeToCamel(key)] = value;
	});
	return newObj;
}

export interface AuthorizationProviderProps {
	children?: ReactNode;
}

export function AuthorizationProvider({
	children,
}: AuthorizationProviderProps) {
	const dispatch = useAppStateDispatch();
	const { tenant } = useAppState();
	const [res] = useQuery({ query: MyPermissionsDocument, variables: {} });

	const rolePermissions = res.data?.rolePermissions;
	const tenantPermissions = res.data?.tenantPermissions;

	const value: AuthorizationContextValue = useMemo(() => {
		const mutationActions =
			rolePermissions?.reduce((acc, { action }) => {
				// Are there more non-mutating actions?
				if (action !== "read") {
					acc.add(action);
				}
				return acc;
			}, new Set<string>()) ?? [];
		const tenantSharingPermissions = [...mutationActions].map((action) => ({
			inverted: true,
			action,
			subject: null,
			fields: undefined,
			conditions: { tenant: { $ne: tenant } },
		}));

		const allPermissions = [
			...(rolePermissions ?? []),
			...(tenantPermissions ?? []),
			...tenantSharingPermissions,
		].map((permission) => {
			const { fields, conditions } = permission;
			if (!fields && !conditions) {
				return permission;
			}
			return {
				...permission,
				fields: fields?.map(snakeToCamel) ?? null,
				conditions: conditions && snakeKeysToCamel(conditions),
			};
		});
		return {
			// We have to use the "never" type here because the type of "permissions"
			// cannot be as accurate as the manually enforced type by specifying subjects and actions.
			ability: new Ability<AbilityTuple>(allPermissions as never, {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				detectSubjectType: (object: any) => object?.__typename,
			}),
		};
	}, [rolePermissions, tenantPermissions, tenant]);

	useEffect(() => {
		if (rolePermissions) {
			dispatch({ type: "PERMISSIONS_LOADED" });
		}
	}, [dispatch, rolePermissions]);

	return (
		<AuthorizationContext.Provider value={value}>
			{children}
		</AuthorizationContext.Provider>
	);
}

export function useAbility() {
	const { ability } = useContext(AuthorizationContext);

	const can: CanFunction = (...args) => {
		const subject = Array.isArray(args[1]) ? args[1] : [args[1]];
		const field = Array.isArray(args[2]) ? args[2] : [args[2]];
		return subject.every((s) =>
			field.every((f) => ability.can(args[0] as any, s as any, f)),
		);
	};
	const cannot: CannotFunction = (...args) => ability.cannot(...args);
	const relevantRuleFor: RelevantRuleForFunction = (...args) =>
		ability.relevantRuleFor(...args);

	return { can, cannot, relevantRuleFor };
}
