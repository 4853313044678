import React from "react";

function SvgToolTipArrow(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={8}
			height={16}
			viewBox="0 0 8 16"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M0 8l8-8v16L0 8z" />
		</svg>
	);
}

const MemoSvgToolTipArrow = React.memo(SvgToolTipArrow);
export default MemoSvgToolTipArrow;
