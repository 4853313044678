import { Close } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import type { LinkProps } from "@app/modules/routes/link";
import { Link } from "@app/modules/routes/link";
import type { RouteName } from "@app/modules/routes/routes";
import { Body14, Caption12 } from "@app/modules/typography";
import clsx from "clsx";
import type { HTMLAttributes, MouseEventHandler, Ref } from "react";
import React, { forwardRef } from "react";

export type TagItemColor =
	| "green"
	| "red"
	| "purple"
	| "blue"
	| "teal"
	| "yellow"
	| "orange"
	| "gray"
	| "grayLight"
	| "brand"
	| "white";

export interface CommonTagItemProps {
	icon?: IconComponent;
	color: TagItemColor;
	big?: boolean;
}

export interface TagItemProps
	extends Omit<HTMLAttributes<HTMLDivElement>, "onClick" | "color">,
		CommonTagItemProps {
	onCloseClick?: MouseEventHandler<HTMLButtonElement>;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const TagItem = forwardRef(
	(
		{
			className,
			children,
			color,
			icon,
			big,
			onCloseClick,
			onClick,
			...props
		}: TagItemProps,
		ref: Ref<HTMLDivElement>,
	) => {
		const isRenderedAsDiv = !onClick;
		const buttonCls = clsx(
			"flex align-middle px-4 transition-all",
			"font-400",
			!isRenderedAsDiv && "hover:opacity-70 disabled:cursor-default",
			!big && !children && "py-2",
			big && !children && "py-4",
		);

		const textElement = (
			<>
				{icon && (
					<Icon
						icon={icon}
						size="16"
						className={clsx("my-auto", children ? "mr-4" : undefined)}
					/>
				)}
				{big ? (
					<Body14 as="span" className="m-auto">
						{children}
					</Body14>
				) : (
					<Caption12 as="span" className="m-auto">
						{children}
					</Caption12>
				)}
			</>
		);

		return (
			<div
				ref={ref}
				className={clsx(
					className,
					"min-w-max inline-flex items-center align-middle rounded leading-none relative whitespace-nowrap overflow-hidden text-ellipsis",
					colorsCls[color],
					onCloseClick && "pr-24",
				)}
				{...props}
			>
				{isRenderedAsDiv ? (
					<div className={buttonCls}>{textElement}</div>
				) : (
					<button
						type="button"
						tabIndex={-1}
						className={buttonCls}
						disabled={!onClick}
						onClick={onClick}
					>
						{textElement}
					</button>
				)}
				{onCloseClick && (
					<button
						type="button"
						onClick={onCloseClick}
						className={clsx(
							"flex align-middle text-center transition-all hover:opacity-70 absolute top-0 right-0 w-24 h-full",
							closeButtonColorsCls[color],
						)}
					>
						<Icon icon={Close} size="16" className="m-auto" />
					</button>
				)}
			</div>
		);
	},
);

export type TagItemLinkProps<Name extends RouteName> = LinkProps<Name> &
	CommonTagItemProps &
	HTMLAttributes<HTMLDivElement>;

export const TagItemLink = forwardRef(function TagItemLink<
	Name extends RouteName,
>(
	{
		className,
		children,
		color,
		icon,
		big,
		to,
		params,
		...props
	}: TagItemLinkProps<Name>,
	ref: Ref<HTMLAnchorElement>,
) {
	return (
		<Link
			innerRef={ref}
			tabIndex={-1}
			className={clsx(
				className,
				colorsCls[color],
				"min-w-max inline-flex items-center align-middle rounded leading-none relative whitespace-nowrap overflow-hidden text-ellipsis",
				"px-4 transition-all hover:opacity-70",
				!big && !children && "py-2",
				big && !children && "py-4",
			)}
			to={to as RouteName}
			params={params}
			{...props}
		>
			{icon && (
				<Icon
					icon={icon}
					size="16"
					className={clsx("my-auto", children ? "mr-4" : undefined)}
				/>
			)}
			{big ? (
				<Body14 as="span" className="m-auto font-400">
					{children}
				</Body14>
			) : (
				<Caption12 as="span" className="m-auto font-400">
					{children}
				</Caption12>
			)}
		</Link>
	);
});

const colorsCls: Record<TagItemColor, string> = {
	green: "bg-green-200 text-green-800",
	red: "bg-red-200 text-red-800",
	purple: "bg-purple-200 text-purple-800",
	blue: "bg-blue-200 text-blue-800",
	teal: "bg-teal-200 text-teal-800",
	yellow: "bg-yellow-200 text-yellow-800",
	orange: "bg-orange-200 text-orange-800",
	gray: "bg-grey-800 text-white",
	grayLight: "bg-grey-200 text-grey-700",
	brand: "bg-brand-700 text-white",
	white: "bg-white text-brand-700",
};

const closeButtonColorsCls: Record<TagItemColor, string> = {
	green: "bg-green-800 text-white",
	red: "bg-red-800 text-white",
	purple: "bg-purple-800 text-white",
	blue: "bg-blue-800 text-white",
	teal: "bg-teal-800 text-white",
	yellow: "bg-yellow-800 text-white",
	orange: "bg-orange-800 text-white",
	gray: "bg-grey-900 text-white",
	grayLight: "bg-grey-400 text-grey-700",
	brand: "bg-brand-900",
	white: "bg-brand-200 text-brand-700",
};

export { TagItem };
