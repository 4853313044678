import { isProd } from "@app/modules/environment";
import { logger } from "@app/modules/logger/logger";
// eslint-disable-next-line no-restricted-imports
import LogRocket from "logrocket";

export function initLogRocket() {
	if (isProd) {
		LogRocket.init("rclsjs/meatico-vfqym");
	}
}

export interface UserTraits {
	[propName: string]: string | number | boolean;
	tenant: string;
	// todo: extend with name and email
}

export function identifyUser(id: string, traits: UserTraits) {
	LogRocket.identify(id, traits);
}

export interface EnvSetupEvent {
	name: "EnvSetup";
	properties: {
		tenant: string;
		stage: string;
		beVersion: string;
		feVersion: string;
	};
}

export interface VersionUpdatedEvent {
	name: "VersionUpdated";
	properties: {
		beVersion: string;
		feVersion: string;
	};
}

export interface RoleSelectedEvent {
	name: "RoleSelected";
	properties: {
		activeRole: string;
	};
}

export interface WorkstationSelectedEvent {
	name: "WorkstationSelected";
	properties: {
		workstationId: string;
	};
}

export interface CostPriceMismatchDetectedEvent {
	name: "CostPriceMismatchDetected";
	properties: {
		productId: string;
		lotNumberId: string;
		expectedCostPrice: string;
		receivedCostPrice: string;
	};
}

type LogRocketEvent =
	| EnvSetupEvent
	| RoleSelectedEvent
	| WorkstationSelectedEvent
	| VersionUpdatedEvent
	| CostPriceMismatchDetectedEvent;

export function trackEvent(event: LogRocketEvent) {
	LogRocket.track(event.name, event.properties);
	logger.info("LogRocket", event.name, event.properties);
}

interface CaptureOptions {
	tags?: Record<string, string | number | boolean>;
	extra?: Record<string, string | number | boolean>;
}

export function captureException(error: Error, options?: CaptureOptions) {
	LogRocket.captureException(error, options);
}

export function captureMessage(message: string, options?: CaptureOptions) {
	LogRocket.captureMessage(message, options);
}
