import type { MouseEventHandler, ReactNode } from "react";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useLocation } from "react-router-dom";

export interface BannerOptions {
	type: "banner";
	variant: "success" | "error";
	text: ReactNode;
	errorCode?: string;
	cta?: {
		text: ReactNode;
		onClick?: MouseEventHandler<HTMLButtonElement>;
	};
}

export interface ToastOptions {
	type: "toast";
	variant: "success" | "error" | "info";
	text: ReactNode;
	ttl?: number;
	cta?: {
		text: ReactNode;
		onClick?: MouseEventHandler<HTMLButtonElement>;
	};
}

type NotificationOptions = BannerOptions | ToastOptions;
export type ToastVariant = ToastOptions["variant"];

export interface ToastData extends Omit<ToastOptions, "ttl"> {
	expiresAt: number;
	id: number;
}

export interface BannerData extends BannerOptions {
	id: number;
}

export type NotifiationData = ToastData | BannerData;

const NotificationContext = createContext<NotifiationData[]>([]);

interface NotificationDispatchContextValue {
	dispatchNotification: (options: NotificationOptions) => void;
	clearNotification: (id: number) => void;
}

const NotificationDispatchContext =
	createContext<NotificationDispatchContextValue>({
		dispatchNotification: () => {},
		clearNotification: () => {},
	});

export interface NotificationProviderProps {
	children?: ReactNode;
}

function NotificationProvider({ children }: NotificationProviderProps) {
	const [notifications, setNotifications] = useState<NotifiationData[]>([]);
	const { pathname } = useLocation();

	const dispatchNotification = useCallback((options: NotificationOptions) => {
		const id = Math.floor(Math.random() * 10000);

		let notification: NotifiationData;
		if (options.type === "toast") {
			const { ttl = 5000, ...otherOptions } = options;
			notification = {
				id,
				...otherOptions,
				expiresAt: Date.now() + ttl,
			};
		} else {
			notification = {
				id,
				...options,
			};
		}

		setNotifications((oldValue) => [notification, ...oldValue]);
	}, []);

	const clearNotification = useCallback((id: number) => {
		setNotifications((oldValue) =>
			oldValue.filter((notification) => notification.id !== id),
		);
	}, []);

	const dispatchValue = useMemo(
		() => ({ dispatchNotification, clearNotification }),
		[clearNotification, dispatchNotification],
	);

	// toast notifications expire after a ttl has passed
	useEffect(() => {
		if (notifications.some(isToast)) {
			const intervalId = setInterval(() => {
				const unexpiredNotifications = notifications.filter(isNotExpired);
				if (unexpiredNotifications.length !== notifications.length) {
					setNotifications(unexpiredNotifications);
				}
			}, 200);
			return () => {
				clearInterval(intervalId);
			};
		}
		return () => {};
	}, [notifications]);

	// banner notifications are removed on page navigation
	useEffect(() => {
		const nextNotifications = notifications.filter(
			(notification) => notification.type !== "banner",
		);
		if (nextNotifications.length !== notifications.length) {
			setNotifications(nextNotifications);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<NotificationDispatchContext.Provider value={dispatchValue}>
			<NotificationContext.Provider value={notifications}>
				{children}
			</NotificationContext.Provider>
		</NotificationDispatchContext.Provider>
	);
}

function isNotExpired(notification: NotifiationData) {
	return notification.type === "toast"
		? Date.now() < notification.expiresAt
		: true;
}

function isToast(data: NotifiationData): data is ToastData {
	return data.type === "toast";
}

function useToastNotifications(): ToastData[] {
	return useContext(NotificationContext).filter(isToast);
}

function isBanner(data: NotifiationData): data is BannerData {
	return data.type === "banner";
}

function useBannerNotifications(): BannerData[] {
	return useContext(NotificationContext).filter(isBanner);
}

function useNotificationDispatch() {
	return useContext(NotificationDispatchContext);
}

export {
	NotificationProvider,
	useToastNotifications,
	useBannerNotifications,
	useNotificationDispatch,
};
