import { Can } from "@app/modules/authorization/Can";
import type { RouteName } from "@app/modules/routes/routes";
import { authParamsByName, fullPathsByName } from "@app/modules/routes/routes";
import { ProductionSiteProductGroupCalendar } from "@app/modules/work-calendar/ProductionSiteProductGroupCalendar";
import type { ComponentType, ReactElement } from "react";
import React from "react";
import type { RouteObject } from "react-router-dom";

const InternalCustomerOrderDetails = React.lazy(() =>
	import("@app/modules/customer-order/InternalCustomerOrderDetails").then(
		(module) => ({ default: module.InternalCustomerOrderDetails }),
	),
);
const CustomerOrderDetails = React.lazy(() =>
	import("@app/modules/customer-order/CustomerOrderDetails").then((module) => ({
		default: module.CustomerOrderDetails,
	})),
);
const CustomerOrderList = React.lazy(() =>
	import("@app/modules/customer-order/CustomerOrderList").then((module) => ({
		default: module.CustomerOrderList,
	})),
);
const CustomerReturnList = React.lazy(() =>
	import("@app/modules/customer-order/CustomerReturnList").then((module) => ({
		default: module.CustomerReturnList,
	})),
);
const CustomerCreate = React.lazy(() =>
	import("@app/modules/customer/CustomerCreate").then((module) => ({
		default: module.CustomerCreate,
	})),
);
const CustomerDetails = React.lazy(() =>
	import("@app/modules/customer/CustomerDetails").then((module) => ({
		default: module.CustomerDetails,
	})),
);
const CustomerList = React.lazy(() =>
	import("@app/modules/customer/CustomerList").then((module) => ({
		default: module.CustomerList,
	})),
);
const DismantlingPlan = React.lazy(() =>
	import("@app/modules/dismantling/DismantlingPlan").then((module) => ({
		default: module.DismantlingPlan,
	})),
);
const NotFound = React.lazy(() =>
	import("@app/modules/errors/NotFound").then((module) => ({
		default: module.NotFound,
	})),
);
const HardwareTestPage = React.lazy(() =>
	import("@app/modules/hardware/HardwareTestPage").then((module) => ({
		default: module.HardwareTestPage,
	})),
);
const CreditNoteDetails = React.lazy(() =>
	import("@app/modules/invoicing/CreditNoteDetails").then((module) => ({
		default: module.CreditNoteDetails,
	})),
);
const InvoiceDetails = React.lazy(() =>
	import("@app/modules/invoicing/InvoiceDetails").then((module) => ({
		default: module.InvoiceDetails,
	})),
);
const InvoiceList = React.lazy(() =>
	import("@app/modules/invoicing/InvoiceList").then((module) => ({
		default: module.InvoiceList,
	})),
);
const PickingDetail = React.lazy(() =>
	import("@app/modules/picking/PickingDetail").then((module) => ({
		default: module.PickingDetail,
	})),
);
const PickingOverview = React.lazy(() =>
	import("@app/modules/picking/PickingOverview").then((module) => ({
		default: module.PickingOverview,
	})),
);
const OrderOverview = React.lazy(() =>
	import("@app/modules/operations/OrderOverview").then((module) => ({
		default: module.OrderOverview,
	})),
);
const GenericGoodsIncome = React.lazy(() =>
	import("@app/modules/goods-income/GenericGoodsIncome").then((module) => ({
		default: module.GenericGoodsIncome,
	})),
);
const CustomerOrderReturn = React.lazy(() =>
	import(
		"@app/modules/goods-income/customer-order-return/CustomerOrderReturn"
	).then((module) => ({ default: module.CustomerOrderReturn })),
);
const SupplierOrderGoodsIncome = React.lazy(() =>
	import(
		"@app/modules/goods-income/supplier-order/SupplierOrderGoodsIncome"
	).then((module) => ({ default: module.SupplierOrderGoodsIncome })),
);
const SupplierOrderReturn = React.lazy(() =>
	import(
		"@app/modules/operations/supplier-order-returns/SupplierOrderReturn"
	).then((module) => ({ default: module.SupplierOrderReturn })),
);
const HierarchicalGoodsIncome = React.lazy(() =>
	import("@app/modules/goods-income/hierarchical/HierarchicalGoodsIncome").then(
		(module) => ({ default: module.HierarchicalGoodsIncome }),
	),
);
const DismantlingPricing = React.lazy(() =>
	import("@app/modules/pricing/dismantling-pricing/DismantlingPricing").then(
		(module) => ({
			default: module.DismantlingPricing,
		}),
	),
);
const DismantlingPricingDetails = React.lazy(() =>
	import(
		"@app/modules/pricing/dismantling-pricing/DismantlingPricingDetails"
	).then((module) => ({
		default: module.DimantlingPricingDetails,
	})),
);
const PriceListDetails = React.lazy(() =>
	import("@app/modules/pricing/price-lists/PriceListDetails").then(
		(module) => ({ default: module.PriceListDetails }),
	),
);
const PriceListCreate = React.lazy(() =>
	import("@app/modules/pricing/price-lists/PriceListCreate").then((module) => ({
		default: module.PriceListCreate,
	})),
);
const PriceListTable = React.lazy(() =>
	import("@app/modules/pricing/price-lists/PriceListTable").then((module) => ({
		default: module.PriceListTable,
	})),
);
const ProductCreate = React.lazy(() =>
	import("@app/modules/product/ProductCreate").then((module) => ({
		default: module.ProductCreate,
	})),
);
const ProductDetails = React.lazy(() =>
	import("@app/modules/product/ProductDetails").then((module) => ({
		default: module.ProductDetails,
	})),
);
const ProductList = React.lazy(() =>
	import("@app/modules/product/ProductList").then((module) => ({
		default: module.ProductList,
	})),
);
const DemandPlanningOverview = React.lazy(() =>
	import("@app/modules/purchasing/DemandPlanningOverview").then((module) => ({
		default: module.DemandPlanningOverview,
	})),
);
const SupplierOrder = React.lazy(() =>
	import("@app/modules/purchasing/supplier-order/SupplierOrderDetails").then(
		(module) => ({ default: module.SupplierOrderDetails }),
	),
);
const SupplierOrderList = React.lazy(() =>
	import("@app/modules/purchasing/supplier-order/SupplierOrderList").then(
		(module) => ({ default: module.SupplierOrderList }),
	),
);
const SupplierReturnList = React.lazy(() =>
	import("@app/modules/purchasing/SupplierReturnList").then((module) => ({
		default: module.SupplierReturnList,
	})),
);
const ContainerInventoryList = React.lazy(() =>
	import("@app/modules/stock/ContainerInventoryList").then((module) => ({
		default: module.ContainerInventoryList,
	})),
);
const InventoryList = React.lazy(() =>
	import("@app/modules/stock/InventoryList").then((module) => ({
		default: module.InventoryList,
	})),
);
const StockTransactionList = React.lazy(() =>
	import("@app/modules/stock/StockTransactionList").then((module) => ({
		default: module.StockTransactionList,
	})),
);
const RollingInventoryOverview = React.lazy(() =>
	import("@app/modules/stock/rolling-inventory/RollingInventoryOverview").then(
		(module) => ({ default: module.RollingInventoryOverview }),
	),
);
const RollingInventory = React.lazy(() =>
	import("@app/modules/stock/rolling-inventory/RollingInventory").then(
		(module) => ({ default: module.RollingInventory }),
	),
);
const SupplierCreate = React.lazy(() =>
	import("@app/modules/supplier/SupplierCreate").then((module) => ({
		default: module.SupplierCreate,
	})),
);
const SupplierDetails = React.lazy(() =>
	import("@app/modules/supplier/SupplierDetails").then((module) => ({
		default: module.SupplierDetails,
	})),
);
const SupplierList = React.lazy(() =>
	import("@app/modules/supplier/SupplierList").then((module) => ({
		default: module.SupplierList,
	})),
);
const Styleguide = React.lazy(() =>
	import("./styleguide").then((module) => ({ default: module.Styleguide })),
);
const TourCreate = React.lazy(() =>
	import("@app/modules/tour/TourCreate").then((module) => ({
		default: module.TourCreate,
	})),
);
const ToursDetailsPage = React.lazy(() =>
	import("@app/modules/tour/TourDetails").then((module) => ({
		default: module.ToursDetailsPage,
	})),
);
const TourList = React.lazy(() =>
	import("@app/modules/tour/TourList").then((module) => ({
		default: module.TourList,
	})),
);
const ContextualDashboard = React.lazy(() =>
	import("@app/modules/dashboard/ContextualDashboard").then((module) => ({
		default: module.ContextualDashboard,
	})),
);
const ProductionList = React.lazy(() =>
	import("@app/pages/production").then((module) => ({
		default: module.ProductionList,
	})),
);
const GraphQlErrorsTestingPage = React.lazy(() =>
	import("@app/modules/graphql/GraphQlErrorsTestingPage").then((module) => ({
		default: module.GraphQlErrorsTestingPage,
	})),
);
const CreditNoteList = React.lazy(() =>
	import("@app/modules/invoicing/CreditNoteList").then((module) => ({
		default: module.CreditNoteList,
	})),
);
const SettingsOverview = React.lazy(() =>
	import("@app/modules/settings/SettingsOverview").then((module) => ({
		default: module.SettingsOverview,
	})),
);
const TemporalProductsList = React.lazy(() =>
	import("@app/modules/product/TemporalProductsList").then((module) => ({
		default: module.TemporalProductsList,
	})),
);
const PriceLabellingDetails = React.lazy(() =>
	import("@app/modules/picking/price-labelling/PriceLabellingDetails").then(
		(module) => ({ default: module.PriceLabellingDetails }),
	),
);
const DebugSettingsPage = React.lazy(() =>
	import("@app/modules/debug/DebugSettingsPage").then((module) => ({
		default: module.DebugSettingsPage,
	})),
);
const LabelList = React.lazy(() =>
	import("@app/modules/label-printing/LabelList").then((module) => ({
		default: module.LabelList,
	})),
);
const TemporalSalesTeamsList = React.lazy(() =>
	import("@app/modules/customer/TemporalSalesTeamsList").then((module) => ({
		default: module.TemporalSalesTeamsList,
	})),
);
const SalesTeamList = React.lazy(() =>
	import("@app/modules/sales-team/SalesTeamList").then((module) => ({
		default: module.SalesTeamList,
	})),
);
const SalesTeamDetails = React.lazy(() =>
	import("@app/modules/sales-team/SalesTeamDetails").then((module) => ({
		default: module.SalesTeamDetails,
	})),
);
const SalesTeamCreate = React.lazy(() =>
	import("@app/modules/sales-team/SalesTeamCreate").then((module) => ({
		default: module.SalesTeamCreate,
	})),
);
const CuttingPatternsDetails = React.lazy(() =>
	import("@app/modules/pricing/cutting-patterns/CuttingPatternsDetails").then(
		(module) => ({
			default: module.CuttingPatternsDetails,
		}),
	),
);
const CuttingPatternsList = React.lazy(() =>
	import("@app/modules/pricing/cutting-patterns/CuttingPatternsList").then(
		(module) => ({
			default: module.CuttingPatternsList,
		}),
	),
);
const UserCreate = React.lazy(() =>
	import("@app/modules/user/UserCreate").then((module) => ({
		default: module.UserCreate,
	})),
);
const UserDetails = React.lazy(() =>
	import("@app/modules/user/UserDetails").then((module) => ({
		default: module.UserDetails,
	})),
);
const UserList = React.lazy(() =>
	import("@app/modules/user/UserList").then((module) => ({
		default: module.UserList,
	})),
);
const JournalEntryList = React.lazy(() =>
	import("@app/modules/journal-entry/JournalEntryList").then((module) => ({
		default: module.JournalEntryList,
	})),
);
const JournalEntryDetails = React.lazy(() =>
	import("@app/modules/journal-entry/JournalEntryPostings").then((module) => ({
		default: module.JournalEntryPostings,
	})),
);

type LazyImport = () => Promise<ComponentType>;

const routeElements: Record<RouteName, ReactElement | LazyImport> = {
	dashboard: <ContextualDashboard />,
	"customer-data": <div />,
	"customer-data.customers": <CustomerList />,
	"customer-data.customers.id": <CustomerDetails />,
	"customer-data.customers.id.temporal-sales-teams": <TemporalSalesTeamsList />,
	"customer-data.customers.new": <CustomerCreate />,
	"customer-data.customer-groups": () =>
		import("@app/modules/customer-group/CustomerGroupList").then(
			({ CustomerGroupList }) => CustomerGroupList,
		),
	"customer-data.customer-groups.new": () =>
		import("@app/modules/customer-group/CustomerGroupCreate").then(
			({ CustomerGroupCreate }) => CustomerGroupCreate,
		),
	"customer-data.customer-groups.id": () =>
		import("@app/modules/customer-group/CustomerGroupDetails").then(
			({ CustomerGroupDetails }) => CustomerGroupDetails,
		),
	"customer-data.sales-teams": <SalesTeamList />,
	"customer-data.sales-teams.id": <SalesTeamDetails />,
	"customer-data.sales-teams.new": <SalesTeamCreate />,
	"customer-data.tours": <TourList />,
	"customer-data.tours.id": <ToursDetailsPage />,
	"customer-data.tours.new": <TourCreate />,
	styleguide: <Styleguide />,
	suppliers: <SupplierList />,
	"suppliers.id": <SupplierDetails />,
	"suppliers.new": <SupplierCreate />,
	"product-data": <div />,
	"product-data.products": <ProductList />,
	"product-data.products.new": <ProductCreate />,
	"product-data.products.id": <ProductDetails />,
	"product-data.products.id.temporal": <TemporalProductsList />,
	"product-data.groups": () =>
		import("@app/modules/product-group/ProductGroupList").then(
			({ ProductGroupList }) => ProductGroupList,
		),
	"product-data.groups.new": () =>
		import("@app/modules/product-group/ProductGroupCreate").then(
			({ ProductGroupCreate }) => ProductGroupCreate,
		),
	"product-data.groups.id": () =>
		import("@app/modules/product-group/ProductGroupDetails").then(
			({ ProductGroupDetails }) => ProductGroupDetails,
		),
	"misc-data": <div />,
	"misc-data.stock-locations": () =>
		import("@app/modules/stock-location/StockLocationList").then(
			({ StockLocationList }) => StockLocationList,
		),
	"misc-data.stock-locations.new": () =>
		import("@app/modules/stock-location/StockLocationCreate").then(
			({ StockLocationCreate }) => StockLocationCreate,
		),
	"misc-data.stock-locations.id": () =>
		import("@app/modules/stock-location/StockLocationDetails").then(
			({ StockLocationDetails }) => StockLocationDetails,
		),
	"product-data.hierarchical-labels": () =>
		import("@app/modules/hierarchical-labels/HierarchicalLabelsList").then(
			({ HierarchicalLabelList }) => HierarchicalLabelList,
		),
	"product-data.hierarchical-labels.id": () =>
		import("@app/modules/hierarchical-labels/HierarchicalLabelDetails").then(
			({ HierarchicalLabelDetails }) => HierarchicalLabelDetails,
		),
	"product-data.hierarchical-labels.new": () =>
		import("@app/modules/hierarchical-labels/HierarchicalLabelCreate").then(
			({ HierarchicalLabelCreate }) => HierarchicalLabelCreate,
		),
	sales: <div />,
	"sales.customer-orders": (
		<CustomerOrderList isInternal={false} key="external" />
	),
	"sales.customer-orders.id": <CustomerOrderDetails />,
	"sales.internal-orders": <CustomerOrderList isInternal key="internal" />,
	"sales.internal-orders.id": <InternalCustomerOrderDetails />,
	"sales.returns": <CustomerReturnList />,
	pricing: <div />,
	"pricing.cutting-patterns": <CuttingPatternsList />,
	"pricing.cutting-patterns.pg-id": <CuttingPatternsDetails />,
	"pricing.dismantling": <DismantlingPricing />,
	"pricing.dismantling.id": <DismantlingPricingDetails />,
	"pricing.price-lists": <PriceListTable />,
	"pricing.price-lists.details": <PriceListDetails />,
	"pricing.price-lists.new": <PriceListCreate />,
	dismantling: <div />,
	"dismantling.plan": <DismantlingPlan />,
	"dismantling.printing": () =>
		import("@app/modules/dismantling/printing/DismantlingPrinting").then(
			({ DismantlingPrinting }) => DismantlingPrinting,
		),
	operations: <div />,
	"operations.dismantling-goods-receipt": <GenericGoodsIncome />,
	"operations.customer-order-returns": (
		<OrderOverview variant="customer-orders" />
	),
	"operations.customer-order-returns.id": <CustomerOrderReturn />,
	"operations.supplier-order-goods-receipt": (
		<OrderOverview variant="supplier-orders" />
	),
	"operations.supplier-order-goods-receipt.id": <SupplierOrderGoodsIncome />,
	"operations.supplier-order-returns": (
		<OrderOverview variant="supplier-order-returns" />
	),
	"operations.supplier-order-returns.id": <SupplierOrderReturn />,
	"operations.hierarchical-goods-receipt": <HierarchicalGoodsIncome />,
	"operations.picking": <PickingOverview />,
	"operations.picking.id": <PickingDetail />,
	"operations.price-labelling": <PriceLabellingDetails />,
	"operations.rolling-inventory": <RollingInventoryOverview />,
	"operations.rolling-inventory.id": <RollingInventory />,
	invoicing: <div />,
	"invoicing.credit-notes": <CreditNoteList />,
	"invoicing.credit-notes.id": <CreditNoteDetails />,
	"invoicing.invoices": <InvoiceList />,
	"invoicing.invoices.id": <InvoiceDetails />,
	purchasing: <div />,
	"purchasing.planning": <DemandPlanningOverview />,
	"purchasing.orders": <SupplierOrderList variant="regular" />,
	"purchasing.framework-agreements": (
		<SupplierOrderList variant="framework-agreement" />
	),
	"purchasing.orders.id": <SupplierOrder variant="regular" />,
	"purchasing.framework-agreements.id": (
		<SupplierOrder variant="framework-agreement" />
	),
	"purchasing.returns": <SupplierReturnList />,
	stock: <div />,
	"stock.inventory": <InventoryList />,
	"stock.transactions": <StockTransactionList />,
	"stock.containers": <ContainerInventoryList />,
	"stock.journal-entries": <JournalEntryList />,
	"stock.journal-entries.id": <JournalEntryDetails />,
	production: <ProductionList />,
	performance: <NotFound />,
	tbd: <NotFound />,
	settings: <div />,
	"settings.misc": <SettingsOverview />,
	"settings.work-calendar": <ProductionSiteProductGroupCalendar />,
	"settings.labels": <LabelList />,
	"settings.scale-setup": <HardwareTestPage />,
	"settings.hardware-devices": () =>
		import("@app/modules/hardware-device/HardwareDeviceList").then(
			({ HardwareDeviceList }) => HardwareDeviceList,
		),
	"settings.hardware-devices.new": () =>
		import("@app/modules/hardware-device/HardwareDeviceCreate").then(
			({ HardwareDeviceCreate }) => HardwareDeviceCreate,
		),
	"settings.hardware-devices.id": () =>
		import("@app/modules/hardware-device/HardwareDeviceDetails").then(
			({ HardwareDeviceDetails }) => HardwareDeviceDetails,
		),
	"settings.workstations": () =>
		import("@app/modules/workstations/WorkstationList").then(
			({ WorkstationList }) => WorkstationList,
		),
	"settings.workstations.new": () =>
		import("@app/modules/workstations/WorkstationCreate").then(
			({ WorkstationCreate }) => WorkstationCreate,
		),
	"settings.workstations.id": () =>
		import("@app/modules/workstations/WorkstationDetails").then(
			({ WorkstationDetails }) => WorkstationDetails,
		),
	testing: <div />,
	"testing.graphql-errors": <GraphQlErrorsTestingPage />,
	debug: <DebugSettingsPage />,
	users: <UserList />,
	"users.id": <UserDetails />,
	"users.new": <UserCreate />,
	notifications: () =>
		import("@app/modules/notification/NotificationList").then(
			({ NotificationList }) => NotificationList,
		),
};

export const privateRoutes: RouteObject[] = (
	Object.entries(routeElements) as [RouteName, ReactElement | LazyImport][]
).map(([name, element]) => {
	const params = authParamsByName[name];
	// We remove the leading "/" because react router requires relative links.  All our routes are setup with absolute links.
	const path = fullPathsByName[name].substring(1);

	if (typeof element === "function") {
		return {
			path,
			lazy: () =>
				element().then((Component) => ({
					Component: params
						? () => (
								<Can params={params} fallback={<NotFound />}>
									<Component />
								</Can>
						  )
						: Component,
				})),
		};
	}

	if (params) {
		return {
			path,
			element: (
				<Can params={params} fallback={<NotFound />}>
					{element}
				</Can>
			),
		};
	}
	return {
		path,
		element,
	};
});
