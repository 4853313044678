import { NewCard } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { SharedEntityTagItem } from "@app/modules/entity/SharedEntityTagItem";
import { HorizontalList } from "@app/modules/layout/HorizontalList";
import { IdTagItem } from "@app/modules/layout/IdTagItem";
import type { TagItemColor } from "@app/modules/layout/TagItem";
import { TagItem } from "@app/modules/layout/TagItem";
import { useFloatingScrollEffect } from "@app/modules/layout/useFloatingScrollEffect";
import { logger } from "@app/modules/logger/logger";
import { flip, shift, useFloating } from "@floating-ui/react-dom";
import clsx from "clsx";
import type { HTMLAttributes, LiHTMLAttributes, ReactNode, Ref } from "react";
import { forwardRef, useEffect } from "react";
import { createPortal } from "react-dom";

export function useOptionsPositioning({
	isOpen,
	preventFlip,
}: {
	isOpen: boolean;
	preventFlip?: boolean;
}) {
	const middleware = [shift()];
	if (!preventFlip) {
		middleware.push(
			flip({ padding: { top: 360, bottom: 360, left: 0, right: 0 } }),
		);
	}
	const floatingReturn = useFloating({
		placement: "bottom-start",
		middleware,
	});

	const { update, reference, refs } = floatingReturn;

	useEffect(() => {
		if (isOpen) {
			update();
		}
	}, [update, isOpen]);

	useFloatingScrollEffect(refs, update, isOpen);

	return {
		referenceRef: reference,
		optionsProps: {
			positioning: {
				isOpen,
				...floatingReturn,
			},
		},
	};
}

export type UseOptionsPositioningResult = ReturnType<
	typeof useOptionsPositioning
>;

const DROPDOWN_PORTAL_NODE = document.getElementById("dropdown-portal");

export interface OptionsProps extends HTMLAttributes<HTMLUListElement> {
	className?: string;
	children?: ReactNode;
	positioning: UseOptionsPositioningResult["optionsProps"]["positioning"];
}

export const Options = forwardRef(
	(
		{ className, children, positioning, ...props }: OptionsProps,
		ref: Ref<HTMLUListElement>,
	) => {
		const { x, y, strategy, floating, refs } = positioning;

		if (!DROPDOWN_PORTAL_NODE) {
			logger.error(
				undefined,
				"Dropdown portal node not found. Make sure an element with id 'dropdown-portal' exists in the DOM.",
			);
			return null;
		}

		const referenceWidth =
			refs.reference.current?.getBoundingClientRect()?.width;

		return createPortal(
			<div
				ref={floating}
				className={clsx(
					className,
					"bg-white rounded-b z-50 border-grey-300 shadow-card overflow-y-auto overflow-x-hidden max-h-360",
				)}
				style={{
					position: strategy,
					top: y ?? undefined,
					left: x ?? undefined,
					minWidth: referenceWidth,
				}}
			>
				<ul ref={ref} {...props} className="focus:outline-none">
					{positioning.isOpen && children}
				</ul>
			</div>,
			DROPDOWN_PORTAL_NODE,
		);
	},
);

export interface OptionItemProps extends LiHTMLAttributes<HTMLLIElement> {
	className?: string;
	children?: ReactNode;
	testid?: string;
	icon?: IconComponent;
	domainId?: string;
	tenant?: string;
	isHighlighted?: boolean;
	onCardClick?: () => void;
	disabled?: boolean;
	tags?: Array<{
		id: string;
		icon?: IconComponent;
		color: TagItemColor;
		children: ReactNode;
	}>;
}

export const OptionItem = forwardRef(
	(
		{
			className,
			icon,
			children,
			onCardClick,
			isHighlighted,
			domainId,
			tenant,
			testid,
			disabled,
			tags = [],
			...props
		}: OptionItemProps,
		ref: Ref<HTMLLIElement>,
	) => (
		<li
			ref={ref}
			className={clsx(
				className,
				"px-16 w-full transition-all bg-transparent border-b border-grey-300 flex",
				disabled ? "text-grey-500" : "text-grey-900 cursor-pointer",
				isHighlighted && "bg-grey-200",
			)}
			{...props}
			data-testid={`OptionItem-${testid}`}
		>
			<HorizontalList className="my-8 gap-8">
				{icon && <Icon icon={icon} size="16" className="" />}
				<div className="text-body-14">{children}</div>
				<IdTagItem id={domainId} />
				<SharedEntityTagItem entity={{ tenant }} />
				{tags.map(({ id, ...tagItemProps }) => (
					<TagItem key={id} {...tagItemProps} />
				))}
			</HorizontalList>

			{onCardClick && (
				<button type="button" className="my-auto mx-16" onClick={onCardClick}>
					<Icon icon={NewCard} size="16" className="text-brand-700" />
				</button>
			)}
		</li>
	),
);
