import { Next, Prev } from "@app/icons/components/action";
import type { IconProps } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { Button } from "@app/modules/Button";
import {
	CalendarShortcuts,
	RangeCalendarShortcuts,
} from "@app/modules/input-fields/date/Shortcuts";
import type {
	CalendarDate,
	DateDuration,
	DateValue,
} from "@internationalized/date";
import {
	createCalendar,
	endOfMonth,
	getDayOfWeek,
	getWeeksInMonth,
	isSameDay,
	isSameMonth,
} from "@internationalized/date";
import clsx from "clsx";
import React, { useRef } from "react";
import type {
	AriaButtonProps,
	CalendarProps as AriaCalendarProps,
	RangeCalendarProps,
} from "react-aria";
import {
	useButton,
	useCalendar,
	useCalendarCell,
	useCalendarGrid,
	useLocale,
	useRangeCalendar,
} from "react-aria";
import type { CalendarState, RangeCalendarState } from "react-stately";
import { useCalendarState, useRangeCalendarState } from "react-stately";

export interface CalendarProps extends AriaCalendarProps<DateValue> {
	closeCalendar: () => void;
}

export function Calendar(props: AriaCalendarProps<DateValue>) {
	const { locale } = useLocale();

	const state = useCalendarState({
		...props,
		locale,
		createCalendar,
	});

	const { calendarProps, prevButtonProps, nextButtonProps, title } =
		useCalendar(props, state);

	return (
		<div {...calendarProps} className="calendar">
			<CalendarHead
				prevButtonProps={prevButtonProps}
				nextButtonProps={nextButtonProps}
				title={title}
			/>
			<div className="p-16 pl-0 flex gap-24">
				<CalendarShortcuts
					className="border-r border-grey-200 pr-8"
					state={state}
				/>
				<CalendarGrid state={state} />
			</div>
		</div>
	);
}

export function CalendarHead({
	nextButtonProps,
	prevButtonProps,
	title,
	className,
}: {
	nextButtonProps: AriaButtonProps;
	prevButtonProps: AriaButtonProps;
	title: string;
	className?: string;
}) {
	return (
		<div
			className={clsx(
				className,
				"grid grid-cols-4 rounded-tl rounded-tr gap-16 bg-brand-700",
			)}
		>
			<div className="col-span-1" />
			<div className="col-span-3 flex justify-between">
				<CalendarButton
					{...prevButtonProps}
					className="border-none focus:outline-none"
					iconClassName="text-white"
					icon={Prev}
					iconSize="16"
				/>
				<div className="text-body-14 w[100px] py-8 text-white">{title}</div>
				<CalendarButton
					{...nextButtonProps}
					className="border-none focus:outline-none p-8"
					iconClassName="text-white"
					icon={Next}
					iconSize="16"
				/>
			</div>
		</div>
	);
}

export function CalendarGrid({
	state,
	offset = {},
}: {
	state: CalendarState | RangeCalendarState;
	offset?: DateDuration;
}) {
	const { locale } = useLocale();

	const startDate = state.visibleRange.start.add(offset);
	const endDate = endOfMonth(startDate);

	const { gridProps, headerProps, weekDays } = useCalendarGrid(
		{ startDate, endDate },
		state,
	);

	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

	return (
		<table {...gridProps} className="text-body-14">
			<thead {...headerProps}>
				<tr>
					{weekDays.map((day, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<th className="text-center font-400" key={`${day}-${index}`}>
							{day}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{[...new Array(weeksInMonth).keys()].map((weekIndex) => (
					<tr key={weekIndex}>
						{state
							.getDatesInWeek(weekIndex, startDate)
							.map((date) =>
								date ? (
									<CalendarCell
										key={date.toString()}
										state={state}
										date={date}
										startDate={startDate}
									/>
								) : (
									<td key={date} />
								),
							)}
					</tr>
				))}
			</tbody>
		</table>
	);
}

export function CalendarButton({
	icon,
	iconSize,
	children,
	className,
	iconClassName,
	...props
}: AriaButtonProps<"button"> & {
	icon?: IconComponent;
	className?: string;
	iconClassName?: string;
	iconSize?: IconProps["size"];
}) {
	const ref = React.useRef(null);
	const { buttonProps } = useButton(props, ref);

	return (
		<Button
			variant="tertiary"
			{...buttonProps}
			innerRef={ref}
			icon={icon}
			iconSize={iconSize}
			className={className}
			iconClassName={iconClassName}
		>
			{children}
		</Button>
	);
}

export function CalendarCell({
	state,
	date,
	startDate,
}: {
	state: CalendarState | RangeCalendarState;
	date: CalendarDate;
	startDate: CalendarDate;
}) {
	const ref = useRef(null);
	const { cellProps, buttonProps, isSelected, isDisabled, formattedDate } =
		useCalendarCell({ date }, state, ref);

	const { locale } = useLocale();
	const dayOfWeek = getDayOfWeek(date, locale);

	const isRange = "highlightedRange" in state;

	const isSelectionStart = isRange
		? isSameDay(date, state.highlightedRange.start) && isSelected
		: isSelected;
	const isSelectionEnd = isRange
		? isSameDay(date, state.highlightedRange.end) && isSelected
		: isSelected;
	const isOutsideMonth = !isSameMonth(startDate, date);
	const isRoundedLeft = isSelectionStart || dayOfWeek === 0;
	const isRoundedRight = isSelectionEnd || dayOfWeek === 6;

	return (
		<td {...cellProps} className="relative p-0 h-32 w-32">
			<div
				className={clsx(
					"absolute w-[32px] h-[20px] top-[6px] z-0",
					isSelected && "bg-brand-200",
					isRoundedLeft && "rounded-l-full",
					isRoundedRight && "rounded-r-full",
				)}
			/>

			<button
				{...buttonProps}
				type="button"
				ref={ref}
				className={clsx(
					"relative w-full h-full my-4 z-10 hover:bg-grey-200 hover:rounded-full focus:outline-none",
					(isDisabled || isOutsideMonth) && "text-grey-400",
					(isSelectionStart || isSelectionEnd) && "rounded-full text-white",
					!isOutsideMonth &&
						(isSelectionStart || isSelectionEnd) &&
						"bg-brand-700 hover:bg-brand-900",
				)}
				disabled={isDisabled || isOutsideMonth}
			>
				{formattedDate}
			</button>
		</td>
	);
}

export function RangeCalendar(props: RangeCalendarProps<DateValue>) {
	const { locale } = useLocale();
	const state = useRangeCalendarState({
		...props,
		visibleDuration: { months: 2 },
		locale,
		createCalendar,
	});

	const ref = React.useRef(null);
	const { calendarProps, prevButtonProps, nextButtonProps, title } =
		useRangeCalendar(props, state, ref);

	return (
		<div {...calendarProps} ref={ref} className="calendar">
			<CalendarHead
				prevButtonProps={prevButtonProps}
				nextButtonProps={nextButtonProps}
				title={title}
			/>
			<div className="p-16 pl-0 flex gap-24">
				<RangeCalendarShortcuts
					className="border-r border-grey-200 pr-8"
					state={state}
				/>
				<CalendarGrid state={state} />
				<CalendarGrid state={state} offset={{ months: 1 }} />
			</div>
		</div>
	);
}
