import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDrag({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M11 4.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM18 4.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM11 11.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM11 18.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM18 11.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM18 18.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z" />
		</svg>
	);
}

const MemoSvgDrag = React.memo(SvgDrag);
export default MemoSvgDrag;
