import { Card } from "@app/modules/cards/Card";
import type { CustomerProductsCardProps } from "@app/modules/cards/card-props";
import { useTranslate } from "@app/modules/i18n/context";
import { useQuery } from "@app/modules/graphql/queries";
import { EntityIdTagItemLink } from "@app/modules/entity/EntityIdTagItem";
import { CustomerOrderContainersTable } from "@app/modules/customer-order/CustomerOrderContainersTable";
import { CustomerOrderLastOrdersDocument } from "@app/modules/customer-order/queries/customer-order-last-orders.query.generated";

export function CustomerProductContainersCard({
	customerId,
}: CustomerProductsCardProps) {
	const t = useTranslate("common");
	const [{ data: lastOrders }] = useQuery({
		query: CustomerOrderLastOrdersDocument,
		variables: {
			customerId,
			limit: 1,
			offset: 0,
		},
		pause: !customerId,
	});
	const lastOrder = lastOrders?.entities[0];

	return (
		<Card>
			<Card.Header>
				<Card.Title>
					{t("cards.customer-products.titles.container-history")}
				</Card.Title>
				<EntityIdTagItemLink entity={lastOrder} to="sales.customer-orders.id" />
			</Card.Header>
			<Card.Body>
				{lastOrder && (
					<CustomerOrderContainersTable
						customerId={customerId}
						customerOrderId={lastOrder?.id}
						isSummary
					/>
				)}
			</Card.Body>
		</Card>
	);
}
