import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgUnsend({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2C6.479 2 2 6.479 2 12s4.479 10 10 10 10-4.479 10-10S17.521 2 12 2zM4 12c0-4.416 3.584-8 8-8s8 3.584 8 8-3.584 8-8 8-8-3.584-8-8z"
			/>
			<rect x={6} y={11} width={12} height={2} rx={1} />
		</svg>
	);
}

const MemoSvgUnsend = React.memo(SvgUnsend);
export default MemoSvgUnsend;
