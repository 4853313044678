import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgNote({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.1 1c-.836 0-1.63.347-2.207.95A3.242 3.242 0 004 4.192v14.616c0 .833.317 1.64.893 2.242.578.603 1.37.95 2.207.95h9.8c.836 0 1.63-.347 2.207-.95A3.242 3.242 0 0020 18.808V9.913c0-.64-.243-1.26-.688-1.725l-6.18-6.452A2.371 2.371 0 0011.42 1H7.1zm-.763 2.334c.21-.22.485-.334.763-.334H11v4.526A2.474 2.474 0 0013.474 10H18v8.808c0 .33-.126.637-.337.858-.21.22-.486.334-.763.334H7.1c-.278 0-.553-.115-.763-.334A1.242 1.242 0 016 18.808V4.192c0-.33.126-.638.337-.858zM16.363 8L13 4.49v3.036a.474.474 0 00.474.474h2.889z"
			/>
		</svg>
	);
}

const MemoSvgNote = React.memo(SvgNote);
export default MemoSvgNote;
