import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgReturnItem({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={23}
			height={20}
			viewBox="0 0 23 20"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M2.365 10.04c-.05-2.593 1.041-5.18 2.925-7.059C9.117-.846 15.533-.88 19.395 2.982c3.863 3.862 3.829 10.278 0 14.107-2.944 2.944-7.54 3.745-11.298 1.911a.975.975 0 11.855-1.752c3.004 1.466 6.705.821 9.065-1.538 3.07-3.07 3.097-8.252 0-11.349-3.098-3.098-8.28-3.07-11.35 0C5.12 5.905 4.21 7.948 4.292 10.04h1.563c.649 0 .974.784.515 1.244l-2.562 2.561a.729.729 0 01-1.03 0L.215 11.285A.728.728 0 01.73 10.04h1.635z" />
			<path d="M16.567 5.323a.988.988 0 00-1.097-.253.995.995 0 00-.34.206l-.761.758a.513.513 0 00-.135.36c0 .13.051.255.144.347l.697.697a.493.493 0 00.538.107h.002a.505.505 0 00.168-.097l.742-.738c.375-.375.4-.995.042-1.387zM12.959 7.444L8.06 12.34a.553.553 0 00-.142.242l-.514 1.53a.24.24 0 00.3.298l1.528-.513a.553.553 0 00.242-.142l4.898-4.898a.553.553 0 000-.779l-.633-.635a.554.554 0 00-.781 0z" />
		</svg>
	);
}

const MemoSvgReturnItem = React.memo(SvgReturnItem);
export default MemoSvgReturnItem;
