import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgEingang({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M9.271 5.256A.723.723 0 019.806 5h9.388c.18 0 .374.078.535.256.164.181.271.448.271.744v12c0 .296-.107.563-.271.744a.723.723 0 01-.535.256H9.806a.723.723 0 01-.535-.256A1.115 1.115 0 019 18v-2a1 1 0 10-2 0v2c0 .765.273 1.516.787 2.084A2.72 2.72 0 009.806 21h9.388a2.72 2.72 0 002.02-.916c.513-.568.786-1.32.786-2.084V6c0-.765-.273-1.516-.787-2.084A2.72 2.72 0 0019.194 3H9.806a2.72 2.72 0 00-2.02.916A3.114 3.114 0 007 6v2a1 1 0 002 0V6c0-.296.107-.563.271-.744z" />
			<path d="M13.457 7.543a1 1 0 10-1.414 1.414L14.086 11H2.25a1 1 0 100 2h11.836l-2.043 2.043a1 1 0 001.414 1.414l3.75-3.75a1 1 0 000-1.414l-3.75-3.75z" />
		</svg>
	);
}

const MemoSvgEingang = React.memo(SvgEingang);
export default MemoSvgEingang;
