import {
	DataTable,
	DataTableCell,
	DataTableRow,
} from "@app/modules/data-table/DataTable";
import { useDataTable } from "@app/modules/data-table/useDataTable";
import { EntityIdTagItem } from "@app/modules/entity/EntityIdTagItem";
import { SharedEntityTagItem } from "@app/modules/entity/SharedEntityTagItem";
import { useTranslate } from "@app/modules/i18n/context";
import { HorizontalList } from "@app/modules/layout/HorizontalList";
import { PRODUCT_GROUP_CONTAINER } from "@app/modules/product-group/product-group-utils";
import { ProductStockQuantity } from "@app/modules/stock/ProductStockQuantity";
import { useStockDate } from "@app/modules/stock/stock-utils";
import { useMutation } from "@app/modules/graphql/queries";
import { Button } from "@app/modules/Button";
import { Save } from "@app/icons/components/action";
import { useEntityForm, useEntityFormContext } from "@app/modules/entity/hooks";
import { type Infer, object, string, optional, record } from "superstruct";
import { FormProvider } from "react-hook-form";
import { ProductQuantityAndUnitField } from "@app/modules/product-unit/ProductQuantityAndUnitField";
import { UpsertCustomerOrderContainerReturnsDocument } from "@app/modules/customer-order/queries/customer-order-returned-containers.mutation.generated";
import {
	CustomerOrderContainersDocument,
	type CustomerOrderContainersQuery,
} from "@app/modules/customer-order/queries/customer-order-containers.query.generated";
import { useEffect, useMemo, useState } from "react";
import {
	Customer_Order_Container_Return_Items_Constraint,
	Customer_Order_Container_Return_Items_Update_Column,
} from "@app/modules/graphql-api-types.generated";
import { useAbility } from "@app/modules/authorization/AuthorizationProvider";

export interface CustomerOrderContainersTableProps {
	customerId?: string;
	customerOrderId?: string;
	isSummary?: boolean;
}
type StockProduct = ElementType<CustomerOrderContainersQuery["entities"]>;

const containerProductReturnItemSchema = object({
	returnedContainerId: optional(string()),
	journalEntryId: optional(string()),
	containers: record(
		string(),
		object({
			id: optional(string()),
			containerId: string(),
			returnedQuantity: optional(string()),
			quantityUnitId: optional(string()),
		}),
	),
});

type FormValues = Infer<typeof containerProductReturnItemSchema>;

export function CustomerOrderContainersTable({
	customerId,
	customerOrderId,
	isSummary,
}: CustomerOrderContainersTableProps) {
	const t = useTranslate("common");
	const [shouldResetForm, setShouldResetForm] = useState<Boolean>(false);
	const { can } = useAbility();
	const { debouncedDateTime } = useStockDate();
	const [, upsertContainerReturn] = useMutation(
		UpsertCustomerOrderContainerReturnsDocument,
	);

	const dataTable = useDataTable({
		name: "containers-table",
		columns: [
			{
				accessor: "product.domainId",
				label: t("stock.table.head.product-id"),
			},
			{
				accessor: "product.name",
				label: t("stock.table.head.article"),
				sortable: false,
			},
			{
				accessor: "monetaryQuantity",
				label: t("stock.table.head.stock"),
				align: "right",
				sortable: false,
				hidden: isSummary,
			},
			{
				accessor:
					"product.customerOrderPickedContainers_aggregate.sum.quantity",
				label: t("orders.adjustments.create-modal.delivered"),
				align: "right",
				sortable: false,
			},
			{
				accessor: "returnedQuantity",
				label: t(
					"operations.returns.supplier-order.positions.head.returned-quantity",
				),
				align: "right",
				sortable: false,
			},
		] as const,
		query: CustomerOrderContainersDocument,
		queryVariables: {
			where: {
				product: {
					productGroupMembers: {
						productGroup: { name: { _eq: PRODUCT_GROUP_CONTAINER } },
					},
				},
				account: {
					customerId: { _eq: customerId },
				},
			},
			order: [],
			date: debouncedDateTime?.toUtcString(),
			customerOrderId: customerOrderId ?? "",
			filterNulls: isSummary ?? false,
		},
		getRowId: ({ hash }) => hash ?? undefined,
		hasRowSelect: false,
	});

	const defaultValues = useMemo(
		() =>
			dataTable.visibleRows.reduce(
				(acc, row) => {
					const containerReturnItem =
						row.product.customerOrderContainerReturnItems[0];
					if (containerReturnItem) {
						acc.returnedContainerId =
							containerReturnItem.customerOrderContainerReturn.id;
						acc.journalEntryId =
							containerReturnItem?.customerOrderContainerReturn
								.journalEntryId ?? undefined;
					}
					acc.containers[row.product.id] = {
						id: containerReturnItem?.id,
						containerId: row.product.id,
						returnedQuantity: containerReturnItem?.quantity ?? "0",
						quantityUnitId: row.storageUnit.id,
					};
					return acc;
				},
				{ containers: {} } as FormValues,
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dataTable.fetching],
	);

	const methods = useEntityForm({
		schema: containerProductReturnItemSchema,
		defaultValues,
	});

	const { handleSubmit, reset } = methods;

	useEffect(() => {
		if (!dataTable.fetching) {
			setShouldResetForm(true);
		}
	}, [dataTable.fetching]);

	useEffect(
		function resetFormAfterSubmit() {
			if (shouldResetForm) {
				reset(defaultValues);
			}
		},
		[defaultValues, shouldResetForm, reset],
	);

	const onSubmit = async (values: FormValues) => {
		const containers = Object.values(values.containers ?? {});
		const customerOrderContainerReturnItems = containers
			.filter((container) => container.returnedQuantity)
			.map((container) => ({
				id: container.id,
				quantity: container.returnedQuantity,
				containerProductId: container.containerId,
				unitId: container.quantityUnitId,
			}));
		setShouldResetForm(false);
		await upsertContainerReturn({
			objects: {
				customerOrderId,
				id: values.returnedContainerId,
				journalEntryId: values.journalEntryId,
				customerOrderContainerReturnItems: {
					data: customerOrderContainerReturnItems,
					on_conflict: {
						constraint:
							Customer_Order_Container_Return_Items_Constraint.CustomerOrderContainerReturnItemsPkey,
						update_columns: [
							Customer_Order_Container_Return_Items_Update_Column.Quantity,
						],
					},
				},
			},
		});
		dataTable.refresh();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...methods}>
				<DataTable
					instance={dataTable}
					extraButtons={
						!isSummary &&
						can("update", "customer_order_container_return_items") && (
							<Button
								type="submit"
								className="mx-12"
								isFetching={Boolean(dataTable.fetching)}
								disableFetchingIndicatorDelay
								variant="tertiary-grey"
								icon={Save}
							/>
						)
					}
				>
					{dataTable.visibleRows.map((row) => (
						<StockContainerRow
							key={row.hash}
							stockProduct={row}
							isSummary={isSummary}
						/>
					))}
				</DataTable>
			</FormProvider>
		</form>
	);
}

interface StockContainerRowProps {
	stockProduct: StockProduct;
	isSummary?: boolean;
}

function StockContainerRow({
	stockProduct,
	isSummary,
}: StockContainerRowProps) {
	const { control } = useEntityFormContext<FormValues>();
	const { can } = useAbility();
	const containerId = stockProduct.product.id;
	const pickedQuantity =
		stockProduct.product.customerOrderPickedContainers_aggregate.aggregate?.sum
			?.quantity ?? "0";
	const returnedContainer =
		stockProduct.product.customerOrderContainerReturnItems[0];

	return (
		<DataTableRow>
			<DataTableCell strong>
				<EntityIdTagItem entity={stockProduct.product} />
			</DataTableCell>
			<DataTableCell strong>
				<HorizontalList>
					{stockProduct.product.name}
					<SharedEntityTagItem entity={stockProduct.product} />
				</HorizontalList>
			</DataTableCell>
			{!isSummary && (
				<DataTableCell className="text-right" strong>
					<ProductStockQuantity
						storageQuantity={stockProduct.storageQuantity}
						storageUnit={stockProduct.storageUnit}
						monetaryQuantity={stockProduct.monetaryQuantity}
						monetaryUnit={stockProduct.monetaryUnit}
					/>
				</DataTableCell>
			)}
			<DataTableCell className="text-right" strong>
				<ProductStockQuantity
					storageQuantity={pickedQuantity}
					storageUnit={stockProduct.storageUnit}
					monetaryQuantity={pickedQuantity}
					monetaryUnit={stockProduct.monetaryUnit}
				/>
			</DataTableCell>
			<DataTableCell className="text-right" strong>
				{!isSummary &&
				can("update", "customer_order_container_return_items") ? (
					<ProductQuantityAndUnitField
						control={control}
						quantityFieldName={`containers.${containerId}.returnedQuantity`}
						unitFieldName={`containers.${containerId}.quantityUnitId`}
						className="w-1/2 float-right"
						units={[stockProduct.storageUnit]}
					/>
				) : (
					<ProductStockQuantity
						storageQuantity={returnedContainer?.quantity ?? "0"}
						storageUnit={stockProduct.storageUnit}
						monetaryQuantity={returnedContainer?.quantity ?? "0"}
						monetaryUnit={stockProduct.monetaryUnit}
					/>
				)}
			</DataTableCell>
		</DataTableRow>
	);
}
