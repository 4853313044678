import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgLabel({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M6 9a1 1 0 011-1h3a1 1 0 110 2H7a1 1 0 01-1-1zM7 11a1 1 0 100 2h10a1 1 0 100-2H7zM7 14a1 1 0 100 2h10a1 1 0 100-2H7z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 7a3 3 0 013-3h14a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm3-1h14a1 1 0 011 1v10a1 1 0 01-1 1H5a1 1 0 01-1-1V7a1 1 0 011-1z"
			/>
		</svg>
	);
}

const MemoSvgLabel = React.memo(SvgLabel);
export default MemoSvgLabel;
