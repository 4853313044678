import {
	Close,
	DropdownClosed,
	DropdownOpen,
} from "@app/icons/components/action";
import type { IconComponent } from "@app/icons/types";
import { Button } from "@app/modules/Button";
import { useInputId } from "@app/modules/form/form-utils";
import { useConsoleAssert } from "@app/modules/hooks/useConsoleAssert";
import { useInputGroup } from "@app/modules/input-fields/InputGroup";
import type { OptionItemProps } from "@app/modules/input-fields/Options";
import {
	OptionItem,
	Options,
	useOptionsPositioning,
} from "@app/modules/input-fields/Options";
import type {
	InputSize,
	ValidationState,
} from "@app/modules/input-fields/types";
import { devlogger } from "@app/modules/logger/devlogger";
import clsx from "clsx";
import { useCombobox } from "downshift";
import type { ChangeEvent, InputHTMLAttributes, ReactNode, Ref } from "react";
import { forwardRef, useEffect, useRef } from "react";
import {
	controlSizeCls,
	getViewState,
	Input,
	legacyGetSize,
	legacyGetValidationState,
	reverseControlPaddingCls,
} from "./commons";

export interface ComboboxItem {
	id: string | null;
	label: string;
	icon?: IconComponent;
	domainId?: string;
	tenant?: string;
	tags?: OptionItemProps["tags"];
	disabled?: boolean;
}

// TODO: find a better solution.
// The way it's written you must provide the generic type if you specify data.
// I'd rather have it automatically infer it base on whether data is provided or not.
// export type ComboboxItem<ItemData = undefined> = ItemData extends undefined
// 	? CommonComboboxItem
// 	: CommonComboboxItem & { data: ItemData };

export interface ComboboxProps<Item extends ComboboxItem>
	extends Omit<
		InputHTMLAttributes<HTMLInputElement>,
		"value" | "onChange" | "onClick"
	> {
	className?: string;
	label?: string;
	error?: string;
	items?: Item[];
	inputValue?: string;
	onInputValueChange?: (inputValue: string) => void;
	defaultInputValue?: string;
	value?: string | null;
	onChange?: ComboboxChangeHandler<Item>;
	sizing?: InputSize;
	state?: ValidationState;
	hint?: ReactNode;
	isSmall?: boolean;
	isTouch?: boolean;
	preventFlip?: boolean;
}

export type ComboboxChangeHandler<Item> = (
	value: string | null,
	item?: Item | null,
) => void;

export const Combobox = forwardRef(function Combobox<Item extends ComboboxItem>(
	{
		className,
		label,
		error,
		disabled,
		isSmall,
		isTouch,
		preventFlip,
		items = [],
		inputValue,
		onInputValueChange,
		defaultInputValue,
		value,
		onChange,
		id,
		sizing = "md",
		state = "default",
		hint,
		readOnly,
		onFocus: onInputFocus,
		...inputProps
	}: ComboboxProps<Item>,
	ref: Ref<HTMLInputElement>,
) {
	const group = useInputGroup(inputProps.name);
	const inputId = useInputId(group.inputId ?? id);
	const validationState = legacyGetValidationState(group.state ?? state, {
		error,
	});
	const viewState = getViewState(validationState, { disabled, readOnly });
	const size = legacyGetSize(sizing, { isSmall, isTouch });

	const selectedItem = items.find((item) => item.id === value) ?? null;
	// This effect will automatically set the input value whenever the selected item changes.
	// Especially useful when the combobox is initialized with a prefilled value.
	// The only problem with this approach is that it will fail if the provided `value` is not found amongst the items.
	useEffect(() => {
		if (selectedItem && inputValue !== selectedItem?.label) {
			onInputValueChange?.(selectedItem.label);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItem?.id]);

	useEffect(() => {
		if (value && !selectedItem && items.length > 0) {
			devlogger.info(
				"Combobox:",
				"Received value that could not be found in the provided items.",
				"To fix this, make sure the items always include the selected value: ",
				value,
			);
		}
	}, [items.length, selectedItem, value]);

	const {
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps,
		openMenu,
	} = useCombobox({
		id: inputId,
		items,
		inputValue,
		defaultInputValue,
		selectedItem,
		// Auto select the first suggestion. This allows the user to simply press
		// ENTER without requiring them to use the arrow keys to select the item,
		// if what they are looking for is the top result
		defaultHighlightedIndex: 0,
		itemToString: (item) => item?.label ?? "",
		onSelectedItemChange: (changes) => {
			onInputValueChange?.(changes.inputValue ?? "");
			if (changes.selectedItem) {
				onChange?.(changes.selectedItem.id, changes.selectedItem);
			} else {
				onChange?.("", undefined);
			}
		},
		scrollIntoView: (item) => {
			// item can be undefined even if not specified by types
			item?.scrollIntoView();
		},
	});

	const comboBoxName = inputProps.name ?? "empty";
	useConsoleAssert(comboBoxName !== "empty", "Combobox: No name provided");

	const clear = () => {
		onInputValueChange?.("");
		onChange?.("", null);
	};

	const hasValue = value !== "" && value !== undefined;
	const hasValueRef = useRef(hasValue);
	hasValueRef.current = hasValue;

	const dropDownIcon = (() => {
		if (hasValue) {
			return Close;
		}
		return isOpen ? DropdownOpen : DropdownClosed;
	})();

	const { referenceRef, optionsProps } = useOptionsPositioning({
		isOpen,
		preventFlip,
	});

	const control = (
		<Input.Control
			{...getComboboxProps()}
			state={viewState}
			sizing={size}
			isInsideGroup={group.isInsideGroup}
			className={clsx(group.isInsideGroup && className)}
		>
			{/* 
			This wrapping `div` is a dirty workaround because passing down the `reference` to `getComobobxProps` causes en infinite loop. 
			Related: https://floating-ui.com/docs/react-dom#stable-ref-props
			Unfortunately the ref merging happens inside `useCombobox`.
			TODO DOWNSHIFT: Revisit when there is more time.
			*/}
			<div
				ref={referenceRef}
				className={clsx(
					"flex flex-1 items-center",
					reverseControlPaddingCls[size],
					controlSizeCls[size],
				)}
			>
				<Input.Input
					className="flex-1 w-full"
					{...getInputProps({
						...inputProps,
						ref,
						disabled,
						onChange: ({ target }: ChangeEvent<HTMLInputElement>) => {
							onInputValueChange?.(target.value);
						},
						onClick: openMenu,
						onFocus: (event) => {
							if (!isOpen) {
								openMenu();
							}
							onInputFocus?.(event);
						},
						onKeyDown: (event) => {
							if (
								(event.key === "Backspace" || event.key === "Delete") &&
								hasValue
							) {
								clear();
								openMenu();
							}
						},
						onBlur: () => {
							// Hacky workaround in case the blur is triggered by selecting an option.
							// In which case the `hasSelecteItemRef.current` has been updated by the time the timeout finishes.
							setTimeout(() => {
								if (!hasValueRef.current && inputValue) {
									onInputValueChange?.("");
								}

								// if a value is selected, reset the input value to the selected item's label
								if (
									hasValueRef.current &&
									selectedItem?.label &&
									inputValue !== selectedItem.label
								) {
									onInputValueChange?.(selectedItem.label);
								}
							}, 150);
						},
					})}
				/>
				<Button
					testid={`dropdown-${comboBoxName}`}
					variant="tertiary-grey"
					icon={dropDownIcon}
					iconSize="16"
					className={clsx("shrink-0 mr-8", disabled && "hidden")}
					{...getToggleButtonProps({
						disabled,
						refKey: "innerRef",
						onClick: () => {
							if (hasValue) {
								clear();
							}
						},
					})}
					aria-label="toggle menu"
				/>
			</div>
			<Options
				{...getMenuProps()}
				{...optionsProps}
				className={clsx(isOpen ? "border-t" : "")}
			>
				{items.map((item, index) => (
					<OptionItem
						testid={item.label}
						key={item.id}
						icon={item.icon}
						isHighlighted={highlightedIndex === index}
						domainId={item.domainId}
						tenant={item.tenant}
						tags={item.tags}
						{...getItemProps({ item, index, disabled: item.disabled })}
					>
						{item.label}
					</OptionItem>
				))}
			</Options>
		</Input.Control>
	);

	if (group.isInsideGroup) {
		return control;
	}

	return (
		<div className={className}>
			<Input.Label {...getLabelProps()} testid={comboBoxName} state={viewState}>
				{label}
			</Input.Label>
			{control}
			{(hint || error) && (
				<Input.Hint state={viewState}>{error ?? hint}</Input.Hint>
			)}
		</div>
	);
});
