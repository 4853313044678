import type {
	HardwareCommand,
	HardwareInstruction,
	ParsedMessage,
} from "@app/modules/hardware/types";
import { unknownMessage } from "@app/modules/hardware/types";

const SEPARATOR = " ";
const TERMINATION_STRING = String.fromCharCode(13, 10);

const instructions: Record<HardwareCommand, HardwareInstruction> = {
	"get-weight": "S",
	tare: "T",
	"set-to-zero": "Z",
	reset: "@",
};

function compile(cmd: HardwareCommand): HardwareInstruction {
	return instructions[cmd] + TERMINATION_STRING;
}

function parse(instr: HardwareInstruction): ParsedMessage {
	const [identifier, ...rest] = instr.split(SEPARATOR).filter(Boolean);
	if (identifier === "S") {
		const [, weight, unit] = rest;
		if (weight && unit && unit === "kg") {
			return { type: "weight", weight, unit };
		}
		// todo: handle error messages
	}
	// todo: support other identifiers
	return unknownMessage;
}

export const sics = {
	compile,
	parse,
	TERMINATION_STRING,
};
