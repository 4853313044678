import { logger } from "@app/modules/logger/logger";

export type CodeType = "00" | "01" | "02" | "03" | "04" | "05";

const CODE_PREFIX = "MEATICO";
const CODE_DELIMITER = "|";

type Prefix = typeof CODE_PREFIX;
type Delimiter = typeof CODE_DELIMITER;

// Not perfect but it's a start.

// Codes without tenants (to be deprecated)
// code 00: product, lotnumber
export type Code00 =
	`${Delimiter}${Prefix}${Delimiter}00${Delimiter}${string}${Delimiter}${string}`;
// code 01: product, lotnumber, stock location
export type Code01 =
	`${Delimiter}${Prefix}${Delimiter}01${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}`;
// code 02: product, lotnumber, stock location, netWeight, tareWeight
export type Code02 =
	`${Delimiter}${Prefix}${Delimiter}02${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}`;

// Codes with tenants that track products via primary key
// code 03: tenantShortName, productId, lotnumber -> analog to code 00
export type Code03 =
	`${Delimiter}${Prefix}${Delimiter}03${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}`;
// code 04: tenantShortName, productId, lotnumber, stock location -> analog to code 01
export type Code04 =
	`${Delimiter}${Prefix}${Delimiter}04${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}`;
// code 05: tenantShortName, productId, lotnumber, stock location, netWeight, tareWeight -> analog to code 02
export type Code05 =
	`${Delimiter}${Prefix}${Delimiter}05${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}${Delimiter}${string}`;

export type CodePayload = Code00 | Code01 | Code02 | Code03 | Code04 | Code05;

export function compileCode(type: CodeType, segments: string[]): CodePayload {
	// fixme: Type check the segments input
	return ["", CODE_PREFIX, type, ...segments].join(
		CODE_DELIMITER,
	) as CodePayload;
}

export function parseCode(code: CodePayload | string) {
	const [_, prefix, type, ...segments] = code.split(CODE_DELIMITER);
	if (prefix !== CODE_PREFIX) {
		logger.warn("Received invalid code:", code);
		return undefined;
	}

	switch (type) {
		case "00": {
			const productDomainId = segments[0] ?? "";
			const lotNumber = segments[1] ?? "";

			return {
				type,
				productDomainId,
				lotNumber,
			};
		}
		case "01": {
			const productDomainId = segments[0] ?? "";
			const lotNumber = segments[1] ?? "";
			const stockLocationId = segments[2];

			return {
				type,
				productDomainId,
				lotNumber,
				stockLocationId,
			};
		}
		case "03": {
			const tenantShortName = segments[0] ?? "";
			const productId = segments[1] ?? "";
			const lotNumber = segments[2] ?? "";

			return {
				type,
				tenantShortName,
				productId,
				lotNumber,
			};
		}
		case "04": {
			const tenantShortName = segments[0] ?? "";
			const productId = segments[1] ?? "";
			const lotNumber = segments[2] ?? "";
			const stockLocationId = segments[3];

			return {
				type,
				tenantShortName,
				productId,
				lotNumber,
				stockLocationId,
			};
		}
		case "05": {
			const tenantShortName = segments[0];
			const productId = segments[1];
			const lotNumber = segments[2];
			const stockLocationId = segments[3];
			const netWeight = segments[4];
			const tareWeight = segments[5];
			if (
				!tenantShortName ||
				!productId ||
				!lotNumber ||
				!stockLocationId ||
				!netWeight ||
				!tareWeight
			) {
				logger.warn("Received invalid code:", code);
				return undefined;
			}
			return {
				type,
				tenantShortName,
				productId,
				lotNumber,
				stockLocationId,
				netWeight,
				tareWeight,
			};
		}
		default: {
			logger.warn("Received unknown code type:", type);
			return undefined;
		}
	}
}

// interfaces/utils to be deprecated -> still here for reference because old codes are in use

interface Code00Args {
	productDomainId: string;
	lotNumber: string;
}

export function makeCode00Payload({ productDomainId, lotNumber }: Code00Args) {
	return compileCode("00", [productDomainId, lotNumber]);
}

interface Code01Args extends Code00Args {
	stockLocationId: string;
}
export function makeCode01Payload({
	productDomainId,
	lotNumber,
	stockLocationId,
}: Code01Args) {
	return compileCode("01", [productDomainId, lotNumber, stockLocationId]);
}

interface Code02Args extends Code01Args {
	netWeight: string;
	tareWeight: string;
}
export function makeCode02Payload({
	productDomainId,
	lotNumber,
	stockLocationId,
	netWeight,
	tareWeight,
}: Code02Args) {
	return compileCode("02", [
		productDomainId,
		lotNumber,
		stockLocationId,
		netWeight,
		tareWeight,
	]);
}

// current interfaces/utils

// replaces code 01
interface Code04Args extends Omit<Code01Args, "productDomainId"> {
	tenantShortName: string;
	productId: string;
}
export function makeCode04Payload({
	productId,
	lotNumber,
	stockLocationId,
	tenantShortName,
}: Code04Args) {
	return compileCode("04", [
		tenantShortName,
		productId,
		lotNumber,
		stockLocationId,
	]);
}

// replaces code 02
interface Code05Args extends Omit<Code02Args, "productDomainId"> {
	tenantShortName: string;
	productId: string;
}
export function makeCode05Payload({
	tenantShortName,
	productId,
	lotNumber,
	stockLocationId,
	netWeight,
	tareWeight,
}: Code05Args) {
	return compileCode("05", [
		tenantShortName,
		productId,
		lotNumber,
		stockLocationId,
		netWeight,
		tareWeight,
	]);
}

export function makeCodePayload({
	productId,
	lotNumber,
	stockLocationId,
	tenantShortName,
}: {
	productId: string;
	lotNumber: string;
	stockLocationId?: string;
	tenantShortName: string;
}) {
	if (stockLocationId) {
		return compileCode("04", [
			tenantShortName,
			productId,
			lotNumber,
			stockLocationId,
		]);
	}
	return compileCode("03", [tenantShortName, productId, lotNumber]);
}
