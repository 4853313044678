import { Check, Notification, Recent, Sales } from "@app/icons/components";
import { OpenPage } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import { useAppState } from "@app/modules/app-state/context";
import {
	useMutation,
	useQuery,
	useSubscription,
} from "@app/modules/graphql/queries";
import { useToggle } from "@app/modules/hooks/useToggle";
import { useTranslate } from "@app/modules/i18n/context";
import {
	DropDown,
	DropDownItem,
	DropDownLink,
} from "@app/modules/layout/DropDown";
import { HorizontalList } from "@app/modules/layout/HorizontalList";
import { LocalDate } from "@app/modules/localdate/localdate";
import { useNotificationDispatch } from "@app/modules/notification/context";
import {
	getIsAcked,
	getNotificationTextParams,
	getToggleIcon,
	useNotificationMutations,
} from "@app/modules/notification/notification-commons";
import type { NotificationPanelItemFragment } from "@app/modules/notification/queries/notification-panel.query.generated";
import { NotificationPanelItemsDocument } from "@app/modules/notification/queries/notification-panel.query.generated";
import { NotificationStreamDocument } from "@app/modules/notification/queries/notification-stream.subscription.generated";
import { SetFirstShownAtDocument } from "@app/modules/notification/queries/set-first-shown-at.mutation.generated";
import { Link } from "@app/modules/routes/link";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

export function NotificationPanel() {
	const t = useTranslate("common");
	const { user, tenant = "" } = useAppState();
	const userId = user?.id.toString() ?? "";
	const [isOpen, toggleIsOpen] = useToggle(false);

	const [{ data }, refetchItems] = useQuery({
		query: NotificationPanelItemsDocument,
		variables: { userId, tenant },
		pause: !userId || !tenant,
	});
	const [, setFirstShownAt] = useMutation(SetFirstShownAtDocument);

	const seenNotificationsRef = useRef(new Set<string>());
	const [from, setFrom] = useState(LocalDate.now().toUtcString());
	const [streaming] = useSubscription({
		query: NotificationStreamDocument,
		variables: {
			from,
			userId,
		},
		pause: !userId,
	});

	const { dispatchNotification } = useNotificationDispatch();
	useEffect(() => {
		// this code assumes that the batch size is 1
		const notification = streaming.data?.notifications_stream[0];
		if (notification && !seenNotificationsRef.current.has(notification.id)) {
			seenNotificationsRef.current.add(notification.id);
			const latestCreatedAt = notification.createdAt;
			setFrom((oldFrom) =>
				oldFrom < latestCreatedAt ? latestCreatedAt : oldFrom,
			);

			const params = getNotificationTextParams(t, notification);
			if (!params) {
				return;
			}

			dispatchNotification({
				type: "toast",
				variant: "info",
				text: params.text,
			});

			if (!notification.firstShownAt) {
				setFirstShownAt({
					id: notification.id,
					firstShownAt: LocalDate.now().toUtcString(),
				});
			}

			refetchItems();
		}
	}, [dispatchNotification, refetchItems, setFirstShownAt, streaming.data, t]);

	const unreadCount = data?.notificationsAggregate.aggregate?.count;

	return (
		<div className="relative">
			<button
				type="button"
				className="text-white p-8 transition-all relative"
				title={t("notifications.title")}
				onClick={() => toggleIsOpen()}
			>
				<Icon icon={Notification} size="24" />
				<Indicator value={unreadCount} />
			</button>
			{userId && (
				<DropDown
					isOpen={isOpen}
					onCloseRequest={() => toggleIsOpen(false)}
					align="right"
					className="w-352"
					width="sm"
					maxHeight="md"
				>
					<DropDownLink
						onClick={() => toggleIsOpen(false)}
						className="flex items-center p-8 border-b border-grey-300"
						to="notifications"
					>
						<HorizontalList>
							{t("notifications.titles.list")}
							<Icon icon={OpenPage} size="16" />
						</HorizontalList>
					</DropDownLink>
					{data?.notifications?.length === 0 && <NotificationPlaceholder />}
					{data?.notifications.map((notification) => (
						<NotificationItem
							key={notification.id}
							userId={userId}
							notification={notification}
						/>
					))}
				</DropDown>
			)}
		</div>
	);
}

interface IndicatorProps {
	value?: number;
}

function Indicator({ value }: IndicatorProps) {
	if (!value) {
		return null;
	}

	return (
		<span className="red absolute right-0 top-0 rounded-full w-20 h-20 bg-red-vibrant flex overflow-hidden">
			<span className="block m-auto text-10 font-500 text-ellipsis overflow-hidden max-w-full px-2 text-center">
				{value}
			</span>
		</span>
	);
}

interface NotificationItemProps {
	notification: NotificationPanelItemFragment;
	userId: string;
}

function NotificationItem({ userId, notification }: NotificationItemProps) {
	const t = useTranslate("common");
	const { toggleAck } = useNotificationMutations({ userId });
	const { id, createdAt, ackedAt } = notification;
	const isAcked = getIsAcked(notification);

	const params = getNotificationTextParams(t, notification);
	if (!params) {
		return (
			<DropDownItem className="px-16 bg-grey-100 border-b border-grey-300">
				<div className="flex items-center w-full justify-between">
					<div className="text-caption-12 text-grey-500">
						{t("notifications.labels.unknown")}
					</div>
					{!isAcked && (
						<button
							type="button"
							className={clsx(
								"my-4 text-grey-700 hover:bg-white rounded-full p-1 border border-grey-700",
							)}
							onClick={() => toggleAck(id, isAcked)}
							title={t("notifications.labels.acknowledge")}
						>
							<Icon
								icon={Check}
								size="24"
								className="transition-all hover:text-grey-900"
							/>
						</button>
					)}
				</div>
			</DropDownItem>
		);
	}

	const { text, routeName, routeParams } = params;
	return (
		<DropDownItem className="relative border-b border-grey-300">
			<Link
				to={routeName}
				params={routeParams}
				className={clsx(
					"w-full p-12 text-grey-800 hover:bg-grey-200",
					!ackedAt && "bg-grey-100",
				)}
			>
				<span className="flex pr-40">
					<span
						className={clsx(
							"bg-grey-100 border border-grey-300",
							"w-32 h-32 rounded-full p-0 flex mb-auto text-white",
						)}
					>
						<Icon icon={Sales} size="16" className="m-auto text-grey-600" />
					</span>{" "}
					<span className="block text-14 pl-6 flex-1">{text}</span>
				</span>
				<small className="text-grey-600 pt-8 flex justify-end">
					{LocalDate.fromUtcString(createdAt).toLocalizedDateString()}
					<Icon icon={Recent} size="16" className="my-auto mx-4" />{" "}
					{LocalDate.fromUtcString(createdAt).toLocalTime()}
				</small>
			</Link>

			<button
				type="button"
				className={clsx(
					"absolute top-12 right-12 w-32 h-32 rounded-full p-0 flex mb-auto mt-0 transition-all group border border-grey-900",
					isAcked && "text-grey-900 bg-white hover:bg-grey-900",
					!isAcked && "text-white bg-grey-900 hover:bg-white",
				)}
				onClick={() => toggleAck(id, isAcked)}
				title={t("notifications.labels.acknowledge")}
			>
				<Icon
					icon={getToggleIcon(isAcked)}
					size={isAcked ? "20" : "24"}
					className={clsx(
						"m-auto transition-all",
						isAcked && "group-hover:text-white",
						!isAcked && "group-hover:text-grey-900",
					)}
				/>
			</button>
		</DropDownItem>
	);
}

function NotificationPlaceholder() {
	const t = useTranslate("common");
	return (
		<DropDownItem className="text-caption-12 p-12 w-full justify-center bg-grey-100 text-grey-500 border-b border-grey-300">
			{t("notifications.labels.empty")}
		</DropDownItem>
	);
}
