/* eslint-disable @typescript-eslint/no-unused-vars */
import { LocalDate } from "@app/modules/localdate/localdate";
import type {
	Contacts,
	Customers,
	Mutation_RootInsertCustomerArgs,
	Mutation_RootUpdateCustomerByPkArgs,
} from "@app/modules/graphql-api-types.generated";
import {
	Customer_States_Enum,
	Languages_Enum,
} from "@app/modules/graphql-api-types.generated";
import type { PartialResponse } from "@app/modules/graphql/optimistic-utils";
import {
	getValueGetter,
	keyof,
	tempId,
} from "@app/modules/graphql/optimistic-utils";
import type {
	Cache,
	OptimisticMutationConfig,
} from "@urql/exchange-graphcache";

function updateCustomerByPk(
	vars: Mutation_RootUpdateCustomerByPkArgs,
): PartialResponse<Customers> {
	return {
		__typename: "customers",
		id: vars.pk_columns.id,
		state: vars._set?.state,
	};
}

function insertCustomer(
	vars: Mutation_RootInsertCustomerArgs,
	cache: Cache,
): PartialResponse<Customers> {
	const customer = vars.object;
	const customerId = customer.id;

	// only apply optimistic updates when updating a customer
	if (!customerId) {
		return {};
	}

	const customerKey = keyof(cache, "customers", customerId);
	const getCustomerValue = getValueGetter(cache, customer, customerKey);

	return {
		__typename: "customers",
		id: customerId,
		name: getCustomerValue("name", ""),
		notes: getCustomerValue("notes", ""),
		state: getCustomerValue("state", Customer_States_Enum.Inactive),
		communicationLanguage: getCustomerValue(
			"communicationLanguage",
			Languages_Enum.DeCh,
		),
		updatedAt: LocalDate.now().toUtcString(),
		contacts: customer.contacts?.data.map(
			(contact, contactIdx): PartialResponse<Contacts> => {
				const contactKey = keyof(cache, "contacts", contact.id || null);
				const getContactValue = getValueGetter(cache, contact, contactKey);
				const contactTempId = tempId("contact", customerId, contactIdx);

				return {
					__typename: "contacts",
					id: getContactValue("id", contactTempId),
					givenNames: getContactValue("givenNames", ""),
					surname: getContactValue("surname", ""),
					ordinalPosition: getContactValue("ordinalPosition", contactIdx),
					emails: contact.emails?.data.map((email, idx) => {
						const emailKey = keyof(cache, "emails", email.id || null);
						const getEmailValue = getValueGetter(cache, email, emailKey);
						const tmpId = tempId("emails", contactIdx, idx);

						return {
							__typename: "emails",
							id: getEmailValue("id", tmpId),
							ordinalPosition: getEmailValue("ordinalPosition", idx),
							email: getEmailValue("email", ""),
						};
					}),
					phones: contact.phones?.data.map((phone, idx) => {
						const phoneKey = keyof(cache, "phones", phone.id || null);
						const getPhoneValue = getValueGetter(cache, phone, phoneKey);
						const tmpId = tempId("phones", contactIdx, idx);

						return {
							__typename: "phones",
							id: getPhoneValue("id", tmpId),
							ordinalPosition: getPhoneValue("ordinalPosition", idx),
							phoneNumber: getPhoneValue("phoneNumber", ""),
						};
					}),
				};
			},
		),
	};
}

// urql only provides a generic vars definition for optimistic functions.
// but we know exactly the shape of the variables and declare it as such.
// for this reason we have to cast it.
export const optimistic: OptimisticMutationConfig = {
	// updateCustomerByPk: (updateCustomerByPk as unknown) as OptimisticMutationResolver,
	// insertCustomer: (insertCustomer as unknown) as OptimisticMutationResolver,
};
