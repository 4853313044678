import { TaskFinished, Unsend } from "@app/icons/components";
import { User } from "@app/icons/components/nav";
import { Button } from "@app/modules/Button";
import {
	getIsDevJWTActive,
	getJWTMeta,
	loginAs,
	logout,
	setIsDevJWTActive,
} from "@app/modules/devdoor/devdoor";
import type { TestUserInfo } from "@app/modules/devdoor/testusers";
import { ff, sempione, tenantx } from "@app/modules/devdoor/testusers";
import type { SelectItem } from "@app/modules/input-fields/Select";
import { Select } from "@app/modules/input-fields/Select";
import { Header16, Header18 } from "@app/modules/typography";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const availableUsers = [tenantx.ted, ff.ted, sempione.ted];

const userToId = (user?: TestUserInfo) =>
	user ? `${user.meta.tenant}-${user.userId.toString()}` : "";

const options: SelectItem[] = availableUsers.map((user) => ({
	id: userToId(user),
	label: `${user?.meta.tenant} - ${user?.meta.name}`,
	icon: User,
}));

export interface DevdoorProps {
	className?: string;
}

/** Just like a backdoor, but for local dev only! */
function Devdoor({ className }: DevdoorProps) {
	const [isDevJWTActive, setIsUsingDevJWT] = useState(getIsDevJWTActive);
	useEffect(() => {
		setIsDevJWTActive(isDevJWTActive);
	}, [isDevJWTActive]);

	const [testUser, setTestUser] = useState(getJWTMeta);
	useEffect(() => {
		if (testUser) {
			loginAs(testUser);
		}
	}, [testUser]);

	return (
		<div className={clsx(className, "mr-auto ml-auto w-712")}>
			<Header18 className="mb-8">Login</Header18>
			<Button
				icon={isDevJWTActive ? Unsend : TaskFinished}
				variant="primary"
				testid="devdoor-toggle-jwt"
				onClick={() => {
					setIsUsingDevJWT(!isDevJWTActive);
				}}
			>
				{isDevJWTActive ? "Deactivate Dev JWT" : "Activate Dev JWT"}
			</Button>

			<Header16 className="mt-16 mb-8">Active User</Header16>
			<Select
				options={options}
				value={userToId(testUser)}
				onChange={(userId) => {
					setTestUser(availableUsers.find((user) => userId === userToId(user)));
				}}
			/>

			<Header16 className="mt-16 mb-8">Actions</Header16>
			<Button
				variant="secondary"
				testid="devdoor-logout"
				className="mr-16"
				onClick={() => {
					setIsUsingDevJWT(false);
					setTestUser(undefined);
					logout();
				}}
			>
				Logout
			</Button>
			<Button
				variant="secondary"
				className="mr-16"
				testid="devdoor-go-to-home"
				onClick={() => {
					window.location.href = "/";
				}}
			>
				Go to Meatico
			</Button>
		</div>
	);
}

export { Devdoor };
