import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOrderInternal({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M13 3a1 1 0 10-2 0v9.836l-2.043-2.043a1 1 0 00-1.414 1.414l3.75 3.75a1 1 0 001.414 0l3.75-3.75a1 1 0 00-1.414-1.414L13 12.836V3z" />
			<path d="M4.154 10.443a1 1 0 10-1.962-.39 10 10 0 1019.616 0 1 1 0 10-1.962.39 8 8 0 11-15.692 0z" />
		</svg>
	);
}

const MemoSvgOrderInternal = React.memo(SvgOrderInternal);
export default MemoSvgOrderInternal;
