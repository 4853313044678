import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCheck({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={40}
			height={41}
			viewBox="0 0 40 41"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.488 12.755a1.667 1.667 0 112.357 2.357l-15 15c-.65.65-1.706.65-2.357 0l-6.666-6.667a1.667 1.667 0 112.357-2.357l5.488 5.488 13.821-13.821z"
			/>
		</svg>
	);
}

const MemoSvgCheck = React.memo(SvgCheck);
export default MemoSvgCheck;
