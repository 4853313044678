import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgRefresh({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2.025c2.66-.003 5.261 1.054 7.059 2.924l1.863-1.863a.728.728 0 011.244.515v5.037a.729.729 0 01-.729.728h-5.036a.729.729 0 01-.516-1.243l1.812-1.813C16.275 4.774 14.187 3.972 12 3.975 7.66 3.975 3.975 7.619 3.975 12c0 4.38 3.683 8.025 8.025 8.025 3.337 0 6.41-2.161 7.497-5.322a.975.975 0 111.844.634c-1.36 3.953-5.177 6.638-9.34 6.638-5.415 0-9.976-4.513-9.976-9.975S6.586 2.025 12 2.025z"
			/>
		</svg>
	);
}

const MemoSvgRefresh = React.memo(SvgRefresh);
export default MemoSvgRefresh;
