import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOperations({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={18}
			height={18}
			viewBox="0 0 18 18"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.66 3.578h.709v-.802h-.708v.802zm-1.3.15V2.426a.95.95 0 01.95-.95H14.72a.95.95 0 01.95.95V6.68a.95.95 0 011.338.867v8.294a.95.95 0 01-.95.95H1.93a.95.95 0 01-.95-.95v-5.454a.95.95 0 01.453-.81l4.598-2.821a.95.95 0 011.447.81v1.075l3.342-1.918a.95.95 0 011.422.824v1.094l.118-.068V3.728zm1.3 4.1v-2.95h.709v2.544l-.708.406zm2.047.325l-2.492 1.43-.85.487a.95.95 0 01-1.422-.824V8.153L7.6 10.07a.95.95 0 01-1.423-.824V8.192L2.28 10.584v4.909h13.427v-7.34z"
			/>
		</svg>
	);
}

const MemoSvgOperations = React.memo(SvgOperations);
export default MemoSvgOperations;
