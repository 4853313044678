import {
	Invoicing,
	Operations,
	Options,
	Sales,
	Settings,
} from "@app/icons/components";
import { LargeDropdownClosed } from "@app/icons/components/action";
import {
	Article,
	Dashboard,
	Dismantling,
	Einkauf,
	Lieferant,
	PriceTag,
	Stock,
	User,
} from "@app/icons/components/nav";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { useTranslate } from "@app/modules/i18n/context";
import type { NavLinkProps } from "@app/modules/routes/link";
import { NavLink } from "@app/modules/routes/link";
import type { RouteName } from "@app/modules/routes/routes";
import { fullPathsByName } from "@app/modules/routes/routes";
import { useLocalStorageState } from "@app/modules/utils/local-storage";
import clsx from "clsx";
import React, { Children } from "react";
import { useMatch } from "react-router-dom";
import { boolean, is } from "superstruct";

export interface NavigationProps {
	className?: string;
}

export function Navigation({ className }: NavigationProps) {
	const [isOpen, setIsOpen] = useLocalStorageState("navigation", true, {
		validate: (value): value is boolean => is(value, boolean()),
	});
	const toggleOpen = () => setIsOpen((oldVal) => !oldVal);
	const width = isOpen ? "w-240" : "w-0";

	return (
		<>
			<div
				data-label="navigation"
				className={clsx(
					className,
					"relative transition-all p-8 overflow-y-auto pb-56",
					!isOpen && "opacity-0",
					width,
				)}
			>
				<NavItem routeName="dashboard" icon={Dashboard} end />

				<Spacer />

				<NavItem routeName="purchasing" icon={Einkauf}>
					<SubNavItem routeName="purchasing.orders" />
					<SubNavItem routeName="purchasing.framework-agreements" />
					<SubNavItem routeName="purchasing.returns" />
					<SubNavItem routeName="purchasing.planning" />
				</NavItem>

				<NavItem routeName="sales" icon={Sales}>
					<SubNavItem routeName="sales.customer-orders" />
					<SubNavItem routeName="sales.internal-orders" />
					<SubNavItem routeName="sales.returns" />
				</NavItem>

				<NavItem routeName="dismantling" icon={Dismantling}>
					<SubNavItem routeName="dismantling.plan" />
					<SubNavItem routeName="dismantling.printing" />
				</NavItem>

				<NavItem routeName="operations" icon={Operations}>
					<SubNavItem routeName="operations.dismantling-goods-receipt" />
					<SubNavItem routeName="operations.hierarchical-goods-receipt" />
					<SubNavItem routeName="operations.supplier-order-goods-receipt" />
					<SubNavItem routeName="operations.customer-order-returns" />
					<SubNavItem routeName="operations.supplier-order-returns" />

					<SubNavItem routeName="operations.picking" />
					<SubNavItem routeName="operations.price-labelling" />

					<SubNavItem routeName="operations.rolling-inventory" />
				</NavItem>

				<NavItem routeName="invoicing" icon={Invoicing}>
					<SubNavItem routeName="invoicing.invoices" />
					<SubNavItem routeName="invoicing.credit-notes" />
				</NavItem>

				<NavItem routeName="stock" icon={Stock}>
					<SubNavItem routeName="stock.inventory" />
					<SubNavItem routeName="stock.containers" />
					<SubNavItem routeName="stock.transactions" />
					<SubNavItem routeName="stock.journal-entries" />
				</NavItem>

				<Spacer />

				<NavItem routeName="pricing" icon={PriceTag}>
					<SubNavItem routeName="pricing.cutting-patterns" />
					<SubNavItem routeName="pricing.dismantling" />
					<SubNavItem routeName="pricing.price-lists" />
				</NavItem>

				<NavItem routeName="customer-data" icon={User}>
					<SubNavItem routeName="customer-data.customers" />
					<SubNavItem routeName="customer-data.customer-groups" />
					<SubNavItem routeName="customer-data.sales-teams" />
					<SubNavItem routeName="customer-data.tours" />
				</NavItem>

				<NavItem routeName="product-data" icon={Article}>
					<SubNavItem routeName="product-data.products" />
					<SubNavItem routeName="product-data.groups" />
					<SubNavItem routeName="product-data.hierarchical-labels" />
				</NavItem>

				<NavItem routeName="suppliers" icon={Lieferant} />

				<NavItem routeName="misc-data" icon={Options}>
					<SubNavItem routeName="misc-data.stock-locations" />
					<div />
				</NavItem>

				<Spacer />

				<NavItem routeName="settings" icon={Settings}>
					<SubNavItem routeName="settings.work-calendar" />
					<SubNavItem routeName="settings.labels" />
					<SubNavItem routeName="settings.misc" />
					<SubNavItem routeName="settings.scale-setup" />
					<SubNavItem routeName="settings.workstations" />
					<SubNavItem routeName="settings.hardware-devices" />
				</NavItem>
			</div>
			<button
				type="button"
				className={clsx(
					"fixed bottom-0 left-0 p-8 z-20 transition-all bg-grey-100",
					width,
				)}
				onClick={toggleOpen}
			>
				<Icon
					icon={LargeDropdownClosed}
					size="16"
					className={clsx(
						"text-grey-500 transform-gpu",
						isOpen ? "rotate-90" : "-rotate-90",
					)}
				/>
			</button>
		</>
	);
}

const baseStyle =
	"flex rounded p-12 py-24 h-32 items-center hover:text-grey-800 shrink-0 text-grey-600";
const iconStyle = "text-grey-600 mr-12 shrink-0";
const labelStyle = "text-16 overflow-hidden whitespace-nowrap text-ellipsis";

type NavItemProps<Name extends RouteName> = Omit<
	NavLinkProps<Name>,
	"to" | "params"
> & {
	icon: IconComponent;
	end?: NavLinkProps<Name>["end"];
	routeName: NavLinkProps<Name>["to"];
	params?: NavLinkProps<Name>["params"];
	children?: React.ReactElement<SubNavItemProps<Name>>[];
};
function NavItem<Name extends RouteName>({
	routeName,
	icon,
	params,
	end = false,
	children: subNavItems,
}: NavItemProps<Name>) {
	const t = useTranslate("common");
	const match = useMatch({ path: fullPathsByName[routeName], end });
	const isOpen = match;

	const label = t(getRouteTranslationKey(routeName));

	const to: RouteName = (() => {
		const all = Children.map(subNavItems, (subNavItem) => {
			if (subNavItem && subNavItem.type === SubNavItem) {
				return subNavItem.props.routeName;
			}

			return routeName;
		});

		return all?.[0] ?? routeName;
	})();

	const navLink = (
		<>
			<NavLink
				title={label}
				params={params}
				className={clsx(baseStyle, match && "font-600 text-grey-800")}
				to={to}
				end={end}
			>
				<Icon icon={icon} size="20" className={iconStyle} />
				<div className={labelStyle}>{label}</div>
			</NavLink>
			{isOpen && (
				<div data-label="SubNavItems" className="flex flex-col gap-4">
					{subNavItems}
				</div>
			)}
		</>
	);

	return navLink;
}

type SubNavItemProps<Name extends RouteName> = Pick<
	NavItemProps<Name>,
	"routeName" | "params"
>;

export function SubNavItem<Name extends RouteName>({
	routeName,
	params,
}: SubNavItemProps<Name>) {
	const t = useTranslate("common");
	const label = t(getRouteTranslationKey(routeName));

	return (
		<div title={label}>
			<NavLink
				to={routeName as RouteName}
				params={params}
				className={clsx(
					baseStyle,
					"py-0 hover:bg-grey-300 hover:text-grey-700",
				)}
				inactiveClassName="text-grey-600"
				activeClassName="bg-grey-300 text-grey-800"
			>
				<div className={labelStyle}>{label}</div>
			</NavLink>
		</div>
	);
}

function Spacer() {
	return <div className="my-20" />;
}

export function getRouteTranslationKey(routeName: RouteName) {
	if (routeName.includes(".")) {
		return `nav.subnav.${routeName}`;
	}
	return `nav.${routeName}`;
}
