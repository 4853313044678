import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { Body14 } from "@app/modules/typography";
import clsx from "clsx";
import type { ReactNode } from "react";
import React from "react";

export interface IconWithTextProps {
	className?: string;
	children?: ReactNode;
	icon: IconComponent;
	inline?: boolean;
	title?: string;
}

export function IconWithText({
	className,
	icon,
	children,
	inline,
	title,
}: IconWithTextProps) {
	if (!children) {
		return null;
	}

	return (
		<div
			className={clsx(
				"justify-start my-8 text-grey-900 whitespace-nowrap text-ellipsis",
				inline ? "inline-flex" : "flex",
				className,
			)}
			title={title}
		>
			<Icon icon={icon} size="16" className="my-auto mr-8" />
			<Body14 className="my-auto">{children}</Body14>
		</div>
	);
}
