import { Plus, Selected } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { useAppState } from "@app/modules/app-state/context";
import { Avatar } from "@app/modules/Avatar";
import type { ButtonVariant } from "@app/modules/Button";
import { Button } from "@app/modules/Button";
import { useClickOutsideEffect } from "@app/modules/hooks/useClickOutside";
import { useTransitionState } from "@app/modules/hooks/useTransitionState";
import { useTranslate } from "@app/modules/i18n/context";
import { SectionTitle } from "@app/modules/layout/SectionTitle";
import type { LinkProps } from "@app/modules/routes/link";
import { Link } from "@app/modules/routes/link";
import type { RouteName } from "@app/modules/routes/routes";
import { Body14 } from "@app/modules/typography";
import { useWorkstation } from "@app/modules/workstations/useWorkstations";
import clsx from "clsx";
import type {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	ReactNode,
} from "react";
import { useRef } from "react";

type DropDownSizes = "sm" | "md" | "lg";

export interface DropDownProps {
	className?: string;
	width?: DropDownSizes;
	children?: ReactNode;
	maxHeight: DropDownSizes;
	isOpen: boolean;
	onCloseRequest: () => void;
	align?: "left" | "right";
	testid?: string;
}

function DropDown({
	className,
	width,
	maxHeight,
	children,
	isOpen,
	align = "left",
	testid,
	onCloseRequest,
}: DropDownProps) {
	const ref = useRef<HTMLDivElement>(null);
	useClickOutsideEffect({
		onClickOutside: onCloseRequest,
		ref,
		isActive: isOpen,
	});

	const state = useTransitionState(isOpen, 150);

	if (state === "exited") {
		return null;
	}

	const isVisible = state === "entering" || state === "entered";

	return (
		<div
			className={clsx(
				className,
				"absolute bottom-0 transform translate-y-full transition-all opacity-0 pointer-events-none z-50 rotate-0",
				isVisible && "opacity-100 pointer-events-auto",
				align === "right" && "right-0",
				align === "left" && "left-0",
				width === "sm" && "w-320",
				width === "md" && "w-592",
				width === "lg" && "w-800",
			)}
			ref={ref}
			data-testid={testid}
		>
			<div
				className={clsx(
					"bg-white shadow-card rounded transition-all transform overflow-auto",
					isVisible
						? "transform translate-x-0 translate-y-0"
						: "-translate-x-4 translate-y-4",
					maxHeight === "sm" && "max-h-320",
					maxHeight === "md" && "max-h-592",
					maxHeight === "lg" && "max-h-800",
				)}
			>
				{children}
			</div>
		</div>
	);
}

export interface DropDownItemProps {
	className?: string;
	children?: ReactNode;
	isPadding?: boolean;
	isFirst?: boolean;
	isLast?: boolean;
}

function DropDownItem({
	className,
	children,
	isPadding,
	isFirst,
	isLast,
}: DropDownItemProps) {
	return (
		<div
			className={clsx(
				className,
				"flex w-full",
				isFirst && "border-b border-grey-200 mb-8",
				isPadding && "p-16",
				!isPadding && isFirst && "py-8",
				isLast && "flex border-t border-grey-200 py-8 mt-8",
			)}
		>
			{children}
		</div>
	);
}

export type DropDownLinkProps<Name extends RouteName> = LinkProps<Name> & {
	active?: boolean;
};

function DropDownLink<Name extends RouteName>({
	children,
	active,
	to,
	params,
	...props
}: DropDownLinkProps<Name>) {
	return (
		<div
			className={clsx(
				"transition-all bg-white hover:bg-grey-200 block w-full font-400 text-left",
				active && "flex",
			)}
		>
			<Link to={to as RouteName} params={params} {...props}>
				{active && (
					<Icon icon={Selected} size="16" className="block my-auto mr-8" />
				)}
				<Body14
					as="span"
					className={clsx(
						"text-grey-900 block ",
						active ? "my-auto" : "m-auto",
					)}
				>
					{children}
				</Body14>
			</Link>
		</div>
	);
}

export function DropDownExternalLink({
	children,
	...props
}: AnchorHTMLAttributes<HTMLAnchorElement>) {
	return (
		<a
			className={clsx(
				"py-8 px-16 transition-all bg-white hover:bg-grey-100 block w-full font-400 text-left",
			)}
			{...props}
		>
			<Body14 as="span" className="text-grey-900 block m-auto">
				{children}
			</Body14>
		</a>
	);
}

export interface DropDownButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	children?: ReactNode;
	active?: boolean;
	testid?: string;
}

function DropDownButton({
	className,
	children,
	active,
	testid,
	disabled,
	...props
}: DropDownButtonProps) {
	const testidValue = testid ? `dropdown-button-${testid}` : undefined;

	return (
		<button
			type="button"
			data-testid={testidValue}
			className={clsx(
				className,
				"py-8 px-16 transition-all bg-white hover:bg-grey-100 block w-full text-left disabled:hover:bg-white",
				active && "flex",
			)}
			disabled={disabled}
			{...props}
		>
			{active && (
				<Icon icon={Selected} size="16" className="block my-auto mr-8" />
			)}
			<span
				className={clsx(
					"text-body-14 block m-auto",
					disabled && "text-grey-500",
				)}
			>
				{children}
			</span>
		</button>
	);
}

export interface DropDownUserProps {
	className?: string;
	name: string | undefined;
}

function DropDownUser({ className, name }: DropDownUserProps) {
	const t = useTranslate("common");
	const { activeRole, workstationId } = useAppState();

	const workstation = useWorkstation(workstationId ?? "");

	return (
		<div className={clsx(className, "flex w-full")}>
			<div className="w-48 h-48">
				<Avatar name={name} />
			</div>
			<div className="pl-8 pr-16">
				<Body14
					as="strong"
					className="block font-500 whitespace-nowrap text-grey-900"
				>
					{name}
				</Body14>
				<Body14 className="text-grey-700">
					{activeRole && t(`roles.${activeRole}`)}
				</Body14>
				{workstation && (
					<Body14 className="text-grey-700">
						{workstation.name} ({workstation.pcName})
					</Body14>
				)}
			</div>
		</div>
	);
}

export interface DropDownTitleProps {
	className?: string;
	children?: ReactNode;
}

function DropDownTitle({ className, children }: DropDownTitleProps) {
	return (
		<SectionTitle className={clsx(className, "text-grey-900 py-8 px-16")}>
			{children}
		</SectionTitle>
	);
}

export interface DropDownActionProps {
	className?: string;
	buttonClassName?: string;
	children?: ReactNode;
	variant?: ButtonVariant;
	buttonTitle: string;
	isOpen: boolean;
	onCloseRequest: () => void;
	onClick: () => void;
	align?: "left" | "right";
	icon?: IconComponent;
	iconPosition?: "left" | "right";
	isFetching?: boolean;
	testid?: string;
}

function DropDownAction({
	className,
	buttonClassName,
	children,
	variant = "primary",
	buttonTitle,
	isOpen,
	onCloseRequest,
	onClick,
	align,
	icon = Plus,
	iconPosition,
	isFetching,
	testid,
}: DropDownActionProps) {
	return (
		<div className={clsx(className, "relative")}>
			<Button
				variant={variant}
				icon={icon}
				className={clsx(buttonClassName, "")}
				onClick={onClick}
				iconPosition={iconPosition}
				isFetching={isFetching}
				testid={testid}
			>
				{buttonTitle}
			</Button>
			<DropDown
				isOpen={isOpen}
				onCloseRequest={onCloseRequest}
				align={align}
				maxHeight="md"
			>
				{children}
			</DropDown>
		</div>
	);
}

export {
	DropDown,
	DropDownItem,
	DropDownLink,
	DropDownButton,
	DropDownUser,
	DropDownTitle,
	DropDownAction,
};
