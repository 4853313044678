import { parseCode } from "@app/modules/goods-income/qr-code";
import { logger } from "@app/modules/logger/logger";
import type {
	DeprecatedProductInfoReceivedAction,
	ProductInfoReceivedAction,
} from "@app/modules/ui-actions/types";
import { useUiActionsContext } from "@app/modules/ui-actions/UiActionsProvider";
import type { ScanOptions } from "onscan.js";
import onScan from "onscan.js";
import type { ReactNode } from "react";
import { createContext, useEffect, useMemo, useState } from "react";

export interface HidScannerContextValue {
	config: ScanOptions;
	setConfig: (config: ScanOptions) => void;
	setIsActive: (state: boolean) => void;
	isActive: boolean;
}

export const HidContext = createContext<HidScannerContextValue>({
	config: {},
	setConfig: () => {},
	setIsActive: () => {},
	isActive: false,
});

export interface HidScannerProviderProps {
	children?: ReactNode;
}

export function HidScannerProvider({ children }: HidScannerProviderProps) {
	const { dispatch } = useUiActionsContext();

	const handleOnScan = (scanned: string) => {
		const action = getProductInfoReceivedAction(scanned);
		if (action) {
			dispatch(action);
			logger.log("Scanner UI action dispatched: ", action);
		}
	};

	const defaultConfig: ScanOptions = {
		onScan: handleOnScan,
	};
	const [config, setConfig] = useState<ScanOptions>(defaultConfig);
	const [isActive, setIsActive] = useState<boolean>(false);

	const deactivateScanner = () => {
		if (onScan.isAttachedTo(document)) {
			onScan.detachFrom(document);
			logger.log("Scanner detached");
		}
	};

	const activateScanner = () => {
		if (!onScan.isAttachedTo(document)) {
			onScan.attachTo(document, config);
			logger.log("Scanner attached");
		}
	};

	useEffect(() => {
		if (!isActive) {
			deactivateScanner();
		} else {
			activateScanner();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive]);

	useEffect(() => {
		if (!isActive || !config || !onScan.isAttachedTo(document)) {
			return;
		}
		onScan.detachFrom(document);
		onScan.attachTo(document, config);
		logger.log("New scanner configuration applied");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config]);

	const value: HidScannerContextValue = useMemo(
		() => ({
			config,
			setConfig,
			isActive,
			setIsActive,
		}),
		[config, isActive],
	);

	return <HidContext.Provider value={value}>{children}</HidContext.Provider>;
}

function getProductInfoReceivedAction(
	input: string,
): ProductInfoReceivedAction | DeprecatedProductInfoReceivedAction | undefined {
	const parsed = parseCode(input);
	if (!parsed) {
		return undefined;
	}
	switch (parsed.type) {
		case "00":
			return {
				type: "deprecated-product-info-received",
				productDomainId: parsed.productDomainId,
				lotNumber: parsed.lotNumber,
			};
		case "01":
			return {
				type: "deprecated-product-info-received",
				productDomainId: parsed.productDomainId,
				lotNumber: parsed.lotNumber,
				stockLocationId: parsed.stockLocationId,
			};
		case "03":
			return {
				type: "product-info-received",
				productId: parsed.productId,
				lotNumber: parsed.lotNumber,
				tenantShortName: parsed.tenantShortName,
			};
		case "04":
			return {
				type: "product-info-received",
				productId: parsed.productId,
				lotNumber: parsed.lotNumber,
				stockLocationId: parsed.stockLocationId,
				tenantShortName: parsed.tenantShortName,
			};
		case "05":
			// This code contains way more information that is currently not supported by the receiving end.
			// Future improvement: add support for weights.
			return {
				type: "product-info-received",
				productId: parsed.productId,
				lotNumber: parsed.lotNumber,
				stockLocationId: parsed.stockLocationId,
				tenantShortName: parsed.tenantShortName,
			};
		default:
			return undefined;
	}
}
