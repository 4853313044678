import { Card } from "@app/modules/cards/Card";
import type { SupplierSubOrdersCardProps } from "@app/modules/cards/card-props";
import type { SupplierSubOrdersCardQueryVariables } from "@app/modules/cards/queries/supplier-sub-orders-card.query.generated";
import { SupplierSubOrdersCardDocument } from "@app/modules/cards/queries/supplier-sub-orders-card.query.generated";
import {
	DataTable,
	DataTableCell,
	DataTableRow,
} from "@app/modules/data-table/DataTable";
import { DataTablePagination } from "@app/modules/data-table/DataTablePagination";
import { useDataTable } from "@app/modules/data-table/useDataTable";
import { EntityIdTagItemLink } from "@app/modules/entity/EntityIdTagItem";
import { useTranslate } from "@app/modules/i18n/context";
import { LocalDate } from "@app/modules/localdate/localdate";
import { SupplierOrderStateTagItem } from "@app/modules/purchasing/supplier-order/SupplierOrderStateTagItem";

export function SupplierSubOrdersCard({
	supplierOrderId,
}: SupplierSubOrdersCardProps) {
	const t = useTranslate("common");

	const queryVariables: Partial<SupplierSubOrdersCardQueryVariables> = {
		filters: { parentSupplierOrderId: { _eq: supplierOrderId } },
	};
	const { pagination, ...dataTable } = useDataTable({
		query: SupplierSubOrdersCardDocument,
		queryVariables,
		columns: [
			{
				accessor: "domainId",
				label: t("cards.supplier-sub-orders.table.head.id"),
			},
			{
				accessor: "deliveryDate",
				label: t("cards.supplier-sub-orders.table.head.delivery-date"),
			},
			{
				accessor: "state",
				label: t("cards.supplier-sub-orders.table.head.state"),
			},
		],
	});

	if (dataTable.visibleRows.length === 0) {
		return null;
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title>{t("cards.supplier-sub-orders.titles.orders")}</Card.Title>
				{pagination && (
					<div className="ml-auto">
						<DataTablePagination
							variant="compact"
							totalRows={dataTable.totalRows}
							{...pagination}
						/>
					</div>
				)}
			</Card.Header>
			<Card.Body>
				<Card.Content>
					<DataTable
						className="border-t border-grey-200"
						instance={dataTable}
						variant="compact"
					>
						{dataTable.visibleRows.map(
							({ id, deliveryDate, state, domainId }) => (
								<DataTableRow key={id}>
									<DataTableCell>
										<EntityIdTagItemLink
											entity={{ id, domainId }}
											to="purchasing.orders.id"
										/>
									</DataTableCell>
									{deliveryDate && (
										<DataTableCell>
											{LocalDate.fromDateString(
												deliveryDate,
											).toLocalizedDateString()}
										</DataTableCell>
									)}
									<DataTableCell>
										<SupplierOrderStateTagItem state={state} />
									</DataTableCell>
								</DataTableRow>
							),
						)}
					</DataTable>
				</Card.Content>
			</Card.Body>
		</Card>
	);
}
