import type { TranslateFunction } from "@app/modules/i18n/types";
import { logger } from "@app/modules/logger/logger";

interface GenericBusinessError<Code extends number, Payload> {
	error_code: Code;
	description: string;
	payload: Payload;
}

type GeneralBusinessError = GenericBusinessError<number, JSON | null>;

// Extend this type to add more specific business errors where we can also type the payload.
type BusinessError = GeneralBusinessError;

export function isBusinessErrorMessage(message: string) {
	return message.startsWith("{") && message.endsWith("}");
}

export function tryParseErrorMessage(
	message: string,
): BusinessError | undefined {
	try {
		return JSON.parse(message) as BusinessError;
	} catch (err) {
		logger.error(err, "Unable to parse message", { message });
		return undefined;
	}
}

const defaultTranslationKey = "notifications.messages.business-error";

export function getTranslatedMessage(
	t: TranslateFunction,
	error: BusinessError,
) {
	const translationKey = `notifications.business-errors.code-${error.error_code}`;
	const translation = t(translationKey, error.payload ?? undefined);
	// This is a hacky check to see if the translation is not missing
	if (translation !== `common.${translationKey}`) {
		return translation;
	}
	return t(defaultTranslationKey, error);
}
