import { EntityOwnerTagItem } from "@app/modules/entity/EntityOwnerTagItem";
import { useIsEntityShared } from "@app/modules/tenant/shared-tenant-utils";
import React from "react";

export interface SharedEntityTagItemProps {
	entity: { tenant?: string } | undefined;
	className?: string;
}

export function SharedEntityTagItem({
	entity,
	className,
}: SharedEntityTagItemProps) {
	const isShared = useIsEntityShared(entity);
	if (!isShared) {
		return null;
	}
	return <EntityOwnerTagItem entity={entity} className={className} />;
}
