import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOrderOffer({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M21.43 2.09c.121.055.23.134.32.233a1.012 1.012 0 01-.042 1.387l-.742.738a.505.505 0 01-.169.096l-.001.001a.493.493 0 01-.538-.107l-.697-.697a.492.492 0 01-.145-.347c0-.13.043-.268.136-.36l.76-.758a.995.995 0 01.34-.206.988.988 0 01.777.02zM10.416 12.17l7.726-7.726a.553.553 0 01.781 0l.633.635a.553.553 0 010 .779l-7.727 7.726a.555.555 0 01-.241.142l-1.53.514a.24.24 0 01-.298-.3l.514-1.529a.553.553 0 01.142-.242z" />
			<path d="M5.256 6.256A.875.875 0 015.875 6H13a1 1 0 100-2H5.875A2.875 2.875 0 003 6.875v11.25A2.875 2.875 0 005.875 21h11.25A2.876 2.876 0 0020 18.125V11a1 1 0 10-2 0v7.125a.874.874 0 01-.875.875H5.875A.875.875 0 015 18.125V6.875c0-.232.092-.455.256-.619z" />
		</svg>
	);
}

const MemoSvgOrderOffer = React.memo(SvgOrderOffer);
export default MemoSvgOrderOffer;
