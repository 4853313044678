import {
	Currencies_Enum,
	Customer_Billing_Format_Enum,
	Customer_Billing_Frequency_Enum,
	Customer_Order_Item_Adjustment_Types_Enum,
	Customer_Payment_Method_Enum,
	Languages_Enum,
	Units_Enum,
	Working_Days_Enum,
} from "@app/modules/graphql-api-types.generated";
import { useTranslate } from "@app/modules/i18n/context";
import type { TranslateFunction } from "@app/modules/i18n/types";
import type { LocalDate } from "@app/modules/localdate/localdate";
import { matchesSuggestion } from "@app/modules/search/suggestion-utils";
import { useNormalizedResult } from "@app/modules/utils/useNormalizedResult";
import { useMemo } from "react";

const DISABLED_WEIGHT = -1;

const languagesWeight: Record<Languages_Enum, number> = {
	de_CH: 0,
	fr_CH: 1,
	it_CH: 2,
	en: 3,
};

export const languages = Object.values(Languages_Enum).sort(
	(a, b) => languagesWeight[a] - languagesWeight[b],
);

export function useLanguages() {
	return languages;
}

const workingDaysWeight: Record<Working_Days_Enum, number> = {
	MONDAY: 0,
	TUESDAY: 1,
	WEDNESDAY: 2,
	THURSDAY: 3,
	FRIDAY: 4,
	SATURDAY: 5,
	SUNDAY: 6,
};

export const workingDays = Object.values(Working_Days_Enum).sort(
	(a, b) => workingDaysWeight[a] - workingDaysWeight[b],
);

export function translateWorkingDays(
	t: TranslateFunction,
	workingDay: Working_Days_Enum,
) {
	return t(`datetime.weekdays.${workingDay}`);
}

export function useWorkingDays() {
	const t = useTranslate("common");

	return workingDays.map((workingDay) => ({
		workingDay,
		label: translateWorkingDays(t, workingDay),
	}));
}

export function getWorkingDayFromLocalDate(date: LocalDate) {
	// dayjs starts the week on Sunday, we start it on Monday - so we have to do some conversion magic
	return workingDays?.[(6 + date.dayjsDate.day()) % 7];
}

const unitsWeights: Record<Units_Enum, number> = {
	CUSTOM: DISABLED_WEIGHT,
	KG: 0,
	PIECE: 1,
	BOX: 2,
	PALLET: 3,
	L: 4,
};

export const units = Object.values(Units_Enum)
	.filter((unit) => unitsWeights[unit] !== DISABLED_WEIGHT)
	.sort((a, b) => unitsWeights[a] - unitsWeights[b]);

export function translateUnit(t: TranslateFunction, unit: Units_Enum) {
	return t(`units.${unit}`);
}

export function useUnits() {
	const t = useTranslate("common");

	return units.map((unit) => ({
		id: unit,
		unit,
		label: translateUnit(t, unit),
	}));
}

const currenciesWeights: Record<Currencies_Enum, number> = {
	CHF: 0,
	EUR: 1,
};

const currencies = Object.values(Currencies_Enum)
	.filter((currency) => currenciesWeights[currency] !== DISABLED_WEIGHT)
	.sort((a, b) => currenciesWeights[a] - currenciesWeights[b]);

export function useCurrencies() {
	return currencies.map((currency) => ({
		id: currency,
		label: currency,
	}));
}

const paymentMethodWeights: Record<Customer_Payment_Method_Enum, number> = {
	BANK_TRANSFER: 0,
	CASH: 1,
};

export function translatePaymentMethod(
	t: TranslateFunction,
	method: Customer_Payment_Method_Enum,
) {
	return t(`customers.billing.payment-methods.${method}`);
}

export function usePaymentMethods() {
	const t = useTranslate("common");

	return Object.values(Customer_Payment_Method_Enum)
		.sort((a, b) => paymentMethodWeights[a] - paymentMethodWeights[b])
		.map((method) => ({
			id: method,
			label: translatePaymentMethod(t, method),
		}));
}

const billingFrequenciesWeights: Record<
	Customer_Billing_Frequency_Enum,
	number
> = {
	DIRECT: 0,
	DAY_DIRECT: 1,
	DAY: 2,
	WEEK: 3,
	HALF_MONTH: 4,
	MONTH: 5,
};

const billingFrequenciesValues = Object.values(
	Customer_Billing_Frequency_Enum,
).sort((a, b) => billingFrequenciesWeights[a] - billingFrequenciesWeights[b]);

export function translateBillingFrequency(
	t: TranslateFunction,
	billingFrequency: Customer_Billing_Frequency_Enum,
) {
	return t(`customers.billing.frequency.${billingFrequency}`);
}

export function useNormalizedBillingFrequencies() {
	return useNormalizedResult(useBillingFrequencies());
}

export function useBillingFrequencies(options?: { search?: string }) {
	const { search } = options ?? {};

	const t = useTranslate("common");

	const billingFrequencies = useMemo(
		() =>
			billingFrequenciesValues.map((billingFrequency) => ({
				id: billingFrequency,
				billingFrequency,
				label: translateBillingFrequency(t, billingFrequency),
			})),
		[t],
	);

	if (search) {
		return billingFrequencies.filter(({ label }) =>
			matchesSuggestion(label, search),
		);
	}

	return billingFrequencies;
}

const billingFormatsWeights: Record<Customer_Billing_Format_Enum, number> = {
	DETAILED: 0,
	COMPACT: DISABLED_WEIGHT,
	DETAILED_WITH_DELIVERY_NOTE: 2,
};

const billingFormats = Object.values(Customer_Billing_Format_Enum)
	.filter(
		(billingFormat) => billingFormatsWeights[billingFormat] !== DISABLED_WEIGHT,
	)
	.sort((a, b) => billingFormatsWeights[a] - billingFormatsWeights[b]);

export function translateBillingFormat(
	t: TranslateFunction,
	billingFormat: Customer_Billing_Format_Enum,
) {
	return t(`customers.billing.format.${billingFormat}`);
}

export function useBillingFormats() {
	const t = useTranslate("common");

	return useMemo(
		() =>
			billingFormats.map((format) => ({
				id: format,
				billingFormat: format,
				label: translateBillingFormat(t, format),
			})),
		[t],
	);
}

const adjustmentTypesWeights: Record<
	Customer_Order_Item_Adjustment_Types_Enum,
	number
> = {
	WEIGHT_CHANGE: 1,
	PRICE_CHANGE: 2,
};

const adjustmentTypes = Object.values(Customer_Order_Item_Adjustment_Types_Enum)
	.filter(
		(adjustmentType) =>
			adjustmentTypesWeights[adjustmentType] !== DISABLED_WEIGHT,
	)
	.sort((a, b) => adjustmentTypesWeights[a] - adjustmentTypesWeights[b]);

export function useCustomerOrderItemAdjustmentTypes() {
	const t = useTranslate("common");

	return adjustmentTypes.map((adjustmentType) => ({
		id: adjustmentType,
		label: translateCustomerOrderItemAdjustmentType(t, adjustmentType),
	}));
}

export function translateCustomerOrderItemAdjustmentType(
	t: TranslateFunction,
	adjustmentType: Customer_Order_Item_Adjustment_Types_Enum,
) {
	return t(`orders.returns.adjustment-types.${adjustmentType}`);
}
