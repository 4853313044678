import { useTranslate } from "@app/modules/i18n/context";
import type { TranslateFunction } from "@app/modules/i18n/types";
import type {
	BannerOptions,
	ToastOptions,
} from "@app/modules/notification/context";
import type { MouseEventHandler } from "react";
import { useMemo } from "react";

export function createTryAgainBanner(
	t: TranslateFunction,
	onClick: MouseEventHandler<HTMLButtonElement>,
): BannerOptions {
	return {
		type: "banner",
		variant: "error",
		text: t("notifications.messages.try-again"),
		cta: {
			text: t("notifications.actions.to-error"),
			onClick,
		},
	};
}

export function createTryAgainToast(t: TranslateFunction): ToastOptions {
	return {
		type: "toast",
		variant: "error",
		text: t("notifications.messages.try-again"),
	};
}

export function createSuccessToast(t: TranslateFunction): ToastOptions {
	return {
		type: "toast",
		variant: "success",
		text: t("notifications.messages.success"),
	};
}

export function useNotificationCreate() {
	const t = useTranslate("common");
	return useMemo(
		() => ({
			createTryAgainBanner: (onClick: MouseEventHandler<HTMLButtonElement>) =>
				createTryAgainBanner(t, onClick),
			createTryAgainToast: () => createTryAgainToast(t),
			createSuccessToast: () => createSuccessToast(t),
		}),
		[t],
	);
}
