import { logger } from "@app/modules/logger/logger";
// eslint-disable-next-line no-restricted-imports
import BigNumber from "bignumber.js";

// Most GQL bigints are scaled by a factor of 1000 to accommodate for up to 3 decimal digits
// without the need of decimal places.
export const SCALE_FACTOR = 1000;

export const MIN_SIGNIFICANT_DIGITS = 0;
export const MAX_SIGNIFICANT_DIGITS = 3;

const BigNumberClone = BigNumber.clone({
	// Our scaled numbers don't need additional decimal places.
	DECIMAL_PLACES: 0,
	ROUNDING_MODE: BigNumber.ROUND_HALF_EVEN,
});

const BigNumberRoundCeil = BigNumber.clone({
	DECIMAL_PLACES: 0,
	ROUNDING_MODE: BigNumber.ROUND_CEIL,
});

export class ScaledNumber extends BigNumberClone {
	constructor(value: BigNumber.Value) {
		super(value);

		// If you find a better solution to this, please let me know.
		const prototype = BigNumberClone.prototype as typeof ScaledNumber.prototype;
		prototype.round = this.round;
		prototype.roundUp = this.roundUp;
		prototype.stimes = this.stimes;
		prototype.toString = this.toString;
		prototype.log = this.log;
	}

	round(significantDigits: number) {
		const power =
			MAX_SIGNIFICANT_DIGITS - getValidSignificantDigit(significantDigits);
		const digitsAsPowerOfTen = 10 ** power;
		// Dividing by 10^n and then multiplying by 10^n forces rounding.
		return new ScaledNumber(this)
			.div(digitsAsPowerOfTen)
			.times(digitsAsPowerOfTen);
	}

	roundUp(significantDigits: number) {
		const power =
			MAX_SIGNIFICANT_DIGITS - getValidSignificantDigit(significantDigits);
		const digitsAsPowerOfTen = 10 ** power;

		// We temporarily create a BigNumber that always rounds up.
		const nr = new BigNumberRoundCeil(this)
			.div(digitsAsPowerOfTen)
			.times(digitsAsPowerOfTen);

		// After the rounding is done we convert back to a ScaledNumber.
		return new ScaledNumber(nr);
	}

	stimes(other: BigNumber.Value) {
		return scaledTimes(this, other);
	}

	/** Log intermediate values to the console. Use for debugging!  */
	log(): this {
		logger.log(this.toString());
		return this;
	}

	toString() {
		// We do NaN handling here because `toFixed` will call `toString` in such a case causing an infinite loop.
		// See: https://github.com/MikeMcl/bignumber.js/blob/2603bfd93e0e51a090b138561a79249f8480156e/bignumber.js#L1241
		if (this.isNaN()) {
			return "NaN";
		}
		return this.toFixed(0);
	}
}

export function scaledTimes(a: BigNumber.Value, b: BigNumber.Value) {
	return new ScaledNumber(a).times(b).div(SCALE_FACTOR);
}

export const stimes = scaledTimes;

function getValidSignificantDigit(significantDigits: number) {
	if (significantDigits < 0) {
		return MIN_SIGNIFICANT_DIGITS;
	}
	if (significantDigits > 3) {
		return MAX_SIGNIFICANT_DIGITS;
	}
	return significantDigits;
}

export function scale(a: BigNumber.Value) {
	return new ScaledNumber(a).times(SCALE_FACTOR).round(MAX_SIGNIFICANT_DIGITS);
}

export function descale(a: BigNumber.Value, significantDigits?: number) {
	if (!a) {
		return "";
	}

	const digits = getValidSignificantDigit(
		significantDigits ?? MAX_SIGNIFICANT_DIGITS,
	);
	const adjusted = new ScaledNumber(a)
		.round(digits)
		.shiftedBy(-MAX_SIGNIFICANT_DIGITS);

	return significantDigits === undefined
		? // We use BigNumber.toString() because ScaledNumber.toString() will always return 0 decimal places.
		  new BigNumber(adjusted).toString()
		: adjusted.toFixed(digits);
}

// might not be the ideal place for this function but we don't have a generic "numbers" module (yet) where this would belong.
export function isZero(zero: string) {
	return zero === "0";
}
