import type { ContactValues } from "@app/modules/contact/contact-schema";
import { revertFormId } from "@app/modules/form/form-utils";
import type {
	Contact_Availability_Ranges_Insert_Input,
	Contacts_Insert_Input,
	Working_Days_Enum,
} from "@app/modules/graphql-api-types.generated";
import {
	Contact_Availability_Constraint,
	Contact_Availability_Ranges_Constraint,
	Contact_Availability_Ranges_Update_Column,
	Contact_Availability_Update_Column,
	Emails_Constraint,
	Emails_Update_Column,
	Phones_Constraint,
	Phones_Update_Column,
} from "@app/modules/graphql-api-types.generated";

export function makeMutationVariablesFromContact(upsert = false) {
	return (contact: ContactValues, position: number): Contacts_Insert_Input => {
		const emails = {
			data: contact.emails.map((email, i) => ({
				id: email._id,
				email: email.email,
				ordinalPosition: i,
			})),
			...(upsert
				? {
						on_conflict: {
							constraint: Emails_Constraint.EmailsPkey,
							update_columns: [
								Emails_Update_Column.Email,
								Emails_Update_Column.OrdinalPosition,
							],
						},
				  }
				: {}),
		};

		const phones = {
			data: contact.phones.map((phone, i) => ({
				id: phone._id,
				phoneNumber: phone.phoneNumber,
				ordinalPosition: i,
			})),
			...(upsert
				? {
						on_conflict: {
							constraint: Phones_Constraint.PhonesPkey,
							update_columns: [
								Phones_Update_Column.PhoneNumber,
								Phones_Update_Column.OrdinalPosition,
							],
						},
				  }
				: {}),
		};

		const availabilities = mapToAvailabilityVariables(contact);

		return {
			id: contact._id,
			givenNames: contact.givenNames.trim() || null,
			surname: contact.surname.trim() || null,
			notes: contact.notes,
			ordinalPosition: position,
			emails,
			phones,
			contactAvailabilities: {
				data: availabilities,
				on_conflict: {
					constraint: Contact_Availability_Constraint.ContactAvailabilityPkey,
					update_columns: [Contact_Availability_Update_Column.WorkingDay],
				},
			},
		};
	};
}

function mapToAvailabilityVariables(
	contact: ContactValues,
): Contact_Availability_Ranges_Insert_Input[] {
	if (contact.isAlwaysAvailable) {
		return [];
	}

	const contactAvailabilities = Object.entries(contact.dayAvailabilities).map(
		([day, dayAvailability]) => {
			const workingDay = day as Working_Days_Enum;
			if (!dayAvailability.isAvailable) {
				return undefined;
			}

			const ranges = dayAvailability.isFullTime
				? []
				: contact[workingDay]?.map(revertFormId) ?? [];

			return {
				workingDay,
				id: dayAvailability._id,
				contactAvailabilityRanges: {
					data: ranges,
					on_conflict: {
						constraint:
							Contact_Availability_Ranges_Constraint.ContactAvailabilityRangesPkey,
						update_columns: [
							Contact_Availability_Ranges_Update_Column.StartTime,
							Contact_Availability_Ranges_Update_Column.EndTime,
						],
					},
				},
			};
		},
	);

	return contactAvailabilities.filter(Boolean);
}

export function getTelHref(phoneNumber: string) {
	return `tel:${phoneNumber.replaceAll(" ", "")}`;
}
