import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSelected({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.293 7.293a1 1 0 111.414 1.414l-9 9a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L10 15.586l8.293-8.293z"
			/>
		</svg>
	);
}

const MemoSvgSelected = React.memo(SvgSelected);
export default MemoSvgSelected;
