import type { UseFloatingReturn } from "@floating-ui/react-dom";
import { autoUpdate } from "@floating-ui/react-dom";
import { useLayoutEffect } from "react";

// See https://floating-ui.com/docs/react-dom#updating
export function useFloatingScrollEffect(
	refs: UseFloatingReturn["refs"],
	onScroll: () => void,
	isVisible: boolean,
) {
	useLayoutEffect(() => {
		if (!isVisible || !refs.reference.current || !refs.floating.current) {
			return;
		}

		// eslint-disable-next-line consistent-return
		return autoUpdate(refs.reference.current, refs.floating.current, onScroll);
	}, [refs.floating, refs.reference, onScroll, isVisible]);
}
