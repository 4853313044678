import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTrendUp({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.613 4.21l.094.083 6 6a1 1 0 01-1.32 1.497l-.094-.083L13 7.415v12.518a1 1 0 01-1.993.117L11 19.933V7.415l-4.293 4.292a1 1 0 01-1.32.083l-.094-.083a1 1 0 01-.083-1.32l.083-.094 6-6a1 1 0 011.32-.083z"
			/>
		</svg>
	);
}

const MemoSvgTrendUp = React.memo(SvgTrendUp);
export default MemoSvgTrendUp;
