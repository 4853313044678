import React from "react";
import { usePublicQuery } from "@app/modules/graphql/queries";
import { OfferDetailsDocument } from "@app/modules/offer/queries/offer-details.query.generated";
import { OfferDetail } from "@app/modules/offer/public/OfferDetail";

export function OfferLayout() {
	const [{ data }, refreshOffer] = usePublicQuery({
		query: OfferDetailsDocument,
	});

	if (!data) {
		return null;
	}

	return (
		<div className="min-h-screen h-full bg-grey-700">
			<OfferDetail offerDetails={data} refreshOffer={refreshOffer} />
		</div>
	);
}
