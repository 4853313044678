import type { Role } from "@app/modules/app-state/roles";
import {
	getDevJWTAction,
	getIsDevJWTActive,
} from "@app/modules/devdoor/devdoor";
import { logger } from "@app/modules/logger/logger";
import { identifyUser, trackEvent } from "@app/modules/logrocket";
import { storage } from "@app/modules/utils/local-storage";
import type { Dispatch, ReactNode } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";
import type { Action, AppState } from "./app-state";
import { defaultAppState, reducer } from "./app-state";

export const AppStateContext = createContext<AppState>(defaultAppState);

export const AppStateDispatchContext = createContext<Dispatch<Action>>(
	() => {},
);

const ACTIVE_ROLE_STORAGE_KEY = "last-active-role";
const WORKSTATION_STORAGE_KEY = "last-selected-workstation";

let initialAppState: AppState = {
	...defaultAppState,
	// We currently only load the last activeRole from local storage.
	// We could also consider reloading tenant information as it rarely changes.
	// For this to be user friendly we have to also cover the use case of when tenant information changes.
	activeRole: storage.get<Role>(ACTIVE_ROLE_STORAGE_KEY),
	workstationId: storage.get<string>(WORKSTATION_STORAGE_KEY),
};

// We allow applying an AUTHENTICATED action if one is set.
// This will only work in dev mode!
if (getIsDevJWTActive()) {
	const action = getDevJWTAction();
	if (action) {
		initialAppState = reducer(initialAppState, action);
	}
}

export interface Props {
	children?: ReactNode;
}

export function AppStateProvider({ children }: Props) {
	const [appState, dispatch] = useReducer(reducer, initialAppState);

	useLocalStorageEffect(appState);
	useLogRocketEffects(appState);
	useVisibilityStateEffect(dispatch);

	return (
		<AppStateDispatchContext.Provider value={dispatch}>
			<AppStateContext.Provider value={appState}>
				{children}
			</AppStateContext.Provider>
		</AppStateDispatchContext.Provider>
	);
}

export function useAppState() {
	return useContext(AppStateContext);
}

export function useAppStateDispatch() {
	return useContext(AppStateDispatchContext);
}

function useLocalStorageEffect({ activeRole, workstationId }: AppState) {
	useEffect(() => {
		if (activeRole) {
			storage.set(ACTIVE_ROLE_STORAGE_KEY, activeRole);
		}
		if (workstationId) {
			storage.set(WORKSTATION_STORAGE_KEY, workstationId);
		} else {
			storage.remove(WORKSTATION_STORAGE_KEY);
		}
	}, [activeRole, workstationId]);
}

function useLogRocketEffects({
	user,
	tenant,
	env,
	latestEnv,
	activeRole,
	workstationId,
}: AppState) {
	useEffect(() => {
		if (user && tenant) {
			identifyUser(user.id, {
				tenant,
			});
		}
	}, [user, tenant]);

	useEffect(() => {
		if (env && tenant) {
			trackEvent({
				name: "EnvSetup",
				properties: {
					stage: env.stage,
					beVersion: env.beVersion,
					feVersion: env.feVersion,
					tenant,
				},
			});
		}
	}, [env, tenant]);

	useEffect(() => {
		if (latestEnv) {
			trackEvent({
				name: "VersionUpdated",
				properties: {
					beVersion: latestEnv.beVersion,
					feVersion: latestEnv.feVersion,
				},
			});
		}
	}, [latestEnv]);

	useEffect(() => {
		if (activeRole) {
			trackEvent({
				name: "RoleSelected",
				properties: {
					activeRole,
				},
			});
		}
	}, [activeRole]);

	useEffect(() => {
		if (workstationId) {
			trackEvent({
				name: "WorkstationSelected",
				properties: {
					workstationId,
				},
			});
		}
	}, [workstationId]);
}

function useVisibilityStateEffect(dispatch: Dispatch<Action>) {
	useEffect(() => {
		const handleChange = () => {
			dispatch({
				type: "VISIBILITY_STATE_CHANGED",
				visibilityState: document.visibilityState,
			});
			logger.info("Visibility state changed to", document.visibilityState);
		};

		document.addEventListener("visibilitychange", handleChange);

		return () => {
			document.removeEventListener("visibilitychange", handleChange);
		};
	}, [dispatch]);
}
