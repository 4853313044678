import { Success, Time } from "@app/icons/components";
import { Plus } from "@app/icons/components/action";
import { Button } from "@app/modules/Button";
import { Card } from "@app/modules/cards/Card";
import type { CustomerProductsCardProps } from "@app/modules/cards/card-props";
import { getProductHistoryCardId } from "@app/modules/cards/card-utils";
import { useCards } from "@app/modules/cards/cards-shared-state";
import type { CustomerProductsCardQuery } from "@app/modules/cards/queries/customer-products-card.query.generated";
import { CustomerProductsCardDocument } from "@app/modules/cards/queries/customer-products-card.query.generated";
import { calculateCoiExtraTotals } from "@app/modules/customer-order/customer-order-utils";
import {
	DataTable,
	DataTableCell,
	DataTableRow,
} from "@app/modules/data-table/DataTable";
import { DataTablePagination } from "@app/modules/data-table/DataTablePagination";
import { useDataTable } from "@app/modules/data-table/useDataTable";
import { EntityIdTagItemLink } from "@app/modules/entity/EntityIdTagItem";
import { Customer_Order_States_Enum } from "@app/modules/graphql-api-types.generated";
import { useQuery } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import {
	getLocalizedDateString,
	LocalDate,
} from "@app/modules/localdate/localdate";
import { BigNumber } from "@app/modules/number/big-number";
import { PercentageCell } from "@app/modules/pricing/cutting-patterns/cutting-pattern-commons";
import { ProductQuantity } from "@app/modules/product-unit/ProductQuantityInputLegacy";
import { CalculateCustomerPricingDocument } from "@app/modules/products/queries/calculate-customer-pricing.query.generated";
import type { AddCustomerOrderItemAction } from "@app/modules/ui-actions/types";
import { useUiActionsContext } from "@app/modules/ui-actions/UiActionsProvider";
import clsx from "clsx";

// How old is the oldest visible COI? Also present in `CustomerProductHistoryCard` and in `search_products_for_customer`. Sometimes changes based on FF requests.
export const MINIMUM_COI_DATE = LocalDate.now()
	.subtract(12, "months")
	.toDateString();

export function CustomerProductsCard({
	customerId,
	customerOrderId,
}: CustomerProductsCardProps) {
	const t = useTranslate("common");

	const { pagination, ...dataTable } = useDataTable({
		name: "customer-products-card",
		pageSize: 10,
		query: CustomerProductsCardDocument,
		queryVariables: {
			minimumCoiDate: MINIMUM_COI_DATE,
			where: {
				product: {
					customerOrderItems: {
						customerOrder: {
							_and: [
								{ id: { _neq: customerOrderId } },
								{
									state: {
										_nin: [
											Customer_Order_States_Enum.Open,
											Customer_Order_States_Enum.Canceled,
											Customer_Order_States_Enum.OfferRejected,
										],
									},
								},
								{ customerId: { _eq: customerId } },
							],
						},
					},
				},
			},
			customerId,
			customerOrderId,
		},
		columns: [
			{
				accessor: "product.domainId",
				className: "w-0",
				label: t("cards.customer-products.titles.product-id"),
			},
			{
				accessor: "",
				label: t("cards.customer-products.titles.delivery-date"),
			},
			{
				accessor: "",
				align: "right",
				label: t("cards.customer-products.titles.invoiced-weight"),
			},
			{
				accessor: "",
				align: "right",
				label: t("cards.customer-products.titles.percentage-margin"),
			},
		] as const,
	});

	const { addCards } = useCards();
	const handleAddCard = ({ productId }: { productId: string }) => {
		addCards([
			{
				type: "customer-product-history",
				cardId: getProductHistoryCardId(productId),
				payload: {
					customerId,
					customerOrderId,
					productId,
				},
			},
		]);
	};

	if (dataTable.visibleRows.length < 1) {
		return null;
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title>{t("cards.customer-products.titles.history")}</Card.Title>
				{pagination && (
					<div className="ml-auto">
						<DataTablePagination
							variant="compact"
							totalRows={dataTable.totalRows}
							{...pagination}
						/>
					</div>
				)}
			</Card.Header>
			<Card.Body>
				<DataTable
					instance={dataTable}
					variant="compact"
					className="border-t border-grey-200"
				>
					{dataTable.visibleRows.map((row) => (
						<Entry
							onClickAddCard={handleAddCard}
							key={row.product.id}
							row={row}
						/>
					))}
				</DataTable>
			</Card.Body>
		</Card>
	);
}

interface EntryProps {
	row: CustomerProductsCardQuery["entities"][number];
	onClickAddCard: (args: { productId: string }) => void;
}
function Entry({ row, onClickAddCard }: EntryProps) {
	const t = useTranslate("common");

	const { dispatch } = useUiActionsContext();

	const isProductOnOrder = row.product.coiOnCurrentOrder.some(
		({ productId }) => productId === row.product.id,
	);

	const handleAddProduct = (
		customerOrderItem: AddCustomerOrderItemAction["payload"],
	) => {
		dispatch({
			type: "add-customer-order-item",
			payload: { ...customerOrderItem },
		});
	};

	const coi = row?.product?.customerOrderItems;
	const firstCoi = coi?.[0];
	const fulfillmentPlan = firstCoi?.customerOrderItemFulfillmentPlans?.[0];

	const [calcRes] = useQuery({
		query: CalculateCustomerPricingDocument,
		variables: {
			customerId: firstCoi?.customerOrder.customer?.id || "",
			productId: row.product.id,
			salesUnitId: firstCoi?.salesUnit.id || "",
			asOf: firstCoi?.customerOrder?.deliveryDate,
		},
		pause:
			!row.product.id ||
			!firstCoi?.customerOrder.customer?.id ||
			!firstCoi?.salesUnit.id ||
			!firstCoi?.orderUnit.id ||
			!firstCoi?.customerOrder?.deliveryDate,
	});
	const salesPricePerUnit =
		calcRes.data?.calculate_customer_pricing[0]?.salesPricePerUnit ||
		firstCoi?.salesPricePerUnit ||
		"0";

	if (!firstCoi) {
		return null;
	}

	const { averageMargin, averageWeight } = calculateCoiExtraTotals(coi);

	return (
		<>
			<tr
				title={row.product.name}
				className="z-10 absolute w-250 pl-4 text-caption-12 font-500 text-grey-900 overflow-hidden whitespace-nowrap text-ellipsis"
			>
				{row.product.name}
			</tr>
			<DataTableRow>
				<DataTableCell className="pl-4 align-bottom">
					<EntityIdTagItemLink
						className="w-fit"
						entity={row.product}
						to="product-data.products.id"
					/>
				</DataTableCell>
				<DataTableCell className="align-bottom">
					<div className="text-caption-12">
						{firstCoi.customerOrder?.deliveryDate &&
							getLocalizedDateString(firstCoi.customerOrder.deliveryDate, {
								includeDayOfWeek: false,
							})}
					</div>
				</DataTableCell>
				<DataTableCell className="text-right">
					{averageWeight && (
						<ProductQuantity
							value={averageWeight.toString()}
							unit={firstCoi.salesUnit} // What happens if there are different sales units in the COI collection?
							significantDigits={0}
						/>
					)}
				</DataTableCell>
				{averageMargin && (
					<PercentageCell
						className="text-right"
						factor={new BigNumber(averageMargin.toString())}
					/>
				)}
				<DataTableCell className="align-top">
					<div className={clsx("flex flex-col items-end")}>
						<Button
							variant="tertiary-grey"
							icon={isProductOnOrder ? Success : Plus}
							title={t("cards.customer-products.actions.add-to-order")}
							onClick={() =>
								firstCoi &&
								fulfillmentPlan &&
								handleAddProduct({
									...firstCoi,
									salesPricePerUnit,
									fixedPrice: false,
									productId: row.product.id,
									salesUnitId: firstCoi.salesUnit.id,
									orderUnitId: firstCoi.orderUnit.id,
									requirePicking: row.product.requirePicking,
									containerProductId: firstCoi.containerProductId,
									fulfillmentPlans: {
										data: [
											{
												isReserved: fulfillmentPlan.isReserved,
												stockLocationId: fulfillmentPlan.stockLocationId,
												productUnitId: firstCoi.orderUnit.id,
												quantity: fulfillmentPlan.quantity,
											},
										],
									},
								})
							}
						/>
						<Button
							className="pr-2"
							variant="tertiary-grey"
							icon={Time}
							iconSize="20"
							title={t(
								"cards.customer-products.actions.add-product-history-card",
							)}
							onClick={() =>
								onClickAddCard({
									productId: row.product.id,
								})
							}
						/>
					</div>
				</DataTableCell>
			</DataTableRow>
		</>
	);
}
