import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPlus({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M12 19a1 1 0 001-1v-5h5a1 1 0 100-2h-5V6a1 1 0 10-2 0v5H6a1 1 0 100 2h5v5a1 1 0 001 1z" />
		</svg>
	);
}

const MemoSvgPlus = React.memo(SvgPlus);
export default MemoSvgPlus;
