import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgNext({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.293 20.707a1 1 0 01-.083-1.32l.083-.094L15.585 12 8.293 4.707a1 1 0 01-.083-1.32l.083-.094a1 1 0 011.32-.083l.094.083 8 8a1 1 0 01.083 1.32l-.083.094-8 8a1 1 0 01-1.414 0z"
			/>
		</svg>
	);
}

const MemoSvgNext = React.memo(SvgNext);
export default MemoSvgNext;
