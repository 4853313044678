import type { ReactNode } from "react";
import React from "react";
import { isReady } from "./app-state";
import { useAppState } from "./context";

export interface AppStateViewProps {
	children?: ReactNode;
}

function AppStateView({ children }: AppStateViewProps) {
	const appState = useAppState();

	if (!isReady(appState)) {
		// TODO: Render a loading skeleton
		return null;
	}

	return <>{children}</>;
}

export { AppStateView };
