import type { RadioCheckboxProps } from "@app/modules/input-fields/RadioCheckbox";
import { nbsp } from "@app/modules/layout/characters";
import { devlogger } from "@app/modules/logger/devlogger";
import { useSearchParam } from "@app/modules/routes/search-params";
import type { LocalSearchFilter } from "@app/modules/search/local/types";
import { isNumber, isString } from "@app/modules/utils/type-guards";
import {
	createContext,
	useCallback,
	useContext,
	useRef,
	useState,
} from "react";
import { get } from "react-hook-form";
import type { Infer } from "superstruct";
import { array, is, literal, number, object, string, union } from "superstruct";

const sortItemsSchema = array(
	object({
		accessor: string(),
		direction: union([literal("asc"), literal("desc")]),
	}),
);

type SortItem = Infer<typeof sortItemsSchema>[number];
type SortItemDirection = SortItem["direction"];

export interface DataTableContextValue {
	getSortDirection: (columnName: string) => SortItemDirection | undefined;
	getToggleRowSelectedProps: (rowId: string) => RadioCheckboxProps;
	selectedRowIds: Set<string>;
}

export const DataTableContext = createContext<DataTableContextValue>({
	selectedRowIds: new Set(),
	getSortDirection: () => undefined,
	getToggleRowSelectedProps: () => ({ type: "checkbox" }),
});

export function useDataTableContext() {
	return useContext(DataTableContext);
}

export function useDataTableRowContext(rowId: string) {
	const { getToggleRowSelectedProps } = useDataTableContext();

	return {
		isSelected: getToggleRowSelectedProps(rowId).checked,
	};
}

export type DataTableVariant = "regular" | "compact" | "items" | "nested";

export interface DataTableRenderContextValue {
	variant: DataTableVariant;
}

export const DataTableRenderContext =
	createContext<DataTableRenderContextValue>({
		variant: "regular",
	});

export function useDataTableRenderContext() {
	return useContext(DataTableRenderContext);
}

const DEFAULT_MAX_SELECTION_SIZE = 50;

interface UseRowSelectOptions {
	visibleRowIds: string[];
	initialSelection?: string[];
	maxSelectionSize?: number;
}
export function useRowSelect({
	visibleRowIds,
	initialSelection = [],
	maxSelectionSize = DEFAULT_MAX_SELECTION_SIZE,
}: UseRowSelectOptions) {
	const [selected, setSelected] = useState<Set<string>>(
		() => new Set(initialSelection),
	);
	const canSelectMore = selected.size < maxSelectionSize;

	const getToggleRowSelectedProps = useCallback(
		(rowId: string): RadioCheckboxProps => {
			const checked = selected.has(rowId);
			return {
				type: "checkbox",
				checked,
				onChange: () => {
					setSelected((currentSelected) => {
						const newSelected = new Set(currentSelected);
						if (newSelected.has(rowId)) {
							newSelected.delete(rowId);
						} else {
							newSelected.add(rowId);
						}
						return newSelected;
					});
				},
				disabled: !checked && !canSelectMore,
			};
		},
		[canSelectMore, selected],
	);

	const getToggleAllPageRowsSelectedProps =
		useCallback((): Partial<RadioCheckboxProps> => {
			const selectedCount = visibleRowIds.filter((rowId) =>
				selected.has(rowId),
			).length;
			const checked =
				selectedCount > 0 && selectedCount === visibleRowIds.length;
			const indeterminate =
				selectedCount > 0 && selectedCount < visibleRowIds.length;
			return {
				checked,
				indeterminate,
				onChange: () => {
					setSelected((currentSelected) => {
						const updated = new Set(currentSelected);
						if (checked || (indeterminate && !canSelectMore)) {
							visibleRowIds.forEach((rowId) => {
								updated.delete(rowId);
							});
						} else {
							visibleRowIds.forEach((rowId) => {
								updated.add(rowId);
							});
						}
						const capped = [...updated].slice(0, maxSelectionSize);
						return new Set(capped);
					});
				},
				// setting a min width avoids layout shifts when the label width
				// changes. The value is chosen so that it works for up to 2 digits.
				className: "min-w-[48px]",
				label: selected.size || nbsp,
			};
		}, [visibleRowIds, selected, canSelectMore, maxSelectionSize]);

	const clearRowSelection = useCallback(() => {
		setSelected(new Set());
	}, []);

	return {
		clearRowSelection,
		selectedRowIds: selected,
		getToggleRowSelectedProps,
		getToggleAllPageRowsSelectedProps,
	};
}

export type UseRowSelectResult = ReturnType<typeof useRowSelect>;

export interface ColumnSortItem<Accessor extends string> {
	accessor: Accessor;
	direction: "asc" | "desc";
}

export function useColumnSort<Accessor extends string>(
	initialSort: ColumnSortItem<Accessor>[] = [],
	name?: string,
) {
	const searchParamName = name ? `${name}-sort` : "sort";
	const [sortItems, setSortItems] = useSearchParam(
		searchParamName,
		initialSort,
		{
			validate: (value) => is(value, sortItemsSchema),
		},
	);

	const getSortToggleProps = useCallback(
		(accessor: Accessor, sortable: boolean) => ({
			onClick: () => {
				if (sortable) {
					setSortItems((currentItems = []) => {
						const currentItem = currentItems.find(
							(item) => item.accessor === accessor,
						);
						const newItems = currentItems.filter(
							(item) => item.accessor !== accessor,
						);
						if (!currentItem) {
							return [{ accessor, direction: "asc" }, ...newItems];
						}
						if (currentItem.direction === "asc") {
							return [{ accessor, direction: "desc" }, ...newItems];
						}
						// Avoid returning an empty array if the last filter is removed.
						// Instead, we flip the direction. Returning an empty array would
						// reset the order to default order. If the default order is has a
						// single "desc" item, without the additional logic below the user
						// gets stuck and cannot change the filter direction.
						if (currentItem.direction === "desc" && newItems.length === 0) {
							return [{ accessor, direction: "asc" }, ...newItems];
						}

						return newItems;
					});
				}
			},
		}),
		[setSortItems],
	);

	const getSortDirection = useCallback(
		(accessor: Accessor) =>
			sortItems.find((item) => item.accessor === accessor)?.direction,
		[sortItems],
	);

	const clearSort = useCallback(() => {
		setSortItems([]);
	}, [setSortItems]);

	return {
		clearSort,
		sortItems,
		getSortToggleProps,
		getSortDirection,
	};
}

export type UseColumnSortResult = ReturnType<typeof useColumnSort>;

export type SortMode = "auto" | "numeric";

export function sortRows<R>(
	rows: R[],
	sortItems: UseColumnSortResult["sortItems"],
	columns: readonly { accessor?: string; sortMode?: SortMode }[] = [],
) {
	const reversedSortItems = [...sortItems].reverse();
	const toBeSorted = [...rows];
	reversedSortItems.forEach(({ accessor, direction }) => {
		const sortMode =
			columns.find((column) => column.accessor === accessor)?.sortMode ??
			"auto";
		toBeSorted.sort((a, b) => {
			const isAsc = direction === "asc";
			const propertyA = isAsc ? get(a, accessor) : get(b, accessor);
			const propertyB = isAsc ? get(b, accessor) : get(a, accessor);

			const isNullishA =
				propertyA === "" || propertyA === undefined || propertyA === null;
			const isNullishB =
				propertyB === "" || propertyB === undefined || propertyB === null;

			if (isNullishA || isNullishB) {
				if (isNullishA && isNullishB) {
					return 0;
				}
				return isNullishA ? 1 : -1;
			}

			switch (sortMode) {
				case "numeric": {
					if (isString(propertyA) && isString(propertyB)) {
						return parseFloat(propertyA) - parseFloat(propertyB);
					}
					return 0;
				}
				default: {
					if (isNumber(propertyA) && isNumber(propertyB)) {
						return propertyA - propertyB;
					}
					if (isString(propertyA) && isString(propertyB)) {
						return propertyA.localeCompare(propertyB);
					}
					return 0;
				}
			}
		});
	});
	return toBeSorted;
}

export function filterRows<R>(rows: R[], filters: LocalSearchFilter[]) {
	return rows.filter((row) =>
		filters.reduce((acc, filter) => {
			if (filter.type !== "Value") {
				devlogger.info(
					"This type of filter is not yet supported. If you need it, consider implementing it.",
					"type:",
					filter.type,
				);
				return acc;
			}
			const { path, operator, value } = filter;
			const currentRowValue = get(row, path);
			switch (operator) {
				case "_eq": {
					return acc && currentRowValue === value;
				}
				case "_in": {
					return Array.isArray(value)
						? value.includes(currentRowValue) && acc
						: acc;
				}
				case "_ilike": {
					return typeof value === "string"
						? value.includes(currentRowValue) && acc
						: acc;
				}
				// Add support for more operators when needed.
				// If we don't know the operator we ignore it.
				default: {
					return acc;
				}
			}
		}, true),
	);
}

export function usePagination({
	limit,
	initialOffset = 0,
	name,
}: {
	limit: number;
	initialOffset?: number;
	name?: string;
}) {
	const searchParamName = name ? `${name}-page` : "page";

	const defaultValueRef = useRef({ offset: initialOffset });

	const [page, setPage] = useSearchParam(
		searchParamName,
		defaultValueRef.current,
		{
			validate: (value) => is(value, object({ offset: number() })),
		},
	);

	const { offset } = page;

	const goNext = useCallback(() => {
		setPage((oldPage = defaultValueRef.current) => ({
			...oldPage,
			offset: oldPage.offset + limit,
		}));
	}, [limit, setPage]);

	const goPrev = useCallback(() => {
		setPage((oldPage = defaultValueRef.current) => ({
			...oldPage,
			offset: Math.max(oldPage.offset - limit, 0),
		}));
	}, [limit, setPage]);

	const getHasNext = useCallback(
		(count: number) => offset + limit < count,
		[limit, offset],
	);

	const resetOffset = useCallback(() => {
		setPage(defaultValueRef.current);
	}, [setPage]);

	return {
		limit,
		offset,
		goNext,
		goPrev,
		hasPrev: offset !== 0,
		getHasNext,
		resetOffset,
	};
}

export type UsePaginationResult = ReturnType<typeof usePagination>;
