import { getCustomerGroupName } from "@app/modules/customer-group/customer-group-utils";
import type { TranslateFunction } from "@app/modules/i18n/types";
import { getProductGroupName } from "@app/modules/product-group/product-group-utils";
import type {
	CustomerGroupSuggestionFragment,
	CustomerInvoiceSuggestionFragment,
	CustomerOrderSuggestionFragment,
	CustomerSuggestionFragment,
} from "@app/modules/search/local/queries/customer-suggestions.fragment.generated";
import type {
	ProductGroupSuggestionFragment,
	ProductSuggestionFragment,
	StockLocationSuggestionFragment,
} from "@app/modules/search/local/queries/product-suggestions.fragment.generated";
import type { RollingInventorySuggestionFragment } from "@app/modules/search/local/queries/stock-suggestions.fragment.generated";
import type {
	SupplierOrderSuggestionFragment,
	SupplierSuggestionFragment,
} from "@app/modules/search/local/queries/supplier-suggestions.fragment.generated";
import type {
	SalesTeamSuggestionFragment,
	UserSuggestionFragment,
} from "@app/modules/search/local/queries/user-suggestions.fragment.generated";

import {
	makeLocalSearchSuggestion,
	makeLocalSearchSuggestionKey,
} from "@app/modules/search/local/utils";
import type { SearchIconName } from "@app/modules/search/search-icons";

export function makeProductSuggestion(idPath: string) {
	return (suggestion: ProductSuggestionFragment) =>
		makeLocalSearchSuggestion(idPath, suggestion.id, {
			label: suggestion.name,
			icon: "article",
			domainId: suggestion.domainId,
			tenant: suggestion.tenant,
		});
}

export function makeCustomerSuggestion(idPath: string) {
	return (suggestion: CustomerSuggestionFragment) =>
		makeLocalSearchSuggestion(idPath, suggestion.id, {
			label: suggestion.name,
			icon: "user",
			domainId: suggestion.domainId,
			tenant: suggestion.tenant,
		});
}

export function makeCustomerGroupSuggestion(idPath: string) {
	return ({ id, tenant, domainId, ...rest }: CustomerGroupSuggestionFragment) =>
		makeLocalSearchSuggestion(idPath, id, {
			label: getCustomerGroupName(rest),
			tenant,
			domainId,
		});
}

export function makePriceCustomerGroupSuggestion(idPath: string) {
	return ({ id, domainId, tenant, ...rest }: CustomerGroupSuggestionFragment) =>
		makeLocalSearchSuggestion(idPath, id, {
			icon: "price-tag",
			label: getCustomerGroupName(rest),
			tenant,
			domainId,
		});
}

export interface TranslatedSuggestionOptions {
	t: TranslateFunction;
	shouldDeactivateDateFilter?: boolean;
}

export function makeCustomerOrderSuggestion(
	path: string,
	{ t, shouldDeactivateDateFilter }: TranslatedSuggestionOptions,
) {
	return (suggestion: CustomerOrderSuggestionFragment) =>
		makeLocalSearchSuggestion(path, suggestion.id, {
			label: t("search.suggestion-labels.customer-order", {
				id: suggestion.domainId,
			}),
			icon: "customer-order",
			shouldDeactivateDateFilter,
		});
}

export function makeSupplierOrderSuggestion(
	path: string,
	{ t, shouldDeactivateDateFilter }: TranslatedSuggestionOptions,
) {
	return (suggestion: SupplierOrderSuggestionFragment) =>
		makeLocalSearchSuggestion(path, suggestion.id, {
			label: t("search.suggestion-labels.supplier-order", {
				id: suggestion.domainId,
			}),
			icon: "supplier",
			shouldDeactivateDateFilter,
		});
}

export function makeCustomerInvoiceSuggestion(
	path: string,
	{ t, shouldDeactivateDateFilter }: TranslatedSuggestionOptions,
) {
	return (suggestion: CustomerInvoiceSuggestionFragment) =>
		makeLocalSearchSuggestion(path, suggestion.id, {
			label: t("search.suggestion-labels.customer-invoice", {
				id: suggestion.domainId,
			}),
			icon: "invoice",
			shouldDeactivateDateFilter,
		});
}

export function makeSupplierSuggestion(idPath: string) {
	return (suggestion: SupplierSuggestionFragment) =>
		makeLocalSearchSuggestion(idPath, suggestion.id, {
			label: suggestion.name,
			icon: "supplier",
			domainId: suggestion.domainId,
			tenant: suggestion.tenant,
		});
}

export function makeProductGroupSuggestion(path: string) {
	return (suggestion: ProductGroupSuggestionFragment) =>
		makeLocalSearchSuggestion(path, suggestion.id, {
			label: getProductGroupName(suggestion),
			tenant: suggestion.tenant,
		});
}

export function makeProductGroupParentSuggestion(
	path: string,
	{ t, shouldDeactivateDateFilter }: TranslatedSuggestionOptions,
) {
	return (suggestion: ProductGroupSuggestionFragment) =>
		makeLocalSearchSuggestion(path, suggestion.id, {
			key: makeLocalSearchSuggestionKey({
				// We use a custom prefix to avoid conflicts with the regular product group filter.
				prefix: "pgp",
				path,
				value: suggestion.id,
				tenant: suggestion.tenant,
			}),
			customFilterKey: `p-${suggestion.id}`,
			label: t("search.suggestion-labels.product-group-parent", {
				name: getProductGroupName(suggestion),
			}),
			tenant: suggestion.tenant,
			shouldDeactivateDateFilter,
		});
}

export function makeStockLocationSuggestion(idPath: string) {
	return ({ id, name, domainId, tenant }: StockLocationSuggestionFragment) =>
		makeLocalSearchSuggestion(idPath, id, {
			label: name,
			domainId,
			tenant,
			icon: "stock",
		});
}

export function makeRollingInventorySuggestion(
	path: string,
	{ t, shouldDeactivateDateFilter }: TranslatedSuggestionOptions,
) {
	return ({ id, tenant }: RollingInventorySuggestionFragment) =>
		makeLocalSearchSuggestion(path, id, {
			label: t("search.suggestion-labels.rolling-inventory", { id }),
			tenant,
			shouldDeactivateDateFilter,
		});
}

export function makeSalesTeamSuggestion(idPath: string) {
	return ({ id, domainId, name, tenant }: SalesTeamSuggestionFragment) =>
		makeLocalSearchSuggestion(idPath, id, {
			icon: "user-group",
			label: name,
			tenant,
			domainId,
		});
}

export function makeUserSuggestion(path: string) {
	return ({ id, name, tenant }: UserSuggestionFragment) =>
		makeLocalSearchSuggestion(path, id, {
			label: name,
			icon: "user",
			tenant,
		});
}

export function makeTextSuggestion({
	name,
	path,
	icon = "search",
}: {
	name: string;
	path: string;
	icon?: SearchIconName;
}) {
	return makeLocalSearchSuggestion(path, `%${name}%`, {
		label: name,
		icon,
		operator: "_ilike",
	});
}
