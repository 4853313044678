// eslint-disable-next-line no-restricted-imports
import BigNumberJs from "bignumber.js";

const BigNumberClone = BigNumberJs.clone({
	ROUNDING_MODE: BigNumberJs.ROUND_HALF_EVEN,
});

export class BigNumber extends BigNumberClone {}

export type BigNumberValue = BigNumberJs.Value;
