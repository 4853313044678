import clsx from "clsx";
import type { HTMLAttributes, Ref } from "react";
import { createElement, forwardRef } from "react";

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
	as?: string;
	className?: string;
}

/** @deprecated use class "text-header-18" instead. */
export const Header18 = forwardRef(
	(
		{ as = "h1", className, ...props }: TypographyProps,
		ref: Ref<HTMLElement>,
	) =>
		createElement(as, {
			ref,
			className: clsx("text-18 font-600", className),
			...props,
		}),
);

/** @deprecated use class "text-header-16" instead. */
export const Header16 = forwardRef(
	(
		{ as = "div", className, ...props }: TypographyProps,
		ref: Ref<HTMLElement>,
	) =>
		createElement(as, {
			ref,
			className: clsx("text-16", className),
			...props,
		}),
);

/** @deprecated use class "text-body-14" instead. */
export const Body14 = forwardRef(
	(
		{ as = "div", className, ...props }: TypographyProps,
		ref: Ref<HTMLElement>,
	) =>
		createElement(as, {
			ref,
			className: clsx("text-14", className),
			...props,
		}),
);

/** @deprecated use class "text-body-14" instead. */
export const DefaultBody14 = forwardRef<HTMLElement, TypographyProps>(
	function DefaultBody14({ className, ...props }, ref) {
		return (
			<Body14
				ref={ref}
				className={clsx("font-400 text-grey-900", className)}
				{...props}
			/>
		);
	},
);

/** @deprecated use class "text-caption-10" instead. */
export const Caption10 = forwardRef(
	(
		{ as = "div", className, ...props }: TypographyProps,
		ref: Ref<HTMLElement>,
	) =>
		createElement(as, {
			ref,
			className: clsx("text-10", className),
			...props,
		}),
);

/** @deprecated use class "text-caption-12" instead. */
export const Caption12 = forwardRef(
	(
		{ as = "div", className, ...props }: TypographyProps,
		ref: Ref<HTMLElement>,
	) =>
		createElement(as, {
			ref,
			className: clsx("text-12", className),
			...props,
		}),
);

/** @deprecated use class "text-caption-12-caps" instead. */
export const Caption12Caps = forwardRef(
	(
		{ as = "div", className, ...props }: TypographyProps,
		ref: Ref<HTMLElement>,
	) =>
		createElement(as, {
			ref,
			className: clsx("text-12 font-600 uppercase", className),
			...props,
		}),
);
