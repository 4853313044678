import { useAppState } from "@app/modules/app-state/context";
import {
	getTranslatedMessage,
	isBusinessErrorMessage,
	tryParseErrorMessage,
} from "@app/modules/graphql/business-error";
import { useSharedPausedState } from "@app/modules/graphql/pause-behavior";
import { NoopDocument } from "@app/modules/graphql/queries/noop.query";
import { useTranslate } from "@app/modules/i18n/context";
import { logger } from "@app/modules/logger/logger";
import { useNotificationDispatch } from "@app/modules/notification/context";
import { useNotificationCreate } from "@app/modules/notification/notification-creators";
import type { DocumentNode } from "graphql";
import { useCallback, useEffect, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import type * as Urql from "urql";
import {
	useClient,
	// eslint-disable-next-line no-restricted-imports
	useMutation as useUrqlMutation,
	// eslint-disable-next-line no-restricted-imports
	useQuery as useUrqlQuery,
	// eslint-disable-next-line no-restricted-imports
	useSubscription as useUrqlSubscription,
} from "urql";

export function usePublicQuery<
	Data = unknown,
	Variables extends Urql.AnyVariables = Urql.AnyVariables,
>(args: Urql.UseQueryArgs<Variables, Data>) {
	return useUrqlQuery(args);
}

export interface UseSubscriptionArgs<
	Variables extends Urql.AnyVariables,
	Data,
	Pause,
> extends ErrorHandlingArgs {
	requestPolicy?: Urql.RequestPolicy;
	context?: Partial<Urql.OperationContext>;
	query: Pause extends true
		? undefined | Urql.DocumentInput<Data, Variables>
		: Urql.DocumentInput<Data, Variables>;
	pause?: Pause;
	variables: Pause extends true ? PartialVariables<Variables> : Variables;
}

export function usePublicSubscription<
	Data = unknown,
	Variables extends Urql.AnyVariables = Urql.AnyVariables,
	Pause extends boolean | undefined = false,
>(args: UseSubscriptionArgs<Variables, Data, Pause>) {
	return useUrqlSubscription({
		...args,
		query: args.query ?? NoopDocument,
		variables: args.variables as Variables,
	});
}

export type PartialVariables<T> =
	| {
			[P in keyof T]?: T[P] | undefined | null;
	  }
	| undefined;

export interface UseQueryArgs<Variables extends Urql.AnyVariables, Data, Pause>
	extends ErrorHandlingArgs {
	requestPolicy?: Urql.RequestPolicy;
	context?: Partial<Urql.OperationContext>;
	query: Pause extends true
		? undefined | Urql.DocumentInput<Data, Variables>
		: Urql.DocumentInput<Data, Variables>;
	pause?: Pause;
	variables: Pause extends true ? PartialVariables<Variables> : Variables;
}

export function useQuery<
	Data = unknown,
	Variables extends Urql.AnyVariables = Urql.AnyVariables,
	Pause extends boolean = boolean,
>(args: UseQueryArgs<Variables, Data, Pause>) {
	const { accessToken } = useAppState();
	const [isPaused] = useSharedPausedState();

	// TODO: cancel active queries on unmount. See: https://github.com/FormidableLabs/urql/discussions/1482

	const response = useUrqlQuery<Data, Variables>({
		...args,
		query: args.query ?? NoopDocument,
		variables: args.variables as Variables,
		pause: !accessToken || args.pause || isPaused || !args.query,
	});

	useErrorHandling(response[0].error, args);

	return response;
}

export function useManualQuery<
	Data = unknown,
	Variables extends Urql.AnyVariables = Urql.AnyVariables,
>(args: Pick<Urql.UseQueryArgs<Variables, Data>, "query" | "context">) {
	// TODO: potentially pause query if access token is not here
	const client = useClient();
	const fetchQuery = useCallback(
		(variables: Variables) =>
			client.query(args.query, variables as never, args.context).toPromise(),
		[args.context, args.query, client],
	);
	return [fetchQuery] as const;
}

export function useSubscription<
	Data = unknown,
	Variables extends Urql.AnyVariables = Urql.AnyVariables,
	Pause extends boolean | undefined = false,
>(args: UseSubscriptionArgs<Variables, Data, Pause> & ErrorHandlingArgs) {
	const { accessToken } = useAppState();
	const [isPaused] = useSharedPausedState();

	const response = useUrqlSubscription({
		...args,
		query: args.query ?? NoopDocument,
		pause: Boolean(!accessToken) || args.pause || isPaused,
		variables: args.variables as Variables,
	});

	useErrorHandling(response[0].error, args);

	return response;
}

export function useMutation<
	Data = unknown,
	Variables extends Urql.AnyVariables = Urql.AnyVariables,
>(
	query: DocumentNode | Urql.TypedDocumentNode<Data, Variables> | string,
	args?: ErrorHandlingArgs,
): Urql.UseMutationResponse<Data, Variables> {
	const response = useUrqlMutation(query);

	useErrorHandling(response[0].error, args);

	return response;
}

interface ErrorHandlingArgs {
	pauseErrorHandling?: boolean;
}

function useErrorHandling(
	error: Urql.CombinedError | undefined,
	{ pauseErrorHandling = false }: ErrorHandlingArgs = {},
) {
	const t = useTranslate("common");
	const { dispatchNotification } = useNotificationDispatch();
	const { createTryAgainToast } = useNotificationCreate();
	const isPausedRef = useRef(pauseErrorHandling);
	isPausedRef.current = pauseErrorHandling;

	useEffect(() => {
		if (error) {
			logger.error(error, error.message);
		}
		if (error && !isPausedRef.current) {
			if (error.networkError) {
				dispatchNotification({
					type: "toast",
					variant: "error",
					text: t("notifications.messages.network-error"),
				});
			} else if (error.graphQLErrors.length) {
				error.graphQLErrors.forEach(({ message }) => {
					if (isBusinessErrorMessage(message)) {
						const businessError = tryParseErrorMessage(message);
						if (businessError) {
							dispatchNotification({
								type: "toast",
								variant: "error",
								text: getTranslatedMessage(t, businessError),
							});
						} else {
							dispatchNotification(createTryAgainToast());
						}
					} else {
						dispatchNotification(createTryAgainToast());
					}
				});
			} else {
				// Haven't found an error that would fall into this category.
				// If it happens, we still want to show a generic error message.
				dispatchNotification(createTryAgainToast());
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);
}
