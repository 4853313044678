import { positiveIntString } from "@app/modules/form/structs";
import type { Product_Units } from "@app/modules/graphql-api-types.generated";
import {
	Product_Unit_States_Enum,
	Units_Enum,
} from "@app/modules/graphql-api-types.generated";
import type { TranslateFunction } from "@app/modules/i18n/types";
import type { SelectItem } from "@app/modules/input-fields/Select";
import { nbsp } from "@app/modules/layout/characters";
import { BigNumber } from "@app/modules/number/big-number";
import { ScaledNumber } from "@app/modules/number/scaled-number";
import type { ProductUnitFragment } from "@app/modules/product-unit/queries/product-unit.fragment.generated";
// eslint-disable-next-line no-restricted-imports
import BigNumberJs from "bignumber.js";
import {
	enums,
	integer,
	literal,
	nullable,
	object,
	optional,
	string,
} from "superstruct";

// TODO: replace some of the functionality here with `ScaledNumber`.

export const THOUSAND_SEPARATOR = nbsp;
export const DECIMAL_SEPARATOR = ".";

export const CANONICAL_SCALE = 3;
export const CANONICAL_SCALE_FACTOR = Number(
	`1${"".padEnd(CANONICAL_SCALE, "0")}`,
);

export const QuantityNumber = BigNumberJs.clone({
	DECIMAL_PLACES: CANONICAL_SCALE,
	ROUNDING_MODE: BigNumberJs.ROUND_HALF_EVEN,
});

export function getDecimalScale(unit?: Pick<Product_Units, "unit">) {
	if (!unit) {
		return 0;
	}

	return [Units_Enum.Piece, Units_Enum.Box].includes(unit.unit)
		? 0
		: CANONICAL_SCALE;
}

/** @deprecated use `descale` instead  */
export function formatCanonicalQuantity(
	value: string,
	unit?: ProductUnitFragment,
): string;
/** @deprecated use `descale` instead  */
export function formatCanonicalQuantity(
	value: string,
	outputScale?: number,
): string;
/** @deprecated use `descale` instead  */
export function formatCanonicalQuantity(
	value: string,
	unitOrScale: number | ProductUnitFragment = CANONICAL_SCALE,
) {
	if (!value.length) {
		return "";
	}

	let outputScale;

	if (typeof unitOrScale === "object") {
		outputScale = getDecimalScale(unitOrScale);
	} else {
		outputScale = unitOrScale;
	}

	const number = new QuantityNumber(value).dividedBy(CANONICAL_SCALE_FACTOR);
	return number.toFixed(outputScale);
}
/** @deprecated use `scale` instead  */
export function asCanonicalQuantity(bigNumber: BigNumber): string;
/** @deprecated use `scale` instead  */
export function asCanonicalQuantity(formattedValue: string): string;
/** @deprecated use `scale` instead  */
export function asCanonicalQuantity(value: string | BigNumber): string {
	if (BigNumber.isBigNumber(value)) {
		return value.multipliedBy(CANONICAL_SCALE_FACTOR).toFixed(0);
	}

	const [whole, decimal = ""] = value.split(DECIMAL_SEPARATOR);
	const number = new QuantityNumber(`${whole}.${decimal}`).multipliedBy(
		CANONICAL_SCALE_FACTOR,
	);

	if (number.isNaN()) {
		return "";
	}

	return number.toFixed(0);
}

export function asNumericQuantity(value: string) {
	return new QuantityNumber(value).dividedBy(CANONICAL_SCALE_FACTOR);
}

/** @deprecated */
export function formatNumericQuantity(
	value: BigNumber,
	outputScale = CANONICAL_SCALE,
) {
	return value.toFixed(outputScale);
}

export function formatUnit(
	t: TranslateFunction,
	unit?: Pick<ProductUnitFragment, "unit" | "productUnitName">,
) {
	if (!unit) {
		return "";
	}

	if (unit.unit === Units_Enum.Custom) {
		return unit.productUnitName ?? "";
	}

	return t(`units.${unit.unit}`);
}

export function formatUnitWithSlash(
	t: TranslateFunction,
	unit?: Pick<ProductUnitFragment, "unit" | "productUnitName">,
) {
	return unit ? `/${formatUnit(t, unit)}` : "";
}

export type ProductUnitSelectOption = ProductUnitFragment & {
	disabled?: boolean;
};

export function asSelectItem(
	t: TranslateFunction,
	units: ProductUnitSelectOption[],
	{ hasSlash = false }: { hasSlash?: boolean } = {},
): SelectItem[] {
	return units.map((unit) => ({
		id: unit.id,
		label: hasSlash ? formatUnitWithSlash(t, unit) : formatUnit(t, unit),
		disabled: unit.disabled,
	}));
}

export const FactorNumber = BigNumber.clone({
	ROUNDING_MODE: BigNumber.ROUND_HALF_EVEN,
});

export function percentageToFactor(percentage: string) {
	return new FactorNumber(percentage).plus("100").div("100").toString();
}

export function percentageToOneMinusFactor(percentage: string) {
	return new FactorNumber(100).minus(percentage).div(100).toString();
}

export function factorToPercentage(factor: string | number) {
	return new FactorNumber(factor).times("100").minus("100").toString();
}

export function invertFactor(factor?: string) {
	if (!factor) {
		return "";
	}

	// 1/0 = Infinity
	if (factor === "0") {
		return factor;
	}

	return new FactorNumber(1).div(factor).toString(10);
}

export function negateCanonicalQuantity(value: string) {
	const n = asNumericQuantity(value);
	return asCanonicalQuantity(n.negated());
}

export function convertUnit(
	from: ProductUnitFragment,
	to: ProductUnitFragment,
	quantity: string | ScaledNumber,
	{ significantDigits }: { significantDigits?: number } = {},
) {
	return new ScaledNumber(quantity)
		.times(to.factor)
		.div(from.factor)
		.round(significantDigits ?? to.significantDigits)
		.toString();
}

export function convertUnitUp(
	from: ProductUnitFragment,
	to: ProductUnitFragment,
	quantity: string | ScaledNumber,
	{ significantDigits }: { significantDigits?: number } = {},
) {
	return new ScaledNumber(quantity)
		.times(to.factor)
		.div(from.factor)
		.roundUp(significantDigits ?? to.significantDigits)
		.toString();
}

export function convertRateUnit(
	from: ProductUnitFragment,
	to: ProductUnitFragment,
	quantity: string | ScaledNumber,
) {
	return new ScaledNumber(quantity)
		.times(from.factor)
		.div(to.factor)
		.toString();
}

export function isKgUnit(unit?: ProductUnitFragment) {
	return unit?.unit === Units_Enum.Kg;
}

export function isPieceUnit(unit?: ProductUnitFragment) {
	return unit?.unit === Units_Enum.Piece;
}

export const FAKE_PIECE_UNIT: ProductUnitFragment = {
	__typename: "product_units",
	id: "",
	state: Product_Unit_States_Enum.Active,
	unit: Units_Enum.Piece,
	significantDigits: 0,
	ordinalPosition: 0,
	factor: "1",
};

export const FAKE_KG_UNIT: ProductUnitFragment = {
	__typename: "product_units",
	id: "",
	state: Product_Unit_States_Enum.Active,
	unit: Units_Enum.Kg,
	significantDigits: 3,
	ordinalPosition: 0,
	factor: "1",
};

// Important: Whenever the ProductUnitFragment is changed update the schema accordingly.
export const productUnitFragmentSchema = object({
	__typename: literal("product_units"),
	id: string(),
	state: enums(Object.values(Product_Unit_States_Enum)),
	unit: enums(Object.values(Units_Enum)),
	productUnitName: optional(nullable(string())),
	significantDigits: integer(),
	ordinalPosition: integer(),
	// we don't have a struct for non integer number strings (yet)
	factor: string(),
	depletionThreshold: optional(nullable(positiveIntString())),
});

export function formatCanonicalQuantityWithUnit(
	t: TranslateFunction,
	value: string,
	unit: ProductUnitFragment,
) {
	return `${formatCanonicalQuantity(value, unit)} ${formatUnit(t, unit)}`;
}
