export const actions = {
	FOCUS_SEARCH: "alt+f",
	TOGGLE_SEARCH_MODE: "alt+x",
} as const;

export type HotkeyAction = keyof typeof actions;

export const holdActions: HotkeyAction[] = [];

export function getIsHoldAction(action: HotkeyAction) {
	return holdActions.includes(action);
}
