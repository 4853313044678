import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSpezialangebot({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.5 11a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm0-2a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
			/>
			<path d="M5.002 18.998c-.39-.39-.412-1.002-.048-1.366l12.67-12.67c.364-.364.976-.343 1.366.048.39.39.412 1.002.048 1.366l-12.67 12.67c-.364.364-.975.343-1.366-.048z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 16.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-2 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
			/>
		</svg>
	);
}

const MemoSvgSpezialangebot = React.memo(SvgSpezialangebot);
export default MemoSvgSpezialangebot;
