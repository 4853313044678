import { useInputId } from "@app/modules/form/form-utils";
import { Input } from "@app/modules/input-fields/commons";
import type { ValidationState } from "@app/modules/input-fields/types";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";

interface ContextValue {
	isInsideGroup: boolean;
	state: ValidationState;
	primaryInputId: string;
	primaryInputName: string;
}

const Context = createContext<ContextValue>({
	isInsideGroup: false,
	state: "default",
	primaryInputId: "",
	primaryInputName: "",
});

export function useInputGroup(name: string | undefined) {
	const { isInsideGroup, primaryInputId, primaryInputName, state } =
		useContext(Context);

	return isInsideGroup
		? {
				isInsideGroup,
				inputId: name === primaryInputName ? primaryInputId : undefined,
				state,
		  }
		: { isInsideGroup };
}

export interface InputGroupProps {
	className?: string;
	children: ReactNode;
	label?: ReactNode;
	state?: ValidationState;
	primaryInputName: string;
	hint?: ReactNode;
}

export function InputGroup({
	className,
	children,
	label,
	state = "default",
	primaryInputName,
	hint,
}: InputGroupProps) {
	const primaryInputId = useInputId();
	const value = useMemo(
		() => ({
			isInsideGroup: true,
			state,
			primaryInputId,
			primaryInputName,
		}),
		[state, primaryInputId, primaryInputName],
	);

	return (
		<Context.Provider value={value}>
			<div className={className}>
				{label && (
					<Input.Label
						state={state}
						testid={primaryInputName}
						htmlFor={primaryInputId}
					>
						{label}
					</Input.Label>
				)}
				<div className="box-border flex items-center">{children}</div>
				{hint && <Input.Hint state={state}>{hint}</Input.Hint>}
			</div>
		</Context.Provider>
	);
}
