import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOrderClient({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M18.158 6.476a5 5 0 01-1.466 3.185c-.733.716-1.679 1.158-2.692 1.158V10c0-.416-.063-.817-.181-1.194.061.009.122.013.18.013.874 0 2.048-.93 2.165-2.492C16.264 5.002 15.334 4 14 4c-1.348 0-2.263.98-2.164 2.328l.011.123A3.983 3.983 0 0010 6h-.17c.064-2.281 1.825-4 4.17-4 2.495 0 4.345 1.989 4.158 4.476zM14 18.997l6.627-.002c.94-.074 1.509-.944 1.345-1.84-.466-2.556-2.353-4.434-4.83-5.29A9.63 9.63 0 0014 11.35v2c2.998 0 5.221 1.551 5.877 3.645L14 16.997v2z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 10H4v10h6V10zM4 8a2 2 0 00-2 2v10a2 2 0 002 2h6a2 2 0 002-2V10a2 2 0 00-2-2H4z"
			/>
		</svg>
	);
}

const MemoSvgOrderClient = React.memo(SvgOrderClient);
export default MemoSvgOrderClient;
