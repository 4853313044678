import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPerformance({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 6a1 1 0 01.993.883L23 7v5.385a1 1 0 01-1.993.116L21 12.385V9.413l-5.666 5.668a2.538 2.538 0 01-3.443.136l-.148-.136-2.44-2.44a.539.539 0 00-.696-.055l-.065.055-5.835 5.835a1 1 0 01-1.497-1.32l.083-.094 5.835-5.835a2.538 2.538 0 013.442-.136l.148.136 2.44 2.44a.538.538 0 00.697.055l.064-.056L19.586 8 16.616 8a1 1 0 01-.994-.883L15.615 7a1 1 0 01.884-.993L16.615 6H22z"
			/>
		</svg>
	);
}

const MemoSvgPerformance = React.memo(SvgPerformance);
export default MemoSvgPerformance;
