import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgNewcard({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M14 10a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2v-2a1 1 0 011-1z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 2a3 3 0 00-3 3v10a3 3 0 003 3h1v1a3 3 0 003 3h10a3 3 0 003-3V9a3 3 0 00-3-3h-1.016c.004-.329.006-.657.008-.984A3.002 3.002 0 0014.99 2H5zm10.984 4c.004-.331.007-.663.008-.995A1.002 1.002 0 0014.99 4H5a1 1 0 00-1 1v10a1 1 0 001 1h1V9a3 3 0 013-3h6.984zM8 9a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1H9a1 1 0 01-1-1V9z"
			/>
		</svg>
	);
}

const MemoSvgNewcard = React.memo(SvgNewcard);
export default MemoSvgNewcard;
