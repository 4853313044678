import type { IconProps } from "@app/icons/Icon";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { FetchingIndicator } from "@app/modules/layout/FetchingIndicator";
import type { LinkProps } from "@app/modules/routes/link";
import { Link } from "@app/modules/routes/link";
import type { RouteName } from "@app/modules/routes/routes";
import { useNavigate } from "@app/modules/routes/routes";
import clsx from "clsx";
import type { ButtonHTMLAttributes, ReactNode, Ref } from "react";
import React from "react";
import { Body14 } from "./typography";

export type ButtonVariant =
	| "primary"
	| "secondary"
	| "secondary-white"
	| "tertiary"
	| "tertiary-grey"
	| "tertiary-destructive"
	| "destructive";

export interface CommonButtonProps {
	className?: string;
	children?: ReactNode;
	variant: ButtonVariant;
	icon?: IconComponent;
	iconPosition?: "left" | "right";
	iconSize?: IconProps["size"];
	isFetching?: boolean;
	disableFetchingIndicatorDelay?: boolean;
	iconClassName?: string;
	disabled?: boolean;
	pointedBorderL?: boolean;
	pointedBorderR?: boolean;
	disabledHoverStyles?: boolean;
	readOnly?: boolean;
	isTouch?: boolean;
}

export interface ButtonProps
	extends CommonButtonProps,
		Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children" | "className"> {
	innerRef?: Ref<HTMLButtonElement>;
	testid?: string;
}

export function Button({
	type = "button",
	innerRef,
	testid,
	...props
}: ButtonProps) {
	const { className, child, elementProps } = processButtonProps(props);

	const testidValue = testid ? `button-${props.variant}-${testid}` : undefined;

	return (
		<button
			data-testid={testidValue}
			ref={innerRef}
			// eslint-disable-next-line react/button-has-type
			type={type}
			className={className}
			{...elementProps}
		>
			{child}
		</button>
	);
}

export type ButtonLinkProps<Name extends RouteName = RouteName> =
	CommonButtonProps &
		Omit<LinkProps<Name>, "children" | "className"> & {
			innerRef?: Ref<HTMLAnchorElement>;
		};

export function ButtonLink<Name extends RouteName>({
	to,
	params,
	innerRef,
	...props
}: ButtonLinkProps<Name>) {
	const { className, child, elementProps } = processButtonProps(props);

	return (
		<Link
			innerRef={innerRef}
			className={className}
			to={to as RouteName}
			params={params}
			{...elementProps}
		>
			{child}
		</Link>
	);
}

export function processButtonProps<T extends CommonButtonProps>({
	className,
	children,
	variant,
	icon,
	iconPosition = "left",
	iconSize = "24",
	isFetching = false,
	disableFetchingIndicatorDelay = false,
	iconClassName,
	pointedBorderL,
	pointedBorderR,
	disabledHoverStyles,
	readOnly,
	isTouch = false,
	disabled,
	...elementProps
}: T) {
	const hasDisabledStyles = !readOnly && !isFetching && disabled;
	const elementCls = clsx(
		className,
		baseCls,
		!hasDisabledStyles && variantCls[variant],
		!hasDisabledStyles && !disabledHoverStyles && hoverCls[variant],
		hasDisabledStyles && disabledCls[variant],
		pointedBorderL && "rounded-l-none",
		pointedBorderR && "rounded-r-none",
		padding(variant, Boolean(children), isTouch),
		isTouch && "h-48",
	);

	const child = (
		<>
			<FetchingIndicator
				className={children ? "mr-8" : undefined}
				isFetching={isFetching}
				delay={disableFetchingIndicatorDelay ? 0 : undefined}
			/>
			{icon && iconPosition === "left" && (
				<Icon
					icon={icon}
					size={iconSize}
					className={clsx(children && "mr-4 ml-auto", iconClassName)}
				/>
			)}
			<Body14
				as="span"
				className={clsx(
					"font-600",
					icon && iconPosition === "left" && "mr-auto",
					icon && iconPosition === "right" && "ml-auto",
					!icon && "m-auto",
				)}
			>
				{children}
			</Body14>
			{icon && iconPosition === "right" && (
				<Icon
					icon={icon}
					size={iconSize}
					className={clsx(children && "mr-4 ml-auto", iconClassName)}
				/>
			)}
		</>
	);
	return {
		className: elementCls,
		child,
		elementProps: {
			...elementProps,
			disabled: isFetching || readOnly || disabled,
		},
	};
}

/* class={ */
const baseCls =
	"inline-flex align-top items-center box-border whitespace-nowrap disabled:cursor-default";

const roundedCls = "border rounded";

const variantCls: Record<ButtonVariant, string> = {
	primary: `${roundedCls} bg-grey-900 border-transparent text-white`,
	secondary: `${roundedCls} bg-white border-grey-300 text-grey-900`,
	"secondary-white": `${roundedCls} bg-transparent border-white text-whte`,
	tertiary: "text-brand-700",
	"tertiary-grey": "text-grey-700",
	"tertiary-destructive": "text-red-800",
	destructive: `${roundedCls} text-red-800 bg-white border-red-800`,
};

const hoverCls: Record<ButtonVariant, string> = {
	primary: " hover:bg-white hover:border-grey-900 hover:text-grey-900",
	secondary: "hover:bg-grey-900 hover:border-transparent hover:text-white",
	"secondary-white":
		"hover:bg-white hover:border-transparent hover:text-red-800",
	tertiary: " hover:text-brand-900",
	"tertiary-grey": "hover:text-grey-900",
	"tertiary-destructive": "hover:text-red-800",
	destructive: "hover:bg-red-800 hover:border-transparent hover:text-white",
};

const disabledBaseCls = "text-grey-500 cursor-default";
const disabledRoundedCls = `${roundedCls} ${disabledBaseCls} bg-grey-200 border-grey-500`;

const disabledCls: Record<ButtonVariant, string> = {
	primary: disabledRoundedCls,
	secondary: disabledRoundedCls,
	"secondary-white": disabledRoundedCls,
	tertiary: disabledBaseCls,
	"tertiary-grey": disabledBaseCls,
	"tertiary-destructive": disabledBaseCls,
	destructive: disabledRoundedCls,
};

function padding(
	variant: ButtonVariant,
	hasChildren: boolean,
	isTouch: boolean,
) {
	if (variant === "tertiary" || variant === "tertiary-grey") {
		return "";
	}
	if (isTouch) {
		return hasChildren ? "px-16 py-12" : "p-12";
	}
	return hasChildren ? "px-16 py-8" : "p-8";
}

/* } */

export function BackButton(props: ButtonProps) {
	const navigate = useNavigate();

	const { onClick } = props;

	return (
		<Button
			{...props}
			onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				onClick?.(event);
				navigate(-1);
			}}
		/>
	);
}

export interface ButtonGroupProps {
	className?: string;
	children?: ReactNode;
}

export function ButtonGroup({ className, children }: ButtonGroupProps) {
	return <div className={clsx(className, "flex")}>{children}</div>;
}

export interface ButtonToggleProps {
	className?: string;
	iconFalse?: IconComponent;
	iconTrue?: IconComponent;
	titleFalse?: string;
	titleTrue?: string;
	value?: boolean;
	onChange?: (value: boolean) => void;
	isTouch?: boolean;
	isSwapped?: boolean;
	disabled?: boolean;
	testid: string;
}

export function ButtonToggle({
	value,
	onChange,
	iconFalse,
	iconTrue,
	titleFalse,
	titleTrue,
	className,
	isTouch,
	isSwapped,
	disabled,
	testid,
}: ButtonToggleProps) {
	// helper variable, just makes it easier to read the code
	const isFalseActive = !value;

	const trueProps: ButtonProps = {
		variant: isFalseActive ? "primary" : "secondary",
		readOnly: isFalseActive && !disabled,
		icon: iconFalse,
		title: titleFalse,
		onClick: () => onChange?.(false),
		testid: `${testid}-true`,
	};

	const falseProps: ButtonProps = {
		variant: !isFalseActive ? "primary" : "secondary",
		readOnly: !isFalseActive && !disabled,
		icon: iconTrue,
		title: titleTrue,
		onClick: () => onChange?.(true),
		testid: `${testid}-false`,
	};

	return (
		<ButtonGroup className={className}>
			<Button
				{...(isSwapped ? falseProps : trueProps)}
				pointedBorderR
				disabledHoverStyles
				disabled={disabled}
				isTouch={isTouch}
			/>
			<Button
				{...(isSwapped ? trueProps : falseProps)}
				pointedBorderL
				disabledHoverStyles
				disabled={disabled}
				isTouch={isTouch}
			/>
		</ButtonGroup>
	);
}
