import { LoaderDark } from "@app/icons/components";
import { Icon } from "@app/icons/Icon";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

export interface SavingIndicatorProps {
	className?: string;
	isFetching?: boolean;
	delay?: number;
}

function FetchingIndicator({
	className,
	isFetching,
	delay,
}: SavingIndicatorProps) {
	const { isIndicatorVisible } = useDelayedFetchingIndicator(isFetching, delay);

	if (!isIndicatorVisible) {
		return null;
	}

	return (
		<Icon
			data-testid="fetching-indicator"
			icon={LoaderDark}
			size="24"
			className={clsx("animate-spin", className)}
		/>
	);
}

const VISIBILITY_DELAY_IN_MS = 500;

export function useDelayedFetchingIndicator(
	isFetching?: boolean,
	delay: number = VISIBILITY_DELAY_IN_MS,
) {
	const [isVisible, setisVisible] = useState(false);
	const delayRef = useRef(delay);
	delayRef.current = delay;

	useEffect(() => {
		if (isFetching) {
			const timeoutId = setTimeout(() => {
				setisVisible(true);
			}, delayRef.current);

			return () => {
				clearTimeout(timeoutId);
			};
		}

		setisVisible(false);
		return () => {};
	}, [isFetching]);

	return { isIndicatorVisible: isVisible };
}

export { FetchingIndicator };
