import type { HotkeyAction } from "@app/modules/hotkey/actions";
import { actions, getIsHoldAction } from "@app/modules/hotkey/actions";
import hotkeys from "hotkeys-js";
import { useEffect, useRef } from "react";

// looks strange, but this is the official way of changing the filter behavior
hotkeys.filter = (event: KeyboardEvent) => {
	const { tagName } = event.target as HTMLElement;
	const isSupportedField = !["INPUT", "SELECT", "TEXTAREA"].includes(tagName);
	// we allow events that use some modifier keys even when the focus is on an unsupported field
	const isUsingModifier = event.altKey;
	return isSupportedField || isUsingModifier;
};

const DEFAULT_HOLD_DURATION_IN_MS = 1000;

export interface HotkeyOptions {
	preventDefault?: boolean;
}

export type HotkeyHandler = (event: KeyboardEvent) => void;

export function useHotkey(
	action: HotkeyAction,
	handler: HotkeyHandler,
	{ preventDefault = true }: HotkeyOptions = {},
) {
	const handlerRef = useRef(handler);
	handlerRef.current = handler;

	const keydownTimestampRef = useRef<number | null>(null);
	const hasBeenPressed = useRef(false);

	const holdDurationInMs = getIsHoldAction(action)
		? DEFAULT_HOLD_DURATION_IN_MS
		: 0;

	useEffect(() => {
		hotkeys(actions[action], { keyup: true }, (event, _handler) => {
			if (preventDefault) {
				event.preventDefault();
			}

			if (event.type === "keydown" && keydownTimestampRef.current === null) {
				keydownTimestampRef.current = Date.now();
			}

			if (
				!hasBeenPressed.current &&
				keydownTimestampRef.current &&
				keydownTimestampRef.current + holdDurationInMs <= Date.now()
			) {
				hasBeenPressed.current = true;
				// if necessary we can either expose "_handler" or add additional code here
				handlerRef.current(event);
			}

			if (event.type === "keyup") {
				keydownTimestampRef.current = null;
				hasBeenPressed.current = false;
			}
		});

		return () => {
			hotkeys.unbind(actions[action]);
		};
	}, [action, holdDurationInMs, preventDefault]);
}
