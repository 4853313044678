import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTrash({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M10.624 8.964a1 1 0 10-1.998.072l.375 9.5a1 1 0 001.998-.072l-.375-9.5zM14.41 8a1 1 0 01.964 1.036l-.375 9.5a1 1 0 01-1.998-.072l.375-9.5a1 1 0 011.035-.963z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5 2A2.5 2.5 0 008 4.5V5H3.75a1 1 0 000 2h.559l.857 13.151A2.5 2.5 0 007.662 22.5h8.676a2.5 2.5 0 002.496-2.349L19.69 7h.559a1 1 0 100-2H16v-.5A2.5 2.5 0 0013.5 2h-3zM14 5v-.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V5h4zM6.312 7h11.376l-.85 13.03a.5.5 0 01-.5.47H7.662a.5.5 0 01-.5-.47L6.313 7z"
			/>
		</svg>
	);
}

const MemoSvgTrash = React.memo(SvgTrash);
export default MemoSvgTrash;
