import { Note } from "@app/icons/components";
import { Trash } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import { Customer_Order_States_Enum } from "@app/modules/graphql-api-types.generated";
import { useTranslate } from "@app/modules/i18n/context";
import { useI18nValueGetter } from "@app/modules/i18n/i18n-utils";
import { Textarea } from "@app/modules/input-fields/Textarea";
import {
	Table,
	TableCell,
	TableHead,
	TableRow,
} from "@app/modules/layout/Table";
import { Money } from "@app/modules/money/Money";
import { ScaledNumber, stimes } from "@app/modules/number/scaled-number";
import type { OfferDetailsQuery } from "@app/modules/offer/queries/offer-details.query.generated";
import type { OfferValues } from "@app/modules/offer/schema";
import {
	asCanonicalQuantity,
	asNumericQuantity,
	formatNumericQuantity,
	formatUnitWithSlash,
} from "@app/modules/product-unit/product-unit-utils";
import {
	ProductQuantity,
	ProductQuantityInputLegacy,
} from "@app/modules/product-unit/ProductQuantityInputLegacy";
import React, { useState } from "react";
import type { FieldArrayWithId } from "react-hook-form";
import {
	Controller,
	useFieldArray,
	useFormContext,
	useWatch,
} from "react-hook-form";

type Offer = ElementType<OfferDetailsQuery["customerOrders"]>;

type OfferItem = ElementType<
	ElementType<OfferDetailsQuery["customerOrders"]>["items"]
>;

const { OfferAccepted, OfferRejected } = Customer_Order_States_Enum;

export interface OfferItemListProps {
	offer: Offer;
	refreshOffer: () => void;
}

export function OfferItemList({ offer, refreshOffer }: OfferItemListProps) {
	const t = useTranslate("common");

	// TODO: Handle this with the authorization system
	const readOnly = [OfferAccepted, OfferRejected].includes(offer.state);

	const { control } = useFormContext<OfferValues>();
	const { fields } = useFieldArray({ name: "items", control });

	const items = offer.items.reduce<Record<string, OfferItem>>(
		(result, item) => ({
			...result,
			[item.id]: item,
		}),
		{},
	);

	const watched = useWatch({ name: "items", control }) || [];

	const total = watched.reduce((sum, field) => {
		const item = items[field._id ?? ""];

		if (!item) {
			return sum;
		}

		const price = getOfferItemPrice(
			field.orderQuantity || item.offeredQuantity || "",
			item.salesPricePerUnit || "",
		);

		return sum.plus(price);
	}, new ScaledNumber(0));

	const showPrice = !offer.isInternal;

	return (
		<Table className="md:pt-24" isInsideSection={false}>
			<TableRow className="grid-cols-16 gap-16 hidden md:grid">
				<TableHead className="col-span-1">ID</TableHead>
				<TableHead className="col-span-3">
					{t("public.offers.table.head.product")}
				</TableHead>
				<TableHead className="col-span-2">
					{t("public.offers.table.head.offered-quantity")}
				</TableHead>
				<TableHead className="col-span-2">
					{t("public.offers.table.head.order-quantity")}
				</TableHead>
				<TableHead className="col-span-2">
					{showPrice && t("public.offers.table.head.unit-price")}
				</TableHead>
				<TableHead className="col-span-2">
					{t("public.offers.table.head.unit-quantity")}
				</TableHead>
				<TableHead className="col-span-2 text-right">
					{showPrice && t("public.offers.table.head.price")}
				</TableHead>
				<TableHead className="col-span-1" />
			</TableRow>

			{fields.map((field, index) => {
				const item = items[field._id ?? ""];

				if (!item) {
					return null;
				}

				return (
					<OfferItemListEntry
						key={field.id}
						field={field}
						item={item}
						index={index}
						readOnly={readOnly}
						refreshOffer={refreshOffer}
						showPrice={showPrice}
					/>
				);
			})}

			<TableRow className="grid-cols-16 gap-16">
				<TableCell className="col-span-8 md:col-span-6">
					{showPrice && (
						<div className="text-header-16 font-600">
							{t("public.offers.total-without-vat")}
						</div>
					)}
					<div className="text-body-14">
						{offer.items.length}{" "}
						{t("public.offers.positions", { smart_count: offer.items.length })}
					</div>
				</TableCell>

				{showPrice && (
					<TableCell className="col-span-8 text-right">
						<div className="text-header-16 font-600">
							<Money currency={offer.currency} value={total.toString()} />
						</div>
					</TableCell>
				)}
			</TableRow>
		</Table>
	);
}

interface OfferItemListEntryProps {
	item: OfferItem;
	field: FieldArrayWithId<OfferValues, "items">;
	index: number;
	readOnly: boolean;
	refreshOffer: () => void;
	showPrice: boolean;
}

function OfferItemListEntry({
	item,
	field,
	index,
	readOnly,
	showPrice,
}: OfferItemListEntryProps) {
	const t = useTranslate("common");
	const getI18nValue = useI18nValueGetter();

	const [isNoteVisible, setIsNoteVisible] = useState(false);

	const { control, register, setValue } = useFormContext<OfferValues>();

	const prefix = `items.${index}` as const;
	const quantityFieldName = `${prefix}.orderQuantity` as const;
	const deletedFieldName = `${prefix}.deleted` as const;

	const currentOrderQuantity = useWatch({
		control,
		name: quantityFieldName,
	});

	const isDeleted = useWatch({
		control,
		name: deletedFieldName,
	});

	const salesQuantity =
		currentOrderQuantity && item.orderUnitToSalesUnitFactor
			? asCanonicalQuantity(
					formatNumericQuantity(
						asNumericQuantity(currentOrderQuantity).multipliedBy(
							item.orderUnitToSalesUnitFactor,
						),
					),
			  )
			: "";

	const price = getOfferItemPrice(
		currentOrderQuantity || item.offeredQuantity || "",
		item.salesPricePerUnit || "",
	);

	const handleDelete = async () => {
		setValue(quantityFieldName, "0");
		setValue(deletedFieldName, true);
	};

	if (isDeleted) {
		return null;
	}

	return (
		<TableRow key={item.id} className="grid-cols-16 gap-x-16">
			<TableCell className="col-span-3 md:col-span-1" height="xs">
				{item.product.domainId}
			</TableCell>
			<TableCell className="col-span-10 md:col-span-3" isStrong height="xs">
				{getI18nValue(item.product.i18n?.name) || item.product.name}
			</TableCell>

			<TableCell className="col-span-3 block md:hidden text-right" height="xs">
				{!readOnly && (
					<button type="button" onClick={handleDelete}>
						<Icon icon={Trash} size="24" className="text-red-800" />
					</button>
				)}
			</TableCell>

			<TableCell className="col-span-3 md:col-span-2" height="xs">
				<ProductQuantity
					value={item.offeredQuantity || ""}
					unit={item.orderUnit}
				/>
			</TableCell>
			<TableCell className="col-span-10 md:col-span-2" height="xs">
				<Controller
					name={quantityFieldName}
					control={control}
					defaultValue={field.orderQuantity}
					render={({ field: f }) => (
						<ProductQuantityInputLegacy
							disabled={readOnly}
							unit={item.orderUnit}
							{...f}
						/>
					)}
				/>
			</TableCell>
			<TableCell
				className="block md:hidden col-span-3 md:col-span-1 text-right"
				height="xs"
			>
				<button
					type="button"
					onClick={() => {
						setIsNoteVisible(!isNoteVisible);
					}}
				>
					<Icon icon={Note} size="24" />
				</button>
			</TableCell>

			<TableCell className="col-span-3 md:col-span-2 md:text-left" height="xs">
				{showPrice && (
					<Money
						currency={item.currency}
						value={item.salesPricePerUnit || ""}
						unit={formatUnitWithSlash(t, item.salesUnit)}
					/>
				)}
			</TableCell>

			<TableCell className="col-span-10 md:col-span-2" height="xs">
				<ProductQuantity value={salesQuantity} unit={item.salesUnit} />
			</TableCell>
			<TableCell
				className="col-span-3 md:col-span-2 text-right"
				isStrong
				height="xs"
			>
				{showPrice && <Money currency={item.currency} value={price || ""} />}
			</TableCell>

			<TableCell
				className="hidden md:block col-span-4 md:col-span-1 text-right"
				height="xs"
			>
				<button
					type="button"
					onClick={() => {
						setIsNoteVisible(!isNoteVisible);
					}}
					className="md:py-4"
				>
					<Icon icon={Note} size="24" />
				</button>
			</TableCell>

			<TableCell
				className="hidden md:block md:col-span-1 text-right"
				height="xs"
			>
				{!readOnly && (
					<button type="button" className="md:py-4" onClick={handleDelete}>
						<Icon icon={Trash} size="24" className="text-red-800" />
					</button>
				)}
			</TableCell>

			{isNoteVisible && (
				<TableCell className="pt-4 col-span-16">
					<Textarea
						placeholder={t("public.offers.form.customer-note.placeholder")}
						{...register(`${prefix}.customerNote`)}
						disabled={readOnly}
					/>
				</TableCell>
			)}
		</TableRow>
	);
}

function getOfferItemPrice(quantity = "", unitPrice = "") {
	if (quantity === "" || unitPrice === "") {
		return "";
	}

	return stimes(quantity, unitPrice).toString();
}
