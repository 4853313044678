import { useTenantFilter } from "@app/modules/entity/entity-utils";
import { useQuery } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import type { TranslateFunction } from "@app/modules/i18n/types";
import { ScaledNumber } from "@app/modules/number/scaled-number";
import { ListContainerPoolsDocument } from "@app/modules/product-group/query/list-container-pools.query.generated";
import type { SearchIconName } from "@app/modules/search/search-icons";

export enum ProductPricingType {
	Recipe = "RECIPE",
	Commodity = "COMMODITY",
	Dismantling = "DISMANTLING",
}

export const pricingTypeIcons: Record<ProductPricingType, SearchIconName> = {
	COMMODITY: "article",
	RECIPE: "product-recipe",
	DISMANTLING: "product-dismantled",
};

export function getPricingTypeIcon(
	pricingType?: string | ProductPricingType | null,
): SearchIconName {
	return (
		pricingTypeIcons[pricingType as ProductPricingType] ??
		pricingTypeIcons.COMMODITY
	);
}

function translateContainerPool(t: TranslateFunction, pool: string) {
	return t(`product-groups.CONTAINER_POOL.${pool}`);
}

export function useContainerPools() {
	const t = useTranslate("common");
	const [res] = useQuery({
		query: ListContainerPoolsDocument,
		variables: {
			where: useTenantFilter(),
		},
	});

	return (
		res.data?.productGroups.map((value) => ({
			id: value.id,
			name: value.name,
			label: translateContainerPool(t, value.name),
		})) ?? []
	);
}

export function apiToFormCostPrice(
	costPrice: string,
	dnaTestPrice: string | undefined | null,
) {
	return new ScaledNumber(costPrice).minus(dnaTestPrice || "0").toString();
}

export function formToApiCostPrice(
	costPrice: string,
	dnaTestPrice: string | undefined | null,
) {
	return new ScaledNumber(costPrice).plus(dnaTestPrice || "0").toString();
}
