import type { InstructionSet } from "@app/modules/hardware/types";

export interface SerialDeviceConfiguration {
	manufacturer: string;
	product: string;
	instructionSet: InstructionSet;
	options: SerialOptions;
}

const configurations: Record<string, SerialDeviceConfiguration> = {
	// our pesola scale
	pesola: {
		manufacturer: "Pesola",
		product: "PFS30K",
		instructionSet: "pesola-tmp",
		options: {
			baudRate: 9600,
		},
	},
	// our mettler toledo scale
	"mettler-toledo-ics425": {
		manufacturer: "METTLER TOLEDO",
		product: "ICS425",
		instructionSet: "SICS",
		options: {
			dataBits: 8,
			baudRate: 9600,
			parity: "none",
		},
	},
	// one of the scales used by FF
	"mettler-toledo-ics429": {
		manufacturer: "METTLER TOLEDO",
		product: "ICS429",
		instructionSet: "SICS",
		options: {
			dataBits: 8,
			baudRate: 9600,
			parity: "none",
			flowControl: "none",
		},
	},
	// also used by FF
	"mettler-toledo-ind690": {
		manufacturer: "METTLER TOLEDO",
		product: "IND690",
		instructionSet: "SICS",
		options: {
			dataBits: 8,
			baudRate: 9600,
			parity: "none",
		},
	},
	// generic Busch scale that uses SysTec
	"busch-scale-systec": {
		manufacturer: "Busch",
		product: "Generic Scale (SysTec)",
		instructionSet: "SysTec",
		options: {
			baudRate: 9600,
			dataBits: 8,
			stopBits: 1,
			parity: "none",
			flowControl: "none",
		},
	},
	"bizerba-st": {
		manufacturer: "Bizerba",
		product: "Generic Scale (ST)",
		instructionSet: "bizerbast",
		options: {
			baudRate: 9600,
			dataBits: 8,
			parity: "none",
			flowControl: "none",
		},
	},
};

export function getSerialConfiguration(productKey: string) {
	return configurations[productKey];
}

const availableDevices = Object.entries(configurations).map(
	([key, config]) => ({
		productKey: key,
		manufacturer: config.manufacturer,
		product: config.product,
	}),
);

export function getSupportedSerialDevices() {
	return availableDevices;
}
