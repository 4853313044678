import clsx from "clsx";

export interface AvatarProps {
	name: string | undefined;
	size?: "small" | "medium";
}

export function Avatar({ name = "", size = "medium" }: AvatarProps) {
	const initials = name
		.split(" ")
		.slice(0, 3)
		.map((word) => word[0])
		.join("");

	return (
		<div
			className={clsx(
				"bg-brand-200 rounded-full",
				"flex justify-center items-center",
				size === "small" && "w-32 h-32",
				size === "medium" && "w-48 h-48",
			)}
		>
			<span>{initials}</span>
		</div>
	);
}
