import { useQuery } from "@app/modules/graphql/queries";
import { organizeById } from "@app/modules/utils/organizeBy";
import type { WorkstationsQuery } from "@app/modules/workstations/queries/workstations.query.generated";
import { WorkstationsDocument } from "@app/modules/workstations/queries/workstations.query.generated";
import { useMemo } from "react";

type WorkstationData = WorkstationsQuery["workstations"][number];

export function useWorkstations() {
	const [res] = useQuery({
		query: WorkstationsDocument,
		requestPolicy: "cache-first",
		variables: {},
	});

	return useMemo(
		() => ({
			list: res.data?.workstations ?? [],
			byId: organizeById(res.data?.workstations ?? []),
		}),
		[res.data?.workstations],
	);
}

export function useWorkstation(id: string): WorkstationData | undefined {
	// no need for a separate query as we have all the workstations cached locally anyways
	return useWorkstations().byId[id];
}
