export type LogLevel = "DEBUG" | "INFO" | "WARN" | "ERROR";

/* eslint-disable no-console */
export function logToConsole(level: LogLevel, ...data: unknown[]) {
	switch (level) {
		case "INFO":
			console.info(...data);
			break;
		case "WARN":
			console.warn(...data);
			break;
		case "ERROR":
			console.error(...data);
			break;
		case "DEBUG":
		default:
			console.log(...data);
			break;
	}
}
/* eslint-enable no-console */
