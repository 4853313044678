import type { AppState as _AppState } from "@app/modules/app-state/app-state";
import { defaultAppState as _defaultAppState } from "@app/modules/app-state/app-state";

export type { Action } from "@app/modules/app-state/app-state";
export { reducer } from "@app/modules/app-state/app-state";

export interface AppState extends _AppState {
	customerOrderToken?: string;
}

export const defaultAppState: AppState = {
	..._defaultAppState,
	customerOrderToken: undefined,
};

// Checks if the app state has enough information for the application to be rendered
export function isReady({ areTranslationsLoaded }: AppState) {
	return areTranslationsLoaded;
}
