import { AlertSolid } from "@app/icons/components";
import { Close } from "@app/icons/components/action";
import { Button } from "@app/modules/Button";
import { useVersionCounter } from "@app/modules/form/form-utils";
import { Customer_Order_States_Enum } from "@app/modules/graphql-api-types.generated";
import {
	useMutation,
	usePublicSubscription,
} from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import { useNotificationDispatch } from "@app/modules/notification/context";
import { useNotificationCreate } from "@app/modules/notification/notification-creators";
import { AcceptOfferDocument } from "@app/modules/offer/queries/accept-offer.mutation.generated";
import type { OfferDetailsQuery } from "@app/modules/offer/queries/offer-details.query.generated";
import { OfferDocument } from "@app/modules/offer/queries/offer.subscription.generated";
import { RejectOfferDocument } from "@app/modules/offer/queries/reject-offer.mutation.generated";
import type { OfferValues } from "@app/modules/offer/schema";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

const { OfferAccepted, OfferRejected } = Customer_Order_States_Enum;

interface OfferActionProps {
	offer: OfferDetailsQuery["customerOrders"][number];
	refreshOffer: () => void;
}

export function OfferActions({ offer, refreshOffer }: OfferActionProps) {
	const t = useTranslate("common");

	const isInitialRender = useRef(false);

	const { version, incrementVersion, decrementVersion } = useVersionCounter(
		offer.version,
	);

	usePublicSubscription({ query: OfferDocument, variables: {} });

	const [{ fetching: isAcceptFetching }, acceptOffer] =
		useMutation(AcceptOfferDocument);

	const [{ fetching: isRejectFetching }, rejectOffer] =
		useMutation(RejectOfferDocument);

	const { createTryAgainToast } = useNotificationCreate();
	const { dispatchNotification } = useNotificationDispatch();

	const { handleSubmit, reset } = useFormContext<OfferValues>();

	useEffect(() => {
		if (!isInitialRender.current && Number(offer.version) > version.current) {
			reset({
				items: offer.items.map((item) => ({
					_id: item.id,
					orderQuantity: item.orderQuantity,
					customerNote: item.customerNote,
				})),
			});

			dispatchNotification({
				type: "toast",
				variant: "success",
				text: t("public.offers.notifications.offer-updated"),
			});

			return;
		}

		isInitialRender.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offer.version]);

	if ([OfferAccepted, OfferRejected].includes(offer.state)) {
		return null;
	}

	const handleAccept = handleSubmit(async (values) => {
		const items = values.items.map((item) => ({
			id: item._id,
			order_quantity: item.orderQuantity,
			customer_note: item.customerNote,
		}));

		incrementVersion();

		const { error } = await acceptOffer({
			id: offer.id,
			items,
			deliveryDate: values.deliveryDate,
		});

		if (error) {
			decrementVersion();
			dispatchNotification(createTryAgainToast());
			return;
		}

		refreshOffer();
	});

	const handleReject = handleSubmit(async (values) => {
		const items = values.items.map((item) => ({
			id: item._id,
			order_quantity: item.orderQuantity,
			customer_note: item.customerNote,
		}));

		incrementVersion();

		const { error } = await rejectOffer({
			id: offer.id,
			items,
			deliveryDate: values.deliveryDate,
		});

		if (error) {
			decrementVersion();
			dispatchNotification(createTryAgainToast());
			return;
		}

		refreshOffer();
	});

	return (
		<div className="fixed bottom-0 w-screen bg-white shadow-card text-right">
			<div className="block sm:flex justify-end py-8 m-auto w-11/12 md:w-5/6">
				{!offer.isOfferExpired && (
					<>
						<Button
							variant="secondary"
							className="my-8"
							icon={Close}
							isFetching={isRejectFetching}
							onClick={handleReject}
						>
							{t("public.offers.reject-offer")}
						</Button>
						<Button
							variant="primary"
							className="bg-red-vibrant my-8 ml-8 "
							isFetching={isAcceptFetching}
							onClick={handleAccept}
						>
							{t("public.offers.accept-offer")}
						</Button>
					</>
				)}
				{offer.isOfferExpired && (
					<Button
						variant="primary"
						className="bg-red-vibrant my-8 ml-8 "
						isFetching={isAcceptFetching}
						onClick={handleAccept}
						icon={AlertSolid}
						title={t("public.offers.offer-expired")}
					>
						{t("public.offers.accept-expired-offer")}
					</Button>
				)}
			</div>
			{offer.isOfferExpired && (
				<p className="text-caption-10 sm:flex justify-end py-8 m-auto w-11/12 md:w-5/6">
					{t("public.offers.expired-conditions")}
				</p>
			)}
		</div>
	);
}
