export type Role =
	| "tenant_admin"
	| "backoffice_admin"
	| "accountant"
	| "dismantling_planner"
	| "purchaser"
	| "sales";

const roleOrders: Record<Role, number> = {
	tenant_admin: 100,
	backoffice_admin: 90,
	dismantling_planner: 30,
	purchaser: 30,
	sales: 20,
	accountant: 10,
};

export function sortRoles(roles: Role[]): Role[] {
	return roles.sort((a, b) => roleOrders[b] - roleOrders[a]);
}
