import type { DependencyList, EffectCallback } from "react";
import { useEffect, useRef } from "react";

/** Works like `useEffect` but will NOT run on initial render. */
export function useUpdateEffect(
	effect: EffectCallback,
	deps: DependencyList = [],
) {
	const isInitialRender = useRef(true);

	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
		} else {
			return effect();
		}
		return undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps]);
}
