import type { UpdatesConfig } from "@urql/exchange-graphcache";
import { updates as _updates } from "@app/modules/graphql/updates";

const { deleteCustomerOrderItemByPk } = _updates.Mutation;

export const updates: UpdatesConfig = {
	Mutation: {
		...(deleteCustomerOrderItemByPk ? { deleteCustomerOrderItemByPk } : {}),
	},
	Subscription: {},
};
