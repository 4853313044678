import type {
	HardwareInstruction,
	ParsedMessage,
} from "@app/modules/hardware/types";

const TERMINATION_STRING = String.fromCharCode(13);

function parse(instr: HardwareInstruction): ParsedMessage {
	return {
		type: "barcode",
		code: instr,
	};
}

export const datalogic = {
	parse,
	TERMINATION_STRING,
};
