import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgProduktRaw({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5 2.711a3 3 0 013 0l5.794 3.346a3 3 0 011.5 2.598v6.69a3 3 0 01-1.5 2.598L13.5 21.29a3 3 0 01-3 0l-5.794-3.346a3 3 0 01-1.5-2.598v-6.69a3 3 0 011.5-2.598L10.5 2.71zm2 1.732a1 1 0 00-1 0L5.706 7.79a1 1 0 00-.5.866v6.69a1 1 0 00.5.866l5.794 3.346a1 1 0 001 0l5.794-3.346a1 1 0 00.5-.866v-6.69a1 1 0 00-.5-.866L12.5 4.443z"
			/>
		</svg>
	);
}

const MemoSvgProduktRaw = React.memo(SvgProduktRaw);
export default MemoSvgProduktRaw;
