import { Card } from "@app/modules/cards/Card";
import type { CustomerProductHistoryCardProps } from "@app/modules/cards/card-props";
import { getProductHistoryCardId } from "@app/modules/cards/card-utils";
import { MINIMUM_COI_DATE } from "@app/modules/cards/CustomerProductsCard";
import type { CustomerProductHistoryCardQuery } from "@app/modules/cards/queries/customer-product-history-card.query.generated";
import { CustomerProductHistoryCardDocument } from "@app/modules/cards/queries/customer-product-history-card.query.generated";
import {
	DataTable,
	DataTableCell,
	DataTableRow,
} from "@app/modules/data-table/DataTable";
import { DataTablePagination } from "@app/modules/data-table/DataTablePagination";
import { useDataTable } from "@app/modules/data-table/useDataTable";
import { useTranslate } from "@app/modules/i18n/context";
import { useI18nValueGetter } from "@app/modules/i18n/i18n-utils";
import { getLocalizedDateString } from "@app/modules/localdate/localdate";
import { Money } from "@app/modules/money/Money";
import { BigNumber } from "@app/modules/number/big-number";
import { ProductQuantity } from "@app/modules/product-unit/ProductQuantityInputLegacy";

export function CustomerProductHistoryCard({
	customerId,
	customerOrderId,
	productId,
}: CustomerProductHistoryCardProps) {
	const t = useTranslate("common");

	const getI18nValue = useI18nValueGetter();

	const { pagination, ...dataTable } = useDataTable({
		name: getProductHistoryCardId(productId),
		pageSize: 10,
		query: CustomerProductHistoryCardDocument,
		queryVariables: {
			where: {
				createdAt: {
					_gte: MINIMUM_COI_DATE,
				},
				productId: { _eq: productId },
				customerOrder: {
					id: { _neq: customerOrderId },
					customerId: { _eq: customerId },
				},
			},
		},
		hasSort: true,
		initialSort: [
			{ accessor: "customerOrder.deliveryDate", direction: "desc" },
		],
		columns: [
			{
				accessor: "customerOrder.deliveryDate",
				label: t("cards.customer-product-history.table.head.delivery-date"),
				sortable: true,
			},
			{
				accessor: "orderQuantity",
				align: "right",
				label: t("cards.customer-product-history.table.head.order-quantity"),
				sortable: true,
			},
			{
				accessor: "customerOrderItemExtra.finalInvoiceWeight",
				align: "right",
				label: t("cards.customer-product-history.table.head.invoiced-weight"),
				sortable: true,
			},
			{
				accessor: "salesPricePerUnit",
				align: "right",
				label: t("cards.customer-product-history.table.head.sales-price"),
				sortable: true,
			},
			{
				accessor: "customerOrderItemExtra.marginPercentage",
				align: "right",
				label: t("cards.customer-product-history.table.head.percentage-margin"),
				sortable: true,
			},
		] as const,
	});

	if (dataTable.visibleRows.length < 1) {
		return null;
	}

	return (
		<Card cardId={getProductHistoryCardId(productId)} showRemoveButton>
			<Card.Header>
				<Card.Title>
					{getI18nValue(dataTable?.visibleRows?.[0]?.product?.i18n?.name)}
				</Card.Title>
				{pagination && (
					<div className="ml-auto">
						<DataTablePagination
							className="border-none pr-0 pb-4"
							totalRows={dataTable.totalRows}
							{...pagination}
						/>
					</div>
				)}
			</Card.Header>
			<Card.Body>
				<DataTable
					instance={dataTable}
					variant="compact"
					className="border-t border-grey-200"
				>
					{dataTable.visibleRows.map((coi) => (
						<Entry key={coi.id} coi={coi} />
					))}
				</DataTable>
			</Card.Body>
		</Card>
	);
}

interface EntryProps {
	coi: CustomerProductHistoryCardQuery["entities"][number];
}
function Entry({ coi }: EntryProps) {
	return (
		<DataTableRow>
			<DataTableCell>
				{coi.customerOrder?.deliveryDate &&
					getLocalizedDateString(coi.customerOrder.deliveryDate, {
						includeDayOfWeek: false,
					})}
			</DataTableCell>
			<DataTableCell className="text-right">
				<ProductQuantity
					value={coi.orderQuantity}
					unit={coi.orderUnit}
					significantDigits={0}
				/>
			</DataTableCell>
			<DataTableCell className="text-right">
				{coi.customerOrderItemExtra?.finalInvoiceWeight && (
					<ProductQuantity
						value={coi.customerOrderItemExtra.finalInvoiceWeight}
						unit={coi.salesUnit}
						significantDigits={0}
					/>
				)}
			</DataTableCell>
			<DataTableCell className="text-right">
				{coi?.salesPricePerUnit && (
					<Money value={coi.salesPricePerUnit} currency={coi.currency} />
				)}
			</DataTableCell>
			<DataTableCell className="text-right">
				{coi.customerOrderItemExtra?.marginPercentage &&
					toPercentageString(
						new BigNumber(coi.customerOrderItemExtra?.marginPercentage),
					)}
			</DataTableCell>
		</DataTableRow>
	);
}

// We have a few percentage conversion utils by now and each one seems to work slightly differently for its use case.
// TODO: collect and try to unify them
function toPercentageString(factor: BigNumber) {
	const percentageString =
		factor && !factor.isNaN() ? `${factor.times(100).toFixed(1)} %` : "- %";

	return percentageString;
}
