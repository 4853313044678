import { logger } from "@app/modules/logger/logger";
import type { ReactNode, SetStateAction } from "react";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

function set<
	T extends string | number | boolean | Record<string, unknown> | unknown[],
>(key: string, data: T) {
	localStorage.setItem(key, JSON.stringify(data));
}

function get<T>(key: string): T | undefined {
	const rawValue = localStorage.getItem(key);
	try {
		return rawValue ? (JSON.parse(rawValue) as T) : undefined;
	} catch (error) {
		logger.warn(
			"Could not parse local storage value of",
			key,
			"Returning undefined.",
			error,
		);
		return undefined;
	}
}

function remove(key: string) {
	return localStorage.removeItem(key);
}

function clear() {
	return localStorage.clear();
}

export const storage = {
	set,
	get,
	remove,
	clear,
};

const LOCAL_STORAGE_STATE_KEY = "local-storage-state";
// We use a predefined list of keys. This will allow us remove unused keys from
// local storage more easily in the future.
const availableKeys = [
	"navigation",
	"card-drawer",
	"price-labelling.idle-form",
	"debug",
	"ui-action-panel.last-values",
	"ui-action-panel.is-open",
	"goods-income.hierarchical.history",
	"generic-goods-income.positions",
	"generic-goods-income.product-id",
	"generic-goods-income.product-id-history",
	"rolling-inventory.product-id-history",
	"customer-order.item-sorting",
	"customer-order.picking-slip.categories",
	"date-mode",
	"favorite-containers",
	"picking.label",
	"picking.order-item-sort-method",
	"dashboard.weather-card.location",
	"dashboard.sales-context-values",
	"dismantling-plan.printing.items",
] as const;
export type LocalStorageStateValueKey = (typeof availableKeys)[number];

export type LocalStorageStateValue =
	| string
	| boolean
	| Record<string, unknown>
	| unknown[]
	| undefined;

export type LocalStorageStateObject = Record<string, LocalStorageStateValue>;

export interface UseLocalStorageStateOptions<T> {
	validate?: (value?: unknown) => value is T;
}

interface NonNullableOptions<T> {
	validate: (value?: unknown) => value is T;
}

export function useLocalStorageState<T extends LocalStorageStateValue>(
	name: LocalStorageStateValueKey,
	defaultValue: T,
	{ validate = (val): val is T => true }: UseLocalStorageStateOptions<T> = {},
) {
	const { setStateObject, stateObject, getValue } = useContext(
		LocalStorageStateContext,
	);

	const defaultValueRef = useRef(defaultValue);
	defaultValueRef.current = defaultValue;

	const validateRef = useRef(validate);
	validateRef.current = validate;

	const setValue = useCallback(
		(value: SetStateAction<T | undefined>) => {
			setStateObject(name, value, defaultValueRef.current, {
				validate: validateRef.current,
			});
		},
		[setStateObject, name],
	);

	const value = getValue<T>(stateObject, name, defaultValue, { validate });
	return [value, setValue] as const;
}

interface LocalStorageStateContextValue {
	setStateObject: <T extends LocalStorageStateValue>(
		key: LocalStorageStateValueKey,
		value: SetStateAction<T | undefined>,
		defaultValue: T,
		options: NonNullableOptions<T>,
	) => void;
	stateObject: Dictionary<LocalStorageStateValueKey, LocalStorageStateValue>;
	getValue: <T extends LocalStorageStateValue>(
		state: LocalStorageStateObject,
		name: LocalStorageStateValueKey,
		defaultValue: T,
		options: NonNullableOptions<T>,
	) => T;
}

export const LocalStorageStateContext =
	createContext<LocalStorageStateContextValue>({
		setStateObject: () => {},
		stateObject: {},
		getValue: () => ({} as any),
	});

export interface LocalStorageStateProviderProps {
	children?: ReactNode;
}

export function LocalStorageStateProvider({
	children,
}: LocalStorageStateProviderProps) {
	const [stateObject, setStoredState] = useState(
		() => storage.get<LocalStorageStateObject>(LOCAL_STORAGE_STATE_KEY) ?? {},
	);

	// Keeps track of already validated keys to avoid revalidating them.
	const validationStateRef = useRef<
		Dictionary<LocalStorageStateValueKey, boolean>
	>({});

	const getValue = useCallback(
		<T extends LocalStorageStateValue>(
			state: LocalStorageStateObject,
			name: LocalStorageStateValueKey,
			defaultValue: T,
			{ validate }: NonNullableOptions<T>,
		) => {
			const stateValue = state[name] as T | undefined;

			// This validation is only necessary for values that we load from local storage.
			// Values that are updated/set via `setStateObject` are already validated.
			// This is also the reason why we don't reset the validation state anywhere.
			if (!validationStateRef.current[name] && validate(stateValue)) {
				validationStateRef.current[name] = true;
			}

			return validationStateRef.current[name]
				? stateValue ?? defaultValue
				: defaultValue;
		},
		[],
	);

	const setStateObject = useCallback(
		<T extends LocalStorageStateValue>(
			key: LocalStorageStateValueKey,
			action: SetStateAction<T | undefined>,
			defaultValue: T,
			options: NonNullableOptions<T>,
		) => {
			setStoredState((prevState) => {
				const value =
					typeof action === "function"
						? action(getValue(prevState, key, defaultValue, options))
						: action;

				if (value !== undefined) {
					return { ...prevState, [key]: value };
				}
				const { [key]: _, ...filteredValues } = prevState;
				return filteredValues;
			});
		},
		[getValue],
	);

	useEffect(() => {
		storage.set(LOCAL_STORAGE_STATE_KEY, stateObject);
	}, [stateObject]);

	const contextValue: LocalStorageStateContextValue = useMemo(
		() => ({
			setStateObject,
			stateObject,
			getValue,
		}),
		[getValue, setStateObject, stateObject],
	);

	return (
		<LocalStorageStateContext.Provider value={contextValue}>
			{children}
		</LocalStorageStateContext.Provider>
	);
}
