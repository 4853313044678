import type { ControlledProps } from "@app/modules/form/controller";
import { useController } from "@app/modules/form/controller";
import { autoHeightCls } from "@app/modules/layout/autoheight";
import clsx from "clsx";
import type { ReactNode, TextareaHTMLAttributes } from "react";
import { forwardRef } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import type { AnyVariables } from "urql";
import { inputCls, InputError, InputLabel } from "./commons";

export interface TextareaProps
	extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	className?: string;
	inputClassName?: string;
	label?: ReactNode;
	error?: string;
	isSmall?: boolean;
	autoHeight?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	(
		{
			className,
			inputClassName,
			label,
			id,
			error,
			disabled,
			isSmall,
			autoHeight,
			...props
		},
		ref,
	) => (
		<div className={clsx(className, autoHeightCls(autoHeight))}>
			<label htmlFor={id} className={autoHeightCls(autoHeight)}>
				{label && <InputLabel className="mb-4">{label}</InputLabel>}
				<textarea
					ref={ref}
					id={id}
					className={clsx(
						"block w-full h-full rounded",
						isSmall ? "py-4 px-8" : "px-12 py-8",
						inputCls({
							error: Boolean(error),
							disabled,
						}),
						autoHeight && "grow",
						inputClassName,
					)}
					disabled={disabled}
					{...props}
				/>
			</label>
			{error && <InputError>{error}</InputError>}
		</div>
	),
);

export function TextareaField<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
>({
	control,
	name,
	defaultValue,
	constraint,
	...props
}: TextareaProps &
	ControlledProps<Values, Name, ConstraintResult, ConstraintVariables>) {
	const { field } = useController({ control, name, defaultValue, constraint });

	return <Textarea {...props} {...field} defaultValue={defaultValue} />;
}
