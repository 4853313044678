import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSuccess({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm4.728-13.207a1 1 0 00-1.414 0l-5.293 5.293-1.814-1.814a1 1 0 00-1.414 1.414l2.521 2.521a1 1 0 001.414 0l6-6a1 1 0 000-1.414z"
			/>
		</svg>
	);
}

const MemoSvgSuccess = React.memo(SvgSuccess);
export default MemoSvgSuccess;
