import type { Params } from "react-router";

export type RouteLayoutName = "desktop" | "touch" | "processing";

export const DEFAULT_ROUTE_LAYOUT: RouteLayoutName = "desktop";

export interface LayoutSettings {
	mainArea: {
		hasPadding: boolean;
	};
	header: {
		isCompact: boolean;
	};
	navigation: {
		isVisible: boolean;
	};
	cardDrawer: {
		isVisible: boolean;
	};
}

export type RouteConfig =
	| {
			path: string;
			params: Params;
			layout?: RouteLayoutName;
			hasShortcut: false;
	  }
	| {
			path: string;
			params: undefined;
			layout?: RouteLayoutName;
			hasShortcut: boolean;
	  };

export type RoutesByPath<T extends string = string> = Record<T, RouteConfig>;
