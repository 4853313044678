import clsx from "clsx";
import type { ReactNode } from "react";
import React from "react";

export interface ItemRowProps {
	className?: string;
	children?: ReactNode;
}

function HorizontalList({ className = "gap-8", children }: ItemRowProps) {
	return <div className={clsx(className, "flex items-center")}>{children}</div>;
}

export { HorizontalList };
