import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCalendarPeriod({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M7.333 11c.402 0 .667.267.667.668v.666c0 .401-.265.666-.667.666h-.666C6.265 13 6 12.735 6 12.334v-.665c0-.402.265-.669.667-.669h.666zM11 11.668c0-.4-.265-.668-.667-.668h-.666c-.402 0-.667.267-.667.668v.666c0 .401.265.666.667.666h.666c.402 0 .667-.265.667-.666v-.665zM13.333 11c.402 0 .667.267.667.668v.666c0 .401-.265.666-.667.666h-.666c-.402 0-.667-.265-.667-.666v-.665c0-.402.265-.669.667-.669h.666zM17 11.668c0-.4-.265-.668-.667-.668h-.666c-.402 0-.667.267-.667.668v.666c0 .401.265.666.667.666h.666c.402 0 .667-.265.667-.666v-.665zM7.333 14c.402 0 .667.267.667.668v.666c0 .401-.265.666-.667.666h-.666C6.265 16 6 15.735 6 15.334v-.665c0-.402.265-.669.667-.669h.666zM11 14.668c0-.4-.265-.668-.667-.668h-.666c-.402 0-.667.267-.667.668v.666c0 .401.265.666.667.666h.666c.402 0 .667-.265.667-.666v-.665z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17 3a1 1 0 01.993.883L18 4v1h.167a2.833 2.833 0 012.828 2.66l.005.173v10.334a2.833 2.833 0 01-2.66 2.828l-.173.005H5.833a2.833 2.833 0 01-2.828-2.66L3 18.167V7.833a2.833 2.833 0 012.66-2.828L5.833 5H6V4a1 1 0 011.993-.117L8 4v1h8V4a1 1 0 011-1zm1.05 6.054H5.833c-.568 0-.8.289-.83.82L5 10v8.167c0 .425.318.775.728.826l.105.007h12.334a.833.833 0 00.826-.728l.007-.105V10c0-.567-.292-.896-.823-.94l-.127-.006z"
			/>
		</svg>
	);
}

const MemoSvgCalendarPeriod = React.memo(SvgCalendarPeriod);
export default MemoSvgCalendarPeriod;
