import {
	Close,
	DropdownClosed,
	DropdownOpen,
	Save,
} from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { DataTableVariant } from "@app/modules/data-table/data-table-utils";
import {
	DataTableContext,
	DataTableRenderContext,
	useDataTableContext,
	useDataTableRenderContext,
} from "@app/modules/data-table/data-table-utils";
import {
	DataTableActionButton,
	DataTableActionsCell,
} from "@app/modules/data-table/DataTableActionsCell";
import { DataTablePagination } from "@app/modules/data-table/DataTablePagination";
import type { DataTableInstance } from "@app/modules/data-table/useDataTable";
import { HiddenSubmitButton } from "@app/modules/form/HiddenSubmitButton";
import { useTranslate } from "@app/modules/i18n/context";
import type { RadioCheckboxProps } from "@app/modules/input-fields/RadioCheckbox";
import { RadioCheckbox } from "@app/modules/input-fields/RadioCheckbox";
import clsx from "clsx";
import type {
	FormHTMLAttributes,
	ReactNode,
	TdHTMLAttributes,
	ThHTMLAttributes,
} from "react";
import { forwardRef, useContext, useMemo } from "react";

export interface DataTableProps {
	className?: string;
	children: ReactNode;
	instance: DataTableInstance;
	variant?: DataTableVariant;
	extraButtons?: ReactNode;
}

export function DataTable({
	className,
	instance,
	children,
	variant = "regular",
	extraButtons,
}: DataTableProps) {
	const {
		contextValue,
		hasRowSelect,
		hasSort,
		columns,
		pagination,
		totalRows,
	} = instance;

	const renderValue = useMemo(
		() => ({
			variant,
		}),
		[variant],
	);

	return (
		<DataTableRenderContext.Provider value={renderValue}>
			<DataTableContext.Provider value={contextValue}>
				<div className={clsx(className, "flex flex-col")}>
					{pagination && (
						<DataTablePagination
							{...pagination}
							totalRows={totalRows}
							extraButtons={extraButtons}
						/>
					)}
					<table className="relative w-full h-full">
						<thead>
							<tr>
								{hasRowSelect && (
									<DataTableHeadCell>
										<DataTableCheckbox
											type="checkbox"
											{...instance.getToggleAllPageRowsSelectedProps()}
											labelCls="ml-4"
										/>
									</DataTableHeadCell>
								)}
								{columns.map(
									(
										{
											accessor,
											label,
											sortable = true,
											align,
											width,
											className: tdCls,
											hidden = false,
										},
										index,
									) => {
										if (hidden) {
											return null;
										}
										const hasColumnSort = hasSort && accessor;
										const sortProps = hasColumnSort
											? instance.getSortToggleProps(
													accessor,
													hasSort && sortable,
											  )
											: undefined;
										return (
											<DataTableHeadCell
												key={accessor || index}
												align={align}
												className={clsx(
													hasSort && sortable && "cursor-pointer",
													width,
													tdCls,
												)}
												{...sortProps}
											>
												{label}
												{hasColumnSort && (
													<SortDirectionIndicator accessor={accessor} />
												)}
											</DataTableHeadCell>
										);
									},
								)}
							</tr>
						</thead>
						<tbody>{children}</tbody>
					</table>
				</div>
			</DataTableContext.Provider>
		</DataTableRenderContext.Provider>
	);
}

export interface DumbDataTableProps {
	className?: string;
	children: ReactNode;
	variant?: DataTableVariant;
}

export function DumbDataTable({
	className,
	children,
	variant = "regular",
}: DumbDataTableProps) {
	const renderValue = useMemo(
		() => ({
			variant,
		}),
		[variant],
	);
	return (
		<DataTableRenderContext.Provider value={renderValue}>
			<div className={clsx(className, "")}>
				<table className="relative w-full h-full">{children}</table>
			</div>
		</DataTableRenderContext.Provider>
	);
}

export interface DataTableRowProps {
	children?: ReactNode;
	className?: string;
}

export function DataTableRow({ children, className }: DataTableRowProps) {
	const { variant } = useDataTableRenderContext();
	return (
		<tr
			className={clsx(
				className,
				"border-b",
				"group relative transition-colors hover:bg-grey-200",
				trVariantCls[variant],
			)}
		>
			{children}
		</tr>
	);
}

const trVariantCls: Record<DataTableVariant, string> = {
	regular: "border-grey-200",
	items: "border-grey-200",
	compact: "border-grey-200",
	nested: "border-grey-300 bg-grey-100",
};

export interface DataTableHeadProps
	extends ThHTMLAttributes<HTMLTableCellElement> {
	className?: string;
	children?: ReactNode;
	align?: "left" | "right" | "center";
}

export function DataTableHeadCell({
	className,
	children,
	align = "left",
	...props
}: DataTableHeadProps) {
	const { variant } = useDataTableRenderContext();
	return (
		<th
			className={clsx(
				"sticky bg-white z-10 text-caption-12 font-600",
				thVariantCls[variant],
				className,
			)}
			{...props}
		>
			<div className={clsx("flex items-center select-none", alignCls[align])}>
				{children}
			</div>
		</th>
	);
}

/* class={ */
const thVariantCls: Record<DataTableVariant, string> = {
	// I don't remember how the top property was defined but it works.
	regular: "p-12 -top-16",
	items: "px-12 -top-16",
	// In the compact case the top property was defined for the compact table
	// inside an accordion (e.g. list inventory screen)
	compact: "p-4 -top-[1px]",
	nested: "p-4 -top-[1px] bg-grey-300",
};

const alignCls = {
	left: "justify-start",
	right: "justify-end",
	center: "justify-center",
};
/* } */

export interface DataTableCellProps
	extends TdHTMLAttributes<HTMLTableCellElement> {
	className?: string;
	children?: ReactNode;
	strong?: boolean;
	testid?: string;
}

export function DataTableCell({
	className,
	children,
	strong,
	testid,
	...props
}: DataTableCellProps) {
	const { variant } = useDataTableRenderContext();
	return (
		<td
			className={clsx(
				className,
				tdVariantCls[variant],
				"text-14",
				strong ? "font-500 text-grey-900" : "font-400 text-grey-700",
			)}
			{...props}
			data-testid={testid}
		>
			{children}
		</td>
	);
}

/* class={ */
const tdVariantCls: Record<DataTableVariant, string> = {
	regular: "px-12 py-8",
	items: "px-12",
	compact: "p-4",
	nested: "p-4",
};
/* } */

export interface DataTableInputCellProps
	extends TdHTMLAttributes<HTMLTableCellElement> {
	className?: string;
	children?: ReactNode;
}

export function DataTableInputCell({
	className,
	children,
	...props
}: DataTableInputCellProps) {
	const { variant } = useDataTableRenderContext();
	return (
		<td className={clsx(className, inputCellVariantCls[variant])} {...props}>
			{children}
		</td>
	);
}

/* class={ */
const inputCellVariantCls: Record<DataTableVariant, string> = {
	regular: "px-12 py-2",
	items: "px-12 py-2",
	compact: "px-4 py-2",
	nested: "px-4 py-2",
};
/* } */

export interface DataTableHoverButtonsProps {
	className?: string;
	children?: ReactNode;
}

/** @deprecated use `DataTableActionsCell` instead. */
export function DataTableHoverButtons({
	className,
	children,
}: DataTableHoverButtonsProps) {
	return (
		<td
			tabIndex={-1}
			className={clsx(
				className,
				"opacity-0 group-hover:opacity-100 bg-grey-200",
				"absolute top-1/2 right-0 -translate-y-1/2 py-4 transition-all",
				"flex pr-16 pl-8 h-full items-center",
			)}
		>
			{children}
		</td>
	);
}

export interface DataTableFormProps
	extends FormHTMLAttributes<HTMLFormElement> {
	id: string;
}

export function DataTableForm({ children, ...props }: DataTableFormProps) {
	return (
		<form {...props}>
			<HiddenSubmitButton />
			{children}
		</form>
	);
}

export const DataTableCheckbox = forwardRef<
	HTMLInputElement,
	Omit<RadioCheckboxProps, "children">
>(function DataTableCheckbox({ className, ...props }, ref) {
	return (
		<div className={clsx("flex items-center", className)}>
			<RadioCheckbox {...props} ref={ref} />
		</div>
	);
});

interface DataTableRowSelectCellProps {
	id: string;
}

export function DataTableRowSelectCell({ id }: DataTableRowSelectCellProps) {
	const { getToggleRowSelectedProps } = useDataTableContext();

	return (
		<DataTableCell>
			<DataTableCheckbox {...getToggleRowSelectedProps(id)} />
		</DataTableCell>
	);
}

export interface DataTableEditActionsProps {
	form: string;
	isEditing?: boolean;
	isSubmitting: boolean;
	onResetClick?: () => void;
}

export function DataTableEditActions({
	form,
	isEditing,
	isSubmitting,
	onResetClick,
}: DataTableEditActionsProps) {
	const t = useTranslate("common");

	// We don't want to remove/add the buttons from/to the DOM.
	// Doing so would trigger the table to recalculate the width of the last
	// column every time we switch edit mode.
	const className = isEditing ? "" : "invisible";

	return (
		<DataTableActionsCell className={className}>
			<DataTableActionButton
				icon={Close}
				onClick={() => {
					onResetClick?.();
				}}
				disabled={isSubmitting}
				title={t("actions.cancel")}
			/>
			<DataTableActionButton
				icon={Save}
				type="submit"
				isFetching={isSubmitting}
				form={form}
				title={t("actions.save")}
			/>
		</DataTableActionsCell>
	);
}

export interface SortDirectionIndicatorProps {
	accessor: string;
}

function SortDirectionIndicator({ accessor }: SortDirectionIndicatorProps) {
	const { getSortDirection } = useContext(DataTableContext);
	const sortDirection = getSortDirection(accessor);
	if (!sortDirection) {
		// We add this spacer element to prevent the cell width from changing
		// TODO: use a generic "unsorted" instead.
		return <div className="pl-4 h-16 w-16 mb-auto text-grey-400">&mdash;</div>;
	}

	const icon = sortDirection === "asc" ? DropdownOpen : DropdownClosed;

	return <Icon icon={icon} size="16" className="inline" />;
}

export interface DataTableAccordionProps {
	isOpen: boolean;
	className?: string;
	children?: ReactNode;
}

export function DataTableAccordion({
	isOpen,
	className,
	children,
}: DataTableAccordionProps) {
	if (!isOpen) {
		return null;
	}
	return (
		<tr className="border-b border-grey-400">
			<td
				// TODO: Is there a better value, so that it always uses all cols?
				colSpan={100}
				className={clsx(className)}
			>
				{children}
			</td>
		</tr>
	);
}

export interface DataTableSelectionVisibilityToggleProps {
	checked: boolean | undefined;
	onChange: (checked: boolean) => void;
}

export function DataTableSelectionVisibilityToggle({
	checked,
	onChange,
}: DataTableSelectionVisibilityToggleProps) {
	const t = useTranslate("common");
	const { selectedRowIds } = useDataTableContext();
	return (
		<RadioCheckbox
			label={t("data-table.selection-only")}
			type="checkbox"
			className="mr-auto"
			checked={checked}
			onChange={({ target }) => onChange(target.checked)}
			disabled={selectedRowIds.size === 0 && !checked}
		/>
	);
}
