import type { AuthenticatedAction } from "@app/modules/app-state/app-state";
import ffTed from "@app/modules/devdoor/jwts/tenant_frischfleisch_user_ted";
import sempioneTed from "@app/modules/devdoor/jwts/tenant_sempione_user_ted";
import tenantxTed from "@app/modules/devdoor/jwts/tenant_tenantx_user_ted";

export type MetaInfo = {
	tenant: string;
	name: string;
};

export type TestUserInfo = Omit<AuthenticatedAction, "type"> & {
	meta: MetaInfo;
	allowedTenants: string[];
};

export type TestUser = "ted";

export const tenantx: Dictionary<TestUser, TestUserInfo> = {
	ted: tenantxTed as TestUserInfo,
};

export const ff: Dictionary<TestUser, TestUserInfo> = {
	ted: ffTed as TestUserInfo,
};

export const sempione: Dictionary<TestUser, TestUserInfo> = {
	ted: sempioneTed as TestUserInfo,
};
