import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgW1({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width="40"
			height="41"
			viewBox="0 0 40 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d="M10.0066 10.7426C10.178 10.4094 10.5212 10.2 10.8959 10.2H29.1041C29.4788 10.2 29.822 10.4094 29.9934 10.7426L39.2505 28.7426C39.5928 29.4081 39.1096 30.2 38.3612 30.2H1.63878C0.890427 30.2 0.407235 29.4081 0.749493 28.7426L10.0066 10.7426Z"
				fill="#F1F2F4"
			/>
			<path
				d="M13.003 13.7652C13.1698 13.4196 13.5198 13.2 13.9035 13.2H26.0965C26.4802 13.2 26.8302 13.4196 26.997 13.7652L32.3074 24.7652C32.6279 25.4292 32.1441 26.2 31.4068 26.2H8.59319C7.85586 26.2 7.37209 25.4292 7.69264 24.7652L13.003 13.7652Z"
				fill="#B0B7C3"
			/>
		</svg>
	);
}

const MemoSvgW1 = React.memo(SvgW1);
export default MemoSvgW1;
