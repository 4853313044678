import { useAppState } from "@app/modules/app-state/context";

export function injectTenantFilter(
	variables: Record<string, unknown> | undefined,
	tenant: string | undefined,
) {
	if (!tenant) {
		return variables;
	}
	const where = variables?.where;
	return {
		...variables,
		where: { _and: [where ?? {}, { tenant: { _eq: tenant } }] },
	};
}

export function useTenantFilter({
	disabled = false,
}: { disabled?: boolean } = {}) {
	const { tenant } = useAppState();
	return disabled ? {} : { tenant: { _eq: tenant } };
}

export function useAllowedTenantsFilter({
	disabled = false,
}: { disabled?: boolean } = {}) {
	const { tenant } = useAppState();
	return disabled
		? {}
		: {
				_or: [
					{ allowedTenants: { _contains: tenant } },
					{ tenant: { _eq: tenant } },
				],
		  };
}
