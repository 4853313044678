import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCopy({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.582 1a2.019 2.019 0 011.416.583l.002.003 4.413 4.413c.375.374.587.88.587 1.41v6.841A2.739 2.739 0 0117.263 17H16v3.25A2.739 2.739 0 0113.263 23H5.74A2.74 2.74 0 013 20.25V9.744c.004-.732.302-1.429.823-1.941A2.805 2.805 0 015.773 7H7V3.744c.004-.732.302-1.429.823-1.941A2.805 2.805 0 019.773 1H13.583zM12 3H9.783a.805.805 0 00-.558.229.746.746 0 00-.225.525V7h.587a2.007 2.007 0 011.414.586l4.415 4.416c.373.375.584.881.584 1.412V15h1.263a.739.739 0 00.737-.75V9h-3.777A2.25 2.25 0 0112 6.777V3zm5.586 4h-3.342A.25.25 0 0114 6.756V3.414L17.586 7zM8 9H5.783a.805.805 0 00-.558.229.746.746 0 00-.225.525V20.25c0 .423.332.75.739.75h7.524a.739.739 0 00.737-.75V15h-3.777A2.25 2.25 0 018 12.777V9zm5.586 4h-3.343a.25.25 0 01-.243-.243V9.414L13.586 13z"
			/>
		</svg>
	);
}

const MemoSvgCopy = React.memo(SvgCopy);
export default MemoSvgCopy;
