import { UNKNOWN_STATE } from "@app/modules/app-state/app-state";
import { useAppState } from "@app/modules/app-state/context";
import { useTranslate } from "@app/modules/i18n/context";
import { useEffect } from "react";

export function useVersionPrompt() {
	const t = useTranslate("common");

	const { env, latestEnv } = useAppState();

	const isFeOutdated =
		env &&
		latestEnv &&
		latestEnv.feVersion !== UNKNOWN_STATE &&
		env.feVersion !== latestEnv.feVersion;

	useEffect(
		function promptForUpdates() {
			if (isFeOutdated) {
				// eslint-disable-next-line no-alert
				const ok = window.confirm(t("notifications.app-version.changed"));

				if (ok) {
					window.location.reload();
				}
			}
		},
		[isFeOutdated, t],
	);

	return {
		isFeOutdated,
		version: {
			feVersion: env?.feVersion,
			beVersion: env?.beVersion,
		},
		latestVersion: {
			feVersion: latestEnv?.feVersion,
			beVersion: latestEnv?.beVersion,
		},
	};
}
