import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgAlertSolid({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.2 3.2c.8-1.4 2.8-1.4 3.5 0l8.6 15.8C23.1 20.4 22.1 22 20.6 22H3.4c-1.5 0-2.5-1.6-1.8-3L10.2 3.2zm.9 14.3a1 1 0 112 0 1 1 0 01-2 0zm1-8.5a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1z"
			/>
		</svg>
	);
}

const MemoSvgAlertSolid = React.memo(SvgAlertSolid);
export default MemoSvgAlertSolid;
