import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPhone({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.77 10.165l.007-.003.105-.148.5-.669c.32-.463.489-.903.466-1.47-.016-.403-.103-.66-.268-1.023l-.32-.67C9.689 5.048 8.668 3.539 7.989 2.87l-.195-.188-.173-.159a2 2 0 00-2.152-.354l-.418.183-.135.064-.126.07-.13.082-.084.045c-.167.094-.326.19-.48.292-.894.594-1.42 1.135-1.792 1.93-.656 1.404-.217 3.291 1.033 5.563 1.436 2.609 2.476 3.992 4.578 6.088 1.95 1.944 3.522 3.116 6.087 4.555 2.456 1.375 4.06 1.745 5.582 1.036.799-.373 1.343-.897 1.928-1.776l.201-.32.162-.29.06-.091c.048-.081.093-.168.134-.26l.184-.415a2 2 0 00-.326-2.13l-.05-.058-.301-.317c-.416-.42-1.034-.881-1.778-1.364l-.475-.3-.497-.295-.394-.22a13.132 13.132 0 00-.354-.186l-.618-.308-.122-.056c-.895-.374-1.625-.168-2.287.33l-.706.533-.089.06-.008.008c-.506-.106-1.727-.883-2.639-1.792l-.16-.165c-.8-.842-1.49-1.904-1.655-2.418l-.023-.082zM6.272 4l.13.12.183.176c.262.257.619.725.965 1.238l.293.447c.237.375.454.749.612 1.061l.306.64c.113.25.118.322.017.468l-.529.71-.143.205c-.302.478-.414.95-.295 1.507.196.912 1.191 2.484 2.385 3.675 1.189 1.185 2.719 2.137 3.64 2.334.561.12 1.037.006 1.517-.297.07-.045.14-.092.217-.148l.684-.517c.146-.11.197-.13.312-.083l.61.305c.194.098.408.213.63.34l.45.268.453.285.387.258.358.254.316.24c.144.116.267.223.36.317l.244.254.05.057-.184.415.133-.32c-.142.338-.32.67-.527.983-.4.602-.687.876-1.108 1.072-.702.327-1.66.207-3.759-.968-2.377-1.333-3.812-2.39-5.652-4.226-1.967-1.962-2.893-3.192-4.239-5.636-1.056-1.919-1.245-3.17-.973-3.751.195-.416.47-.702 1.078-1.106.233-.153.479-.291.732-.412l.255-.114-.326.133L6.272 4z"
			/>
		</svg>
	);
}

const MemoSvgPhone = React.memo(SvgPhone);
export default MemoSvgPhone;
