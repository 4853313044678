import { useEffect } from "react";

export function usePageTitle(title?: string) {
	useEffect(() => {
		if (!title) {
			return () => {};
		}
		const oldTitle = document.title;
		document.title = title;
		return () => {
			document.title = oldTitle;
		};
	}, [title]);
}
