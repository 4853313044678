import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgNotification({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 19c-1.7 0-2.79-2.175-1.864-3.513l.09-.12.182-.218.274-.311.497-.55c1.02-1.174 1.385-2.138 1.414-4.5l.005-.569C4.708 4.604 7.678 1 12 1c4.32 0 7.294 3.607 7.404 8.22l.005.569c.028 2.358.39 3.322 1.407 4.493l.645.717.13.15.124.146.149.192c.895 1.295-.104 3.382-1.712 3.507L20 19h-4c0 2.323-1.677 4-4 4-2.324 0-4-1.677-4-4H4zm6 0c0 1.219.78 2 2 2 1.163 0 1.928-.712 1.995-1.837L14 19h-4zm2-16C8.81 3 6.595 5.763 6.595 9.487c-.002 3.073-.528 4.526-1.906 6.111l-.76.851-.155.184c-.01.012.112.273.19.347L4 17h16c.055 0 .188-.262.22-.343l.005-.024-.267-.314-.648-.72c-1.324-1.525-1.86-2.928-1.9-5.765l-.006-.6C17.306 5.642 15.117 3 12 3z"
			/>
		</svg>
	);
}

const MemoSvgNotification = React.memo(SvgNotification);
export default MemoSvgNotification;
