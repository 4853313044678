import { Close } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import { Button } from "@app/modules/Button";
import { useTranslate } from "@app/modules/i18n/context";
import {
	useBannerNotifications,
	useNotificationDispatch,
} from "@app/modules/notification/context";
import { useNavigationPrompt } from "@app/modules/notification/navigation-prompt";
import { Body14, Caption12 } from "@app/modules/typography";
import clsx from "clsx";
import type { MouseEvent, ReactNode } from "react";
import { useMemo } from "react";

function BannerNotification() {
	const notification = useBannerNotifications()[0];
	const { clearNotification } = useNotificationDispatch();
	const t = useTranslate("common");

	useNavigationPrompt({
		shouldBlockNavigation: notification?.variant === "error",
		prompt: useMemo(
			() => ({ message: t("notifications.messages.error-prompt") }),
			[t],
		),
	});

	if (!notification) {
		return null;
	}
	const { text, id, variant, cta, errorCode } = notification;
	return (
		<BannerNotificationView
			onCloseRequest={() => {
				clearNotification(id);
			}}
			variant={variant}
		>
			<div>{text}</div>
			{errorCode && (
				<Caption12>
					{t("notifications.error-code")}: {errorCode}
				</Caption12>
			)}
			{cta && (
				<Button
					variant="secondary-white"
					className="mt-24"
					onClick={(event: MouseEvent<HTMLButtonElement>) => {
						clearNotification(id);
						cta.onClick?.(event);
					}}
				>
					{cta.text}
				</Button>
			)}
		</BannerNotificationView>
	);
}

interface BannerNotificationViewProps {
	className?: string;
	children?: ReactNode;
	onCloseRequest?: () => void;
	variant?: "success" | "error";
}

function BannerNotificationView({
	className,
	children,
	onCloseRequest,
	variant = "success",
}: BannerNotificationViewProps) {
	return (
		<div
			className={clsx(
				className,
				"rounded p-24 sticky top-0 z-45",
				variant === "success" && "bg-green-800",
				variant === "error" && "bg-red-800",
			)}
		>
			{onCloseRequest && (
				<button
					type="button"
					className="absolute right-24 top-24"
					onClick={onCloseRequest}
				>
					<Icon icon={Close} size="16" className="text-white" />
				</button>
			)}
			<Body14 className={clsx("text-white", onCloseRequest && "pr-32")}>
				{children}
			</Body14>
		</div>
	);
}

export { BannerNotification };
