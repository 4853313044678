import { AppStateView as PrivateAppStateView } from "@app/modules/app-state/AppStateView";
import { AppStateProvider as PrivateAppStateProvider } from "@app/modules/app-state/context";
import { AppStateView as PublicAppStateView } from "@app/modules/app-state/public/AppStateView";
import { AppStateProvider as PublicAppStateProvider } from "@app/modules/app-state/public/context";
import { Auth0Provider } from "@app/modules/auth0/Auth0Provider";
import { AuthorizationProvider } from "@app/modules/authorization/AuthorizationProvider";
import { CardDrawer } from "@app/modules/cards/CardDrawer";
import { ConfirmationDialogProvider } from "@app/modules/confirmation-dialog/confirmation-dialog-provider";
import { getIsDevJWTActive } from "@app/modules/devdoor/devdoor";
import { EnvLoader } from "@app/modules/env/EnvLoader";
import { NotFound } from "@app/modules/errors/NotFound";
import { UrqlClientProvider as PublicUrqlClientProvider } from "@app/modules/graphql/public/UrqlClientProvider";
import { UrqlClientProvider as PrivateUrqlClientProvider } from "@app/modules/graphql/UrqlClientProvider";
import { HardwareUiActionMapper } from "@app/modules/hardware/hardware-ui-action-mapper";
import { HardwareProvider } from "@app/modules/hardware/HardwareProvider";
import { HidScannerProvider } from "@app/modules/hardware/hid-scanner/hid-scanner-context";
import { Header } from "@app/modules/Header";
import { HotkeyOverlayProvider } from "@app/modules/hotkey/HotkeyOverlayProvider";
import { I18nProvider } from "@app/modules/i18n/context";
import { LoginOverlay } from "@app/modules/layout/LoginOverlay";
import { MainArea } from "@app/modules/layout/MainArea";
import { ModalProvider } from "@app/modules/layout/Modal";
import { Navigation } from "@app/modules/Navigation";
import { NotificationProvider } from "@app/modules/notification/context";
import { NavigationPromptProvider } from "@app/modules/notification/navigation-prompt";
import { ToastNotifications } from "@app/modules/notification/ToastNotifications";
import { OfferLayout } from "@app/modules/offer/public/OfferLayout";
import { useActiveRouteLayout } from "@app/modules/routes/routes";
import { SearchParamsProvider } from "@app/modules/routes/search-params";
import { LocalSearchProvider } from "@app/modules/search/local/LocalSearchProvider";
import { TenantFavicon } from "@app/modules/tenant/TenantFavicon";
import { TenantLoader } from "@app/modules/tenant/TenantLoader";
import { UiActionsProvider } from "@app/modules/ui-actions/UiActionsProvider";
import { LocalStorageStateProvider } from "@app/modules/utils/local-storage";
import { Devdoor } from "@app/pages/devdoor";
import { privateRoutes } from "@app/pages/_routes";
import clsx from "clsx";
import type { ComponentType, ReactElement, ReactNode } from "react";
import React, { Suspense, useCallback } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
	{
		path: "/public/:locale/*",
		element: <PublicArea />,
		children: [
			{ path: "offer", element: <OfferLayout /> },
			{ path: "*", element: <NotFound /> },
		],
	},
	{ path: "/devdoor", element: <Devdoor /> },
	{
		path: "/*",
		element: <PrivateArea />,
		children: [...privateRoutes, { path: "*", element: <NotFound /> }],
	},
]);

export function App() {
	return <RouterProvider router={router} />;
}

function PrivateArea() {
	const layout = useActiveRouteLayout();
	const [refreshKey, setRefreshKey] = React.useState(0);
	const refresh = useCallback(() => {
		setRefreshKey((curr) => curr + 1);
	}, []);

	return composeProviders(
		[
			[ModalProvider],
			[NotificationProvider],
			[PrivateAppStateProvider],
			[SearchParamsProvider],
			[LocalStorageStateProvider],
			[PrivateUrqlClientProvider, { refreshKey }],
			[I18nProvider],
			[LocalSearchProvider],
			[NavigationPromptProvider],
			getIsDevJWTActive() ? undefined : [Auth0Provider],
			[AuthorizationProvider],
			[UiActionsProvider],
			[HardwareProvider],
			[HardwareUiActionMapper],
			[HotkeyOverlayProvider],
			[ConfirmationDialogProvider],
			[HidScannerProvider],
		],
		<>
			<PrivateAppStateView>
				<Header compact={layout.header.isCompact} onRefreshClick={refresh} />
				<TenantFavicon />
				<div
					className={clsx(
						"bg-grey-100 flex",
						layout.header.isCompact ? "h-content-compact" : "h-content",
					)}
				>
					{layout.navigation.isVisible && <Navigation className="shrink-0" />}
					<MainArea
						key={refreshKey}
						className={clsx(
							"w-full max-w-screen-m h-full",
							layout.mainArea.hasPadding && "p-main-area-padding",
						)}
					>
						<Suspense>
							<Outlet />
						</Suspense>
					</MainArea>
					{layout.cardDrawer.isVisible && <CardDrawer className="shrink-0" />}
				</div>
				<ToastNotifications />
			</PrivateAppStateView>
			<LoginOverlay />
			<TenantLoader />
			<EnvLoader />
		</>,
	);
}

function composeProviders(
	providers: (
		| [ComponentType<{ children?: ReactNode | undefined }>]
		| [ComponentType<{ children?: ReactNode | undefined }>, {}]
		| undefined
	)[],
	children: ReactElement,
): ReactElement {
	return providers.reduceRight((acc, provider) => {
		if (provider) {
			const [Component, props] = provider;
			return <Component {...props}>{acc}</Component>;
		}
		return acc;
	}, children);
}

export function PublicArea() {
	return composeProviders(
		[
			[SearchParamsProvider],
			[ModalProvider],
			[NotificationProvider],
			[PublicAppStateProvider],
			[PublicUrqlClientProvider],
			[I18nProvider],
		],
		<PublicAppStateView>
			<Outlet />
			<ToastNotifications />
			<EnvLoader />
		</PublicAppStateView>,
	);
}
