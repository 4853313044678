import { Next, Prev } from "@app/icons/components/action";
import { Button } from "@app/modules/Button";
import type { UsePaginationResult } from "@app/modules/graphql/utils";
import { useTranslate } from "@app/modules/i18n/context";
import { Body14 } from "@app/modules/typography";
import clsx from "clsx";
import type { ReactNode } from "react";

export interface DataTablePaginationProps extends UsePaginationResult {
	className?: string;
	totalRows: number;
	variant?: "default" | "compact" | "touch";
	extraButtons?: ReactNode;
}

export function DataTablePagination({
	totalRows,
	goNext,
	goPrev,
	hasPrev,
	getHasNext,
	limit,
	offset,
	variant = "default",
	extraButtons,
	className,
}: DataTablePaginationProps) {
	const t = useTranslate("common");
	const firstRowIndex = Math.min(offset + 1, totalRows);
	const lastRowIndex = Math.min(firstRowIndex + limit - 1, totalRows);
	const rowIndexRange =
		firstRowIndex === lastRowIndex
			? lastRowIndex
			: `${firstRowIndex}-${lastRowIndex}`;

	if (variant === "touch") {
		return (
			<div className="sticky top-0 bg-grey-100 z-10">
				<div className={clsx("flex items-center justify-end gap-24 mb-8")}>
					<Button
						isTouch
						variant="secondary"
						icon={Prev}
						onClick={goPrev}
						disabled={!hasPrev}
						className="w-full h-64"
					>
						{t("pagination.previous")}
					</Button>
					<Button
						isTouch
						variant="secondary"
						icon={Next}
						onClick={goNext}
						disabled={!getHasNext(totalRows)}
						className="w-full h-64"
					>
						{t("pagination.next")}
					</Button>
				</div>

				<Body14 className="text-grey-700 text-right px-16">{`${rowIndexRange} ${t(
					"data-table.pagination.of",
				)} ${totalRows}`}</Body14>
			</div>
		);
	}

	return (
		<div
			className={clsx(
				className,
				"flex items-center justify-end sticky top 0",
				variant === "default" && "px-12 py-8 border-t border-b border-grey-200",
			)}
		>
			{extraButtons}

			{variant === "default" && (
				<div className="text-body-14 text-grey-700">{`${rowIndexRange} ${t(
					"data-table.pagination.of",
				)} ${totalRows}`}</div>
			)}
			<Button
				variant="tertiary-grey"
				icon={Prev}
				onClick={goPrev}
				disabled={!hasPrev}
				className="ml-16"
			/>
			<Button
				variant="tertiary-grey"
				icon={Next}
				onClick={goNext}
				disabled={!getHasNext(totalRows)}
				className="ml-12"
			/>
		</div>
	);
}
