import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDeactivate({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 12C2 6.479 6.479 2 12 2s10 4.479 10 10-4.479 10-10 10S2 17.521 2 12zm10-8c-4.416 0-8 3.584-8 8 0 1.87.642 3.59 1.718 4.953L16.953 5.718A7.965 7.965 0 0012 4zm6.357 3.143L7.143 18.357A8.002 8.002 0 0018.356 7.144z"
			/>
		</svg>
	);
}

const MemoSvgDeactivate = React.memo(SvgDeactivate);
export default MemoSvgDeactivate;
