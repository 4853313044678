import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSearch({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.364 2l.255.004a8.364 8.364 0 016.323 13.524l4.765 4.765a1 1 0 01-1.32 1.497l-.094-.083-4.764-4.765a8.364 8.364 0 11-5.42-14.938L10.363 2zm0 2l-.229.004a6.364 6.364 0 10.457 0L10.363 4z"
			/>
		</svg>
	);
}

const MemoSvgSearch = React.memo(SvgSearch);
export default MemoSvgSearch;
