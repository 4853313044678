import type { Currencies_Enum } from "@app/modules/graphql-api-types.generated";
import { descale, scale } from "@app/modules/number/scaled-number";

export function bigintToValue(
	value: string | undefined | null,
	decimalDigits: number,
): string {
	if (!value || value.length === 0) {
		return "";
	}

	return descale(value, decimalDigits);
}

export function valueToBigint(formattedValue: string): string {
	return scale(formattedValue).toString();
}

export interface SharedMoneyProps {
	currency: Currencies_Enum;
	unit?: string;
	hideCurrency?: boolean;
}
