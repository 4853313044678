import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgEmail({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M5.65 6.24a1 1 0 00-1.3 1.52l7 6a1 1 0 001.3 0l7-6a1 1 0 00-1.3-1.52L12 11.684 5.65 6.24z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.923 3C2.273 3 1 4.38 1 6v12c0 1.62 1.272 3 2.923 3h16.154C21.727 21 23 19.62 23 18V6c0-1.62-1.272-3-2.923-3H3.923zM3 6c0-.59.45-1 .923-1h16.154c.473 0 .923.41.923 1v12c0 .59-.45 1-.923 1H3.923C3.45 19 3 18.59 3 18V6z"
			/>
		</svg>
	);
}

const MemoSvgEmail = React.memo(SvgEmail);
export default MemoSvgEmail;
