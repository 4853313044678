import type { UiAction } from "@app/modules/ui-actions/types";
import { generateUiActionId } from "@app/modules/ui-actions/ui-actions-utils";
import type { ReactNode } from "react";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from "react";
import type { Operator } from "wonka";
import { filter, makeSubject, pipe, subscribe } from "wonka";

export const UiActionContext = createContext({
	source: makeSubject<UiAction>().source,
	dispatch: (_action: UiAction) => {},
});

interface UiActionsProviderProps {
	children?: ReactNode;
}

export function UiActionsProvider({ children }: UiActionsProviderProps) {
	const subjectRef = useRef(makeSubject<UiAction>());
	const { source, next } = subjectRef.current;

	const dispatch = useCallback(
		(action: UiAction) => {
			next({
				...action,
				id: action.id ?? generateUiActionId(action.type),
			});
		},
		[next],
	);
	const value = useMemo(() => ({ source, dispatch }), [source, dispatch]);

	return (
		<UiActionContext.Provider value={value}>
			{children}
		</UiActionContext.Provider>
	);
}

export function useUiActionsContext() {
	return useContext(UiActionContext);
}

export interface UseUiActionSubscriptionOptions<A extends UiAction> {
	type: A["type"] | A["type"][];
	onAction: (action: A) => void;
	pause?: boolean;
}

export function useUiActionSubscription<A extends UiAction>({
	type,
	onAction,
	pause,
}: UseUiActionSubscriptionOptions<A>) {
	const { source } = useUiActionsContext();
	const handleActionRef = useRef(onAction);
	handleActionRef.current = onAction;
	const typeRef = useRef(type);
	typeRef.current = type;
	useEffect(() => {
		if (!pause) {
			const currentType = typeRef.current;
			const typeArray = Array.isArray(currentType)
				? currentType
				: [currentType];
			const { unsubscribe } = pipe(
				source,
				filter((action) => typeArray.includes(action.type)) as Operator<
					UiAction,
					A
				>,
				subscribe((action) => handleActionRef.current(action)),
			);
			return unsubscribe;
		}
		return undefined;
	}, [pause, source]);
}
