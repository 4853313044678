import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTime({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.283 1C18.235 1.137 23 6.01 23 11.974c0 6.072-4.91 10.985-10.976 10.974C5.94 22.937.986 17.935 1.053 11.86a10.966 10.966 0 012.796-7.202 1 1 0 011.49 1.334 8.967 8.967 0 00-2.286 5.89c-.055 4.962 4.002 9.057 8.976 9.066 4.96.01 8.972-4.007 8.972-8.974a8.98 8.98 0 00-7.723-8.89l-.252-.032.001 3.52a1 1 0 01-.883.993l-.117.006a1 1 0 01-.993-.883l-.007-.117V2.23A1.227 1.227 0 0112.283 1zm-4.64 6.074l5.679 3.968a1.632 1.632 0 11-2.278 2.277l-3.97-5.677a.408.408 0 01.569-.568z"
			/>
		</svg>
	);
}

const MemoSvgTime = React.memo(SvgTime);
export default MemoSvgTime;
