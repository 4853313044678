import { useClickOutsideEffect } from "@app/modules/hooks/useClickOutside";
import { useFloatingScrollEffect } from "@app/modules/layout/useFloatingScrollEffect";
import { logger } from "@app/modules/logger/logger";
import type { Placement } from "@floating-ui/react-dom";
import { arrow, flip, shift, useFloating } from "@floating-ui/react-dom";
import clsx from "clsx";
import type { FocusEventHandler, MouseEventHandler, ReactNode } from "react";
import {
	cloneElement,
	isValidElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { createPortal } from "react-dom";

const TOOLTIP_PORTAL_NODE = document.getElementById("tooltip-portal");

// We keep extending these props, in part because we are using this component like a Popover in many places.
// TODO: create a more generic `Popover` component instead of abusing the tooltip.
export interface ToolTipV2Props {
	className?: string;
	contentClassName?: string;
	content?: ReactNode;
	// Only single children are supported. Make sure it accepts mouse event handlers (e.g. onMouseOver, onMouseOut)
	children?: ReactNode;
	placement?: Placement;
	// Use this prop to override the built-in hover/focus detection
	visible?: boolean;
	refPropName?: string;
	hasArrow?: boolean;
	preventFlip?: boolean;
	onClickOutside?: () => void;
}

export function TooltipV2({
	className,
	contentClassName,
	children,
	content,
	visible,
	preventFlip,
	placement = "top",
	refPropName = "ref",
	hasArrow = true,
	onClickOutside,
}: ToolTipV2Props) {
	const arrowRef = useRef<HTMLDivElement>(null);

	const middleware = [shift(), arrow({ element: arrowRef })];
	if (!preventFlip) {
		middleware.push(
			// 88 is the header height (taken from `tailwind.config.js`)
			flip({ padding: { top: 88, bottom: 12, left: 0, right: 0 } }),
		);
	}

	const {
		x,
		y,
		reference,
		floating,
		strategy,
		update,
		refs,
		placement: actualPlacement,
		middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
	} = useFloating({
		placement,
		middleware,
	});

	const [isHovering, setIsHovering] = useState(false);
	const isVisible = visible ?? isHovering;

	useEffect(() => {
		if (isVisible) {
			update();
		}
	}, [update, isVisible]);

	useFloatingScrollEffect(refs, update, isVisible);

	const handleHover: MouseEventHandler & FocusEventHandler = useCallback(
		(event) => {
			const relatedTarget = event.relatedTarget as HTMLElement;
			const isInternalHoverChange =
				event.relatedTarget &&
				((refs.reference.current as HTMLElement)?.contains(relatedTarget) ||
					refs.floating.current?.contains(relatedTarget));

			if (!isInternalHoverChange) {
				if (event.type === "mouseover" || event.type === "focus") {
					setIsHovering(true);
				} else if (event.type === "mouseout" || event.type === "blur") {
					setIsHovering(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const focusProps = {
		onFocus: handleHover,
		onBlur: handleHover,
		onMouseOver: handleHover,
		onMouseOut: handleHover,
	} as const;

	useClickOutsideEffect({ onClickOutside, ref: refs.floating });

	if (!TOOLTIP_PORTAL_NODE) {
		logger.error(
			undefined,
			"Tooltip portal node not found. Make sure an element with id 'tooltip-portal' exists in the DOM.",
		);
		return null;
	}

	return (
		<>
			{isValidElement(children) &&
				cloneElement(children, {
					[refPropName]: reference,
					...focusProps,
				})}
			{isVisible &&
				createPortal(
					// We use this div which neatly attaches to the reference element.
					// This way, when moving the mouse from reference element to tooltip we
					// can detect that movement and decide not to hide the tooltip.
					<div
						ref={floating}
						className={clsx("p-12 z-tooltip", className)}
						style={{
							position: strategy,
							top: y ?? "",
							left: x ?? "",
						}}
						tabIndex={-1}
						{...focusProps}
					>
						{/* This is the actual, visible tooltip element. */}
						<div
							className={clsx(
								"bg-white rounded drop-shadow-tooltip p-8",
								contentClassName,
							)}
						>
							{content}
							{hasArrow && (
								<div
									ref={arrowRef}
									className="absolute rotate-45 w-[14px] h-[14px] bg-white -z-10"
									style={{
										// -6px makes the arrow look the same size when it is attached to the top
										top: actualPlacement.startsWith("bottom")
											? "-6px"
											: arrowY ?? "",
										left: arrowX ?? "",
									}}
								/>
							)}
						</div>
					</div>,
					TOOLTIP_PORTAL_NODE,
				)}
		</>
	);
}
