import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPricetag({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.294 2c.505 0 .99.178 1.374.5l.14.127 8.73 8.732a1.57 1.57 0 01.108 2.1l-.108.12-7.96 7.962c-.503.503-1.272.6-1.936.215l-.14-.09-.145-.125-8.73-8.732a2.14 2.14 0 01-.619-1.325L2 11.296V3.57a1.57 1.57 0 011.427-1.564L3.57 2h7.724zm0 2H4v7.296a.14.14 0 00.019.07l.022.029 8.423 8.43 7.355-7.356-8.426-8.428-.03-.022-.033-.014L11.294 4zM8.856 6a2.856 2.856 0 110 5.711 2.856 2.856 0 010-5.711zm0 1.711a1.144 1.144 0 100 2.289 1.144 1.144 0 000-2.289z"
			/>
		</svg>
	);
}

const MemoSvgPricetag = React.memo(SvgPricetag);
export default MemoSvgPricetag;
