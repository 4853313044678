export function setIn(
	object: Record<string, unknown>,
	path: string,
	value: unknown,
) {
	const segments = path.split(".");

	return segments.reduceRight((acc, segment, index, self) => {
		if (index === self.length - 1) {
			return { ...acc, [segment]: value };
		}

		return { [segment]: acc };
	}, object);
}

export function getIn(object: Record<string, unknown>, path: string) {
	const segments = path.split(".");
	return segments.reduce<unknown | undefined>((acc, segment) => {
		if (typeof acc === "object") {
			return (acc as Record<string, unknown>)[segment];
		}
		return undefined;
	}, object);
}

export function isEmptyObject(object: Record<string, unknown>) {
	return Object.keys(object).length < 1;
}
