/* eslint-disable no-console */
import { useEffect } from "react";
/**
 * @description Log error to console when condition is false
 * @param condition when false message will be logged
 * @param message
 */
export function useConsoleAssert(condition: boolean, message: string) {
	useEffect(() => {
		console.assert(condition, message);
	}, [condition, message]);
}
