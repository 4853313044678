import { dateString, timeString } from "@app/modules/form/structs";
import { LocalDate } from "@app/modules/localdate/localdate";
import { useSearchParam } from "@app/modules/routes/search-params";
import { makeLocalSearchSuggestion } from "@app/modules/search/local/utils";
import { matchesSuggestion } from "@app/modules/search/suggestion-utils";
import type { StockLocation } from "@app/modules/stock/useStockLocations";
import { useDebouncedValue } from "@app/modules/utils/debounce";
import { useMemo } from "react";
import { is } from "superstruct";

const DEBOUNCE_DELAY = 300;

export type AccountName =
	| "STOCK"
	| "AMENDMENTS"
	| "SCRAPING"
	| "TRANSFER_ADJUSTMENTS"
	| "INTERNAL_CONSUMPTION"
	| "PICKING";

export function useStockDate() {
	const now = LocalDate.now();

	const [date, setDate] = useSearchParam<string>("date", now.toDateString(), {
		validate: (value) => is(value, dateString()),
	});

	const [time, setTime] = useSearchParam<string>("time", "", {
		validate: (value) => is(value, timeString()),
	});

	const dateWithTime = useMemo(() => {
		const timeObject = time
			? LocalDate.fromLocalTime(time)
			: LocalDate.now().endOf("day");
		return LocalDate.fromDateString(date)
			.startOf("d")
			.add(timeObject.dayjsDate.hour(), "h")
			.add(timeObject.dayjsDate.minute(), "m")
			.endOf("m");
	}, [date, time]);

	const isInFuture = dateWithTime.isAfter(now);
	const isToday = dateWithTime.isToday();

	const dateTime = (() => {
		if (isInFuture) {
			// We don't want to activate time travel if a future date is selected.
			// Also, we don't want to create transactions in the future.
			return undefined;
		}

		return !isToday || time ? dateWithTime : undefined;
	})();

	// useDebouncedValue needs a memoized value as it only checks referential equality.
	const debouncedDateTime = useDebouncedValue(dateTime, DEBOUNCE_DELAY, {
		leading: true,
	});

	return {
		date,
		setDate,
		time,
		setTime,
		dateTime,
		debouncedDateTime,
		isTimeTravelling: dateTime !== undefined,
	};
}

export function stockLocationFilter(
	stockLocations: StockLocation[],
	key: string,
) {
	return {
		key,
		getSuggestions: (_: unknown, search: string) =>
			stockLocations
				.filter(({ label }) => matchesSuggestion(label, search))
				.map(({ id, label, tenant }) =>
					makeLocalSearchSuggestion(key, id, {
						label,
						tenant,
						icon: "stock",
					}),
				),
	};
}
