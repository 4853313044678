import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSales({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={18}
			height={18}
			viewBox="0 0 18 18"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.5 5.932l1.275-3.496h9.506L15 5.972v1.814a1.475 1.475 0 01-.565.325c-.214.059-.449.051-.722-.137-.595-.41-1.386-.306-1.884.041a.688.688 0 01-.42.128c-.17-.004-.434-.067-.794-.32-.563-.395-1.249-.264-1.675.007a.681.681 0 01-.341.11c-.12.005-.29-.022-.516-.158-.548-.332-1.204-.232-1.643.048a.68.68 0 01-.346.111c-.121.004-.297-.026-.529-.17a1.457 1.457 0 00-1.654.09c-.222.172-.464.277-.686.282-.175.004-.422-.049-.725-.346V5.932zM3.6.936a1.25 1.25 0 00-1.174.821L1.076 5.46A1.25 1.25 0 001 5.888v2.019c0 .28.095.581.314.82.37.403.771.666 1.186.803v6.284c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25v-6.31a3.086 3.086 0 001.17-.764c.237-.245.33-.556.33-.833V5.915c0-.19-.043-.376-.126-.547L14.561 1.64a1.25 1.25 0 00-1.124-.703H3.6zm9.9 8.594a2.502 2.502 0 01-.63-.316.165.165 0 00-.06-.005.294.294 0 00-.075.014.194.194 0 00-.049.023c-.342.238-.787.41-1.314.397-.513-.013-1.045-.198-1.581-.566a.137.137 0 00-.046.018c-.6.382-1.48.544-2.425-.022h-.007a.15.15 0 00-.068.022c-.605.385-1.49.545-2.44-.03-.22.167-.492.33-.805.44v6.06h2v-3.322c0-.69.56-1.25 1.25-1.25h3c.69 0 1.25.56 1.25 1.25v3.321h2V9.53zM10 15.564H7.5v-3.071H10v3.071z"
			/>
		</svg>
	);
}

const MemoSvgSales = React.memo(SvgSales);
export default MemoSvgSales;
