import { Icon } from "@app/icons/Icon";
import { AlertSolid, Logo, LogoAchromatic } from "@app/icons/components";
import { Refresh } from "@app/icons/components/action";
import { Avatar } from "@app/modules/Avatar";
import {
	useAppState,
	useAppStateDispatch,
} from "@app/modules/app-state/context";
import { useQuery } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import { Divider } from "@app/modules/layout/Divider";
import { NotificationPanel } from "@app/modules/notification/NotificationPanel";
import { Link } from "@app/modules/routes/link";
import { SearchInput } from "@app/modules/search/SearchInput";
import { TenantColorBar } from "@app/modules/tenant/TenantColorBar";
import { MyUserDocument } from "@app/modules/user/queries/my-user.query.generated";
import { useVersionPrompt } from "@app/modules/utils/useAppVersion";
import { useWorkstations } from "@app/modules/workstations/useWorkstations";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { useAuth0 } from "./auth0/Auth0Provider";
import {
	DropDown,
	DropDownButton,
	DropDownExternalLink,
	DropDownItem,
	DropDownUser,
} from "./layout/DropDown";

export interface HeaderProps {
	className?: string;
	compact?: boolean;
	onRefreshClick?: () => void;
}
export function Header({ className, compact, onRefreshClick }: HeaderProps) {
	const t = useTranslate("common");
	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
	const toggleUserMenu = useCallback(() => {
		setIsUserMenuOpen((oldValue) => !oldValue);
	}, [setIsUserMenuOpen]);

	const [isRoleSelectionOpen, setIsRoleSelectionOpen] = useState(false);

	const [isWorkstationSelectionOpen, setIsWorkstationSelectionOpen] =
		useState(false);

	const workstations = useWorkstations();

	const { logout } = useAuth0();
	const { user, activeRole } = useAppState();
	const dispatch = useAppStateDispatch();
	const availableRoles = (user?.allowedRoles ?? []).filter(
		(role) => role !== activeRole,
	);

	const [{ fetching: fetchingUserData, data }] = useQuery({
		query: MyUserDocument,
		variables: { id: user?.id ?? "" },
		pause: !user?.id,
	});
	const { isFeOutdated, version, latestVersion } = useVersionPrompt();

	const nameOfTheUser = data?.userByPk?.name;
	const versionInformation = t("notifications.app-version.profile-string", {
		feVersion: version.feVersion ?? "",
		beVersion: latestVersion.beVersion ?? "",
	});

	const bgColor = useEnvBgColor();

	return (
		<header
			data-label="header"
			className={clsx(
				className,
				"relative z-50 w-full flex",
				bgColor,
				compact && "py-8 justify-between",
				compact && "h-header-compact-with-search px-16",
				!compact && "h-header py-24 pr-32 pl-16",
			)}
		>
			<Link
				to="dashboard"
				className={clsx("mr-16", !compact && "-mt-2")}
				title={versionInformation}
				onClick={(event) => {
					if (isFeOutdated) {
						event.preventDefault();
						event.stopPropagation();
						window.location.reload();
					}
				}}
			>
				<div className="relative">
					{!isFeOutdated && <Logo className="w-32" />}
					{isFeOutdated && (
						<>
							<LogoAchromatic className="w-32" />
							<AlertSolid
								width={14}
								className="absolute right-0 bottom-0 fill-white animate-buzz"
							/>
						</>
					)}
				</div>
			</Link>

			<SearchInput />

			<div className="flex px-8">
				{onRefreshClick && (
					<button
						type="button"
						className="text-white p-8 transition-all"
						title={t("actions.refresh")}
						onClick={onRefreshClick}
					>
						<Icon icon={Refresh} size="24" />
					</button>
				)}
				<NotificationPanel />
			</div>

			<div className="relative z-40 flex align-center">
				<button
					aria-label="benutzermenü umschalten"
					type="button"
					onClick={toggleUserMenu}
					className="ml-auto"
					data-testid="user-menu-toggle"
				>
					<span
						className={clsx(
							"block",
							compact && "w-32 h-32 ml-24",
							!compact && "w-48 h-48 -mt-4",
							fetchingUserData && "animate-pulse",
						)}
					>
						<Avatar name={nameOfTheUser} size={compact ? "small" : "medium"} />
					</span>
				</button>
				<DropDown
					testid="user-menu"
					isOpen={isUserMenuOpen}
					onCloseRequest={() => setIsUserMenuOpen(false)}
					align="right"
					width="sm"
					maxHeight="md"
				>
					<DropDownItem isFirst isPadding>
						<DropDownUser
							name={nameOfTheUser}
							className={clsx(fetchingUserData && "animate-pulse")}
						/>
					</DropDownItem>

					{availableRoles.length > 0 && (
						<DropDownItem>
							<DropDownButton
								onClick={() => {
									setIsUserMenuOpen(false);
									setIsRoleSelectionOpen(true);
								}}
								testid="user-menu-switch-role"
							>
								{t("actions.switch-role")}
							</DropDownButton>
						</DropDownItem>
					)}
					{workstations.list.length > 0 && (
						<DropDownItem>
							<DropDownButton
								onClick={() => {
									setIsUserMenuOpen(false);
									setIsWorkstationSelectionOpen(true);
								}}
								testid="user-menu-switch-workstation"
							>
								{t("actions.select-workstation")}
							</DropDownButton>
						</DropDownItem>
					)}

					<Divider className="my-8" />

					<ExternalLinks />

					<DropDownItem isLast>
						<DropDownButton
							onClick={() => {
								setIsUserMenuOpen(false);
								logout();
							}}
						>
							{t("actions.logout")}
						</DropDownButton>
					</DropDownItem>
				</DropDown>
				{availableRoles.length > 0 && (
					<DropDown
						isOpen={isRoleSelectionOpen}
						onCloseRequest={() => setIsRoleSelectionOpen(false)}
						align="right"
						width="sm"
						maxHeight="md"
					>
						{availableRoles.map((role) => (
							<DropDownItem key={role}>
								<DropDownButton
									onClick={() => {
										setIsRoleSelectionOpen(false);
										dispatch({ type: "ROLE_CHANGED", role });
									}}
									testid={`user-menu-role-${role}`}
								>
									{t(`roles.${role}`)}
								</DropDownButton>
							</DropDownItem>
						))}
					</DropDown>
				)}
				{workstations.list.length > 0 && (
					<DropDown
						isOpen={isWorkstationSelectionOpen}
						onCloseRequest={() => setIsWorkstationSelectionOpen(false)}
						align="right"
						width="sm"
						maxHeight="md"
					>
						{workstations.list.map((workstation) => (
							<DropDownItem key={workstation.id}>
								<DropDownButton
									onClick={() => {
										setIsWorkstationSelectionOpen(false);
										dispatch({
											type: "WORKSTATION_SELECTED",
											workstationId: workstation.id,
										});
									}}
									testid={`user-menu-workstation-${workstation.id}`}
								>
									{workstation.name} ({workstation.pcName})
								</DropDownButton>
							</DropDownItem>
						))}
						<DropDownItem>
							<DropDownButton
								onClick={() => {
									setIsWorkstationSelectionOpen(false);
									dispatch({
										type: "WORKSTATION_SELECTED",
										workstationId: undefined,
									});
								}}
							>
								{t("actions.no-workstation")}
							</DropDownButton>
						</DropDownItem>
					</DropDown>
				)}
			</div>

			<TenantColorBar className="absolute bottom-0 left-0 right-0" />
		</header>
	);
}

export function useEnvBgColor() {
	const { env } = useAppState();
	/* class={ */
	switch (env?.stage) {
		case "local":
			return "bg-teal-800";
		case "dev":
			return "bg-yellow-800";
		case "stage":
			return "bg-orange-800";
		default:
			return "bg-brand-900";
	}
	/* } */
}

function ExternalLinks() {
	const t = useTranslate("common");

	return (
		<>
			<DropDownItem>
				<DropDownExternalLink>
					<a href="https://meatico.metabaseapp.com/">{t("home.first-link")}</a>
				</DropDownExternalLink>
			</DropDownItem>
			<DropDownItem>
				<DropDownExternalLink href="https://help.meatico.ch/measu/meatico-bedienungsanleitungen">
					{t("home.second-link")}
				</DropDownExternalLink>
			</DropDownItem>
			<DropDownItem>
				<DropDownExternalLink href="https://help.meatico.ch/measu/meatico-updates">
					{t("home.third-link")}
				</DropDownExternalLink>
			</DropDownItem>
		</>
	);
}
