import { dateString } from "@app/modules/form/structs";
import { nbsp } from "@app/modules/layout/characters";
import { logger } from "@app/modules/logger/logger";
// eslint-disable-next-line no-restricted-imports
import type { Dayjs, ManipulateType, OpUnitType } from "dayjs";
// eslint-disable-next-line no-restricted-imports
import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";
import { is } from "superstruct";

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(isBetweenPlugin);
dayjs.extend(localizedFormat);

// list of timezone names: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// We enforce the swiss time zone for now. This should be made configurable at some point.
let localTimezone = "Europe/Zurich";

export function setTimezone(timezone: string) {
	localTimezone = timezone;
}

type DateString = `${string}-${string}-${string}`;

const LOCAL_TIME_FORMAT = "HH:mm";
const UTC_TIME_FORMAT = "HH:mm:ssZ";

export class LocalDate {
	readonly timezone: string;

	readonly dayjsDate: Dayjs;

	private constructor(
		timezone: string,
		date: Dayjs | string,
		{ invalid } = { invalid: false },
	) {
		this.timezone = timezone;
		if (invalid) {
			this.dayjsDate = dayjs("");
		} else {
			// try/catch due to bug in dayjs.tz that crashes the app: https://github.com/iamkun/dayjs/issues/1637
			try {
				this.dayjsDate = dayjs.tz(date, timezone);
			} catch (e) {
				logger.warn("Could not parse date:", date);
				this.dayjsDate = dayjs("");
			}
		}
	}

	static invalid() {
		return new LocalDate(localTimezone, "", { invalid: true });
	}

	static now() {
		return new LocalDate(localTimezone, dayjs());
	}

	/**
	 * @deprecated use more specific 'from' methods instead (e.g. `fromLocalTime`)
	 */
	static fromString(date?: string) {
		if (!date) {
			return LocalDate.now();
		}
		return new LocalDate(localTimezone, date);
	}

	static fromDateString(date: string) {
		if (isDateString(date)) {
			return new LocalDate(localTimezone, date);
		}
		return LocalDate.invalid();
	}

	static fromLocalTime(time: string) {
		const date = dayjs.tz(time, LOCAL_TIME_FORMAT, localTimezone);
		return new LocalDate(localTimezone, date);
	}

	static fromUtcString(date?: string) {
		const utcdate = dayjs.utc(date);
		return new LocalDate(localTimezone, utcdate);
	}

	static fromUtcTime(time: string) {
		const date = dayjs.utc(time, UTC_TIME_FORMAT);
		return new LocalDate(localTimezone, date);
	}

	static minDate() {
		return LocalDate.fromDateString("0001-01-01");
	}

	static maxDate() {
		return LocalDate.fromDateString("9999-12-31");
	}

	// TODO: remove this from localdate.
	static fromLotNumberString(lotNumber: string) {
		// Currently the same for every tenant; might change in the future
		const parsedDate = dayjs(lotNumber, "DDMMYY", true);

		if (!parsedDate.isValid()) {
			return LocalDate.invalid();
		}
		return new LocalDate(localTimezone, parsedDate);
	}

	isValid() {
		return this.dayjsDate.isValid();
	}

	add(value: number, unit?: ManipulateType) {
		const date = this.dayjsDate.add(value, unit);
		return new LocalDate(localTimezone, date);
	}

	subtract(value: number, unit?: ManipulateType) {
		const date = this.dayjsDate.subtract(value, unit);
		return new LocalDate(localTimezone, date);
	}

	startOf(unit: OpUnitType) {
		const date = this.dayjsDate.startOf(unit);
		return new LocalDate(localTimezone, date);
	}

	endOf(unit: OpUnitType) {
		const date = this.dayjsDate.endOf(unit);
		return new LocalDate(localTimezone, date);
	}

	tomorrow() {
		return this.add(1, "day");
	}

	isFirstDayOfWeek() {
		// sunday is day 0
		return this.dayjsDate.day() === 1;
	}

	isWorkingDay() {
		return workingDays.includes(this.dayjsDate.day());
	}

	nextWorkingDay(): LocalDate {
		const tomorrow = this.add(1, "day");
		return tomorrow.isWorkingDay() ? tomorrow : tomorrow.nextWorkingDay();
	}

	previousWorkingDay(): LocalDate {
		const yesterday = this.subtract(1, "day");
		return yesterday.isWorkingDay()
			? yesterday
			: yesterday.previousWorkingDay();
	}

	minusWorkingDays(days: number): LocalDate {
		if (days <= 0 || !Number.isInteger(days)) {
			return this;
		}
		return this.previousWorkingDay().minusWorkingDays(days - 1);
	}

	addWorkingDays(days: number): LocalDate {
		if (days <= 0 || !Number.isInteger(days)) {
			return this;
		}

		return this.nextWorkingDay().addWorkingDays(days - 1);
	}

	isBefore(other: LocalDate, unit?: OpUnitType) {
		return this.dayjsDate.isBefore(other.dayjsDate, unit);
	}

	isSame(other: LocalDate, unit?: OpUnitType) {
		return this.dayjsDate.isSame(other.dayjsDate, unit);
	}

	isToday() {
		return this.dayjsDate.isSame(LocalDate.now().toDateString(), "d");
	}

	isAfter(other: LocalDate, unit?: OpUnitType) {
		return this.dayjsDate.isAfter(other.dayjsDate, unit);
	}

	isBetween(
		from: LocalDate = LocalDate.minDate(),
		to: LocalDate = LocalDate.maxDate(),
		unit?: OpUnitType,
		// '[' means inclusive, '(' exclusive
		// '()' excludes start and end date (default)
		// '[]' includes start and end date
		// '[)' includes the start date but excludes the stop
		inclusivity?: `${"(" | "["}${")" | "]"}`,
	) {
		return this.dayjsDate.isBetween(
			from.dayjsDate,
			to.dayjsDate,
			unit,
			inclusivity ?? "[)",
		);
	}

	// formatting

	toLocalTime() {
		return this.dayjsDate.format(LOCAL_TIME_FORMAT);
	}

	toUtcTime() {
		return this.dayjsDate.utc().format(UTC_TIME_FORMAT);
	}

	toDateString(): DateString {
		return this.dayjsDate.format("YYYY-MM-DD") as DateString;
	}

	toLocalizedDateString({
		includeDayOfWeek = true,
	}: LocalizedFormatOptions = {}) {
		const localized = this.dayjsDate.format("L");
		return includeDayOfWeek
			? this.dayjsDate.format("dd,") + nbsp + localized
			: localized;
	}

	toUtcDateString(): DateString {
		return this.dayjsDate.utc().format("YYYY-MM-DD") as DateString;
	}

	toUtcString() {
		return this.dayjsDate.utc().format();
	}

	format(template: string) {
		return this.dayjsDate.format(template);
	}

	formatShort({ includeDayOfWeek = true }: LocalizedFormatOptions = {}) {
		const short = this.dayjsDate.format("DD.MM.");
		return includeDayOfWeek ? this.dayjsDate.format("dd, ") + short : short;
	}

	formatDateTime({ includeDayOfWeek = true }: LocalizedFormatOptions = {}) {
		const date = this.toLocalizedDateString({ includeDayOfWeek });
		const time = this.toLocalTime();
		return `${date}, ${time}`;
	}
}

export interface LocalizedFormatOptions {
	includeDayOfWeek?: boolean;
}

export interface GetNDaysOptions {
	startDate?: LocalDate;
	excludeStartDate?: boolean;
}

export function getPreviousNDays(n: number, options?: GetNDaysOptions) {
	const { startDate = LocalDate.now(), excludeStartDate } = options ?? {};

	const days = [];

	for (let index = n; excludeStartDate ? index > 0 : index >= 0; index -= 1) {
		days.push(startDate.subtract(index, "day"));
	}

	return days;
}

export function getNextNDays(n: number, options?: GetNDaysOptions) {
	const { startDate = LocalDate.now(), excludeStartDate } = options ?? {};
	const days = [];
	const startIndex = excludeStartDate ? 1 : 0;

	for (let index = startIndex; index <= n; index += 1) {
		days.push(startDate.add(index, "day"));
	}

	return days;
}

// for more information see here: https://day.js.org/docs/en/get-set/day
// when we go international this needs to be configurable
const workingDays = [1, 2, 3, 4, 5];

export function getLocalizedDateString(
	date: string,
	options?: LocalizedFormatOptions,
) {
	return LocalDate.fromString(date).toLocalizedDateString(options);
}

export function formatLocalTime(time: string) {
	return LocalDate.fromLocalTime(time).toLocalTime();
}

export function isDateString(
	date: string | undefined | null,
): date is DateString {
	if (!date || !is(date, dateString())) {
		return false;
	}

	const yearString = parseInt(date.split("-")[0] ?? "", 10);
	const isValidYear = yearString >= 1000 && yearString <= 9999;
	return isValidYear;
}
