import Polyglot from "node-polyglot";
import type { TranslateFunction, Translations } from "./types";

interface Options {
	translations: Translations;
	onMissingKey?: (key: string) => void;
	onKeyUsage?: (lkey: string) => void;
}

function makeTranslate({
	translations,
	onMissingKey,
	onKeyUsage,
}: Options): TranslateFunction {
	const polyglot = new Polyglot({
		phrases: translations,
		allowMissing: true,
		onMissingKey: (key) => {
			onMissingKey?.(key);
			return key;
		},
		interpolation: { prefix: "{{", suffix: "}}" },
	});
	return (key, options) => {
		const value = polyglot.t(key, options);
		if (value !== key) {
			onKeyUsage?.(key);
		}
		return value;
	};
}

export { makeTranslate };
