import { UserGroup } from "@app/icons/components/nav";
import { Card } from "@app/modules/cards/Card";
import type { SupplierCardProps } from "@app/modules/cards/card-props";
import { SupplierCardDocument } from "@app/modules/cards/queries/supplier-card.query.generated";
import { ContactDetails } from "@app/modules/contact/ContactDetails";
import { useQuery } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import { Textarea } from "@app/modules/input-fields/Textarea";
import { Body14 } from "@app/modules/typography";

export function SupplierCard({ supplierId }: SupplierCardProps) {
	const t = useTranslate("common");

	const [{ data }] = useQuery({
		query: SupplierCardDocument,
		variables: { supplierId },
		pause: !supplierId,
	});
	const supplier = data?.supplierByPk;

	if (!supplier) {
		return null;
	}

	const { name, mainAddress, contacts } = supplier;

	return (
		<Card>
			<Card.Header>
				<Card.Icon icon={UserGroup} />
				<Card.Title>{name}</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Content className="grid grid-cols-2">
					<div>
						{mainAddress?.id && (
							<div className="flex flex-col text-grey-900">
								<Body14 className="font-500">
									{t("cards.supplier.titles.main-address")}
								</Body14>
								<Body14 className="font-400">{mainAddress?.street1}</Body14>
								<Body14 className="font-400">{mainAddress?.street2}</Body14>
								<Body14 className="font-400">
									{mainAddress?.zip} {mainAddress?.city}
								</Body14>
							</div>
						)}
					</div>
					<div className="grid gap-16">
						{contacts.map((contact) => (
							<ContactDetails
								key={contact.id}
								variant="compact"
								contact={contact}
							/>
						))}
					</div>
				</Card.Content>
				{supplier.notes && (
					<Card.Content>
						<Textarea
							label={t("cards.supplier.titles.supplier-notes")}
							readOnly
							rows={3}
							value={supplier.notes}
						/>
					</Card.Content>
				)}
			</Card.Body>
		</Card>
	);
}
