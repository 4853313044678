import { useEffect, useRef, useState } from "react";

export type TransitionState =
	| "appear"
	| "entering"
	| "entered"
	| "exiting"
	| "exited";

export function useTransitionState(
	isOpen: boolean,
	duration: number,
): TransitionState {
	const [state, setState] = useState<TransitionState>(
		isOpen ? "entered" : "exited",
	);
	const durationRef = useRef(duration);
	durationRef.current = duration;
	useEffect(() => {
		const timeouts: ReturnType<typeof setTimeout>[] = [];
		if (isOpen) {
			setState("appear");
			timeouts.push(
				// We change the state only after 16ms. This gives the component enough
				// time to render before any transition classes are applied.
				// Required for components that onmount on exit.
				setTimeout(() => {
					setState("entering");
				}, 16),
				setTimeout(() => {
					setState("entered");
				}, durationRef.current),
			);
		} else if (state !== "exited") {
			setState("exiting");
			timeouts.push(
				setTimeout(() => {
					setState("exited");
				}, durationRef.current),
			);
		}

		return () => {
			timeouts.forEach((timeout) => clearTimeout(timeout));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return state;
}
