import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgFrist({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.012 5.528C4.882 4.19 5.92 3 7.282 3h8.432c1.36 0 2.403 1.188 2.27 2.529v-.001c-.269 2.76-1.31 4.18-2.293 5.178-.163.165-.33.326-.478.466l-.158.152c-.19.183-.332.33-.444.468-.202.249-.281.44-.281.706 0 .27.08.467.28.72.112.14.254.287.441.472l.166.162c.144.14.306.297.463.458.971 1 2.013 2.413 2.303 5.15a2.292 2.292 0 01-2.269 2.536H7.282a2.291 2.291 0 01-2.269-2.536c.29-2.738 1.333-4.143 2.305-5.137.197-.2.36-.359.503-.495.229-.221.401-.387.563-.592.198-.25.283-.452.283-.738 0-.28-.084-.478-.283-.725a5.27 5.27 0 00-.442-.467l-.167-.159c-.145-.138-.309-.294-.467-.454-.984-.994-2.027-2.404-2.296-5.165zm2.27-.532a.3.3 0 00-.283.338c.216 2.221 1.002 3.222 1.727 3.954.138.139.266.261.401.39l.201.192c.2.194.417.41.61.65.411.51.725 1.136.725 1.978 0 .838-.308 1.464-.713 1.977-.242.305-.576.63-.869.914-.122.118-.237.23-.335.33-.726.742-1.514 1.748-1.748 3.952-.02.182.125.329.284.329h8.432a.296.296 0 00.284-.33c-.234-2.205-1.023-3.22-1.75-3.968-.136-.14-.264-.265-.397-.395l-.2-.195a7.337 7.337 0 01-.603-.652c-.403-.508-.715-1.13-.715-1.962 0-.837.318-1.458.726-1.963.193-.237.409-.453.608-.646l.19-.182c.138-.132.27-.259.41-.401.726-.738 1.514-1.749 1.73-3.972v-.002a.298.298 0 00-.283-.336H7.282z"
			/>
			<path d="M14.496 19H8.519c-.536 0-.687-.688-.311-1.114C9 16.5 10.95 15.127 10.95 13.951v-2.895c0-.759-1.304-1.337-2.111-2.567C8.707 8.286 8.72 8 9.058 8h4.899c.288 0 .35.284.22.487-.796 1.232-2.128 1.807-2.128 2.569v2.895c0 1.166 1.951 2.549 2.759 3.936.341.46.222 1.113-.312 1.113z" />
		</svg>
	);
}

const MemoSvgFrist = React.memo(SvgFrist);
export default MemoSvgFrist;
