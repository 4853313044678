import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgInvoiceChecked({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={16}
			height={16}
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.667 2.556H4.333a.778.778 0 00-.777.777v9.334c0 .43.348.777.777.777h2.879c.17.568.44 1.093.788 1.556H4.333A2.333 2.333 0 012 12.667V3.333A2.333 2.333 0 014.333 1h7.334A2.333 2.333 0 0114 3.333v4.083a4.97 4.97 0 00-1.556-.397V3.333a.778.778 0 00-.777-.777zM10.432 7.25a.778.778 0 00-.21-.029H5.778a.778.778 0 100 1.556h2.399a5.007 5.007 0 012.255-1.527zm-3.148 3.085a.781.781 0 00-.062-.003H5.778a.778.778 0 100 1.556H7c.012-.544.11-1.066.283-1.553zM5 4.889c0-.43.348-.778.778-.778h4.444a.778.778 0 110 1.556H5.778A.778.778 0 015 4.889z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.921 14.81a.803.803 0 01-.115-.095l-1.572-1.572a.8.8 0 111.13-1.13l1.066 1.065 2.844-2.844a.8.8 0 111.13 1.13l-3.4 3.402a.8.8 0 01-1.082.045z"
			/>
		</svg>
	);
}

const MemoSvgInvoiceChecked = React.memo(SvgInvoiceChecked);
export default MemoSvgInvoiceChecked;
