import { initLogRocket } from "@app/modules/logrocket";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { App } from "./pages/_app";

initLogRocket();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
