import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOpenTab({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.75 3a1 1 0 011-1H21a1 1 0 011 1v5.25a1 1 0 11-2 0V5.414l-8.793 8.793a1 1 0 11-1.414-1.414L18.586 4H15.75a1 1 0 01-1-1zM4.875 7A.875.875 0 004 7.875v11.25a.875.875 0 00.875.875h11.25a.874.874 0 00.875-.875V10.5a1 1 0 012 0v8.625A2.877 2.877 0 0116.125 22H4.875A2.875 2.875 0 012 19.125V7.875A2.875 2.875 0 014.875 5h7.85a1 1 0 110 2h-7.85z"
			/>
		</svg>
	);
}

const MemoSvgOpenTab = React.memo(SvgOpenTab);
export default MemoSvgOpenTab;
