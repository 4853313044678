import {
	useAppState,
	useAppStateDispatch,
} from "@app/modules/app-state/context";
import type { Languages_Enum } from "@app/modules/graphql-api-types.generated";
import { usePublicQuery } from "@app/modules/graphql/queries";
import { useEffect } from "react";
import { PublicTenantInfoDocument } from "./queries/public-tenant-info.query.generated";

function TenantLoader() {
	const dispatch = useAppStateDispatch();
	const { tenant: currentTenant } = useAppState();

	const [result] = usePublicQuery({
		query: PublicTenantInfoDocument,
		variables: { hostname: window.location.hostname },
		// Don't reexecute the query if we already have a tenant
		pause: Boolean(currentTenant),
	});

	useEffect(() => {
		const tenantConfig = result.data?.get_public_tenant_info[0];
		if (tenantConfig) {
			const {
				tenant,
				auth0ClientId,
				auth0Domain,
				languages,
				mainLanguage,
				tenantShortName,
			} = tenantConfig;
			dispatch({
				type: "TENANT_LOADED",
				tenant,
				tenantDoc: {
					auth0ClientId,
					auth0Domain,
					languages: languages as Languages_Enum[],
					mainLanguage: mainLanguage as Languages_Enum,
					tenantShortName,
					tenantColor: dirtyHackyTenantColors[tenant],
				},
			});
		}
	}, [dispatch, result]);

	return null;
}

const dirtyHackyTenantColors: Record<string, string | undefined> = {
	frischfleisch: undefined,
	sempione: "#007D32",
};

export { TenantLoader };
