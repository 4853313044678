import type { ReactNode } from "react";
import React from "react";
import { isReady } from "./app-state";
import { useAppState } from "./context";

export interface AppStateViewProps {
	children?: ReactNode;
}

function AppStateView({ children }: AppStateViewProps) {
	const appState = useAppState();

	if (!isReady(appState)) {
		return (
			<>
				<header
					data-label="header-skeleton"
					className="h-header w-full bg-brand-900 animate-pulse"
				/>
				<div className="flex h-content bg-grey-100 animate-pulse">
					<div className="p-8 w-64 shrink-0">
						<div className="w-48 h-48 bg-grey-700 rounded" />
						<div className="p-12">
							<div className="w-24 h-24 bg-grey-600 rounded" />
						</div>
						<div className="p-12">
							<div className="w-24 h-24 bg-grey-600 rounded" />
						</div>
					</div>
					<div className="w-full max-w-screen-m" />
					<div className="bg-grey-300 w-32 shrink-0" />
				</div>
			</>
		);
	}

	return <>{children}</>;
}

export { AppStateView };
