import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCloudDownload({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<g
				clipPath="url(#CloudDownload_svg__clip0_4_24)"
				fillRule="evenodd"
				clipRule="evenodd"
			>
				<path d="M5.409 6.27C6.365 4.055 8.642 2 12 2c3.63 0 6.727 2.438 7.477 6.366.918.165 1.865.53 2.658 1.119 1.06.787 1.865 1.999 1.865 3.617 0 1.554-.658 2.804-1.732 3.64-1.047.814-2.427 1.191-3.851 1.191h-3.484a1 1 0 110-2h3.484c1.096 0 2.008-.292 2.623-.77.587-.457.96-1.123.96-2.061 0-.875-.41-1.53-1.057-2.012-.672-.499-1.574-.78-2.393-.821a1 1 0 01-.945-.895C17.25 5.971 14.82 4 12 4 9.338 4 7.641 5.752 7.086 7.485a1 1 0 01-.858.69C3.782 8.409 2 9.894 2 12.058c0 2.18 1.885 3.876 4.5 3.876h2.567a1 1 0 110 2H6.5c-3.435 0-6.5-2.326-6.5-5.876C0 8.735 2.587 6.76 5.409 6.269z" />
				<path d="M12.707 23.727a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L11 20.606V11a1 1 0 112 0v9.605l1.293-1.292a1 1 0 011.414 1.414l-3 3z" />
			</g>
			<defs>
				<clipPath id="CloudDownload_svg__clip0_4_24">
					<path d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

const MemoSvgCloudDownload = React.memo(SvgCloudDownload);
export default MemoSvgCloudDownload;
