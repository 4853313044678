// THIS FILE IS GENERATED, DO NOT EDIT!
import type * as Types from '../../graphql-api-types.generated';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllowedTenantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AllowedTenantsQuery = { __typename: 'query_root', tenants: Array<{ __typename: 'tenants', tenant: string, tenantName: string }> };


export const AllowedTenantsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AllowedTenants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tenants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"tenant"}},{"kind":"Field","name":{"kind":"Name","value":"tenantName"}}]}}]}}]} as unknown as DocumentNode<AllowedTenantsQuery, AllowedTenantsQueryVariables>;