import { createSharedState } from "@app/modules/graphql/pause-behavior";
import { useCallback } from "react";

export interface SharedCardState {
	cardId: string;
}

export interface CustomerCardPayload {
	customerId: string;
}
export interface CustomerCardState extends SharedCardState {
	type: "customer";
	payload: CustomerCardPayload;
}

export interface CustomerProductsCardPayload {
	customerId: string;
	customerOrderId: string;
}
export interface CustomerProductsCardState extends SharedCardState {
	type: "customer-products";
	payload: CustomerProductsCardPayload;
}

export interface CustomerOrderContainersCardPayload {
	customerId: string;
}
export interface CustomerOrderContainersCardState extends SharedCardState {
	type: "customer-order-containers";
	payload: CustomerOrderContainersCardPayload;
}

export interface CustomerProductHistoryCardPayload {
	customerId: string;
	customerOrderId: string;
	productId: string;
}
export interface CustomerProductHistoryCardState extends SharedCardState {
	type: "customer-product-history";
	payload: CustomerProductHistoryCardPayload;
}

export interface SupplierCardPayload {
	supplierId: string;
}
export interface SupplierCardState extends SharedCardState {
	type: "supplier";
	payload: SupplierCardPayload;
}

export interface SupplierSubOrdersCardPayload {
	supplierOrderId: string;
}
export interface SupplierSubOrdersCardState extends SharedCardState {
	type: "supplier-sub-orders";
	payload: SupplierSubOrdersCardPayload;
}

export interface SupplierOrderRelatedProductsCardPayload {
	supplierOrderId: string;
	supplierId: string;
	supplierOrderProductIds: string[];
}
export interface SupplierOrderRelatedProductsCardState extends SharedCardState {
	type: "supplier-order-related-products";
	payload: SupplierOrderRelatedProductsCardPayload;
}

export type CardState =
	| CustomerCardState
	| CustomerProductsCardState
	| CustomerOrderContainersCardState
	| CustomerProductHistoryCardState
	| SupplierCardState
	| SupplierSubOrdersCardState
	| SupplierOrderRelatedProductsCardState;
export type CardType = CardState["type"];
export type CreateAction = CardState & { cardId?: string };
export type UpdateAction<T extends CardState = CardState> = Partial<T>;

const sharedCardsState = createSharedState<{
	cards: CardState[];
}>({
	cards: [],
});

export function useSharedCardsState() {
	return sharedCardsState.useSharedState();
}

export function useCards() {
	const [{ cards: currentCards }, setCards] = useSharedCardsState();

	const addCards = useCallback(
		(cardsToAdd: CreateAction[]) => {
			setCards(({ cards }) => {
				const newIds = cardsToAdd.map(({ cardId }) => cardId);
				// Existing cards with the same ID as one of the new cards are replaced.
				const keptCards = cards.filter((card) => !newIds.includes(card.cardId));
				const newCards = cardsToAdd.map((card) =>
					card?.cardId
						? (card as CardState)
						: ({ ...card, cardId: generateCardId(card.type) } as CardState),
				);
				return {
					cards: [...keptCards, ...newCards],
				};
			});
		},
		[setCards],
	);

	const updateCard = useCallback(
		<T extends CardState>(cardId: string, action: UpdateAction<T>) => {
			setCards(({ cards }) => {
				const cardToUpdate = findCardToUpdate(cardId, cards, action);

				if (!action.payload || !cardToUpdate) {
					return { cards };
				}

				const keptCards = cards.filter((card) => card.cardId !== cardId);
				const updatedCard = { ...cardToUpdate, payload: action.payload };
				return {
					cards: [...keptCards, updatedCard],
				};
			});
		},
		[setCards],
	);

	const removeCards = useCallback(
		(cardIds: string[]) => {
			setCards(({ cards }) => ({
				cards: [...cards.filter((card) => !cardIds.includes(card.cardId))],
			}));
		},
		[setCards],
	);

	const removeCardsByType = useCallback(
		(cardTypes: CardType[]) => {
			setCards(({ cards }) => ({
				cards: [...cards.filter((card) => !cardTypes.includes(card.type))],
			}));
		},
		[setCards],
	);

	return {
		currentCards,
		setCards,
		addCards,
		removeCards,
		removeCardsByType,
		updateCard,
	};
}

function generateCardId(type: CardType): string {
	// We don't need true randomness. Ids only need to be session unique.
	return `${type}-${Date.now()}-${Math.floor(Math.random() * 100)}`;
}

function findCardToUpdate<T extends CardState>(
	cardId: string,
	cards: CardState[],
	action: UpdateAction<T>,
) {
	const foundCard = cards.find((card) => card.cardId === cardId);
	if (foundCard && action.type === foundCard.type) {
		return foundCard as T;
	}

	return undefined;
}
