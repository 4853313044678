import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCalculatorclose({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M4.44 2.112A2.562 2.562 0 016.557 1h10.889C18.845 1 20 2.126 20 3.538v14.134l-2-2V3.538A.547.547 0 0017.445 3H6.555A.547.547 0 006 3.538v.134l-1.56-1.56zM6 9.328l-2-2v13.133A2.547 2.547 0 006.556 23h10.889a2.56 2.56 0 001.64-.592l-.477-.472-.97-.97a.571.571 0 01-.194.034H6.557A.547.547 0 016 20.462V9.328z" />
			<path d="M10.384 13.712l-2.096-2.096a1.5 1.5 0 002.096 2.096zM15.384 18.712l-2.096-2.096a1.5 1.5 0 002.096 2.096zM8.042 5.713A1 1 0 019 5h6a1 1 0 011 1v2a1 1 0 01-1 1h-3.672L8.042 5.713zM13.616 11.288a1.5 1.5 0 012.096 2.096l-2.096-2.096zM11 17.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.613 3.71l.094.083 16 16a1 1 0 01-1.32 1.497l-.094-.083-16-16a1 1 0 011.32-1.497z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.906 2.003L3 2.086 21.914 21a1 1 0 01-1.32 1.497l-.094-.083L1.586 3.5a1 1 0 011.32-1.497z"
			/>
		</svg>
	);
}

const MemoSvgCalculatorclose = React.memo(SvgCalculatorclose);
export default MemoSvgCalculatorclose;
