import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTaskfinished({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M16.366 9.643a1 1 0 00-1.532-1.286l-4.3 5.12-1.39-1.546a1 1 0 10-1.487 1.338l2.16 2.4a1 1 0 001.509-.026l5.04-6z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2C6.479 2 2 6.479 2 12s4.479 10 10 10 10-4.479 10-10S17.521 2 12 2zM4 12c0-4.416 3.584-8 8-8s8 3.584 8 8-3.584 8-8 8-8-3.584-8-8z"
			/>
		</svg>
	);
}

const MemoSvgTaskfinished = React.memo(SvgTaskfinished);
export default MemoSvgTaskfinished;
