import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgPrint({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={40}
			height={41}
			viewBox="0 0 40 41"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M31.667 15.4a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.125 3.733H26.88a4.8 4.8 0 014.787 4.787v.213h.213a4.837 4.837 0 013.367 1.373c.901.88 1.416 2.082 1.42 3.345V25.683a4.697 4.697 0 01-1.42 3.345c-.9.879-2.11 1.368-3.367 1.372H30v3.246c0 1.725-1.299 3.42-3.25 3.42h-13.5c-1.951 0-3.25-1.695-3.25-3.42V30.4H8.12a4.838 4.838 0 01-3.367-1.372 4.698 4.698 0 01-1.42-3.345V13.45a4.697 4.697 0 011.42-3.344c.9-.88 2.11-1.369 3.367-1.373h.214V8.52a4.801 4.801 0 014.786-4.787h.005zm15.209 4.795v.205H11.666v-.205a1.468 1.468 0 011.461-1.461h13.744a1.468 1.468 0 011.462 1.46zM30 27.067h1.872a1.504 1.504 0 001.045-.423c.27-.264.415-.613.416-.97V13.46a1.364 1.364 0 00-.416-.97 1.504 1.504 0 00-1.045-.422H8.128a1.504 1.504 0 00-1.045.422c-.27.264-.415.613-.416.97v12.216c.001.356.147.705.416.97.271.264.646.42 1.045.422H10v-4.913c0-1.725 1.299-3.42 3.25-3.42h13.5c1.951 0 3.25 1.695 3.25 3.42v4.913zm-16.666-4.913c0-.038.006-.067.014-.087h13.304c.008.02.015.049.015.087v11.492a.247.247 0 01-.015.087H13.348a.247.247 0 01-.014-.087V22.154z"
			/>
		</svg>
	);
}

const MemoSvgPrint = React.memo(SvgPrint);
export default MemoSvgPrint;
