import type { EntitySelectWrapperProps } from "@app/modules/entity/EntitySelect";
import { EntitySelect } from "@app/modules/entity/EntitySelect";
import type { ControlledProps } from "@app/modules/form/controller";
import { useController } from "@app/modules/form/controller";
import type { SelectItem } from "@app/modules/input-fields/Select";
import { getProductGroupName } from "@app/modules/product-group/product-group-utils";
import type {
	ProductGroupSelectItemFragment,
	ProductGroupSelectQueryVariables,
} from "@app/modules/product-group/query/product-group-select.query.generated";
import { ProductGroupSelectDocument } from "@app/modules/product-group/query/product-group-select.query.generated";
import { forwardRef } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import type { AnyVariables } from "urql";

function makeSelectItem(group: ProductGroupSelectItemFragment): SelectItem {
	return {
		id: group.id,
		label: getProductGroupName(group),
		domainId: group.domainId,
		tenant: group.tenant,
	};
}

export interface ProductGroupSelectProps extends EntitySelectWrapperProps {
	queryVariables?: ProductGroupSelectQueryVariables;
}

export const ProductGroupSelect = forwardRef<
	HTMLButtonElement,
	ProductGroupSelectProps
>(function ProductGroupSelect(props, ref) {
	return (
		<EntitySelect
			ref={ref}
			queryDocument={ProductGroupSelectDocument}
			getEntities={({ entities }) => entities}
			asSelectOption={makeSelectItem}
			showSharedEntities
			requestPolicy="cache-first"
			{...props}
		/>
	);
});

export function ProductGroupSelectField<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
>({
	control,
	name,
	defaultValue,
	constraint,
	...props
}: ProductGroupSelectProps &
	ControlledProps<Values, Name, ConstraintResult, ConstraintVariables>) {
	const { field } = useController({ control, name, defaultValue, constraint });
	return <ProductGroupSelect {...props} {...field} />;
}
