import type { StructErrorValue } from "@app/modules/form/structs";
import { MESSAGE_STRUCT_TYPE } from "@app/modules/form/structs";
import { useTranslate } from "@app/modules/i18n/context";
import { useCallback, useRef } from "react";
import type { FieldError, FieldErrors } from "react-hook-form";
import { get } from "react-hook-form";

const DEFAULT_ERROR_MESSAGE = "form.errors.required";

export interface CustomMessages {
	[key: string]: string;
}

export function useGetErrorMessage(customMessages: CustomMessages = {}) {
	const t = useTranslate("common");
	const msgsRef = useRef(customMessages);
	msgsRef.current = customMessages;

	const getErrorMessage = useCallback(
		(error?: FieldError | FieldErrors, name?: string) => {
			const foundError = name ? get(error, name) : error;
			if (!isFieldError(foundError)) {
				return undefined;
			}

			const errorKey =
				foundError.type === MESSAGE_STRUCT_TYPE
					? foundError.message
					: foundError.type;

			if (!errorKey) {
				return DEFAULT_ERROR_MESSAGE;
			}

			const customMsg = msgsRef.current[errorKey];
			if (customMsg) {
				return customMsg;
			}

			const translationKey =
				errorTranslationKeys[errorKey as StructErrorValue] ??
				DEFAULT_ERROR_MESSAGE;

			return t(translationKey);
		},
		[t],
	);

	return getErrorMessage;
}

function isFieldError(error: FieldError | undefined): error is FieldError {
	return Boolean(error?.type);
}

const errorTranslationKeys: Record<StructErrorValue, string> = {
	required: "form.errors.required",
	"positive-numeric-string": "form.errors.positive-string",
	email: "form.errors.email",
	json: "products.form.sales.bizerba-template.invalid-json",
	phone: "form.errors.phone",
	ip: "form.errors.ip",
	"future-time": "form.errors.future-time",
};
