export function organizeBy<
	Key extends string,
	T extends { [key in Key]: string },
>(list: T[], key: Key): Record<string, T> {
	return list.reduce((acc, cur) => {
		acc[cur[key]] = cur;
		return acc;
	}, {} as Record<string, T>);
}

export function organizeById<T extends { id: string }>(
	list: T[],
): Record<string, T> {
	return organizeBy(list, "id");
}

export function organizeByDomainId<T extends { domainId: string }>(
	list: T[],
): Record<string, T> {
	return organizeBy(list, "domainId");
}

export function organizeByFn<T extends Record<string, unknown>>(
	list: T[],
	fn: (arg: T) => string,
): Record<string, T> {
	return list.reduce((acc, cur) => {
		const key = fn(cur);
		acc[key] = cur;
		return acc;
	}, {} as Record<string, T>);
}
