import {
	HardwareUserConfigProvider,
	useHardwareUserConfig,
} from "@app/modules/hardware/HardwareUserConfig";
import {
	getPairedSerialDevices,
	listenForSerialMessages,
	pairSerialDevice,
	write,
} from "@app/modules/hardware/serial";
import { getSerialConfiguration } from "@app/modules/hardware/serial-configuration";
import type {
	DeviceType,
	HardwareCommand,
	HardwareDevice,
	HardwareMessage,
} from "@app/modules/hardware/types";
import {
	claimInterface,
	createUSBHardwareDevice,
	getDeviceKey,
	getPairedUSBDevices,
	listenForUSBMessages,
	pairUSBDevice,
	releaseInterface,
} from "@app/modules/hardware/usb";
import { getUSBDeviceConfiguration } from "@app/modules/hardware/usb-configurations";
import { logger } from "@app/modules/logger/logger";
import type { UiActionTarget } from "@app/modules/ui-actions/types";
import type { ReactNode } from "react";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { makeSubject, pipe, subscribe } from "wonka";

type DeviceMap = Record<string, HardwareDevice>;

const HardwareDevicesContext = createContext({
	devices: {} as DeviceMap,
	sendCommand: (_deviceKey: string, _cmd: HardwareCommand) => {},
	pairDevice: async (_type: DeviceType) => {},
	claimDevice: async (_deviceKey: string) => {},
	releaseDevice: async (_deviceKey: string) => {},
	listen: (_deviceKey: string) => () => {},
	messages: makeSubject<HardwareMessage>().source,
});

interface HardwareDevicesProviderProps {
	children?: ReactNode;
}

function HardwareDevicesProvider({ children }: HardwareDevicesProviderProps) {
	const [devices, setDevices] = useState<DeviceMap>({});
	const { getConfig } = useHardwareUserConfig();
	const subjectRef = useRef(makeSubject<HardwareMessage>());
	const { source, next } = subjectRef.current;

	const pairDevice = useCallback(async (type: DeviceType) => {
		const device =
			type === "usb" ? await pairUSBDevice() : await pairSerialDevice();
		if (device) {
			setDevices((currDevices) => ({ ...currDevices, [device.key]: device }));
		}
	}, []);

	const claimDevice = useCallback(
		async (deviceKey: string) => {
			const device = devices[deviceKey];
			if (device && device.type === "usb") {
				const config = getUSBDeviceConfiguration(device.vendorProductId);
				if (config) {
					await claimInterface(device, config);
				}
			}
		},
		[devices],
	);

	const releaseDevice = useCallback(
		async (deviceKey: string) => {
			const device = devices[deviceKey];
			if (device && device.type === "usb") {
				const config = getUSBDeviceConfiguration(device.vendorProductId);
				if (config) {
					await releaseInterface(device, config);
				}
			}
		},
		[devices],
	);

	const listen = useCallback(
		(deviceKey: string) => {
			const device = devices[deviceKey];
			if (device?.type === "usb") {
				const config = getUSBDeviceConfiguration(device.vendorProductId);
				if (config) {
					return listenForUSBMessages(device, config, next);
				}
			} else if (device?.type === "serial") {
				const userConfig = getConfig(deviceKey);
				if (userConfig?.productKey) {
					const config = getSerialConfiguration(userConfig?.productKey);
					if (config) {
						return listenForSerialMessages(device, config, next);
					}
				}
			}
			logger.warn(
				"HARDWARE",
				"tried listening to non-existing device",
				deviceKey,
			);
			return () => {};
		},
		[devices, getConfig, next],
	);

	const sendCommand = useCallback(
		(deviceKey: string, cmd: HardwareCommand) => {
			const device = devices[deviceKey];
			if (device?.type === "usb") {
				const config = getUSBDeviceConfiguration(device.vendorProductId);
				if (config) {
					// todo: implement
				}
			} else if (device?.type === "serial") {
				const userConfig = getConfig(deviceKey);
				if (userConfig?.productKey) {
					const config = getSerialConfiguration(userConfig?.productKey);
					if (config) {
						write(device, config, cmd);
					}
				}
			}
		},
		[devices, getConfig],
	);

	// Connect to paired devices on mount
	useEffect(() => {
		let isMounted = true;
		Promise.all([getPairedUSBDevices(), getPairedSerialDevices()]).then(
			([usbDevices, serialPorts]) => {
				if (isMounted) {
					setDevices((currentDevices) =>
						[...usbDevices, ...serialPorts].reduce(
							(acc, device) => {
								const deviceKey = device.key;
								logger.info("HARDWARE", "connected on load to", deviceKey);
								acc[deviceKey] = device;
								return acc;
							},
							{ ...currentDevices },
						),
					);
				}
			},
		);
		return () => {
			isMounted = false;
		};
	}, []);

	// connect/disconnect event listeners
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (navigator.usb) {
			const connectListener = (event: USBConnectionEvent) => {
				const { device } = event;
				const deviceKey = getDeviceKey(device);
				logger.info("HARDWARE", "connected", deviceKey);
				setDevices((currentDevices) => ({
					...currentDevices,
					[deviceKey]: createUSBHardwareDevice(device),
				}));
			};

			navigator.usb.addEventListener("connect", connectListener);

			const disconnectListener = (event: USBConnectionEvent) => {
				const { device } = event;
				const deviceKey = getDeviceKey(device);
				logger.info("HARDWARE", "disconnected", deviceKey);
				setDevices((currentDevices) => {
					const { [deviceKey]: _removedDevice, ...connectedDevices } = {
						...currentDevices,
					};
					return connectedDevices;
				});
			};

			navigator.usb.addEventListener("disconnect", disconnectListener);

			return () => {
				navigator.usb.removeEventListener("connect", connectListener);
				navigator.usb.removeEventListener("disconnect", disconnectListener);
			};
		}

		logger.warn("HARDWARE", "WebUSB is not available on this platform!");
	}, []);

	const value = useMemo(
		() => ({
			devices,
			sendCommand,
			pairDevice,
			claimDevice,
			releaseDevice,
			listen,
			messages: source,
		}),
		[
			claimDevice,
			devices,
			listen,
			pairDevice,
			releaseDevice,
			sendCommand,
			source,
		],
	);

	return (
		<HardwareDevicesContext.Provider value={value}>
			{children}
		</HardwareDevicesContext.Provider>
	);
}

export function useListenHardwareDevice(target: UiActionTarget) {
	const { findDeviceKeyByAssignment } = useHardwareUserConfig();
	const deviceKey = findDeviceKeyByAssignment(target);

	const { listen, devices } = useHardware();
	const isDeviceAvailable = deviceKey ? Boolean(devices[deviceKey]) : false;

	useEffect(() => {
		if (deviceKey && isDeviceAvailable) {
			const unlisten = listen(deviceKey);
			return unlisten;
		}

		return undefined;
	}, [isDeviceAvailable, deviceKey, listen]);
}

export function useDefaultScaleConnection() {
	useListenHardwareDevice("SCALE_1");
}

export function useHardware() {
	return useContext(HardwareDevicesContext);
}

export function useHardwareMessageSubscription(
	onMessage: (message: HardwareMessage) => void,
) {
	const { messages } = useContext(HardwareDevicesContext);
	useEffect(() => {
		const { unsubscribe } = pipe(messages, subscribe(onMessage));
		return unsubscribe;
	}, [messages, onMessage]);
}

interface HardwareProviderProps {
	children?: ReactNode;
}

export function HardwareProvider({ children }: HardwareProviderProps) {
	return (
		<HardwareUserConfigProvider>
			<HardwareDevicesProvider>{children}</HardwareDevicesProvider>
		</HardwareUserConfigProvider>
	);
}
