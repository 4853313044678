import type {
	Maybe,
	Product_Group_Types_Enum,
} from "@app/modules/graphql-api-types.generated";
import type { TranslateFunction } from "@app/modules/i18n/types";

export const PRODUCT_GROUP_CONTAINER = "CONTAINER";

export function isContainerProduct(product: {
	productGroupMembers: Array<{ productGroup: { name: string } }>;
}) {
	return product.productGroupMembers.some(
		({ productGroup: { name } }) => name === PRODUCT_GROUP_CONTAINER,
	);
}

export function getProductGroupName({
	internalName,
	name,
}: {
	internalName?: Maybe<string> | undefined;
	name: string;
}) {
	return internalName ?? name;
}

export function getProductGroupTypeName(
	t: TranslateFunction,
	type: Product_Group_Types_Enum,
) {
	return t(`product-groups.types.${type}`);
}
