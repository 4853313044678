import type {
	LocalSearchFilter,
	LocalSearchFilterValue,
	LocalSearchRangeFilter,
	LocalSearchScope,
	LocalSearchScopeKey,
	LocalSearchSuggestion,
	LocalSearchValueFilter,
	ValueOperator,
} from "@app/modules/search/local/types";
import type { SearchIconName } from "@app/modules/search/search-icons";
import { assertUnreachableDefaultCase } from "@app/modules/utils/assertions";
import { setIn } from "@app/modules/utils/object";
import type { VariablesOf } from "@graphql-typed-document-node/core";
import type { DependencyList } from "react";
import { useMemo } from "react";
import type { DocumentInput } from "urql";

export const DATE_FILTER_KEY = "date";

/**
 * @deprecated Use `useLocalSearchScope` instead as it offers more options and is memoized by default
 */
export function makeLocalSearchScope<QueryDocument extends DocumentInput>(
	scopeKey: LocalSearchScopeKey,
	queryDocument: QueryDocument,
	fields: LocalSearchScope<QueryDocument>["fields"],
	queryVariables?: (search: string) => VariablesOf<QueryDocument>,
): LocalSearchScope<QueryDocument> {
	return {
		scopeKey,
		queryDocument,
		fields,
		queryVariables,
	};
}

export function useLocalSearchScope<QueryDocument extends DocumentInput>(
	scope: () => LocalSearchScope<QueryDocument>,
	deps: DependencyList,
) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => scope(), deps);
}

interface MakeLocalSearchSuggestionOptions
	extends Omit<MakeLocalSearchValueFilterOptions, "label"> {
	key?: string;
	domainId?: string;
	tenant?: string;
	label?: string;
}

export function makeLocalSearchSuggestion(
	path: string,
	value: LocalSearchFilterValue,
	{
		key,
		icon,
		label = value.toString(),
		domainId,
		tenant,
		...other
	}: MakeLocalSearchSuggestionOptions = {},
): LocalSearchSuggestion {
	return {
		key: key ?? makeLocalSearchSuggestionKey({ path, value, tenant }),
		label,
		icon,
		domainId,
		tenant,
		filter: makeLocalSearchValueFilter(path, value, { icon, label, ...other }),
	};
}

export function makeLocalSearchSuggestionKey<FilterValue>({
	prefix,
	path,
	value,
	tenant,
}: {
	/** Use to avoid conflicts when same entities appear as different filters. */
	prefix?: string;
	path: string;
	value?: FilterValue;
	tenant: string | undefined;
}) {
	return [prefix, path, value, tenant].join(":");
}

export interface MakeLocalSearchValueFilterOptions {
	customFilterKey?: string;
	operator?: ValueOperator;
	label?: string;
	icon?: SearchIconName;
	visible?: boolean;
	shouldDeactivateDateFilter?: boolean;
}

export function makeLocalSearchValueFilter(
	path: string,
	value: LocalSearchFilterValue,
	{
		customFilterKey,
		icon,
		operator = typeof value === "string" || typeof value === "boolean"
			? "_eq"
			: "_in",
		label,
		visible = true,
		shouldDeactivateDateFilter = false,
	}: MakeLocalSearchValueFilterOptions,
): LocalSearchValueFilter {
	return {
		type: "Value",
		key: customFilterKey,
		path,
		operator,
		value,
		label,
		visible,
		icon,
		shouldDeactivateDateFilter,
	};
}

export type MakeLocalSearchRangeFilterOptions = Omit<
	PartialPartial<LocalSearchRangeFilter, "visible">,
	"type"
>;

export function makeLocalSearchRangeFilter({
	visible = true,
	shouldDeactivateDateFilter = false,
	...rest
}: MakeLocalSearchRangeFilterOptions): LocalSearchRangeFilter {
	return {
		type: "Range",
		visible,
		shouldDeactivateDateFilter,
		...rest,
	};
}

export function makeQueryFiltersFromLocalSearchFilters(
	filters: LocalSearchFilter[],
) {
	const isDateFilterDisabled = filters.some(
		(filter) => filter.shouldDeactivateDateFilter,
	);
	const conditions = filters.reduce<Record<string, unknown>>((acc, filter) => {
		const { key, path } = filter;
		if (key === DATE_FILTER_KEY && isDateFilterDisabled) {
			return acc;
		}

		const conditionKey = key ?? path;
		const condition = getFilterCondition(filter);
		acc[conditionKey] = acc[conditionKey]
			? { _or: [acc[conditionKey], condition] }
			: condition;
		return acc;
	}, {});

	return { _and: Object.values(conditions) };
}

function getFilterCondition(filter: LocalSearchFilter) {
	const { path, type } = filter;
	switch (type) {
		case "Value":
			return setIn({}, path, { [filter.operator]: filter.value });
		case "Range":
			return setIn({}, path, {
				...(filter.from && { [filter.fromOperator]: filter.from }),
				...(filter.to && { [filter.toOperator]: filter.to }),
			});
		default:
			return assertUnreachableDefaultCase(type);
	}
}
