import React from "react";

interface HiddenSubmitButtonProps {
	disabled?: boolean;
}

/**
 * Add this component to a form without a submit button.
 * This will make it possible to submit the form by pressing ENTER.
 */
function HiddenSubmitButton({ disabled }: HiddenSubmitButtonProps) {
	return (
		<button type="submit" className="hidden" aria-hidden disabled={disabled} />
	);
}

export { HiddenSubmitButton };
