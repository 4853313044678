import { Email, Phone } from "@app/icons/components";
import { User } from "@app/icons/components/nav";
import { Icon } from "@app/icons/Icon";
import { getTelHref } from "@app/modules/contact/contact-utils";
import type {
	Contacts,
	Emails,
	Phones,
} from "@app/modules/graphql-api-types.generated";
import { useTranslate } from "@app/modules/i18n/context";
import { CopyButton } from "@app/modules/layout/CopyButton";
import { HorizontalList } from "@app/modules/layout/HorizontalList";
import { IconWithText } from "@app/modules/layout/IconWithText";
import { TooltipV2 } from "@app/modules/layout/TooltipV2";
import { Body14, Caption12 } from "@app/modules/typography";

export interface ContactDetailsProps {
	contact: Pick<Contacts, "name" | "notes"> & {
		phones: Pick<Phones, "id" | "phoneNumber">[];
	} & { emails: Pick<Emails, "id" | "email">[] };
	variant?: "default" | "compact";
}

function ContactDetails({ contact, variant = "default" }: ContactDetailsProps) {
	const t = useTranslate("common");
	return (
		<div>
			{variant === "default" && (
				<Caption12 className="text-grey-700 font-500">
					{t("contacts.titles.contact")}
				</Caption12>
			)}
			<HorizontalList className="justify-start text-grey-900 text-ellipsis gap-8 font-500 ">
				<Icon icon={User} size="16" />
				<Body14>
					<div>{contact.name}</div>
				</Body14>
			</HorizontalList>
			{contact.phones.map(({ id: phoneId, phoneNumber }) => (
				<a key={phoneId} href={getTelHref(phoneNumber)}>
					<IconWithText className="mt-0 mb-4" icon={Phone}>
						{phoneNumber}
					</IconWithText>
				</a>
			))}
			{variant === "compact" &&
				contact.emails.map(({ id: emailId, email }) => (
					<TooltipV2
						key={emailId}
						placement="bottom-start"
						content={
							<Body14 className="font-500 text-grey-900">{email}</Body14>
						}
					>
						<div className="flex gap-8 ">
							<a key={emailId} href={`mailto:${email}`}>
								<Icon icon={Email} size="16" className="text-grey-900" />
							</a>
							<CopyButton text={email} />
						</div>
					</TooltipV2>
				))}
			{variant === "default" &&
				contact.emails.map(({ id: emailId, email }) => (
					<a key={emailId} href={`mailto:${email}`}>
						<IconWithText icon={Email}>{email}</IconWithText>
					</a>
				))}
		</div>
	);
}

export { ContactDetails };
