import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgBestandknapp({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 5a7 7 0 00-7 7h14a7 7 0 00-7-7zm-9 7a9 9 0 1118 0 9 9 0 01-18 0z"
			/>
		</svg>
	);
}

const MemoSvgBestandknapp = React.memo(SvgBestandknapp);
export default MemoSvgBestandknapp;
