import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgEyeopen({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.962 5c4.202 0 8.188 2.574 10.72 6.485.365.566.365 1.294.002 1.857-2.512 3.934-6.476 6.486-10.722 6.486-4.272 0-8.168-2.52-10.688-6.466a1.714 1.714 0 01.032-1.906C4.209 7.294 8.033 5 11.962 5zm0 2c-3.128 0-6.268 1.827-8.78 5.269l-.123.171.093.14c2.047 3.042 5.001 5 8.19 5.226l.31.017.31.005c3.4 0 6.67-2.04 8.844-5.268l.094-.145-.094-.14c-2.059-3.017-5.09-5.02-8.234-5.252l-.305-.017L11.962 7zm.015.85a4.563 4.563 0 110 9.128 4.563 4.563 0 010-9.127zm0 2a2.564 2.564 0 100 5.128 2.564 2.564 0 000-5.127z"
			/>
		</svg>
	);
}

const MemoSvgEyeopen = React.memo(SvgEyeopen);
export default MemoSvgEyeopen;
