import { autoHeightCls } from "@app/modules/layout/autoheight";
import { useExperimentalScrollRestoration } from "@app/modules/layout/useScrollRestoration";
import clsx from "clsx";
import type { ReactNode } from "react";
import React from "react";

export interface MainAreaProps {
	className?: string;
	children?: ReactNode;
}

export function MainArea({ className, children }: MainAreaProps) {
	const enabledPaths = [
		"/dismantling",
		"/products",
		"/customers",
		"/tours",
		"/suppliers",
	];
	const { ref } = useExperimentalScrollRestoration({
		// we experimentally only enable scroll restoration for the dismantling plan
		// todo: enable for more/all pages if the feature works well enough
		isEnabledForPath: (path) =>
			enabledPaths.some((enabledPath) => path.startsWith(enabledPath)),
	});

	return (
		<div
			className={clsx(
				className,
				"overflow-auto scrollbar-stable flex justify-center w-full",
			)}
			ref={ref}
		>
			<div
				className={clsx("max-w-screen-2xl", autoHeightCls(true))}
				data-label="main-area"
			>
				{children}
			</div>
		</div>
	);
}
