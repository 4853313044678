import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOpenpage({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.724 11.387l-.083-.094-6-6a1 1 0 00-1.498 1.32l.083.094L16.52 11H4a1 1 0 00-.116 1.993L4 13H16.52l-4.292 4.293a1 1 0 00-.084 1.32l.083.094a1 1 0 001.32.083l.095-.083 6-6a1 1 0 00.083-1.32z"
			/>
		</svg>
	);
}

const MemoSvgOpenpage = React.memo(SvgOpenpage);
export default MemoSvgOpenpage;
