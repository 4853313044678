import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgEdi({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.333 3.74h2.834C16.732 3.74 18 4.964 18 6.477V10.1a.944.944 0 11-1.889 0v-3.62c0-.505-.423-.914-.944-.914H3.833c-.521 0-.944.409-.944.913v12.783c0 .504.423.913.944.913h11.334c.521 0 .944-.409.944-.913v-2.708a.944.944 0 011.889 0v2.708C18 20.774 16.732 22 15.167 22H3.833C2.27 22 1 20.774 1 19.26V6.479C1 4.965 2.269 3.74 3.833 3.74h2.834C6.667 2.226 7.935 1 9.5 1c1.565 0 2.833 1.226 2.833 2.74zm-1.889 0c0-.505-.422-.914-.944-.914s-.944.409-.944.913h1.888z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.932 9.889a.987.987 0 000 1.353l2.748 2.87a.889.889 0 001.295 0 .987.987 0 000-1.354l-1.184-1.236h8.798c.506 0 .916-.428.916-.957 0-.528-.41-.956-.916-.956H7.79l1.184-1.237a.987.987 0 000-1.353.889.889 0 00-1.295 0L4.932 9.89zM22.732 15.628a.987.987 0 010 1.353l-2.748 2.87a.889.889 0 01-1.295 0 .987.987 0 010-1.353l1.184-1.237h-8.798c-.506 0-.916-.428-.916-.957 0-.528.41-.956.916-.956h8.798l-1.184-1.237a.987.987 0 010-1.353.889.889 0 011.295 0l2.748 2.87z"
			/>
		</svg>
	);
}

const MemoSvgEdi = React.memo(SvgEdi);
export default MemoSvgEdi;
