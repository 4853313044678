import type { Product_Groups_Bool_Exp } from "@app/modules/graphql-api-types.generated";
import { Product_Group_Types_Enum } from "@app/modules/graphql-api-types.generated";

// This mirrors how the backend handles product groups for dismantling.
// I am aware the the production site calendar has a bigger scope than just dismantling. But we don't have production yet.
export const productGroupsForDismantlingBoolExp = {
	type: { _eq: Product_Group_Types_Enum.Animal },
	// do not include animal pgs that have animal children pgs
	productGroups_aggregate: {
		count: {
			filter: { type: { _eq: Product_Group_Types_Enum.Animal } },
			predicate: { _eq: 0 },
		},
	},
} satisfies Product_Groups_Bool_Exp;
