import { useAppState } from "@app/modules/app-state/context";
import { useAuth0 } from "@app/modules/auth0/Auth0Provider";
import { useEffect } from "react";

function LoginOverlay() {
	const { authenticationState } = useAppState();
	const { login } = useAuth0();

	useEffect(() => {
		if (authenticationState === "unauthenticated") {
			login();
		}
	}, [authenticationState, login]);

	return null;
}

export { LoginOverlay };
