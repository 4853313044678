import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgW3({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width="40"
			height="41"
			viewBox="0 0 40 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<g clipPath="url(#clip0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M22 1.2998C22.8284 1.2998 23.5 1.97138 23.5 2.7998V32.0267C23.5 36.9964 19.8384 41.2998 15 41.2998C10.1616 41.2998 6.5 36.9964 6.5 32.0267C6.5 31.1983 7.17157 30.5267 8 30.5267C8.82843 30.5267 9.5 31.1983 9.5 32.0267C9.5 35.6429 12.1064 38.2998 15 38.2998C17.8936 38.2998 20.5 35.6429 20.5 32.0267V2.7998C20.5 1.97138 21.1716 1.2998 22 1.2998Z"
					fill="#F1F2F4"
				/>
				<ellipse cx="22" cy="6.7998" rx="6" ry="6" fill="#F1F2F4" />
				<ellipse cx="22" cy="6.7998" rx="3" ry="3" fill="#B0B7C3" />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect
						width="40"
						height="40"
						fill="white"
						transform="translate(0 0.799805)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

const MemoSvgW3 = React.memo(SvgW3);
export default MemoSvgW3;
