import type {
	LocalSearchFilter,
	LocalSearchFilterType,
	LocalSearchScope,
} from "@app/modules/search/local/types";
import type { UseLocalSearchFiltersOptions } from "@app/modules/search/local/useLocalSearchFilters";
import { useLocalSearchFilters } from "@app/modules/search/local/useLocalSearchFilters";
import type { ReactNode } from "react";
import { createContext, useCallback, useMemo, useState } from "react";

export interface LocalSearchContextProps {
	searchFilters: LocalSearchFilter[];
	queryFilters: Record<string, unknown>;
	activeSearchScope?: LocalSearchScope;
	addSearchFilters(filter: LocalSearchFilter[]): void;
	removeSearchFilter(filter: LocalSearchFilter): void;
	removeSearchFilterByKey(...keys: string[]): void;
	setSearchFilters(except?: LocalSearchFilter[]): void;
	setActiveSearchScope(
		scope: LocalSearchScope | undefined,
		options?: UseLocalSearchFiltersOptions,
	): void;
	hasFilter(filter: LocalSearchFilter): boolean;
	findFilterByKey<T extends LocalSearchFilterType>(
		key: string,
		type: T,
	): DiscriminateUnion<LocalSearchFilter, "type", T> | undefined;
}

const LocalSearchContext = createContext<LocalSearchContextProps>({
	searchFilters: [],
	queryFilters: {},
	addSearchFilters: () => {},
	removeSearchFilter: () => {},
	removeSearchFilterByKey: () => {},
	setSearchFilters: () => {},
	setActiveSearchScope: () => {},
	hasFilter: () => false,
	findFilterByKey: () => undefined,
});

export interface LocalSearchProviderProps {
	children?: ReactNode;
}

function LocalSearchProvider({ children }: LocalSearchProviderProps) {
	const [activeSearchScope, setActiveSearchScope] = useState<
		| {
				scope: LocalSearchScope;
				options: UseLocalSearchFiltersOptions;
		  }
		| undefined
	>(undefined);

	const activeScope = activeSearchScope?.scope;
	const activeScopeKey = (() => {
		if (!activeScope) {
			return undefined;
		}

		if (activeScope.scopeKeySuffix) {
			return `${activeScope}:${activeScope.scopeKeySuffix}`;
		}

		return activeScope.scopeKey;
	})();

	const {
		filters,
		queryFilters,
		addFilters,
		removeFilter,
		removeFilterByKey,
		setFilters,
		hasFilter,
		findFilterByKey,
	} = useLocalSearchFilters(
		activeScopeKey,
		activeSearchScope?.options ?? { shouldUpdateHistoryOnChange: false },
	);

	const setActiveSearchScopeWithOptions = useCallback(
		(
			scope: LocalSearchScope | undefined,
			options: UseLocalSearchFiltersOptions = {
				shouldUpdateHistoryOnChange: false,
			},
		) => {
			if (scope) {
				setActiveSearchScope({
					scope,
					options,
				});
				return;
			}
			setActiveSearchScope(undefined);
		},
		[],
	);

	const value = useMemo(
		() => ({
			searchFilters: filters,
			queryFilters,
			activeSearchScope: activeSearchScope?.scope,
			setActiveSearchScope: setActiveSearchScopeWithOptions,
			addSearchFilters: addFilters,
			removeSearchFilter: removeFilter,
			removeSearchFilterByKey: removeFilterByKey,
			setSearchFilters: setFilters,
			hasFilter,
			findFilterByKey,
		}),
		[
			filters,
			queryFilters,
			activeSearchScope?.scope,
			setActiveSearchScopeWithOptions,
			addFilters,
			removeFilter,
			removeFilterByKey,
			setFilters,
			hasFilter,
			findFilterByKey,
		],
	);

	return (
		<LocalSearchContext.Provider value={value}>
			{children}
		</LocalSearchContext.Provider>
	);
}

export { LocalSearchProvider, LocalSearchContext };
