import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgKommissionieren({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.971.451a2 2 0 012.058 0l4 2.4A2 2 0 0118 4.566v4.868l.029.017 4 2.4A2 2 0 0123 13.566v4.868a2 2 0 01-.971 1.715l-4 2.4a2 2 0 01-2.058 0L12 20.166 8.029 22.55a2 2 0 01-2.058 0l-4-2.4A2 2 0 011 18.434v-4.868a2 2 0 01.971-1.715l4-2.4A1.456 1.456 0 006 9.434V4.566a2 2 0 01.971-1.715l4-2.4zm2.973 12.55L17 11.165 20.056 13 17 14.834 13.944 13zM10.056 13L7 11.166 3.944 13 7 14.834 10.056 13zM3 14.766v3.668l3 1.8v-3.668l-3-1.8zm5 5.468l3-1.8v-3.668l-3 1.8v3.668zm3-9l-3-1.8V5.766l3 1.8v3.668zm1-5.4L8.944 4 12 2.166 15.056 4 12 5.834zm1 1.732l3-1.8v3.668l-3 1.8V7.566zm0 7.2v3.668l3 1.8v-3.668l-3-1.8zm8 3.668l-3 1.8v-3.668l3-1.8v3.668z"
			/>
		</svg>
	);
}

const MemoSvgKommissionieren = React.memo(SvgKommissionieren);
export default MemoSvgKommissionieren;
