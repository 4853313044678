import { useAbility } from "@app/modules/authorization/AuthorizationProvider";
import type {
	AuthorizationParameters,
	CanParameters,
} from "@app/modules/authorization/types";
import type { ReactNode } from "react";

export interface CanProps {
	params: CanParameters;
	children?: ReactNode;
	fallback?: ReactNode;
}

export function Can({ params, children, fallback = null }: CanProps) {
	const { can } = useAbility();
	if (can(...params)) {
		return <>{children}</>;
	}
	return <>{fallback}</>;
}

export interface CannotProps {
	params: AuthorizationParameters;
	children?: ReactNode;
	fallback?: ReactNode;
}

export function Cannot({ params, children, fallback = null }: CannotProps) {
	const { cannot } = useAbility();
	if (cannot(...params)) {
		return <>{children}</>;
	}
	return <>{fallback}</>;
}
