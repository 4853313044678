import type {
	DeprecatedProductInfoReceivedAction,
	ProductInfoReceivedAction,
	UiActionId,
	UiActionType,
} from "@app/modules/ui-actions/types";

export function generateUiActionId(type: UiActionType): UiActionId {
	// We don't need true randomness. Ids only need to be session unique.
	return `${type}-${Date.now()}-${Math.floor(Math.random() * 100)}`;
}

export function extractProductIdsFromUiAction(
	action:
		| ProductInfoReceivedAction
		| DeprecatedProductInfoReceivedAction
		| undefined,
) {
	const productId =
		action?.type === "product-info-received" ? action.productId : undefined;
	const domainId =
		action?.type === "deprecated-product-info-received"
			? action.productDomainId
			: undefined;
	return {
		productId,
		domainId,
	};
}
