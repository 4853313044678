import { datalogic } from "@app/modules/hardware/instruction-sets/datalogic";
import { pesola } from "@app/modules/hardware/instruction-sets/pesola";
import { sics } from "@app/modules/hardware/instruction-sets/sics";
import { systec } from "@app/modules/hardware/instruction-sets/systec";
import { bizerbast } from "@app/modules/hardware/instruction-sets/bizerbast";
import type {
	HardwareCommand,
	InstructionSet,
	ParsedMessage,
} from "@app/modules/hardware/types";
import { unknownMessage } from "@app/modules/hardware/types";

export function parse(set: InstructionSet, instr: string): ParsedMessage {
	switch (set) {
		case "SICS":
			return sics.parse(instr);
		case "pesola-tmp":
			return pesola.parse(instr);
		case "datalogic":
			return datalogic.parse(instr);
		case "SysTec":
			return systec.parse(instr);
		case "bizerbast":
			return bizerbast.parse(instr);
		default:
			return unknownMessage;
	}
}

export function compile(
	instrSet: InstructionSet,
	cmd: HardwareCommand,
): string | undefined {
	switch (instrSet) {
		case "SICS":
			return sics.compile(cmd);
		case "SysTec":
			return systec.compile(cmd);
		case "bizerbast":
			return bizerbast.compile(cmd);
		default:
			return undefined;
	}
}

export function getTerminationString(set: InstructionSet) {
	switch (set) {
		case "SICS":
			return sics.TERMINATION_STRING;
		case "pesola-tmp":
			return pesola.TERMINATION_STRING;
		case "datalogic":
			return datalogic.TERMINATION_STRING;
		case "SysTec":
			return systec.TERMINATION_STRING;
		case "bizerbast":
			return bizerbast.TERMINATION_STRING;
		default:
			return String.fromCharCode(10);
	}
}
