import type {
	ColumnSortItem,
	DataTableContextValue,
	SortMode,
	UseColumnSortResult,
	UsePaginationResult,
	UseRowSelectResult,
} from "@app/modules/data-table/data-table-utils";
import {
	filterRows,
	sortRows,
	useColumnSort,
	usePagination,
	useRowSelect,
} from "@app/modules/data-table/data-table-utils";
import { Order_By } from "@app/modules/graphql-api-types.generated";
import { useQuery } from "@app/modules/graphql/queries";
import { useUpdateEffect } from "@app/modules/hooks/useUpdateEffect";
import {
	useLocalSearchFilters,
	useRegisterLocalSearchScope,
} from "@app/modules/search/local/hooks";
import type { LocalSearchScope } from "@app/modules/search/local/types";
import { setIn } from "@app/modules/utils/object";
import { usePaginatedArray } from "@app/modules/utils/usePaginatedArray";
import type { DependencyList, ReactNode } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import type { DocumentInput } from "urql";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

export type DataTableColumns = readonly {
	accessor?: string;
	label: ReactNode;
	sortable?: boolean;
	sortMode?: SortMode;
	align?: "left" | "right" | "center";
	width?: string;
	className?: string;
	hidden?: boolean;
}[];

export type BaseVariables = {
	limit: number;
	offset: number;
	filters: Record<string, unknown>;
	orderBy: Record<string, unknown> | unknown[];
};

export type DataTableQueryDocument<
	Entity,
	Variables extends BaseVariables,
> = DocumentInput<
	{
		entities: Entity[];
		aggregate: {
			aggregate?: Maybe<{
				count: number;
			}>;
		};
	},
	Variables
>;

export type AccessorOf<F extends DataTableColumns> = NonNullable<
	F[number]["accessor"]
>;

export type DataTableInstance<RowData = Record<string, unknown>> = {
	reset: () => void;
	refresh: () => void;
	visibleRows: RowData[];
	totalRows: number;
	columns: DataTableColumns;
	contextValue: DataTableContextValue;
	pagination?: UsePaginationResult;
	selectedRowIds: Set<string>;
	fetching?: boolean;
} & ColumnSortProperties &
	RowSelectProperties;

type ColumnSortProperties =
	| ({ hasSort: true } & UseColumnSortResult)
	| { hasSort: false };

type RowSelectProperties =
	| ({ hasRowSelect: true } & UseRowSelectResult)
	| { hasRowSelect: false };

const EMPTY_FILTER = {};

export interface UseDataTableOptions<
	Columns extends DataTableColumns,
	RowData extends Record<string, unknown>,
	SearchQueryDocument extends DocumentInput,
	Variables extends BaseVariables,
> {
	name?: string;
	columns: Columns;
	hasRowSelect?: boolean;
	initialSelection?: string[];
	maxSelectionSize?: number;
	hasSort?: boolean;
	initialSort?: ColumnSortItem<AccessorOf<Columns>>[];
	pageSize?: number;
	query: DataTableQueryDocument<RowData, Variables>;
	queryVariables?: Omit<Variables, keyof BaseVariables>;
	pause?: boolean;
	searchScope?: LocalSearchScope<SearchQueryDocument>;
	getRowId?: (entity: RowData) => string | undefined;
}

export function useDataTable<
	Columns extends DataTableColumns,
	RowData extends Record<string, unknown>,
	SearchQueryDocument extends DocumentInput,
	Variables extends BaseVariables,
>(
	options: UseDataTableOptions<
		Columns,
		RowData,
		SearchQueryDocument,
		Variables
	>,
): DataTableInstance<RowData> {
	const {
		name,
		columns,
		hasRowSelect = false,
		initialSelection,
		maxSelectionSize,
		hasSort = false,
		initialSort,
		pageSize = 25,
		query,
		queryVariables,
		searchScope,
		getRowId = defaultGetRowId,
		pause = false,
	} = options;

	useRegisterLocalSearchScope(searchScope, { pause: !searchScope });

	const { queryFilters, isScopeReady } = useLocalSearchFilters(
		searchScope?.scopeKey,
	);
	// If no search scope is provided we need to ignore the query filters.
	// This is required for secondary tables (e.g. card or modal).
	const activeFilters = searchScope ? queryFilters : undefined;

	const pagination = usePagination({
		limit: pageSize,
		initialOffset: 0,
		name,
	});

	const sortMethods = useColumnSort(initialSort, name);
	const { sortItems } = sortMethods;

	// TODO: would be great to "persist" the sort items in the search scope.
	// This would make it easier to persist them in the URL along with the active filters.
	const orderBy = useMemo(
		() =>
			sortItems.map(({ accessor, direction }) => {
				const orderByDir = direction === "asc" ? Order_By.Asc : Order_By.Desc;
				return setIn({}, accessor, orderByDir);
			}),
		[sortItems],
	);

	const { limit, offset } = pagination;

	const [res, refetch] = useQuery({
		query,
		variables: {
			limit,
			offset,
			filters: activeFilters ?? EMPTY_FILTER,
			orderBy,
			...queryVariables,
			// TODO: fix this type cast
		} as Variables,
		pause,
	});

	const visibleRows = res.data?.entities ?? [];
	const totalRows = res.data?.aggregate.aggregate?.count ?? 0;

	const visibleRowIds = visibleRows.map(getRowId).filter(Boolean);
	const selectMethods = useRowSelect({
		visibleRowIds,
		initialSelection,
		maxSelectionSize,
	});
	const contextValue = useMemo<DataTableContextValue>(
		() => ({
			getSortDirection: sortMethods.getSortDirection,
			getToggleRowSelectedProps: selectMethods.getToggleRowSelectedProps,
			selectedRowIds: selectMethods.selectedRowIds,
		}),
		[
			selectMethods.getToggleRowSelectedProps,
			selectMethods.selectedRowIds,
			sortMethods.getSortDirection,
		],
	);

	const [resetCount, setResetCount] = useState(0);

	useUpdateEffect(() => {
		if (!pause && resetCount !== 0) {
			refetch({ requestPolicy: "network-only" });
		}
	}, [resetCount]);

	const { clearRowSelection } = selectMethods;
	const { resetOffset } = pagination;
	const reset = useCallback(() => {
		clearRowSelection();
		resetOffset();
		setResetCount((count) => count + 1);
	}, [clearRowSelection, resetOffset]);

	const isInitialFilterRef = useRef(true);

	// We need deep compare because `activeFilters` is not memoized. Idea: memoize it upstream.
	// We also use the `NoCheck` variant because `activeFilters` can be undefined.
	useDeepCompareEffectNoCheck(
		function resetDataTableOnFilterChange() {
			if (!isScopeReady) {
				return;
			}
			// We don't want to reset the data table when the filters are restored from the URL (happens on browser navigation, e.g. browser back)
			if (isInitialFilterRef.current) {
				isInitialFilterRef.current = false;
				return;
			}
			clearRowSelection();
			resetOffset();
		},
		[activeFilters, isScopeReady],
	);

	return {
		refresh: useCallback(() => {
			setResetCount((count) => count + 1);
			clearRowSelection();
		}, [clearRowSelection]),
		reset,
		visibleRows,
		totalRows,
		columns,
		hasRowSelect,
		hasSort,
		contextValue,
		...selectMethods,
		...sortMethods,
		pagination,
		fetching: res.fetching,
	};
}

export interface UseStaticDataTableOptions<
	Columns extends DataTableColumns,
	RowData extends Record<string, unknown>,
> {
	name?: string;
	columns: Columns;
	rows: RowData[];
	pageSize?: number;
	hasRowSelect?: boolean;
	initialSelection?: string[];
	maxSelectionSize?: number;
	hasSort?: boolean;
	initialSort?: ColumnSortItem<AccessorOf<Columns>>[];
	searchScope?: LocalSearchScope<DocumentInput>;
	getRowId?: (entity: RowData) => string | undefined;
}

export function useStaticDataTable<
	Columns extends DataTableColumns,
	RowData extends Record<string, unknown>,
>(
	options: UseStaticDataTableOptions<Columns, RowData>,
	deps?: DependencyList,
): DataTableInstance<RowData> {
	const {
		name,
		columns,
		rows,
		pageSize = 0,
		hasRowSelect = false,
		initialSelection,
		maxSelectionSize,
		getRowId = defaultGetRowId,
		hasSort = false,
		initialSort,
		searchScope,
	} = options;

	useRegisterLocalSearchScope(searchScope, { pause: !searchScope });

	const { searchFilters } = useLocalSearchFilters(searchScope?.scopeKey);
	const activeFilters = searchScope ? searchFilters : [];

	const filteredRows = useMemo(
		() => filterRows(rows, activeFilters),
		// We only want to filter on filter changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeFilters, deps ?? rows.length],
	);

	const sortMethods = useColumnSort(initialSort, name);
	const { sortItems } = sortMethods;

	const sortedRows = useMemo(
		() => sortRows(filteredRows, sortItems, columns),
		// We only want to re-sort on sorting changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sortItems, filteredRows],
	);

	const hasPagination = pageSize > 0;
	const { visibleEntries, ...paginatedArray } = usePaginatedArray(sortedRows, {
		limit: pageSize,
	});

	const pagination = {
		pagination: {
			...paginatedArray,
			getHasNext: () => paginatedArray.hasNext,
		},
	};

	const visibleRows = hasPagination ? visibleEntries : sortedRows;

	const visibleRowIds = visibleRows.map(getRowId).filter(Boolean);
	const selectMethods = useRowSelect({
		visibleRowIds,
		initialSelection,
		maxSelectionSize,
	});

	const contextValue = useMemo<DataTableContextValue>(
		() => ({
			getSortDirection: sortMethods.getSortDirection,
			getToggleRowSelectedProps: selectMethods.getToggleRowSelectedProps,
			selectedRowIds: selectMethods.selectedRowIds,
		}),
		[
			selectMethods.getToggleRowSelectedProps,
			selectMethods.selectedRowIds,
			sortMethods.getSortDirection,
		],
	);

	return {
		contextValue,
		reset: () => {},
		refresh: () => {},
		visibleRows,
		totalRows: filteredRows.length,
		columns,
		hasSort,
		hasRowSelect,
		...selectMethods,
		...sortMethods,
		...(hasPagination && { ...pagination }),
	};
}

function defaultGetRowId(entity: Record<string, unknown>) {
	if (entity.id && typeof entity.id === "string") {
		return entity.id;
	}
	return undefined;
}
