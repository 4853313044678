import { logger } from "@app/modules/logger/logger";
import type { Struct } from "superstruct";
import { assert } from "superstruct";

function set<T extends string | Record<string, unknown> | unknown[]>(
	key: string,
	data: T,
) {
	window.sessionStorage.setItem(key, JSON.stringify(data));
}

function get<T>(key: string, schema: Struct<T>): T | undefined {
	const rawValue = window.sessionStorage.getItem(key);
	try {
		const parsed = rawValue ? JSON.parse(rawValue) : undefined;
		if (parsed === undefined) {
			return undefined;
		}
		assert(parsed, schema);
		return parsed;
	} catch (error) {
		logger.error(error, "Could not parse session storage value", {
			key,
			rawValue: rawValue ?? "",
		});
		return undefined;
	}
}

function remove(key: string) {
	return window.sessionStorage.removeItem(key);
}

function clear() {
	return window.sessionStorage.clear();
}

export const sessionStorage = {
	set,
	get,
	remove,
	clear,
};
