import { TaskFinished, Unsend } from "@app/icons/components";
import { Close, Edit, Save, Trash } from "@app/icons/components/action";
import { Button } from "@app/modules/Button";
import { EntityStateTagItem } from "@app/modules/entity/EntityStateTagItem";
import { useTranslate } from "@app/modules/i18n/context";
import { Anchor } from "@app/modules/layout/Anchor";
import { AutoHeight, autoHeightCls } from "@app/modules/layout/autoheight";
import { Divider } from "@app/modules/layout/Divider";
import { HorizontalList } from "@app/modules/layout/HorizontalList";
import { useUnsavedChangesPrompt } from "@app/modules/notification/navigation-prompt";
import clsx from "clsx";
import type { MouseEventHandler, ReactNode, Ref, RefObject } from "react";
import { forwardRef, useState } from "react";

export interface SectionCardProps {
	className?: string;
	autoHeight?: boolean;
	children?: ReactNode;
}

const SectionCard = forwardRef(function SectionCardElement(
	{ className, autoHeight, children }: SectionCardProps,
	ref: Ref<HTMLDivElement>,
) {
	return (
		<section
			ref={ref}
			className={clsx(
				className,
				"relative shadow bg-white rounded pb-24",
				autoHeightCls(autoHeight),
			)}
		>
			<AutoHeight disabled={!autoHeight}>{children}</AutoHeight>
		</section>
	);
});

export type SectionCardHeaderVariant = "default" | "main";

export interface SectionCardHeaderProps {
	variant?: SectionCardHeaderVariant;
	isSticky?: boolean;
	buttons?: ReactNode;
	buttonsRef?: RefObject<HTMLDivElement>;
	title: string;
	tags?: ReactNode;
	anchorId?: string;
}

function Header({
	variant = "default",
	isSticky = true,
	buttons,
	buttonsRef,
	title,
	tags,
	anchorId,
}: SectionCardHeaderProps) {
	return (
		<header
			className={clsx(
				"pt-24 pb-16 px-section-padding flex gap-16",
				isSticky && "sticky -top-main-area-padding bg-white z-40",
			)}
		>
			<div className="flex-1">
				<OptionalAnchor anchorId={anchorId}>
					<h1 className={headerH1Cls[variant]}>{title}</h1>
				</OptionalAnchor>
				{tags && <HorizontalList>{tags}</HorizontalList>}
			</div>
			<div
				ref={buttonsRef}
				className="grid gap-8 grid-flow-col h-fit w-fit justify-self-end"
			>
				{buttons}
			</div>
		</header>
	);
}

const headerH1Cls: Record<SectionCardHeaderVariant, string> = {
	default: "text-caption-12-caps text-grey-700",
	main: "text-header-18",
};

interface AnchorOrDivProps {
	anchorId?: string;
	children?: ReactNode;
}

function OptionalAnchor({ anchorId, children }: AnchorOrDivProps) {
	if (anchorId) {
		return <Anchor id={anchorId}>{children}</Anchor>;
	}
	return <>{children}</>;
}

export interface SectionCardEditableHeaderProps
	extends Omit<SectionCardHeaderProps, "buttons"> {
	isEditable: boolean;
	isEditing: boolean;
	onEditClick?: MouseEventHandler<HTMLButtonElement>;
	onCancelClick?: MouseEventHandler<HTMLButtonElement>;
	isFetching?: boolean;
	formId?: string;
	editButtons?: ReactNode;
	readOnlyButtons?: ReactNode;
}

function EditableHeader({
	isEditable,
	isEditing,
	isFetching,
	onEditClick,
	onCancelClick,
	formId,
	editButtons,
	readOnlyButtons,
	variant = "default",
	...props
}: SectionCardEditableHeaderProps) {
	const buttonVariant = variant === "default" ? "tertiary-grey" : "secondary";

	const buttons = (() => {
		if (!isEditable) {
			return readOnlyButtons;
		}
		if (isEditing) {
			return (
				<>
					{editButtons}
					<Button
						variant={buttonVariant}
						icon={Close}
						onClick={onCancelClick}
						testid={`editable-section-${formId}-cancel`}
					/>
					<Button
						type="submit"
						variant={buttonVariant}
						icon={Save}
						form={formId}
						testid={`editable-section-${formId}-submit`}
					/>
				</>
			);
		}
		return (
			<>
				{readOnlyButtons}
				<Button
					variant={buttonVariant}
					icon={Edit}
					onClick={onEditClick}
					isFetching={isFetching}
					testid={`editable-section-${formId}-edit`}
				/>
			</>
		);
	})();

	return <Header {...props} variant={variant} buttons={buttons} />;
}

export function useEditableSectionCardHeader(
	{ isEditable }: { isEditable: boolean } = { isEditable: true },
) {
	const [isEditing, setIsEditing] = useState(false);

	useUnsavedChangesPrompt(isEditing);

	const startEditing = () => {
		if (isEditable) {
			setIsEditing(true);
		}
	};

	const stopEditing = () => {
		setIsEditing(false);
	};

	return {
		isEditing,
		isEditable,
		startEditing,
		stopEditing,
		editableHeaderProps: {
			isEditable,
			isEditing,
			onEditClick: startEditing,
			onCancelClick: stopEditing,
		},
	};
}

export interface SectionCardBodyProps {
	className?: string;
	children?: ReactNode;
}

function Body({ className, children }: SectionCardBodyProps) {
	return (
		<div className={clsx("mx-section-padding", className)}>{children}</div>
	);
}

interface SectionCardSubSectionProps {
	title: string;
	anchorId?: string;
	className?: string;
	children?: ReactNode;
}

function SubSection({
	title,
	anchorId,
	className,
	children,
}: SectionCardSubSectionProps) {
	return (
		<section className={className}>
			<header className="mx-section-padding mt-16">
				<OptionalAnchor anchorId={anchorId}>
					<h2 className="text-caption-12-caps text-grey-700">{title}</h2>
				</OptionalAnchor>
			</header>
			{children}
		</section>
	);
}

function SectionDivider() {
	return <Divider size="l" />;
}

// I'm not super happy with the solution but it has less type issues compared to `"ACTIVE" | "INACTIVE"`. If we ever change graphql types from enums to types we should revisit this code.
export type SupportedStates = "ACTIVE" | "INACTIVE";

export interface EntityStateSubSectionProps {
	className?: string;
	children?: ReactNode;
	state: SupportedStates;
	onStateChange: (newState: SupportedStates) => void;
	hasDelete?: boolean;
	onDeleteClick?: MouseEventHandler<HTMLButtonElement>;
}

// Consider moving this out of this file or add it to SectionCard export.
export function EntityStateSubSection({
	className,
	children,
	state,
	onStateChange,
	hasDelete,
	onDeleteClick,
}: EntityStateSubSectionProps) {
	const t = useTranslate("common");
	const stateButtonProps =
		state === "ACTIVE"
			? {
					icon: Unsend,
					label: t("actions.deactivate"),
					children: t("actions.deactivate"),
					onClick: () => onStateChange("INACTIVE"),
			  }
			: {
					icon: TaskFinished,
					label: t("actions.activate"),
					children: t("actions.activate"),
					onClick: () => onStateChange("ACTIVE"),
			  };
	return (
		<section className={className}>
			<header className="flex mx-16 gap-16 mb-16 items-center">
				<div className="flex-1 flex gap-6 h-fit">
					<EntityStateTagItem state={state} />
					<Button variant="tertiary-grey" iconSize="20" {...stateButtonProps} />
				</div>
				{hasDelete && (
					<div className="justify-end">
						<Button
							variant="tertiary-destructive"
							icon={Trash}
							onClick={onDeleteClick}
							title={t("actions.delete")}
						/>
					</div>
				)}
			</header>
			{children}
		</section>
	);
}

interface SectionCardGridProps {
	className?: string;
	children?: ReactNode;
}

function Grid({ className, children }: SectionCardGridProps) {
	return (
		<div
			className={clsx(className, "mt-16 grid gap-16 h-fit mx-section-padding")}
		>
			{children}
		</div>
	);
}

const ExtendedSectionCard = SectionCard as typeof SectionCard & {
	Header: typeof Header;
	EditableHeader: typeof EditableHeader;
	Body: typeof Body;
	Grid: typeof Grid;
	SectionDivider: typeof SectionDivider;
	SubSection: typeof SubSection;
};

ExtendedSectionCard.Header = Header;
ExtendedSectionCard.EditableHeader = EditableHeader;
ExtendedSectionCard.Body = Body;
ExtendedSectionCard.Grid = Grid;
ExtendedSectionCard.SectionDivider = SectionDivider;
ExtendedSectionCard.SubSection = SubSection;

export { ExtendedSectionCard as SectionCard };
