import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCalculator({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M9 5a1 1 0 00-1 1v2a1 1 0 001 1h6a1 1 0 001-1V6a1 1 0 00-1-1H9zM9.5 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16 12.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM9.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16 17.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.556 1A2.547 2.547 0 004 3.538v16.924A2.547 2.547 0 006.556 23h10.888A2.547 2.547 0 0020 20.462V3.538A2.547 2.547 0 0017.444 1H6.556zM6 3.538C6 3.251 6.238 3 6.556 3h10.888c.318 0 .556.251.556.538v16.924a.547.547 0 01-.556.538H6.556A.547.547 0 016 20.462V3.538z"
			/>
		</svg>
	);
}

const MemoSvgCalculator = React.memo(SvgCalculator);
export default MemoSvgCalculator;
