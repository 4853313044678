import { Units_Enum } from "@app/modules/graphql-api-types.generated";
import { useHardware } from "@app/modules/hardware/HardwareProvider";
import type { HardwareUserConfig } from "@app/modules/hardware/HardwareUserConfig";
import { useHardwareUserConfig } from "@app/modules/hardware/HardwareUserConfig";
import type {
	HardwareCommand,
	HardwareMessage,
	ParsedMessageType,
} from "@app/modules/hardware/types";
import { logger } from "@app/modules/logger/logger";
import { scale } from "@app/modules/number/scaled-number";
import type {
	UiAction,
	UiActionTarget,
	UiActionType,
} from "@app/modules/ui-actions/types";
import { useUiActionsContext } from "@app/modules/ui-actions/UiActionsProvider";
import type { ReactNode } from "react";
import React, { useEffect } from "react";
import { filter, map, pipe, subscribe } from "wonka";

interface MessageUiActionMapperProps {
	children?: ReactNode;
}

// We provide a component that is used similar to a provider.
// This allows us to keep this "global" feature visible in _app.tsx.
// It also makes it easier to move this functionality into a separate module.
export function HardwareUiActionMapper({
	children,
}: MessageUiActionMapperProps) {
	useMessageUiActionMapper();
	return <>{children}</>;
}

function useMessageUiActionMapper() {
	const { messages, sendCommand } = useHardware();
	const { findDeviceKeyByAssignment, getConfig } = useHardwareUserConfig();
	const { source, dispatch } = useUiActionsContext();

	useEffect(() => {
		const { unsubscribe } = pipe(
			source,
			filter(isSupportedAction),
			map((action) =>
				mapUiActionToHardwareCmd(action, findDeviceKeyByAssignment),
			),
			subscribe((datum) => {
				if (datum) {
					const [key, cmd] = datum;
					sendCommand(key, cmd);
				}
			}),
		);
		return unsubscribe;
	}, [findDeviceKeyByAssignment, sendCommand, source]);

	useEffect(() => {
		const { unsubscribe } = pipe(
			messages,
			filter(isSupportedMessage),
			map((msg) => mapMessageToUiAction(msg, getConfig)),
			subscribe((action) => {
				if (action) {
					dispatch(action);
				}
			}),
		);
		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

const supportedActions: UiActionType[] = ["get-weight", "tare"];

function isSupportedAction(action: UiAction) {
	return supportedActions.includes(action.type);
}

const supportedMessages: ParsedMessageType[] = ["barcode", "weight"];

function isSupportedMessage(message: HardwareMessage) {
	return supportedMessages.includes(message.parsed.type);
}

function mapUiActionToHardwareCmd(
	action: UiAction,
	findDeviceKey: (target: UiActionTarget) => string | undefined,
): [string, HardwareCommand] | undefined {
	switch (action.type) {
		case "tare":
		case "get-weight": {
			const deviceKey = findDeviceKey(action.target);
			if (deviceKey) {
				return [deviceKey, action.type];
			}
			logger.warn(
				"Could not map action to hardware command.",
				"No device assigned to provided target:",
				action.target,
			);
			return undefined;
		}
		default:
			return undefined;
	}
}

function mapMessageToUiAction(
	{ parsed, device }: HardwareMessage,
	getConfig: (deviceKey: string) => HardwareUserConfig | undefined,
): UiAction | undefined {
	const deviceConfig = getConfig(device.key);
	switch (parsed.type) {
		case "weight": {
			const scaledWeight = scale(parsed.weight);
			return {
				type: "weight-received",
				weight: scaledWeight.toString(),
				unit: Units_Enum.Kg,
				source: deviceConfig?.targetAssignment,
			};
		}
		default:
			return undefined;
	}
}
