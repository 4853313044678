import { Plus } from "@app/icons/components/action";
import { Button } from "@app/modules/Button";
import { useEntityForm } from "@app/modules/entity/hooks";
import { dateString, required } from "@app/modules/form/structs";
import { useMutation } from "@app/modules/graphql/queries";
import { useTranslate } from "@app/modules/i18n/context";
import { DateInputLegacyField } from "@app/modules/input-fields/date/DateInputLegacy";
import {
	Modal,
	ModalBody,
	ModalButtons,
	ModalCancelButton,
	ModalPrimaryButton,
	ModalTitle,
	useModalState,
} from "@app/modules/layout/Modal";
import { LocalDate } from "@app/modules/localdate/localdate";
import { ProductGroupSelectField } from "@app/modules/product-group/ProductGroupSelect";
import { ProductionSiteComboboxField } from "@app/modules/work-calendar/ProductionSiteCombobox";
import { InsertProductionSiteProductGroupCalendarDocument } from "@app/modules/work-calendar/queries/insert-production-site-product-group-calendar.mutation.generated";
import { productGroupsForDismantlingBoolExp } from "@app/modules/work-calendar/work-calendar-utils";
import type { Infer } from "superstruct";
import { object, string } from "superstruct";

export function AddProductionSiteCalendarButton() {
	const t = useTranslate("common");
	const { showModal, isModalOpen, closeModal } = useModalState();

	return (
		<>
			<Button icon={Plus} variant="primary" onClick={showModal}>
				{t("work-calendar.actions.add")}{" "}
			</Button>
			<Modal isOpen={isModalOpen} onCloseRequest={closeModal}>
				<AddCalendarModalContent closeModal={closeModal} />
			</Modal>
		</>
	);
}

const addCalendarSchema = object({
	day: required(dateString()),
	productGroupId: required(string()),
	productionSiteId: required(string()),
});

export type AddCalendarValues = Infer<typeof addCalendarSchema>;

function AddCalendarModalContent({ closeModal }: { closeModal: () => void }) {
	const t = useTranslate("common");

	const [{ fetching }, insertProductionSiteProductGroupCalendar] = useMutation(
		InsertProductionSiteProductGroupCalendarDocument,
	);
	const methods = useEntityForm({
		schema: addCalendarSchema,
		defaultValues: { day: LocalDate.now().toDateString() },
	});
	const { handleSubmit, control } = methods;

	const onSubmit = async (values: AddCalendarValues) => {
		const { error } = await insertProductionSiteProductGroupCalendar({
			object: values,
		});

		if (error) {
			return;
		}
		closeModal();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<ModalTitle>{t("work-calendar.actions.add")}</ModalTitle>
			<ModalBody>
				<div className="flex flex-col gap-16">
					<DateInputLegacyField
						disabled={fetching}
						control={control}
						name="day"
					/>
					<ProductionSiteComboboxField
						control={control}
						disabled={fetching}
						name="productionSiteId"
						label={t("work-calendar.table-header.production-site")}
					/>
					<ProductGroupSelectField
						control={control}
						disabled={fetching}
						name="productGroupId"
						label={t("work-calendar.table-header.product-group")}
						queryVariables={{ where: productGroupsForDismantlingBoolExp }}
						showSharedEntities
					/>
				</div>
			</ModalBody>
			<ModalButtons>
				<ModalCancelButton onClick={closeModal} disabled={fetching} />
				<ModalPrimaryButton type="submit" disabled={fetching} />
			</ModalButtons>
		</form>
	);
}
