import { Close } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { Button } from "@app/modules/Button";
import { useCards } from "@app/modules/cards/cards-shared-state";
import { HorizontalList } from "@app/modules/layout/HorizontalList";
import clsx from "clsx";
import type { ReactNode } from "react";
import { useEffect, useRef } from "react";

function Card({
	children,
	className,
	cardId,
	showRemoveButton = false,
	scrollIntoView = true,
}: {
	children?: React.ReactNode;
	className?: string;
	cardId?: string;
	showRemoveButton?: boolean;
	scrollIntoView?: boolean;
}) {
	const { removeCards } = useCards();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!scrollIntoView) {
			return;
		}

		ref.current?.scrollIntoView({
			behavior: "smooth",
		});
	}, [scrollIntoView]);

	return (
		<div
			ref={ref}
			className={clsx("flex flex-col shadow-card rounded bg-white", className)}
		>
			{showRemoveButton && cardId && (
				<Button
					className="self-end absolute text-grey-500"
					variant="tertiary-grey"
					icon={Close}
					onClick={() => removeCards([cardId])}
				/>
			)}
			{children}
		</div>
	);
}

interface CardHeaderProps {
	children?: ReactNode;
}

Card.Header = function CardHeader({ children }: CardHeaderProps) {
	return (
		<HorizontalList className="px-card-padding pt-card-padding pb-8 gap-8">
			{children}
		</HorizontalList>
	);
};

interface CardTitleProps {
	children?: ReactNode;
}

Card.Title = function CardTitle({ children }: CardTitleProps) {
	return <div className="text-header-18">{children}</div>;
};

interface CardIconProps {
	icon: IconComponent;
}

Card.Icon = function CardIcon({ icon }: CardIconProps) {
	return <Icon icon={icon} size="16" />;
};

interface CardBodyProps {
	children?: ReactNode;
}

Card.Body = function CardBody({ children }: CardBodyProps) {
	return <div className="flex flex-col gap-16 pb-card-padding">{children}</div>;
};

interface CardContentProps {
	className?: string;
	children?: ReactNode;
}

Card.Content = function CardContent({ children, className }: CardContentProps) {
	return <div className={clsx("px-card-padding", className)}>{children}</div>;
};

export { Card };
