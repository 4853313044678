import { useTranslate } from "@app/modules/i18n/context";
import type { TagItemColor } from "@app/modules/layout/TagItem";
import { TagItem } from "@app/modules/layout/TagItem";
import React from "react";

export type EntityState = "ACTIVE" | "INACTIVE" | "DRAFT" | "ARCHIVED";

export interface EntityStateTagItemProps {
	state: EntityState;
}

function EntityStateTagItem({ state }: EntityStateTagItemProps) {
	const t = useTranslate("common");
	const color = colors[state];
	return <TagItem color={color}>{t(`entity.states.${state}`)}</TagItem>;
}

const colors: Record<EntityState, TagItemColor> = {
	ACTIVE: "green",
	INACTIVE: "grayLight",
	DRAFT: "yellow",
	ARCHIVED: "grayLight",
};

export { EntityStateTagItem };
