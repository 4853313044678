import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDropdownclosed({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.293 8.293a1 1 0 011.32-.083l.094.083L12 15.585l7.293-7.292a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-8 8a1 1 0 01-1.32.083l-.094-.083-8-8a1 1 0 010-1.414z"
			/>
		</svg>
	);
}

const MemoSvgDropdownclosed = React.memo(SvgDropdownclosed);
export default MemoSvgDropdownclosed;
