import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgAddStock({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.617 2.32l-.207-.084c-.927-.34-2.2-.312-3.07.085l-6.202 2.83-.15.074c-1.36.726-1.31 2.291.15 2.955L6.67 9.334l-2.53 1.136-.152.075c-1.364.728-1.314 2.293.147 2.957l2.535 1.156-2.526 1.134-.152.075c-1.364.727-1.313 2.293.147 2.957l6.15 2.804.214.088c.957.35 2.278.32 3.174-.087.464-.212.924-.447.924-1.055a1 1 0 00-1-1 .991.991 0 00-.384.068l-.369.167-.16.059c-.462.138-1.18.118-1.57-.06l-5.476-2.497 3.45-1.549 1.193.544.214.088c.957.35 2.278.321 3.175-.087l6.15-2.805.152-.075c1.361-.723 1.314-2.29-.149-2.955l-2.535-1.137 2.537-1.155.15-.075c1.357-.728 1.304-2.292-.155-2.955l-6.206-2.83zm-7.98 9.669l3.455-1.55 1.196.546.214.087c.957.35 2.278.322 3.175-.087l1.191-.545 3.452 1.548-5.477 2.499-.16.059c-.463.138-1.18.118-1.57-.06L5.638 11.99zM11.17 4.14l.15-.055c.432-.13 1.103-.11 1.466.055l5.54 2.526-5.479 2.5-.16.058-.163.04c-.455.092-1.06.059-1.406-.099l-5.482-2.5 5.534-2.525z"
			/>
			<path d="M18 16a1 1 0 112 0v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2v-2z" />
		</svg>
	);
}

const MemoSvgAddStock = React.memo(SvgAddStock);
export default MemoSvgAddStock;
