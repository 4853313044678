import type { Languages_Enum } from "@app/modules/graphql-api-types.generated";

export function makeNumberFormatter(
	locale: Languages_Enum,
	options?: Intl.NumberFormatOptions,
) {
	const formatter = new Intl.NumberFormat(locale.replace("_", "-"), options);
	return (number: number | bigint | string) => {
		if (typeof number === "string") {
			return formatter.format(parseFloat(number));
		}
		return formatter.format(number);
	};
}

export function makeDateTimeFormatter(
	locale: Languages_Enum,
	options?: Intl.DateTimeFormatOptions,
) {
	const formatter = new Intl.DateTimeFormat(locale.replace("_", "-"), options);
	return (date: Date | number) => formatter.format(date);
}
