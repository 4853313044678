import type { Role } from "@app/modules/app-state/roles";

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export function hasAuthParams(searchParams: string): boolean {
	return (
		(CODE_RE.test(searchParams) && STATE_RE.test(searchParams)) ||
		ERROR_RE.test(searchParams)
	);
}

export interface IdTokenClaims {
	"https://hasura.io/jwt/claims"?: {
		"x-hasura-allowed-roles": Role[];
		"x-hasura-default-role": Role;
		"x-hasura-tenant": string;
		"x-hasura-user-id": string;
		"x-hasura-allowed-tenants": string;
	};
}

export function parseHasuraClaims<T extends IdTokenClaims>(claims: T) {
	const hasuraClaims = claims["https://hasura.io/jwt/claims"];
	if (!hasuraClaims) {
		return {};
	}
	const userId = parseInt(hasuraClaims["x-hasura-user-id"], 10);
	return {
		userId,
		allowedRoles: hasuraClaims["x-hasura-allowed-roles"],
		defaultRole: hasuraClaims["x-hasura-default-role"],
		allowedTenants: hasuraClaims["x-hasura-allowed-tenants"],
	};
}
