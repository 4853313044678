import type { Supplier_Order_States_Enum } from "@app/modules/graphql-api-types.generated";
import { useTranslate } from "@app/modules/i18n/context";
import type { TagItemColor } from "@app/modules/layout/TagItem";
import { TagItemLink, TagItem } from "@app/modules/layout/TagItem";

export interface SupplierOrderStateTagItemProps {
	state: Supplier_Order_States_Enum;
}

export function SupplierOrderStateTagItem({
	state,
}: SupplierOrderStateTagItemProps) {
	const t = useTranslate("common");
	const color = colors[state];

	return (
		<TagItem color={color}>{t(`purchasing.orders.states.${state}`)}</TagItem>
	);
}

export interface SupplierOrderStateTagItemLinkProps {
	entity: { state: Supplier_Order_States_Enum; id: string };
}

export function SupplierOrderStateTagItemLink({
	entity: { state, id },
}: SupplierOrderStateTagItemLinkProps) {
	const t = useTranslate("common");
	const color = colors[state];

	return (
		<TagItemLink
			to="operations.supplier-order-goods-receipt.id"
			params={{ id }}
			color={color}
		>
			{t(`purchasing.orders.states.${state}`)}
		</TagItemLink>
	);
}

const colors: Record<Supplier_Order_States_Enum, TagItemColor> = {
	CANCELED: "gray",
	OPEN: "yellow",
	ORDERED: "blue",
	RECEIPT: "green",
	ORDER_REQUESTED: "purple",
	PARTIAL_RECEIPT: "blue",
	APPROVED_RECEIPT: "green",
};
