import { createSharedState } from "@app/modules/graphql/pause-behavior";
import type { MouseEventHandler, ReactNode } from "react";
import { useCallback, useEffect, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { Link, useLocation } from "react-router-dom";

const clickCountState = createSharedState(0);

function useAnchorClickCount() {
	const [count, setCount] = clickCountState.useSharedState();
	return {
		count,
		incCount: useCallback(() => {
			setCount((old) => old + 1);
		}, [setCount]),
	};
}

export interface AnchorProps {
	id: string;
	children?: ReactNode;
	className?: string;
}

export function Anchor({ className, children, id }: AnchorProps) {
	const location = useLocation();
	const ref = useRef<HTMLAnchorElement>(null);
	const hash = `#${id}`;
	const { count, incCount } = useAnchorClickCount();

	useEffect(() => {
		if (id && hash === location.hash && ref.current) {
			ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}, [hash, id, location.hash, count]);

	return (
		<Link
			ref={ref}
			className={className}
			to={{ pathname: "", hash, search: location.search }}
			tabIndex={-1}
			onClick={() => {
				incCount();
			}}
		>
			{children}
		</Link>
	);
}

export interface AnchorLinkProps {
	to: string;
	children?: ReactNode;
	className?: string;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export function AnchorLink({
	className,
	children,
	to,
	onClick,
}: AnchorLinkProps) {
	const { search } = useLocation();
	const hash = `#${to}`;
	const { incCount } = useAnchorClickCount();

	return (
		<Link
			className={className}
			to={{ pathname: "", hash, search }}
			onClick={(event) => {
				incCount();
				onClick?.(event);
			}}
		>
			{children}
		</Link>
	);
}
