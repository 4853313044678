import { useTranslate } from "@app/modules/i18n/context";
import { useConfirmPrompt } from "@app/modules/notification/useConfirmPrompt";
import { generatePseudoRandomNumber } from "@app/modules/utils/pseudo-random";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

interface Prompt {
	message: string;
	onProceed?: () => void;
	onCancel?: () => void;
	proceedNavigationOnCancel?: boolean;
}

interface PromptWithId extends Prompt {
	id: number;
}

interface PromptsContextValue {
	add: (prompt: Prompt) => number;
	remove: (id: number) => void;
}

const PromptsContext = createContext<PromptsContextValue>({
	add: () => 0,
	remove: () => {},
});

export interface UnsavedChangesProviderProps {
	children?: ReactNode;
}

// react-router internally uses a singleton to block navigation. For this reason we have this central provider that makes sure at most one prompt is active at any time.
// Related discussion: https://github.com/remix-run/react-router/discussions/9978
export function NavigationPromptProvider({
	children,
}: UnsavedChangesProviderProps) {
	const [prompts, setPrompts] = useState<PromptWithId[]>([]);

	const value = useMemo(
		() => ({
			add: (prompt: Prompt) => {
				const idLength = 6;
				const id = generatePseudoRandomNumber(idLength);
				setPrompts((old) => [{ ...prompt, id }, ...old]);
				return id;
			},
			remove: (id: number) =>
				setPrompts((old) => old.filter((prompt) => prompt.id !== id)),
		}),
		[],
	);

	const topPrompt = prompts[0];
	useConfirmPrompt({
		message: topPrompt?.message ?? "",
		when: Boolean(topPrompt),
		onProceed: async () => {
			if (topPrompt) {
				await topPrompt.onProceed?.();
				value.remove(topPrompt.id);
			}
		},
		onCancel: topPrompt?.onCancel,
		proceedNavigationOnCancel: topPrompt?.proceedNavigationOnCancel,
	});

	return (
		<PromptsContext.Provider value={value}>{children}</PromptsContext.Provider>
	);
}

export interface UseNavigationPromptInput {
	shouldBlockNavigation: boolean | undefined;
	/** Make sure to memoize `prompt` to reduce unnecessary effects. */
	prompt: Prompt;
}

export function useNavigationPrompt({
	shouldBlockNavigation,
	prompt,
}: UseNavigationPromptInput) {
	const { add, remove } = useContext(PromptsContext);

	useEffect(() => {
		if (shouldBlockNavigation) {
			const id = add(prompt);
			return () => remove(id);
		}
		return () => {};
	}, [remove, shouldBlockNavigation, add, prompt]);
}

export function useUnsavedChangesPrompt(hasUnsavedChanges: boolean) {
	const t = useTranslate("common");

	const prompt = useMemo(
		() => ({ message: t("notifications.messages.unsaved-prompt") }),
		[t],
	);

	useNavigationPrompt({
		shouldBlockNavigation: hasUnsavedChanges,
		prompt,
	});
}
