import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgOrderReservation({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.25 2h9.5A3.25 3.25 0 0120 5.25V22a1 1 0 01-1.65.76L12 17.316l-6.35 5.442A1 1 0 014 22V5.25A3.25 3.25 0 017.25 2zm9.5 2h-9.5A1.25 1.25 0 006 5.25v14.576l5.35-4.585a1 1 0 011.3 0L18 19.826V5.25A1.25 1.25 0 0016.75 4z"
			/>
		</svg>
	);
}

const MemoSvgOrderReservation = React.memo(SvgOrderReservation);
export default MemoSvgOrderReservation;
