import { Close } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { ButtonProps } from "@app/modules/Button";
import { Button } from "@app/modules/Button";
// import { useClickOutside } from "@app/modules/hooks/useClickOutside";
import { useTransitionState } from "@app/modules/hooks/useTransitionState";
import { useTranslate } from "@app/modules/i18n/context";
import { useDraggable } from "@app/modules/layout/useDraggable";
import { logger } from "@app/modules/logger/logger";
import { Body14, Caption12, Header18 } from "@app/modules/typography";
import clsx from "clsx";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import React, {
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react";
import { createPortal } from "react-dom";
import type { Struct } from "superstruct";
import { is } from "superstruct";

// can be extended as we see fit
type ModalWidth = "s" | "m" | "l" | "xl" | "screen";
type ModalHeight = "screen";

const MODAL_PORTAL_NODE = document.getElementById("modal-portal");

export interface ModalProps {
	className?: string;
	children?: ReactNode;
	isOpen: boolean;
	onCloseRequest: () => void;
	hasCloseButton?: boolean;
	hasPadding?: boolean; // TODO: rework this prop so that it behaves more similarly to section cards (i.e. only the body padding is affected)
	width?: ModalWidth;
	height?: ModalHeight;
	draggable?: boolean;
}

export function Modal({
	className,
	children,
	isOpen,
	onCloseRequest,
	hasCloseButton = false,
	hasPadding = true,
	draggable = true,
	width,
	height,
}: ModalProps) {
	const state = useTransitionState(isOpen, 500);

	const { ref, styles } = useDraggable({ disabled: !draggable || !isOpen });

	if (!MODAL_PORTAL_NODE) {
		logger.error(
			undefined,
			"Modal portal node not found. Make sure an element with id 'modal-portal' exists in the DOM.",
		);
		return null;
	}

	if (state === "exited") {
		return null;
	}

	const isVisible = state === "entering" || state === "entered";
	const isChildrenVisible = isVisible || state === "exiting";

	return createPortal(
		<>
			<div className="fixed z-50 top-1/2 left-1/2" style={styles}>
				<div
					className={clsx(
						className,
						"transform -translate-x-1/2 -translate-y-1/2 transition-all rotate-0 shadow-card",
						isVisible
							? "opacity-100 pointer-events-auto"
							: "opacity-0 pointer-events-none",
					)}
					// ref={ref}
				>
					<div
						className={clsx(
							"bg-white shadow-card rounded transition-all transform max-h-screen max-w-screen overflow-y-auto",
							hasPadding && "p-32",
							isVisible ? "transform translate-y-0" : "translate-y-32",
							width && widthCls[width],
							height && heightCls[height],
						)}
					>
						{draggable && (
							<div
								className="bg-grey-300 h-8 cursor-move -m-32 absolute w-full"
								ref={ref}
							/>
						)}
						{hasCloseButton && (
							<button
								type="button"
								className="absolute top-12 right-12 transition-all text-brand-700 hover:text-brand-900"
								onClick={() => onCloseRequest()}
								aria-label="Close"
								data-testid="button-modal-close"
							>
								<Icon icon={Close} size="24" />
							</button>
						)}
						{isChildrenVisible && children}
					</div>
				</div>
			</div>
			<div
				className={clsx(
					"fixed top-0 left-0 w-full h-full bg-black transition-all z-40",
					isVisible
						? "opacity-10 pointer-events-auto"
						: "opacity-0 pointer-events-none",
				)}
			/>
		</>,
		MODAL_PORTAL_NODE,
	);
}

/* class={ */
const widthCls: Record<ModalWidth, string> = {
	s: "w-424",
	m: "w-776",
	l: "w-1024",
	xl: "w-1440",
	screen: "w-screen",
};

const heightCls: Record<ModalHeight, string> = {
	screen: "h-screen",
};
/* } */

export interface ModalButtonsProps {
	className?: string;
	children?: ReactNode;
	variant?: "default" | "s" | "grid";
}

export function ModalButtons({
	className,
	children,
	variant = "default",
}: ModalButtonsProps) {
	return (
		<div className={clsx(className, "gap-16 pt-32", modalButtonsCls[variant])}>
			{children}
		</div>
	);
}

/* class={ */
const modalButtonsCls: Record<
	NonNullable<ModalButtonsProps["variant"]>,
	string
> = {
	default: "grid w-fit grid-flow-col ml-auto",
	s: "grid grid-flow-col",
	grid: "grid",
};
/* } */

export function ModalCancelButton({
	className,
	children,
	testid = "modal-cancel",
	...props
}: Omit<ButtonProps, "variant">) {
	const t = useTranslate("common");
	return (
		<Button
			testid={testid}
			className={className}
			variant="secondary"
			icon={Close}
			{...props}
		>
			{children ?? t("actions.cancel")}
		</Button>
	);
}

export function ModalPrimaryButton({
	className,
	children,
	testid = "modal-primary",
	...props
}: Omit<ButtonProps, "variant">) {
	const t = useTranslate("common");
	return (
		<Button testid={testid} className={className} variant="primary" {...props}>
			{children ?? t("actions.save")}
		</Button>
	);
}

export interface ModalTitleProps {
	className?: string;
	children?: ReactNode;
}

export function ModalTitle({ className, children }: ModalTitleProps) {
	return (
		<Header18 className={clsx(className, "text-grey-900 mb-24")}>
			{children}
		</Header18>
	);
}

export interface ModalBodyProps {
	className?: string;
	children?: ReactNode;
}

export function ModalBody({ className, children }: ModalBodyProps) {
	return (
		<Body14 className={clsx(className, "text-grey-900 font-400")}>
			{children}
		</Body14>
	);
}
interface ModalPropsState {
	[name: string]: {
		isOpen: boolean;
		props?: unknown;
	};
}

const ModalContext = React.createContext<{
	modals: ModalPropsState;
	setModalProps: Dispatch<SetStateAction<ModalPropsState>>;
}>({
	modals: {},
	setModalProps: () => {},
});

export interface ModalProviderProps {
	children?: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps) {
	const [modalProps, setModalProps] = useState<ModalPropsState>({});

	const value = useMemo(
		() => ({ modals: modalProps, setModalProps }),
		[modalProps],
	);

	return (
		<ModalContext.Provider value={value}>{children}</ModalContext.Provider>
	);
}

interface UseModalConfig<T> {
	name: string;
	propsSchema: Struct<T>;
}

export interface UseModalOptions {
	onCloseRequest?: () => void;
}

export function useModal<T>(
	{ name, propsSchema }: UseModalConfig<T>,
	{ onCloseRequest }: UseModalOptions = {},
) {
	const { modals, setModalProps } = useContext(ModalContext);
	const openModal = useCallback(
		(props: T) => {
			if (!is(props, propsSchema)) {
				logger.warn("Received invalid props for modal", name, props);
				return;
			}
			setModalProps((val) => ({ ...val, [name]: { isOpen: true, props } }));
		},
		[name, propsSchema, setModalProps],
	);

	const onCloseRequestRef = useRef(onCloseRequest);
	onCloseRequestRef.current = onCloseRequest;

	const closeModal = useCallback(() => {
		setModalProps((val) => ({
			...val,
			[name]: { ...val[name], isOpen: false },
		}));
		onCloseRequestRef.current?.();
	}, [name, setModalProps]);

	const { isOpen, props } = modals[name] ?? { isOpen: false, props: {} };
	const hasInValidProps = isOpen && !is(props, propsSchema);
	const isOpenAndValid = isOpen && !hasInValidProps;
	const modalProps: ModalProps = {
		isOpen: isOpenAndValid,
		onCloseRequest: closeModal,
	};

	return {
		isOpen: isOpenAndValid,
		openModal,
		closeModal,
		props: hasInValidProps ? undefined : (props as T),
		modalProps,
	};
}

// Currently it's unclear whether this is an app-wide pattern.
// For now, we're using this in modals.
export interface ModalSubtitleProps {
	title?: string;
	extraElement?: React.ReactNode;
}
export function ModalSubtitle({ title, extraElement }: ModalSubtitleProps) {
	return (
		<div className="mb-16">
			<div className="flex justify-between items-end">
				<Caption12 className="font-500 text-grey-700">{title}</Caption12>
				{extraElement}
			</div>
			<div className="w-full border-b-[1px] border-grey-200 mt-8" />
		</div>
	);
}

export function useModalState() {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const showModal = useCallback(() => setIsModalOpen(true), []);
	const closeModal = useCallback(() => setIsModalOpen(false), []);

	return { isModalOpen, setIsModalOpen, showModal, closeModal };
}
