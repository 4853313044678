import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgEdit({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.551 5.104a3.782 3.782 0 015.186-.147l.156.147.153.163a3.782 3.782 0 01-.006 5.03l-.148.155-7.573 7.57c-.092.094-.187.172-.335.268l-.121.076-.09.047-.198.084-3.912 1.39a2 2 0 01-2.597-2.398l.049-.158 1.434-4.021.062-.13.102-.177c.051-.08.109-.155.169-.224l.093-.098 7.576-7.577zm3.835 1.51a1.649 1.649 0 00-2.209-.107l-.117.106-7.553 7.552-1.288 3.615 3.607-1.283 7.558-7.554c.579-.579.64-1.486.203-2.096l-.094-.117-.107-.115z"
			/>
		</svg>
	);
}

const MemoSvgEdit = React.memo(SvgEdit);
export default MemoSvgEdit;
