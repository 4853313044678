import { useTranslate } from "@app/modules/i18n/context";
import { autoSelectOnFocus } from "@app/modules/input-fields/NumericInputLegacy";
import type { TextInputProps } from "@app/modules/input-fields/TextInput";
import { TextInput } from "@app/modules/input-fields/TextInput";
import { nbsp } from "@app/modules/layout/characters";
import {
	descale,
	scale,
	ScaledNumber,
} from "@app/modules/number/scaled-number";
import {
	formatCanonicalQuantity,
	formatUnit,
	getDecimalScale,
	THOUSAND_SEPARATOR,
} from "@app/modules/product-unit/product-unit-utils";
import type { ProductUnitFragment } from "@app/modules/product-unit/queries/product-unit.fragment.generated";
import clsx from "clsx";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { NumberFormatValues } from "react-number-format";
import { NumericFormat } from "react-number-format";

export interface ProductQuantityInputLegacyProps
	extends Omit<
		TextInputProps,
		| "type"
		| "value"
		| "onChange"
		| "onClick"
		| "defaultValue"
		| "suffix"
		| "prefix"
	> {
	unit?: Pick<ProductUnitFragment, "unit" | "productUnitName"> &
		Partial<Pick<ProductUnitFragment, "significantDigits">>;
	hasSuffix?: boolean;
	className?: string;
	children?: ReactNode;
	value?: string;
	onChange?: (value: string) => void;
	defaultValue?: string;
	autoSelect?: boolean;
	allowNegative?: boolean;
	forceNegative?: boolean;
}

/**
 * @see `ProductQuantity` is preferred
 */
const ProductQuantityInputLegacy = forwardRef<
	HTMLInputElement,
	ProductQuantityInputLegacyProps
>(function ProductQuantityInputLegacy(
	{
		className,
		value = "",
		onChange,
		inputClassName,
		unit,
		hasSuffix = true,
		autoSelect = true,
		allowNegative = true,
		forceNegative = false,
		...props
	},
	ref,
) {
	const t = useTranslate("common");

	const handleChange = (values: NumberFormatValues) => {
		if (value !== values.value) {
			if (values.value) {
				const scaled = scale(values.value);

				if (forceNegative) {
					const negative = scaled.abs().negated();
					if (negative.isZero()) {
						onChange?.("");
					} else {
						onChange?.(negative.toString());
					}
					return;
				}

				onChange?.(scaled.toString());
				return;
			}

			onChange?.(values.value);
		}
	};

	const decimalScale = unit?.significantDigits ?? getDecimalScale(unit);

	const inputSuffix = hasSuffix && unit ? formatUnit(t, unit) : undefined;

	return (
		<NumericFormat
			decimalScale={decimalScale}
			fixedDecimalScale
			valueIsNumericString
			thousandSeparator={THOUSAND_SEPARATOR}
			value={descale(value, decimalScale)}
			onValueChange={handleChange}
			getInputRef={ref}
			allowNegative={allowNegative}
			// TextInput props
			customInput={NumericTextInput}
			className={className}
			inputClassName={clsx(inputClassName, "text-right")}
			inputSuffix={inputSuffix}
			inputMode="numeric"
			{...autoSelectOnFocus({ isActive: autoSelect })}
			{...props}
		/>
	);
});

export interface NumericTextInputProps
	extends Omit<TextInputProps, "prefix" | "suffix"> {
	inputPrefix?: ReactNode;
	inputSuffix?: string;
}

const NumericTextInput = forwardRef<HTMLInputElement, NumericTextInputProps>(
	function NumericTextInput({ inputSuffix, inputPrefix, ...props }, ref) {
		return (
			<TextInput
				{...props}
				prefix={inputPrefix}
				suffix={inputSuffix}
				ref={ref}
			/>
		);
	},
);

export interface ProductQuantityProps {
	className?: string;
	unit?: ProductUnitFragment;
	value: string;
	isRed?: boolean;
	isSmall?: boolean;
	diff?: boolean;
	hideUnit?: boolean;
	significantDigits?: number;
}

export function ProductQuantity({
	className,
	value,
	unit,
	isRed,
	isSmall,
	diff = false,
	hideUnit = false,
	significantDigits,
}: ProductQuantityProps) {
	const t = useTranslate("common");

	const decimalScale =
		significantDigits ?? unit?.significantDigits ?? getDecimalScale(unit);
	const prefix = diff && new ScaledNumber(value).gte(0) ? "+" : undefined;

	return (
		<div className={clsx(className, "inline-block gap-4 whitespace-nowrap")}>
			<NumericFormat
				decimalScale={decimalScale}
				fixedDecimalScale
				valueIsNumericString
				thousandSeparator={THOUSAND_SEPARATOR}
				value={descale(value, decimalScale)}
				displayType="text"
				prefix={prefix}
				className={clsx(
					isSmall ? "text-caption-10" : "text-body-14",
					"font-500",
					isRed && "text-red-800",
				)}
			/>
			{unit && !hideUnit && (
				<span
					className={clsx(
						"text-grey-700 ml-4",
						isSmall ? "text-caption-10" : "text-caption-12",
						isRed && "text-red-800",
					)}
				>
					{formatUnit(t, unit)}
				</span>
			)}
		</div>
	);
}

export interface ProductQuantityInlineProps {
	className?: string;
	value: string;
	unit?: ProductUnitFragment;
	hideUnit?: boolean;
	significantDigits?: number;
}

export function ProductQuantityInline({
	className,
	value,
	unit,
	hideUnit = false,
	significantDigits,
}: ProductQuantityInlineProps) {
	const decimalScale =
		significantDigits ?? unit?.significantDigits ?? getDecimalScale(unit);
	const t = useTranslate("common");

	return (
		<span className={clsx(className)}>
			<NumericFormat
				decimalScale={decimalScale}
				fixedDecimalScale
				valueIsNumericString
				thousandSeparator={THOUSAND_SEPARATOR}
				value={formatCanonicalQuantity(value, decimalScale)}
				displayType="text"
			/>
			{unit && !hideUnit && (
				<>
					{nbsp}
					{formatUnit(t, unit)}
				</>
			)}
		</span>
	);
}

export { ProductQuantityInputLegacy };
