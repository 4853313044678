import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgProduktRezeptur({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.974 2.419c-.6-.36-1.35-.36-1.95 0L7.873 4.31c-.57.343-.92.96-.92 1.625V9.72l-3.128 1.876c-.57.343-.92.96-.92 1.625v3.844c0 .665.35 1.282.92 1.625l3.153 1.891c.6.36 1.35.36 1.95 0l3.072-1.843 3.073 1.843c.6.36 1.35.36 1.95 0l3.153-1.891c.57-.343.92-.96.92-1.625V13.22c0-.666-.35-1.282-.92-1.625L17.047 9.72V5.935c0-.665-.35-1.282-.92-1.625L12.974 2.42zM8.952 5.995l3.047-1.829 3.048 1.829V9.72L12 11.548 8.952 9.72V5.995zm4.047 7.285v3.725l3.048 1.829 3.048-1.829v-3.724l-3.048-1.83L13 13.282zm-5.047-1.828l3.047 1.829v3.724l-3.047 1.829-3.048-1.829v-3.724l3.048-1.83z"
			/>
		</svg>
	);
}

const MemoSvgProduktRezeptur = React.memo(SvgProduktRezeptur);
export default MemoSvgProduktRezeptur;
