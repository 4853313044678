import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgSideNav({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 7a4 4 0 014-4h14a4 4 0 014 4v10a4 4 0 01-4 4H5a4 4 0 01-4-4V7zm4-2a2 2 0 00-2 2h18a2 2 0 00-2-2H5zm10 4H3v8a2 2 0 002 2h10V9z"
			/>
		</svg>
	);
}

const MemoSvgSideNav = React.memo(SvgSideNav);
export default MemoSvgSideNav;
