// THIS FILE IS GENERATED, DO NOT EDIT!
import type * as Types from '../../graphql-api-types.generated';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RejectOfferMutationVariables = Types.Exact<{
  id: Types.Scalars['bigint'];
  items: Types.Scalars['json'];
  deliveryDate: Types.Scalars['date'];
}>;


export type RejectOfferMutation = { __typename: 'mutation_root', change_customer_order_with_token: Array<{ __typename: 'change_customer_order_with_token_result', ok: boolean }> };


export const RejectOfferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RejectOffer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bigint"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"items"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"json"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deliveryDate"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"date"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"change_customer_order_with_token"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"args"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_customer_order_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"_customer_order_state"},"value":{"kind":"StringValue","value":"OFFER_REJECTED","block":false}},{"kind":"ObjectField","name":{"kind":"Name","value":"_customer_order_items"},"value":{"kind":"Variable","name":{"kind":"Name","value":"items"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"_delivery_date"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deliveryDate"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"ok"}}]}}]}}]} as unknown as DocumentNode<RejectOfferMutation, RejectOfferMutationVariables>;