import { BigNumber } from "@app/modules/number/big-number";
import type { ScaledNumber } from "@app/modules/number/scaled-number";
import { convertRateUnit } from "@app/modules/product-unit/product-unit-utils";
import type { ProductUnitFragment } from "@app/modules/product-unit/queries/product-unit.fragment.generated";

export interface SalesMargin {
	absolute: BigNumber;
	percentage: BigNumber;
	salesPrice: string;
	costPrice: string;
}
export interface COISalesMargin extends SalesMargin {
	salesPriceUnit: ProductUnitFragment;
	costPriceUnit: ProductUnitFragment;
}

export function computeConvertedSalesMargin(
	salesPrice: string | ScaledNumber,
	salesPriceUnit: ProductUnitFragment,
	costPrice: string | ScaledNumber,
	costPriceUnit: ProductUnitFragment,
): COISalesMargin {
	const convertedCostPrice = convertRateUnit(
		costPriceUnit,
		salesPriceUnit,
		costPrice,
	);

	const salesMargin = computeSalesMargin({
		salesPrice,
		costPrice: convertedCostPrice,
	});

	return {
		...salesMargin,
		salesPriceUnit,
		costPriceUnit,
	};
}

export function computeSalesMargin({
	salesPrice,
	costPrice,
}: {
	salesPrice: string | ScaledNumber;
	costPrice: string | ScaledNumber;
}): SalesMargin {
	const absoluteMargin = new BigNumber(salesPrice).minus(costPrice);
	const percentageMargin = absoluteMargin.times(100).div(salesPrice);

	return {
		absolute: absoluteMargin,
		percentage: percentageMargin.isFinite()
			? percentageMargin
			: new BigNumber(0),
		salesPrice: salesPrice.toString(),
		costPrice: costPrice.toString(),
	};
}
