import { useAppState } from "@app/modules/app-state/context";
import { injectTenantFilter } from "@app/modules/entity/entity-utils";
import { useQuery } from "@app/modules/graphql/queries";
import type {
	SelectItem,
	SelectWrapperProps,
} from "@app/modules/input-fields/Select";
import { Select, useNullOption } from "@app/modules/input-fields/Select";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import type { DocumentInput, RequestPolicy } from "urql";

export interface EntitySelectProps<
	QueryDocument extends DocumentInput,
	Entity,
	ValueQueryDocument extends DocumentInput<
		{ value?: Entity | null },
		{ id: string }
	>,
> extends SelectWrapperProps {
	valueQueryDocument?: ValueQueryDocument;
	queryDocument: QueryDocument;
	queryVariables?: VariablesOf<QueryDocument>;
	requestPolicy?: RequestPolicy;
	getEntities: (data: ResultOf<QueryDocument>) => Entity[];
	asSelectOption: (entity: Entity) => SelectItem;
	getDefaultOptionId?: (
		options: SelectItem[],
		entities: Entity[],
	) => string | undefined;
	staticOptions?: SelectItem[];
	showSharedEntities?: boolean;
	showNullOption?: boolean;
}

export type EntitySelectWrapperProps = SelectWrapperProps & {
	showSharedEntities?: boolean;
};

const EntitySelect = forwardRef(function EntitySelect<
	QueryDocument extends DocumentInput,
	Entity,
	ValueQueryDocument extends DocumentInput<
		{ value?: Entity | null },
		{ id: string }
	>,
>(
	{
		queryDocument,
		queryVariables,
		getEntities,
		asSelectOption,
		valueQueryDocument,
		getDefaultOptionId,
		staticOptions,
		requestPolicy,
		showSharedEntities = false,
		showNullOption = false,
		defaultValue,
		...props
	}: EntitySelectProps<QueryDocument, Entity, ValueQueryDocument>,
	ref: ForwardedRef<HTMLButtonElement>,
) {
	const { tenant } = useAppState();

	const variables = showSharedEntities
		? queryVariables
		: injectTenantFilter(queryVariables, tenant);

	const [{ data, fetching }] = useQuery<ResultOf<QueryDocument>>({
		query: queryDocument,
		variables,
		requestPolicy,
		pause: props.disabled,
	});

	const entities = data && getEntities(data);
	const nullOption = useNullOption();
	const items = [
		...(showNullOption ? [nullOption] : []),
		...(staticOptions || []),
		...(entities || []).map(asSelectOption),
	];

	const defaultOptionId = getDefaultOptionId?.(items || [], entities || []);

	const defaultValueEntity = items.find(({ id }) => id === defaultValue);

	const [defaultValueResult] = useQuery({
		query: valueQueryDocument,
		variables: { id: defaultValue ?? "" },
		pause: !defaultValue || (!fetching && Boolean(defaultValueEntity)),
	});

	const selectedOption = (() => {
		if (props.value !== defaultValue) {
			return undefined;
		}
		return defaultValueResult.data?.value
			? asSelectOption(defaultValueResult.data?.value)
			: undefined;
	})();

	return (
		<Select
			{...props}
			ref={ref}
			options={items}
			defaultValue={defaultOptionId ?? defaultValue}
			selectedOption={selectedOption}
		/>
	);
});

export { EntitySelect };
