import type {
	FieldConstraintStatus,
	FormControl,
} from "@app/modules/form/form";
import type { CustomMessages } from "@app/modules/form/hooks";
import { useGetErrorMessage } from "@app/modules/form/hooks";
import { logger } from "@app/modules/logger/logger";
import { useUnsavedChangesPrompt } from "@app/modules/notification/navigation-prompt";
import { superstructResolver } from "@hookform/resolvers/superstruct";
import type { BaseSyntheticEvent } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import type {
	DeepPartial,
	FieldName,
	FieldPath,
	FieldValues,
	Mode,
	SubmitErrorHandler,
	SubmitHandler,
	UnpackNestedValue,
	UseFormReturn,
} from "react-hook-form";
import {
	useForm,
	useFormContext as useFormContextOriginal,
} from "react-hook-form";
import type { Struct } from "superstruct";

export interface UseEntityFormOptions<FormValues> {
	schema: Struct<FormValues>;
	defaultValues?: UnpackNestedValue<DeepPartial<FormValues>>;
	shouldFocusError?: boolean;
	mode?: Mode;
}

export interface UseEntityFormReturn<
	TFieldValues extends FieldValues = FieldValues,
> extends UseFormReturn<TFieldValues> {
	getErrorMessage: (name: FieldName<TFieldValues>) => string | undefined;
	control: FormControl<TFieldValues>;
}

// This hook looks out of place. We should move it to the "form" module.
export function useEntityForm<FormValues extends FieldValues>({
	schema,
	defaultValues,
	shouldFocusError,
	mode,
	customerErrorMessages,
}: UseEntityFormOptions<FormValues> & {
	customerErrorMessages?: CustomMessages;
}): UseEntityFormReturn<FormValues> {
	const methods = useForm({
		resolver: superstructResolver(schema),
		mode,
		criteriaMode: "all",
		shouldFocusError,
		defaultValues,
	});

	const fieldConstraintStates = useRef<Record<string, FieldConstraintStatus>>(
		{},
	);

	const getErrorMessageFn = useGetErrorMessage(customerErrorMessages);
	const getErrorMessage = (name: string) =>
		getErrorMessageFn(methods.formState.errors, name);

	const handleSubmit =
		(
			handler: SubmitHandler<FormValues>,
			errorHandler: SubmitErrorHandler<FormValues> | undefined = logger.warn,
		) =>
		(e?: BaseSyntheticEvent<object, any, any>) => {
			e?.stopPropagation();

			const isConstraintValidating = Object.values(
				fieldConstraintStates.current,
			).some((status) => status === "validating");
			if (isConstraintValidating) {
				e?.preventDefault();
				logger.info(
					"Ignoring submit due to active async validation",
					fieldConstraintStates,
				);
				return Promise.resolve();
			}

			const hasConstraintErrors = Object.values(
				fieldConstraintStates.current,
			).some((status) => status === "error");
			if (hasConstraintErrors) {
				e?.preventDefault();
				logger.info(
					"Ignoring submit due to async validation errors",
					fieldConstraintStates,
				);
				return Promise.resolve();
			}
			return methods.handleSubmit(
				// For some reason RHF returns objects on submit that it still keeps
				// modifying after the submit. To avoid bugs we make a clone of the
				// submitted data before handing it over to the handler.
				(data, event) => handler(structuredClone(data), event),
				errorHandler,
			)(e);
		};

	// This is a hack. Creating a new control object using the spread operator and adding the function that way cause regular react hook form functionality to break.
	// For future reference: the first bug we noticed was in supplier order details. The product combobox would not store its value, nor would the quantity field show the order unit.
	const control = useMemo(() => {
		const extendedControl = methods.control as FormControl<FormValues>;
		extendedControl.setFieldConstraintState = (
			name: FieldPath<FormValues>,
			status: FieldConstraintStatus,
		) => {
			fieldConstraintStates.current[name] = status;
		};
		return extendedControl;
	}, [methods.control]);

	return {
		...methods,
		handleSubmit,
		control,
		getErrorMessage,
	};
}

export function useEntityFormContext<
	TFieldValues extends FieldValues,
>(): UseEntityFormReturn<TFieldValues> {
	return useFormContextOriginal() as UseEntityFormReturn<TFieldValues>;
}

/** @deprecated Use `useEditableSectionCardHeader` instead. */
export function useEditEntityActions(
	{ isEditable }: { isEditable: boolean } = { isEditable: true },
) {
	const [isEditing, setIsEditing] = useState(false);

	useUnsavedChangesPrompt(isEditing);

	const startEditing = useCallback(() => {
		if (isEditable) {
			setIsEditing(true);
		}
	}, [isEditable]);

	const stopEditing = useCallback(() => {
		setIsEditing(false);
	}, []);

	return {
		isEditing,
		isEditable,
		startEditing,
		stopEditing,
	};
}
