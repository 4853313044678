import { LoaderDark } from "@app/icons/components";
import { Trash } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { autoHeightCls } from "@app/modules/layout/autoheight";
import { useDestructiveClickHandler } from "@app/modules/layout/DestructiveButton";
import { useDelayedFetchingIndicator } from "@app/modules/layout/FetchingIndicator";
import type { LinkProps } from "@app/modules/routes/link";
import { Link } from "@app/modules/routes/link";
import type { RouteName } from "@app/modules/routes/routes";
import { Body14, Caption12 } from "@app/modules/typography";
import clsx from "clsx";
import type {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	CSSProperties,
	HTMLAttributes,
	ReactNode,
} from "react";
import { createContext, forwardRef, memo, useContext, useMemo } from "react";

export interface TableProps extends HTMLAttributes<HTMLDivElement> {
	autoHeight?: boolean;
	isInsideSection?: boolean;
}

export function Table({
	className,
	autoHeight,
	isInsideSection = true,
	...props
}: TableProps) {
	return (
		<div
			className={clsx(
				// TODO: remove manual padding compensation and move concern to parent BaseDataSection
				isInsideSection && "-mr-16 -ml-16",
				autoHeightCls(autoHeight),
				className,
			)}
			{...props}
		/>
	);
}

const TableRowContext = createContext<{ height: TableCellHeight }>({
	height: "m",
});

export interface TableRowProps extends HTMLAttributes<HTMLDivElement> {
	hasHover?: boolean;
	hasBorder?: boolean;
	sticky?: boolean;
	height?: TableCellHeight;
}

export const TableRow = forwardRef<HTMLDivElement, TableRowProps>(
	function TableRow(
		{
			className,
			children,
			hasHover,
			hasBorder = true,
			sticky,
			style,
			height = "m",
			...props
		},
		ref,
	) {
		const contextValue = useMemo(() => ({ height }), [height]);

		return (
			<div
				ref={ref}
				style={style}
				className={clsx(
					className,
					"grid text-grey-700 px-16",
					hasBorder && "border-b border-grey-200",
					hasHover &&
						"group relative transition-all bg-transparent hover:bg-grey-200",
					sticky && "sticky -top-16 bg-white z-10",
				)}
				{...props}
			>
				<TableRowContext.Provider value={contextValue}>
					{children}
				</TableRowContext.Provider>
			</div>
		);
	},
);

type TableHeadProps = HTMLAttributes<HTMLDivElement>;

export function TableHead({ className, children, ...props }: TableHeadProps) {
	return (
		<Caption12
			className={clsx(className, "text-grey-900 font-600 py-4")}
			{...props}
		>
			{children}
		</Caption12>
	);
}

export type TableCellHeight = "xs" | "s" | "m";

export interface TableCellProps extends HTMLAttributes<HTMLDivElement> {
	isStrong?: boolean;
	hasHover?: boolean;
	truncate?: boolean;
	height?: TableCellHeight;
	innerClassName?: string;
}

export function TableCell({
	className,
	innerClassName,
	children,
	isStrong,
	hasHover,
	height,
	truncate,
	...props
}: TableCellProps) {
	const { height: contextHeight } = useContext(TableRowContext);
	const cellHeight = height ?? contextHeight;
	return (
		<Body14
			className={clsx(
				"flex items-center",
				cellHeightCls[cellHeight],
				hasHover &&
					"group relative bg-transparent hover:bg-grey-200 transition-all",
				isStrong ? "font-500 text-grey-900" : "font-400 text-grey-700",
				className,
			)}
			{...props}
		>
			<div className={clsx("w-full", truncate && "truncate", innerClassName)}>
				{children}
			</div>
		</Body14>
	);
}

/* class={ */
const cellHeightCls: Record<TableCellHeight, string> = {
	m: "py-12",
	s: "py-8",
	xs: "py-4",
};

/* } */

export interface TableRowActionsProps extends HTMLAttributes<HTMLDivElement> {
	isOpen?: boolean;
	isTransparent?: boolean;
}

export function TableRowActions({
	className,
	children,
	isOpen,
	isTransparent,
	...props
}: TableRowActionsProps) {
	return (
		<Body14
			tabIndex={-1}
			className={clsx(
				className,
				isOpen ? "opacity-100" : "opacity-0",
				"group-hover:opacity-100 absolute top-1/2 right-0 transform -translate-y-1/2 py-4 transition-all flex pr-16 pl-8 h-full items-center",
				!isTransparent && "bg-grey-200",
			)}
			{...props}
		>
			{children}
		</Body14>
	);
}

export interface TableRowActionProps {
	className?: string;
	children?: ReactNode;
}

export function TableRowAction({ className, children }: TableRowActionProps) {
	return <div className={clsx(className, tableActionCls)}>{children}</div>;
}

export interface TableRowActionButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	children?: ReactNode;
	isOpen?: boolean;
	icon?: IconComponent;
	isFetching?: boolean;
	disableFetchingIndicatorDelay?: boolean;
}

export function TableRowActionButton({
	className,
	children,
	isOpen,
	icon,
	isFetching,
	disableFetchingIndicatorDelay,
	disabled,
	...props
}: TableRowActionButtonProps) {
	const { isIndicatorVisible } = useDelayedFetchingIndicator(
		isFetching,
		disableFetchingIndicatorDelay ? 0 : undefined,
	);

	return (
		<button
			type="button"
			tabIndex={-1}
			className={clsx(
				className,
				tableActionCls,
				"text-grey-700",
				isOpen && "transform -rotate-180",
				disabled && "text-grey-500 cursor-default",
			)}
			disabled={disabled || isFetching}
			{...props}
		>
			{icon && (
				<Icon
					icon={isIndicatorVisible ? LoaderDark : icon}
					size="16"
					className={clsx("inline-block", isIndicatorVisible && "animate-spin")}
				/>
			)}
			{children}
		</button>
	);
}

interface TableRowDestructiveButtonProps
	extends Omit<TableRowActionButtonProps, "icon" | "isOpen"> {
	confirmButtonLabel?: string;
}

export function TableRowDestructiveButton({
	className,
	children,
	isFetching,
	onClick,
	confirmButtonLabel,
	...props
}: TableRowDestructiveButtonProps) {
	const { isIndicatorVisible } = useDelayedFetchingIndicator(isFetching);
	const handleClick = useDestructiveClickHandler({
		onClick,
		confirmButtonLabel,
	});
	return (
		<button
			type="button"
			tabIndex={-1}
			className={clsx(className, tableActionCls, "text-red-800")}
			disabled={isFetching}
			onClick={handleClick}
			{...props}
		>
			<Icon
				icon={isIndicatorVisible ? LoaderDark : Trash}
				size="16"
				className={clsx("inline-block", isIndicatorVisible && "animate-spin")}
			/>
			{children}
		</button>
	);
}

export type TableRowActionLinkProps<Name extends RouteName> =
	LinkProps<Name> & {
		icon?: IconComponent;
		hidden?: boolean;
	};

export function TableRowActionLink<Name extends RouteName>({
	className,
	to,
	params,
	icon,
	children,
	hidden,
	...props
}: TableRowActionLinkProps<Name>) {
	return (
		<Link
			to={to as RouteName}
			params={params}
			tabIndex={-1}
			className={clsx(
				className,
				tableActionCls,
				"text-grey-700",
				hidden && "invisible",
			)}
			{...props}
		>
			{icon && <Icon icon={icon} size="16" className="inline-block" />}
			{children}
		</Link>
	);
}

export type TableRowActionExternalLinkProps =
	AnchorHTMLAttributes<HTMLAnchorElement> & {
		icon?: IconComponent;
	};

export function TableRowActionExternalLink({
	className,
	children,
	icon,
	...props
}: TableRowActionExternalLinkProps) {
	return (
		<a
			target="_blank"
			tabIndex={-1}
			className={clsx(className, tableActionCls, "text-grey-700")}
			{...props}
		>
			{icon && <Icon icon={icon} size="16" className="inline-block" />}
			{children}
		</a>
	);
}

/* class={ */
export const tableActionCls =
	"bg-white transition-all p-0 w-32 h-32 rounded-full hover:bg-grey-300 ml-8 flex items-center justify-center";

/* } */

export interface TableAccordionProps {
	className?: string;
	children?: ReactNode;
	isOpen: boolean;
}

export function TableAccordion({
	className,
	children,
	isOpen,
}: TableAccordionProps) {
	return (
		<div
			className={clsx(
				className,
				"col-span-full grid bg-grey-200 -mb-0",
				"transition-all overflow-hidden",
				isOpen ? "max-h-screen" : "max-h-0",
			)}
		>
			{children}
		</div>
	);
}

export interface TableRowSkeletonLoaderProps {
	/* eslint-disable react/no-unused-prop-types */
	className?: string;
	style?: CSSProperties;
	rowClassNames: string[];
	height?: TableCellHeight;
	/* eslint-enable react/no-unused-prop-types */
}

export const TableRowSkeletonLoader = memo(
	forwardRef<HTMLDivElement, TableRowSkeletonLoaderProps>(
		({ className, rowClassNames, style, height }, ref) => (
			<TableRow
				style={style}
				ref={ref}
				className={clsx(className, "animate-pulse")}
				height={height}
			>
				{rowClassNames.map((rowCls, index) => (
					<TableCell
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className={rowCls}
						innerClassName="flex items-center"
					>
						<TableCellContentPlaceholder />
					</TableCell>
				))}
			</TableRow>
		),
	),
);

interface TableCellContentPlaceholderProps {
	bg?: string;
	width?: string;
	height?: string;
	display?: string;
}

export function TableCellContentPlaceholder({
	bg = "bg-grey-500",
	width = "w-2/3",
	height = "h-20",
	display = "inline-block",
}: TableCellContentPlaceholderProps) {
	return <div className={clsx(bg, width, height, display)} />;
}
