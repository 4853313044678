import { getCurrencyFormat } from "@app/modules/money/currency-format";
import type { SharedMoneyProps } from "@app/modules/money/money-utils";
import { bigintToValue } from "@app/modules/money/money-utils";
import { ScaledNumber } from "@app/modules/number/scaled-number";
import { THOUSAND_SEPARATOR } from "@app/modules/product-unit/product-unit-utils";
import clsx from "clsx";
import type { ReactNode } from "react";
import { NumericFormat } from "react-number-format";

export interface MoneyProps extends SharedMoneyProps {
	className?: string;
	value: string;
	color?: "green" | "red" | "default" | "grey900" | "auto";
	isSmall?: boolean;
	diff?: boolean;
	currencyLabel?: ReactNode;
}

export function Money({
	className,
	currency,
	value,
	unit,
	color = "default",
	isSmall,
	diff = false,
	hideCurrency = false,
	currencyLabel,
}: MoneyProps) {
	const prefix = diff && new ScaledNumber(value).gte(0) ? "+" : undefined;

	const format = getCurrencyFormat(currency);

	const colorCls = (() => {
		if (color === "auto") {
			const scaledValue = new ScaledNumber(value);

			if (scaledValue.isLessThan(0)) {
				return colors.red;
			}

			if (scaledValue.isGreaterThan(0)) {
				return colors.green;
			}

			return colors.default;
		}

		return colors[color];
	})();

	return (
		<div className={clsx(className, "inline-block whitespace-nowrap")}>
			{!hideCurrency && (
				<span
					className={clsx(
						isSmall ? "text-caption-10" : "text-caption-12",
						"font-500 mr-4",
						colorCls,
					)}
				>
					{currencyLabel ?? format.label}
				</span>
			)}
			<NumericFormat
				decimalScale={format.decimalDigits}
				prefix={prefix}
				fixedDecimalScale
				thousandSeparator={THOUSAND_SEPARATOR}
				value={bigintToValue(value, format.decimalDigits)}
				valueIsNumericString
				displayType="text"
				className={clsx(
					isSmall ? "text-caption-10" : "text-body-12",
					"font-500",
					colorCls,
				)}
			/>
			{unit && (
				<span
					className={clsx(
						isSmall ? "text-caption-10" : "text-caption-12",
						"ml-4",
						colorCls,
					)}
				>
					{unit}
				</span>
			)}
		</div>
	);
}

/* class={ */
const colors = {
	green: "text-green-800",
	red: "text-red-800",
	default: "text-grey-700",
	grey900: "text-grey-900",
};
/* } */
