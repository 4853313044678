import type {
	HardwareCommand,
	HardwareInstruction,
	ParsedMessage,
} from "@app/modules/hardware/types";
import { unknownMessage } from "@app/modules/hardware/types";

const TERMINATION_STRING = String.fromCharCode(13, 10);

const instructions: Record<HardwareCommand, HardwareInstruction> = {
	"get-weight": "q$",
	tare: 'q"',
	"set-to-zero": "q!",
	reset: "q#",
};

function compile(cmd: HardwareCommand): HardwareInstruction {
	return instructions[cmd] + TERMINATION_STRING;
}

function parse(instr: HardwareInstruction): ParsedMessage {
	// messages have a fixed size format
	// 1: type
	// 2: status
	// 3: sign (space or -)
	// 4: 10^4
	// 5: 10^3
	// 6: 10^2
	// 7: 10^1
	// 8: 10^0
	// 9: ,
	// 10: 10^-1
	// 11: 10^-2
	// 12: k
	// 13: g
	//
	// ATTENTION: the place of the comma is not fixed, but depending on the
	// resolution of the scale. It can be at any place or even be missing completely.
	// The length of the message is somewhere between 13 and 15 characters.

	let weight: string;
	// unit is in the last two characters of the instruction
	// should be "kg"
	const unit = instr.substring(instr.length - 2, instr.length);
	if (unit !== "kg") {
		return unknownMessage;
	}
	let commaIndex = instr.indexOf(",");
	if (
		instr.charCodeAt(0) === 43 ||
		instr.charCodeAt(0) === 44 ||
		instr.charCodeAt(0) === 59 ||
		instr.charCodeAt(0) === 61 ||
		instr.charCodeAt(0) === 60 ||
		instr.charCodeAt(0) === 41 ||
		instr.charCodeAt(0) === 48
	) {
		// "+" or ","  -> gross or net with tare
		// ";" or "=" -> gross or net with tare, 10x resolution
		// "<" -> net without tare, 10x resolution (used by iS30 scale)
		// ")" or "0" -> new without tare, internal resolution (used by iS30 scale)

		if (commaIndex === 0) {
			// if the comma is the first character, we need to search for another comma.
			commaIndex = instr.indexOf(",", 1);
		}
		if (commaIndex === -1) {
			// no comma found
			const kg = parseInt(instr.substring(3, instr.length - 2), 10);
			weight = kg.toString().concat(".", "0");
		} else {
			const kg = parseInt(instr.substring(3, commaIndex), 10);
			const g = instr.substring(commaIndex + 1, instr.length - 2);
			weight = kg.toString().concat(".", g.toString());
		}
	} else {
		return unknownMessage;
	}
	if (instr.charCodeAt(2) === 45) {
		// negative weight
		weight = "-".concat(weight);
	}

	return { type: "weight", weight, unit };
}

export const bizerbast = {
	compile,
	parse,
	TERMINATION_STRING,
};
