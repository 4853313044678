import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgScalemultiply({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.077 3H9.923A1.923 1.923 0 008 4.923V5h6.846A4.154 4.154 0 0119 9.154V16h.077A1.923 1.923 0 0021 14.077V4.923A1.923 1.923 0 0019.077 3zM5.154 5H6v-.077A3.923 3.923 0 019.923 1h9.154A3.923 3.923 0 0123 4.923v9.154A3.923 3.923 0 0119.077 18H19v.846A4.154 4.154 0 0114.846 23H5.154A4.154 4.154 0 011 18.846V9.154A4.154 4.154 0 015.154 5zm0 2h9.692C16.036 7 17 7.964 17 9.154v9.692c0 1.19-.964 2.154-2.154 2.154H5.154A2.154 2.154 0 013 18.846V9.154C3 7.964 3.964 7 5.154 7zm-1.151 4.037c-.024.364.098.723.34 1l1.385 1.592a1.106 1.106 0 001.397.211c.076-.045.156-.095.241-.148.66-.41 1.595-.99 2.634-.99 1.038 0 1.97.58 2.63.989l.243.15a1.106 1.106 0 001.398-.212l1.385-1.591a1.385 1.385 0 00-.127-1.952A8.225 8.225 0 0010 8a8.225 8.225 0 00-5.53 2.086 1.394 1.394 0 00-.467.95zm2.265.166l.464.533c.233-.137.501-.287.774-.418.633-.303 1.494-.616 2.494-.616 1 0 1.861.313 2.494.617.272.13.54.28.773.417l.465-.534A6.22 6.22 0 0010 10a6.22 6.22 0 00-3.732 1.203z"
			/>
		</svg>
	);
}

const MemoSvgScalemultiply = React.memo(SvgScalemultiply);
export default MemoSvgScalemultiply;
