// THIS FILE IS GENERATED, DO NOT EDIT!
import type * as Types from '../../graphql-api-types.generated';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PublicTenantInfoQueryVariables = Types.Exact<{
  hostname?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PublicTenantInfoQuery = { __typename: 'query_root', get_public_tenant_info: Array<{ __typename: 'public_tenant_info_result', tenant: string, tenantShortName: string, auth0Domain: string, auth0ClientId: string, mainLanguage: string, languages: string[] }> };


export const PublicTenantInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PublicTenantInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"hostname"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"get_public_tenant_info"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"args"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"hostname"},"value":{"kind":"Variable","name":{"kind":"Name","value":"hostname"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"tenant"}},{"kind":"Field","name":{"kind":"Name","value":"tenantShortName"}},{"kind":"Field","name":{"kind":"Name","value":"auth0Domain"}},{"kind":"Field","name":{"kind":"Name","value":"auth0ClientId"}},{"kind":"Field","name":{"kind":"Name","value":"mainLanguage"}},{"kind":"Field","name":{"kind":"Name","value":"languages"}}]}}]}}]} as unknown as DocumentNode<PublicTenantInfoQuery, PublicTenantInfoQueryVariables>;