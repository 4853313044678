import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgAi({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 1a1 1 0 011 1v2h4V2a1 1 0 112 0v2h1a3 3 0 013 3v1h2a1 1 0 110 2h-2v4h2a1 1 0 110 2h-2v1a3 3 0 01-3 3h-1v2a1 1 0 11-2 0v-2h-4v2a1 1 0 11-2 0v-2H7a3 3 0 01-3-3v-1H2a1 1 0 110-2h2v-4H2a1 1 0 010-2h2V7a3 3 0 013-3h1V2a1 1 0 011-1zM7 6a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1H7zm5.251 7.871l.342.899.16.386c.066.143.126.25.182.322a.628.628 0 00.498.225.678.678 0 00.498-.206.64.64 0 00.21-.459c0-.16-.074-.415-.22-.766l-1.87-4.62a15.217 15.217 0 00-.22-.551 1.881 1.881 0 00-.21-.39.92.92 0 00-.327-.294.983.983 0 00-.503-.117 1 1 0 00-.503.117.959.959 0 00-.327.298 2.61 2.61 0 00-.234.469l-.186.478-1.831 4.649c-.075.185-.129.33-.161.434a1.012 1.012 0 00-.049.303c0 .17.07.32.21.454.14.134.301.2.483.2.215 0 .37-.062.464-.185.095-.127.209-.37.342-.728l.342-.918h2.91zm-1.47-4.043l1.08 2.955H9.72l1.06-2.955zm3.988-.649v5.64c0 .293.066.514.2.664.136.146.309.22.517.22a.68.68 0 00.528-.22c.136-.147.205-.368.205-.664v-5.64c0-.296-.069-.516-.205-.66a.68.68 0 00-.528-.219.668.668 0 00-.517.22c-.134.146-.2.366-.2.659z"
			/>
		</svg>
	);
}

const MemoSvgAi = React.memo(SvgAi);
export default MemoSvgAi;
