import { BigNumber } from "@app/modules/number/big-number";
import { isZero } from "@app/modules/number/scaled-number";
import { get } from "react-hook-form";
import {
	any,
	define,
	dynamic,
	is,
	pattern,
	refine,
	string,
	Struct,
} from "superstruct";

export const StructError = {
	Required: "required",
	PositiveNumericString: "positive-numeric-string",
	Email: "email",
	JSON: "json",
	Phone: "phone",
	IP: "ip",
	FutureTime: "future-time",
} as const;

export type StructErrorValue = Values<typeof StructError>;

// TODO: add generic dateRange schema - ideally validates that `from` is before `to`

/**
 * This struct also accepts empty strings as valid values. This is required for custom structs (e.g. email).
 */
export function optionalString(
	struct: Struct<string, null>,
): Struct<string | undefined, null> {
	// based on https://github.com/ianstormtaylor/superstruct/blob/main/src/structs/types.ts#L357
	return new Struct({
		...struct,
		validator: (value, ctx) =>
			value === undefined || value === "" || struct.validator(value, ctx),
		refiner: (value, ctx) =>
			value === undefined || value === "" || struct.refiner(value, ctx),
	});
}

export const requiredString = define<string>("non-empty string", (value) => {
	if (typeof value === "string") {
		return value.length > 0;
	}
	return false;
});

export function required<T extends string, S extends any>(
	struct: Struct<T, S>,
): Struct<T, S> {
	return refine(struct, "required", (value) => value.trim().length > 0);
}

export function nonZero<T extends string, S extends any>(
	struct: Struct<T, S>,
): Struct<T, S> {
	return refine(struct, "nonZero", (value) => !isZero(value));
}

// based on the original superstruct implementation but supports numeric strings
export function min<T extends number | Date | string, S extends any>(
	struct: Struct<T, S>,
	threshold: T,
	options: {
		exclusive?: boolean;
		numeric?: boolean;
	} = {},
): Struct<T, S> {
	const { exclusive = false, numeric = false } = options;
	return refine(struct, "min", (value) => {
		const comparableValue =
			typeof value === "string" && numeric ? Number(value) : value;
		const comparableThreshold =
			typeof threshold === "string" && numeric ? Number(threshold) : threshold;
		return exclusive
			? comparableValue > comparableThreshold
			: comparableValue >= comparableThreshold ||
					`Expected a ${struct.type} greater than ${
						exclusive ? "" : "or equal to "
					}${threshold} but received \`${value}\``;
	});
}

// based on the original superstruct implementation but supports numeric strings
export function max<T extends number | Date | string, S extends any>(
	struct: Struct<T, S>,
	threshold: T,
	options: {
		exclusive?: boolean;
		numeric?: boolean;
	} = {},
): Struct<T, S> {
	const { exclusive = false, numeric = false } = options;
	return refine(struct, "max", (value) => {
		const comparableValue =
			typeof value === "string" && numeric ? Number(value) : value;
		const comparableThreshold =
			typeof threshold === "string" && numeric ? Number(threshold) : threshold;
		return exclusive
			? comparableValue < comparableThreshold
			: comparableValue <= comparableThreshold ||
					`Expected a ${struct.type} less than ${
						exclusive ? "" : "or equal to "
					}${threshold} but received \`${value}\``;
	});
}

export const intString = () =>
	define<string>("numeric-string", (value) => {
		if (typeof value === "string") {
			return Number.isInteger(Number(value)) && value.at(-1) !== ".";
		}
		return false;
	});

export const requiredIntString = () =>
	define<string>("numeric-string", (value) => {
		if (typeof value === "string") {
			return Number.isInteger(Number(value)) && value.length > 0;
		}
		return false;
	});

export const positiveIntString = () =>
	define<string>("positive-numeric-string", (value) => {
		if (typeof value === "string") {
			return !value.startsWith("-");
		}
		return false;
	});

export const requiredPositiveIntString = () =>
	define<string>("positive-numeric-string", (value) => {
		if (typeof value === "string") {
			return !value.startsWith("-") && value.length > 0;
		}
		return false;
	});

export function emptyArray<T>() {
	return define<T[]>("empty array", (value) => {
		if (Array.isArray(value)) {
			return value.length === 0;
		}
		return false;
	});
}

export function nonEmptyArray<T>() {
	return define<T[]>("non-empty array", (value) => {
		if (Array.isArray(value)) {
			return value.length > 0;
		}
		return false;
	});
}

export const EMAIL_REGEX =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function email(): Struct<string, null> {
	return define(
		"email",
		(value) =>
			(typeof value === "string" && EMAIL_REGEX.test(value)) || "invalid-email",
	);
}

export const DAYSTRING_REGEX = /^\d{4}-\d{2}-\d{2}/;

export function dateString(): Struct<string, null> {
	return define(
		"dateString",
		(value) =>
			(typeof value === "string" && DAYSTRING_REGEX.test(value)) ||
			"invalid-date",
	);
}

export const DATERANGE_REGEX = /^[[(]\d{4}-\d{2}-\d{2},\d{4}-\d{2}-\d{2}[\])]/;

export function dateRangeString(): Struct<string, null> {
	return define(
		"dateRangeString",
		(value) =>
			(typeof value === "string" && DATERANGE_REGEX.test(value)) ||
			"invalid-daterange",
	);
}

export const TIMESTRING_REGEX = /^\d{2}:\d{2}/;

export function timeString(): Struct<string, null> {
	return define(
		"timeString",
		(value) =>
			(typeof value === "string" && TIMESTRING_REGEX.test(value)) ||
			"invalid-time",
	);
}

export const ZERO_TO_HUNDRED_REGEX = /^[1-9][0-9]?$|^100$/;

export function zeroToHundredString(): Struct<string, null> {
	return define(
		"zeroToHundredString",
		(value) =>
			(typeof value === "string" && ZERO_TO_HUNDRED_REGEX.test(value)) ||
			"invalid-number",
	);
}

/**
 * This struct will skip validation without widening the type to `any`. This is useful for dynamic validation that depends on other fields.
 *
 * Example: We can define a struct that validates `circle` field only when `type === "circle"`:
 * ```ts
 * const struct = object({
 *   type: string(),
 *   circle: when("type", (type) =>
 *     type === "circle" ? object({ radius: number() }) : skip()
 *   ),
 *   square: when("type", (value) =>
 *     value === "square" ? object({ x: number() }) : skip()
 *   ),
 * });
 * ```
 */
export function skip() {
	return any() as Struct<{}, any>;
}

/**
 * This function should be used when the validation should always fail with the given reason.
 * Useful inside `dynamic` validations.
 */
export const fail = (msg: string) => define<string>(msg, () => false);

export const lessThanOrEq = (otherField: string) =>
	dynamic((value, { branch }) => {
		if (!value) {
			return requiredString;
		}

		const values = branch[branch.length - 2];
		const otherValue = get(values, otherField);

		const isLessEq = new BigNumber(value as string).lte(otherValue ?? 0);
		return isLessEq ? requiredString : fail(lessThanOrEq.ERROR_MESSAGE);
	});

lessThanOrEq.ERROR_MESSAGE = "less-eq-than-other-field";

export const greaterThanOrEq = (otherField: string) =>
	dynamic((value, { branch }) => {
		if (!value) {
			return requiredString;
		}

		const values = branch[branch.length - 2];
		const otherValue = get(values, otherField);

		const isGte = new BigNumber(value as string).gte(otherValue ?? 0);
		return isGte ? requiredString : fail(lessThanOrEq.ERROR_MESSAGE);
	});

greaterThanOrEq.ERROR_MESSAGE = "greater-eq-than-other-field";

export function requiredIf<T extends string, S extends any>(
	struct: Struct<T, S>,
	otherField: string,
	otherStruct: Struct<any, any>,
): Struct<T, null> {
	return dynamic((_value, { branch }) => {
		const values = branch[branch.length - 2];
		const otherValue = get(values, otherField);

		if (is(otherValue, otherStruct)) {
			return required(struct);
		}

		return struct;
	});
}

export function dependentOn<T extends Struct<any, any>>(
	otherField: string,
	fn: (value: unknown) => T,
): T {
	return dynamic((_value, { branch }) => {
		const values = branch[branch.length - 2];
		const otherValue = get(values, otherField);
		return fn(otherValue);
		// Would be good to avoid the casting. We are not doing it now due to
		// time constraints.
	}) as T;
}

export const when = dependentOn;

// The regex is based on the auth0 "GOOD" strength setting.
const PASSWORD_REGEX =
	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?^[a-zA-Z0-9 ]).{8,}$/;

export function password() {
	return pattern(string(), PASSWORD_REGEX);
}

export const MESSAGE_STRUCT_TYPE = "message";

/**
 * Decorate your structs with a custom message which is returned if validation fails. Especially useful in the form context.
 */
export function message<T>(
	struct: Struct<T, any>,
	customMessage: string,
): Struct<T, any> {
	return define(MESSAGE_STRUCT_TYPE, (value) =>
		is(value, struct) ? true : customMessage,
	);
}

// We also allow spaces for grouping so the user can optionally provide formatting.
// This format might be too restrictive for exotic international numbers. If we get there we should consider offloading the validation logic to a proper phone lib.
const E164_REGEX = /^\+[1-9]\d{0,2}(?: ?\d{1,3}){0,4}$/;

export function phoneNumber() {
	return message(pattern(string(), E164_REGEX), "phone");
}

export function jsonString<T>(
	struct?: Struct<T, unknown>,
): Struct<string, null> {
	return define("json", (value) => {
		if (typeof value !== "string") {
			return "invalid-json";
		}

		try {
			const parsed = JSON.parse(value);
			const isValid = struct ? is(parsed, struct) : true;
			return isValid ? true : "invalid-json";
		} catch (e) {
			return "invalid-json";
		}
	});
}

const IP_REGEX = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;

// IPv4 address in dot-decimal notation
// https://stackoverflow.com/a/36760050/11822720
export function ipString() {
	return message(pattern(string(), IP_REGEX), "ip");
}

// Blocked by https://github.com/ianstormtaylor/superstruct/issues/1188
// export function numericRange() {
// 	return refine(
// 		object({ from: string(), to: optional(string()) }),
// 		"numeric-range",
// 		(value) => {
// 			const from = Number(value.from);
// 			const to = Number(value.to);
//
// 			return !Number.isNaN(from) && !Number.isNaN(to) && from < to;
// 		},
// 	);
// }
