import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgLabelmultiple({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 2a3 3 0 013 3v10a3 3 0 01-3 3h-1v1a3 3 0 01-3 3H5a3 3 0 01-3-3V9a3 3 0 013-3h1.016c-.004-.329-.006-.657-.008-.984A3.002 3.002 0 019.01 2H19zM8.016 6c-.004-.331-.006-.663-.008-.995A1.002 1.002 0 019.01 4H19a1 1 0 011 1v10a1 1 0 01-1 1h-1V9a3 3 0 00-3-3H8.016zM16 9a1 1 0 00-1-1H5a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V9z"
			/>
			<rect x={5} y={16} width={10} height={2} rx={1} />
			<rect x={5} y={13} width={10} height={2} rx={1} />
			<rect x={5} y={10} width={5} height={2} rx={1} />
		</svg>
	);
}

const MemoSvgLabelmultiple = React.memo(SvgLabelmultiple);
export default MemoSvgLabelmultiple;
