import { useAbility } from "@app/modules/authorization/AuthorizationProvider";
import type { RouteName, RoutesConfig } from "@app/modules/routes/routes";
import {
	appendSearch,
	canAccessRoute,
	fullPathsByName,
	getHref,
} from "@app/modules/routes/routes";
import type { SearchObject } from "@app/modules/routes/utils";
import clsx from "clsx";
import type { Ref } from "react";
import type {
	// eslint-disable-next-line no-restricted-imports
	LinkProps as RouterLinkProps,
} from "react-router-dom";
import {
	// eslint-disable-next-line no-restricted-imports
	Link as RouterLink,
	useMatch,
} from "react-router-dom";

type CommonProps<Name extends RouteName> = {
	to: Name;
	innerRef?: Ref<HTMLAnchorElement>;
	disabled?: boolean;
	search?: SearchObject;
};

type RouteProps<Name extends RouteName> = CommonProps<Name> &
	(RoutesConfig[Name]["params"] extends undefined
		? { params?: undefined }
		: { params: RoutesConfig[Name]["params"] });

export type LinkProps<N extends RouteName> = Omit<RouterLinkProps, "to"> &
	RouteProps<N>;

function Link<Name extends RouteName>({
	to: routeName,
	params,
	search,
	innerRef,
	disabled,
	...props
}: LinkProps<Name>) {
	const hasPermission = useHasRoutePermission(routeName);
	if (disabled) {
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		return <a ref={innerRef} {...props} />;
	}
	if (hasPermission) {
		const href = getHref(routeName, params);
		const to = search ? appendSearch(href, search) : href;
		return (
			<RouterLink ref={innerRef} to={to} data-testid={routeName} {...props} />
		);
	}
	return null;
}

function useHasRoutePermission(routeName: RouteName) {
	const { can } = useAbility();
	return canAccessRoute(can, routeName);
}

export type NavLinkProps<Name extends RouteName> = LinkProps<Name> & {
	activeRoute?: RouteName;
	activeClassName?: string;
	inactiveClassName?: string;
	end?: boolean;
};

function NavLink<Name extends RouteName>({
	className,
	activeClassName,
	inactiveClassName,
	end = false,
	to,
	activeRoute = to,
	params,
	...props
}: NavLinkProps<Name>) {
	const match = useMatch({ path: fullPathsByName[activeRoute], end });

	return (
		<Link
			to={to as RouteName}
			params={params}
			className={clsx(className, match ? activeClassName : inactiveClassName)}
			{...props}
		/>
	);
}

export { Link, NavLink };
