import { Invoice, Phone } from "@app/icons/components";
import { Bestellungen, Lieferant, User } from "@app/icons/components/nav";
import type { IconComponent } from "@app/icons/types";
import type { TranslateFunction } from "@app/modules/i18n/types";
import type { OptionItemProps } from "@app/modules/input-fields/Options";
import { getPricingTypeIcon } from "@app/modules/product/product-utils";
import type { RouteName } from "@app/modules/routes/routes";
import { getHref, to } from "@app/modules/routes/routes";
import type { GlobalSearchQuery } from "@app/modules/search/global-search.query.generated";
import { getSearchIcon } from "@app/modules/search/search-icons";
import { substringSimilarity } from "@app/modules/search/similarity";

type SearchEntityType =
	| "customer"
	| "customer-order"
	| "internal-order"
	| "product"
	| "product-group"
	| "sales-team"
	| "supplier"
	| "filter"
	| "contact"
	| "route"
	| "credit-note"
	| "customer-invoice";

export type SuggestionTagItem = NonNullable<OptionItemProps["tags"]>[number];

export interface BasicSuggestion {
	// globally unique key (used for rendering in a list)
	key: string;
	type: Exclude<SearchEntityType, "contact">;
	id: string;
	icon: IconComponent;
	label: string;
	domainId?: string;
	tenant?: string;
	tags?: SuggestionTagItem[];
}

export interface ContactSuggestion extends Omit<BasicSuggestion, "type"> {
	type: Extract<SearchEntityType, "contact">;
	customerId?: string;
	supplierId?: string;
}

export type Suggestion = BasicSuggestion | ContactSuggestion;

export function responseToSuggestion(
	t: TranslateFunction,
	resultItem: ElementType<GlobalSearchQuery["global_search"]>,
): Suggestion | undefined {
	let { name: label } = resultItem;

	if (resultItem.contactId) {
		const id = resultItem.contactId;
		const type = "contact";
		const key = `${type}:${id}`;
		const customer = resultItem.contact?.customer;
		const supplier = resultItem.contact?.supplier;

		const customerTags: SuggestionTagItem[] = customer
			? [
					{
						id: customer.id,
						icon: User,
						color: "grayLight",
						children: customer.name,
					},
					{
						id: customer.domainId,
						icon: User,
						color: "grayLight",
						children: customer.domainId,
					},
			  ]
			: [];
		const supplierTags: SuggestionTagItem[] = supplier
			? [
					{
						id: supplier.id,
						icon: Lieferant,
						color: "grayLight",
						children: supplier.name,
					},
					{
						id: supplier.domainId,
						icon: Lieferant,
						color: "grayLight",
						children: supplier.domainId,
					},
			  ]
			: [];

		return {
			key,
			type,
			id: resultItem.contactId,
			tenant: resultItem.contact?.tenant,
			label,
			customerId: customer?.id,
			supplierId: supplier?.id,
			icon: Phone,
			tags: [...customerTags, ...supplierTags],
		};
	}

	let id: string | undefined;
	let domainId: string | undefined;
	let type: SearchEntityType | undefined;
	let icon: IconComponent | undefined;
	let tenant: string | undefined;

	if (resultItem.customerId) {
		id = resultItem.customerId;
		domainId = resultItem.customer?.domainId;
		tenant = resultItem.customer?.tenant;
		type = "customer";
		icon = User;
	} else if (resultItem.productId) {
		id = resultItem.productId;
		domainId = resultItem.product?.domainId;
		tenant = resultItem.product?.tenant;
		type = "product";
		icon = getSearchIcon(getPricingTypeIcon(resultItem.product?.pricingType));
	} else if (resultItem.supplierId) {
		id = resultItem.supplierId;
		domainId = resultItem.supplier?.domainId;
		tenant = resultItem.supplier?.tenant;
		type = "supplier";
		icon = Lieferant;
	} else if (resultItem.customerOrder) {
		id = resultItem.customerOrder.id;
		type = resultItem.customerOrder.isInternal
			? "internal-order"
			: "customer-order";
		icon = Bestellungen;
		label = `${t("orders.titles.order")} ${resultItem.name}`;
		tenant = resultItem.customerOrder?.tenant;
	} else if (resultItem.creditNoteId) {
		id = resultItem.creditNoteId;
		domainId = resultItem.creditNote?.domainId;
		type = "credit-note";
		icon = Invoice;
		label = `${t("invoices.titles.credit-note")} ${resultItem.name}`;
		tenant = resultItem.creditNote?.tenant;
	} else if (resultItem.customerInvoice) {
		id = resultItem.customerInvoice.id;
		domainId = resultItem.customerInvoice.domainId;
		type = "customer-invoice";
		icon = Invoice;
		label = t("search.suggestion-labels.customer-invoice", {
			id: resultItem.customerInvoice.domainId,
		});
		tenant = resultItem.customerInvoice.tenant;
	}

	if (type && id && icon) {
		const key = `${type}:${id}`;
		return {
			key,
			type,
			id,
			tenant,
			domainId,
			label,
			icon,
		};
	}

	return undefined;
}

export const routesMapping: Record<
	Exclude<SearchEntityType, "contact" | "product-group" | "filter" | "route">,
	RouteName
> = {
	customer: "customer-data.customers.id",
	product: "product-data.products.id",
	supplier: "suppliers.id",
	"sales-team": "customer-data.sales-teams.id",
	"customer-order": "sales.customer-orders.id",
	"internal-order": "sales.internal-orders.id",
	"credit-note": "invoicing.credit-notes.id",
	"customer-invoice": "invoicing.invoices.id",
};

export function getSuggestionPath(suggestion: Suggestion): string | undefined {
	switch (suggestion.type) {
		case "contact":
			if (suggestion.supplierId) {
				return to("suppliers.id", {
					id: suggestion.supplierId,
				});
			}
			if (suggestion.customerId) {
				return to("customer-data.customers.id", { id: suggestion.customerId });
			}
			return undefined;
		case "customer":
		case "customer-order":
		case "internal-order":
		case "product":
		case "supplier":
		case "sales-team":
		case "credit-note":
		case "customer-invoice":
			return to(routesMapping[suggestion.type], { id: suggestion.id });
		case "route":
			// We cannot use "to" here as it is too typesafe. "to" only works if we have the exact RouteName type.
			return getHref(suggestion.id as RouteName);
		default:
			return undefined;
	}
}

/** Check if inputValue matches the suggestion. Used for suggestions that do not come from the back end. */
export function matchesSuggestion(
	suggestion: string,
	inputValue: string,
): boolean {
	return !inputValue || substringSimilarity(suggestion, inputValue) > 0.5;
}
