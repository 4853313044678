import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgProduktZerlegt({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.5 2.711a3 3 0 00-3 0L4.706 6.057a3 3 0 00-1.5 2.598v6.69a3 3 0 001.5 2.598l3.763 2.173 8.794-15.232L13.5 2.711zm-2 1.732a1 1 0 011 0l2.031 1.173-6.794 11.768-2.031-1.173a1 1 0 01-.5-.866v-6.69a1 1 0 01.5-.866L11.5 4.443z"
			/>
			<path d="M19.794 6.345l-.474-.274-1 1.733c.294.169.474.482.474.821h2v-.548a2 2 0 00-1-1.732zM20.794 13.125v-2.25h-2v2.25h2zM20.794 15.923v-.548h-2c0 .339-.18.652-.474.822l1 1.732.474-.274a2 2 0 001-1.732zM15.423 20.178l1.949-1.125-1-1.732-1.949 1.125 1 1.732zM11.526 19.572l-1 1.732.474.273a2 2 0 002 0l.475-.274-1-1.732a.949.949 0 01-.949 0z" />
		</svg>
	);
}

const MemoSvgProduktZerlegt = React.memo(SvgProduktZerlegt);
export default MemoSvgProduktZerlegt;
