import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgInvoicePen({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={16}
			height={16}
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.667 2.556H4.333a.778.778 0 00-.777.777v9.334c0 .43.348.777.777.777h3.02c.216.57.523 1.095.904 1.556H4.333A2.333 2.333 0 012 12.667V3.333A2.333 2.333 0 014.333 1h7.334A2.333 2.333 0 0114 3.333v2.874A5.504 5.504 0 0012.444 6V3.333a.778.778 0 00-.777-.777zM9.043 7.222H5.778a.778.778 0 100 1.556H7.72a5.526 5.526 0 011.323-1.556zm-1.919 3.111H5.778a.778.778 0 100 1.556h1.236a5.585 5.585 0 01.11-1.556zM5 4.89c0-.43.348-.778.778-.778h4.444a.778.778 0 110 1.556H5.778A.778.778 0 015 4.889z"
			/>
			<path d="M14.526 8.359a1.418 1.418 0 00-1.944.055L9.74 11.255l-.034.037a.758.758 0 00-.064.084l-.038.067-.023.048L9.043 13l-.018.06a.75.75 0 00.973.899l1.468-.521.074-.032.034-.018.045-.028a.708.708 0 00.126-.1l2.84-2.84.055-.058c.48-.536.48-1.348.002-1.886l-.057-.06-.059-.056z" />
		</svg>
	);
}

const MemoSvgInvoicePen = React.memo(SvgInvoicePen);
export default MemoSvgInvoicePen;
