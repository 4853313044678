import { Calendar, DropdownClosed } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { ButtonProps } from "@app/modules/Button";
import { Button } from "@app/modules/Button";
import type { ControlledFieldProps } from "@app/modules/form/form-utils";
import { mergeRefs, useInputId } from "@app/modules/form/form-utils";
import {
	getViewState,
	Input,
	inputTextCls,
	legacyGetValidationState,
	showPicker,
} from "@app/modules/input-fields/commons";
import { useInputGroup } from "@app/modules/input-fields/InputGroup";
import type {
	InputSize,
	ValidationState,
} from "@app/modules/input-fields/types";
import { isDateString, LocalDate } from "@app/modules/localdate/localdate";
import clsx from "clsx";
import type { InputHTMLAttributes, MouseEvent, ReactNode } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import "./date-time-input.css";

export interface DateInputLegacyProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
	name: string;
	className?: string;
	label?: string;
	disabled?: boolean;
	error?: string;
	isSmall?: boolean;
	nextButton?: ButtonProps;
	prevButton?: ButtonProps;
	value?: string;
	onChange?: (value: string) => void;
	inputClassName?: string;
	sizing?: InputSize;
	state?: ValidationState;
	hint?: ReactNode;
	showPickerOnInputClick?: boolean;
}

/** @deprecated use `DateInput` instead. */
export const DateInputLegacy = forwardRef<
	HTMLInputElement,
	DateInputLegacyProps
>(
	(
		{
			className,
			id,
			label,
			disabled,
			error,
			isSmall,
			nextButton,
			prevButton,
			onChange,
			value,
			max,
			min,
			inputClassName,
			readOnly,
			sizing = "md",
			state = "default",
			showPickerOnInputClick = true,
			hint,
			...props
		},
		ref,
	) => {
		const group = useInputGroup(props.name);
		const inputId = useInputId(group.inputId ?? id);
		const validationState = legacyGetValidationState(group.state ?? state, {
			error,
		});
		const viewState = getViewState(validationState, { disabled, readOnly });
		const padding = isSmall ? "py-4 pl-8" : "p-8";

		const inputRef = useRef<HTMLInputElement>();
		const [inputValue, setInputValue] = useState(value);

		useEffect(() => {
			if (!isDateString(value)) {
				setInputValue(LocalDate.invalid().toDateString());
			} else if (value !== inputValue) {
				setInputValue(value);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value]);

		const date =
			value && isDateString(value)
				? LocalDate.fromDateString(value)
				: LocalDate.now();

		const handleDateChange = (newValue: string) => {
			setInputValue(newValue);
			if (isDateString(newValue)) {
				onChange?.(newValue);
			}
		};

		const control = (
			<Input.Control
				state={viewState}
				sizing={sizing}
				isInsideGroup={group.isInsideGroup}
				className="gap-8"
			>
				<span
					className={clsx(
						inputTextCls,
						"w-24 flex-shrink-0",
						disabled && "text-grey-500",
					)}
				>
					{date.format("dd")},
				</span>
				<Input.Input
					type="date"
					className={clsx(padding, inputClassName)}
					onClick={() => {
						if (showPickerOnInputClick && !readOnly && !disabled) {
							showPicker(inputRef.current);
						}
					}}
					onChange={(e) => handleDateChange(e.target.value)}
					value={inputValue}
					ref={mergeRefs(ref, inputRef)}
					disabled={disabled}
					readOnly={readOnly}
					max={max}
					min={min}
					{...props}
				/>
				{!disabled && !readOnly && (
					<>
						<Button
							variant="tertiary"
							tabIndex={-1}
							{...prevButton}
							onClick={(event: MouseEvent<HTMLButtonElement>) => {
								prevButton?.onClick?.(event);
								const newValue = date.subtract(1, "d").toDateString();
								handleDateChange(newValue);
							}}
							data-testid={`${props.name}-calendar-prev`}
							disabled={min ? min === date.toDateString() : false}
						>
							<Icon
								icon={DropdownClosed}
								size="16"
								className="transform rotate-90"
							/>
						</Button>
						<Button
							variant="tertiary"
							tabIndex={-1}
							{...nextButton}
							onClick={(event: MouseEvent<HTMLButtonElement>) => {
								nextButton?.onClick?.(event);
								const newValue = date.add(1, "d").toDateString();
								handleDateChange(newValue);
							}}
							data-testid={`${props.name}-calendar-next`}
							disabled={max ? max === date.toDateString() : false}
						>
							<Icon
								icon={DropdownClosed}
								size="16"
								className="transform -rotate-90"
							/>
						</Button>
						<Button
							variant="tertiary"
							tabIndex={-1}
							onClick={() => showPicker(inputRef.current)}
						>
							<Icon icon={Calendar} size="24" />
						</Button>
					</>
				)}
			</Input.Control>
		);

		return (
			<div className={clsx(className, "date-input whitespace-nowrap")}>
				<Input.Label htmlFor={inputId} testid={props.name} state={viewState}>
					{label}
				</Input.Label>

				{control}
				{(hint || error) && (
					<Input.Hint state={viewState}>{error ?? hint}</Input.Hint>
				)}
			</div>
		);
	},
);

export interface DateInputLegacyFieldProps<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
> extends Omit<DateInputLegacyProps, "name" | "defaultValue">,
		ControlledFieldProps<Values, Name> {}

export function DateInputLegacyField<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
>({
	control,
	name,
	defaultValue,
	...props
}: DateInputLegacyFieldProps<Values, Name>) {
	const { field } = useController({
		control,
		name,
		defaultValue,
	});
	return <DateInputLegacy {...props} {...field} />;
}
