import { useEvent } from "@app/modules/hooks/useEvent";
import type { SearchObjectValue } from "@app/modules/routes/utils";
import { parseSearch, stringifySearch } from "@app/modules/routes/utils";
import type { ReactNode, SetStateAction } from "react";
import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react";
// eslint-disable-next-line no-restricted-imports
import { useLocation, useNavigate } from "react-router-dom";

export interface UseSearchParamOptions {
	validate?: (value: unknown) => boolean;
	isValueNullish?: boolean;
	shouldUpdateHistoryOnChange?: boolean;
}

interface NullishOptions extends UseSearchParamOptions {
	isValueNullish: true;
}

interface NonNullableOptions {
	validate: (value: unknown) => boolean;
	isValueNullish: boolean;
	shouldUpdateHistoryOnChange: boolean;
}

type Value<
	V,
	Options extends NullishOptions | UseSearchParamOptions,
> = Options extends NullishOptions ? V | undefined : V;

export function useSearchParam<
	T extends SearchObjectValue,
	Options extends
		| NullishOptions
		| UseSearchParamOptions = UseSearchParamOptions,
>(name: string, defaultValue: Value<T, Options>, options?: Options) {
	const { setParam, params } = useContext(SearchParamsContext);
	const [isDirty, setIsDirty] = useState(false);

	const nonNullOptions: NonNullableOptions = {
		validate: options?.validate ?? (() => true),
		isValueNullish: options?.isValueNullish ?? false,
		shouldUpdateHistoryOnChange: options?.shouldUpdateHistoryOnChange ?? false,
	};

	const setValue = useEvent((value: SetStateAction<T | undefined>) => {
		setParam(name, value, defaultValue, nonNullOptions);
		if (isDirty) {
			setIsDirty(true);
		}
	});

	const validValue = getValidValue(params, name, defaultValue, nonNullOptions);

	const value =
		!isDirty && validValue === undefined ? defaultValue : validValue;

	return [value, setValue] as const;
}

interface SearchParamsContextValue {
	setParam: <T extends SearchObjectValue>(
		key: string,
		value: SetStateAction<T | undefined>,
		defaultValue: T,
		options: NonNullableOptions,
	) => void;
	// With the current implementation, eve1ry param change will cause all context consumers to rerender :)
	// Better TODO: instead of observable lets useSyncExternalStore (React 18)
	params: { [key: string]: SearchObjectValue };
}

export const SearchParamsContext = createContext<SearchParamsContextValue>({
	setParam: () => {},
	params: {},
});

export interface SearchParamsProviderPropsProps {
	children?: ReactNode;
}

function getValidValue<T extends SearchObjectValue>(
	params: { [key: string]: SearchObjectValue },
	name: string,
	defaultValue: T,
	{ validate, isValueNullish }: NonNullableOptions,
) {
	const stateValue = params[name] as T;
	const validValue = validate(stateValue) ? stateValue : defaultValue;
	if (validValue === undefined && isValueNullish) {
		return validValue;
	}
	const fallbackValue = isValueNullish ? validValue : defaultValue;
	return validValue ?? fallbackValue;
}

export function SearchParamsProvider({
	children,
}: SearchParamsProviderPropsProps) {
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const pathnameRef = useRef(pathname);
	pathnameRef.current = pathname;

	const params = useMemo(() => parseSearch(search), [search]);
	const paramsRef = useRef(params);
	paramsRef.current = params;

	const setParam = useCallback(
		<T extends SearchObjectValue>(
			key: string,
			action: SetStateAction<T | undefined>,
			defaultValue: T,
			options: NonNullableOptions,
		) => {
			const prevParams = paramsRef.current;
			const value =
				typeof action === "function"
					? action(getValidValue(prevParams, key, defaultValue, options))
					: action;
			const newParams = (() => {
				if (value) {
					return { ...prevParams, [key]: value };
				}
				const { [key]: _, ...filteredParams } = prevParams;
				return filteredParams;
			})();

			// If we have multiple `setParam` calls in the same render cycle, they down't see each others params changes. The last `setParam` call wins.
			// To avoid this issue we optimistically update our params here. The next `setParam` in the same cycle will then see the updated values.
			paramsRef.current = newParams;

			navigate(
				{
					pathname: pathnameRef.current,
					search: stringifySearch(newParams),
				},
				{ replace: !options.shouldUpdateHistoryOnChange },
			);
		},
		[navigate],
	);

	const contextValue: SearchParamsContextValue = useMemo(
		() => ({
			setParam,
			params,
		}),
		[setParam, params],
	);

	return (
		<SearchParamsContext.Provider value={contextValue}>
			{children}
		</SearchParamsContext.Provider>
	);
}
