import {
	CalendarPeriod,
	Edi,
	Email,
	Format,
	House,
	Invoice,
	Label,
	Notification,
	ReturnItem,
	Search,
	Star,
	TaskFinished,
	Unsend,
	WirdZerlegt,
} from "@app/icons/components";
import { Edit, Refresh } from "@app/icons/components/action";
import {
	Article,
	Bestellungen,
	Kommissionieren,
	Lieferant,
	PriceTag,
	Stock,
	Touren,
	User,
	UserGroup,
} from "@app/icons/components/nav";
import {
	OrderClient,
	ProduktRezeptur,
	ProduktZerlegt,
} from "@app/icons/components/status";
import type { IconComponent } from "@app/icons/types";

const searchIconsByName = {
	"calendar-period": CalendarPeriod,
	"customer-order": Bestellungen,
	"cutting-pattern": WirdZerlegt,
	"order-client": OrderClient,
	"price-tag": PriceTag,
	"product-dismantled": ProduktZerlegt,
	"product-recipe": ProduktRezeptur,
	"return-item": ReturnItem,
	"task-finished": TaskFinished,
	"user-group": UserGroup,
	article: Article,
	edi: Edi,
	edit: Edit,
	email: Email,
	format: Format,
	house: House,
	invoice: Invoice,
	label: Label,
	notification: Notification,
	picking: Kommissionieren,
	refresh: Refresh,
	search: Search,
	star: Star,
	stock: Stock,
	supplier: Lieferant,
	tour: Touren,
	unsend: Unsend,
	user: User,
};

export type SearchIconName = keyof typeof searchIconsByName;

export function getSearchIcon(
	name: SearchIconName | string,
): IconComponent | undefined {
	return searchIconsByName[name as SearchIconName];
}
