import { LoaderDark } from "@app/icons/components";
import { Trash } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import type { IconComponent } from "@app/icons/types";
import { useDestructiveClickHandler } from "@app/modules/layout/DestructiveButton";
import { useDelayedFetchingIndicator } from "@app/modules/layout/FetchingIndicator";
import type { LinkProps } from "@app/modules/routes/link";
import { Link } from "@app/modules/routes/link";
import type { RouteName } from "@app/modules/routes/routes";
import clsx from "clsx";
import type { ReactNode } from "react";

export interface DataTableActionsCellProps {
	children: ReactNode;
	visibleOnHover?: boolean;
	className?: string;
}

export function DataTableActionsCell({
	children,
	visibleOnHover,
	className,
}: DataTableActionsCellProps) {
	return (
		<td
			className={clsx(
				className,
				"h-full flex gap-8 items-center justify-end pr-8",
				visibleOnHover &&
					"opacity-0 group-hover:opacity-100 transition-opacity bg-grey-200 absolute top-1/2 right-0 -translate-y-1/2",
			)}
		>
			{children}
		</td>
	);
}

export type DataTableActionButtonProps = Omit<ActionButtonProps, "variant">;

export function DataTableActionButton(props: DataTableActionButtonProps) {
	return <ActionButton variant="default" {...props} />;
}

interface DataTableDestructiveButtonProps
	extends Omit<ActionButtonProps, "variant"> {
	confirmButtonLabel?: string;
}

export function DataTableDestructiveButton({
	onClick,
	confirmButtonLabel,
	icon = Trash,
	...props
}: DataTableDestructiveButtonProps) {
	const handleClick = useDestructiveClickHandler({
		onClick,
		confirmButtonLabel,
	});
	return (
		<ActionButton
			variant="destructive"
			{...props}
			onClick={handleClick}
			icon={icon}
		/>
	);
}

type ButtonProps = Omit<JSX.IntrinsicElements["button"], "hidden">;
interface ActionButtonProps extends ButtonProps {
	variant: ActionVariant;
	isFetching?: boolean;
	icon?: IconComponent;
	className?: string;
	children?: ReactNode;
	isHidden?: boolean;
}

function ActionButton({
	variant,
	isFetching,
	icon,
	children,
	className,
	isHidden,
	...props
}: ActionButtonProps) {
	const { isIndicatorVisible } = useDelayedFetchingIndicator(isFetching);
	return (
		<button
			type="button"
			className={clsx(
				className,
				actionCls,
				actionVariantCls[variant],
				isHidden && "invisible",
			)}
			disabled={isFetching}
			{...props}
		>
			{icon && (
				<Icon
					icon={isIndicatorVisible ? LoaderDark : icon}
					size="16"
					className={clsx("inline-block", isIndicatorVisible && "animate-spin")}
				/>
			)}
			{children}
		</button>
	);
}

export type DataTableActionLinkProps<Name extends RouteName> =
	LinkProps<Name> & {
		icon?: IconComponent;
	};

export function DataTableActionLink<Name extends RouteName>({
	className,
	to,
	params,
	icon,
	children,
	...props
}: DataTableActionLinkProps<Name>) {
	return (
		<Link
			to={to as RouteName}
			params={params}
			tabIndex={-1}
			className={clsx(className, actionCls, actionVariantCls.default)}
			{...props}
		>
			{icon && <Icon icon={icon} size="16" className="inline-block" />}
			{children}
		</Link>
	);
}

type ActionVariant = "default" | "destructive";

/* class={ */
export const actionCls =
	"bg-white transition-all p-0 w-32 h-32 rounded-full hover:bg-grey-300 flex items-center justify-center";
export const actionVariantCls: Record<ActionVariant, string> = {
	default: "text-grey-700",
	destructive: "text-red-800",
};
/* } */
