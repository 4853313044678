import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgTopProducts({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.569 2.557A1.585 1.585 0 0016.37 2l-8.74.012c-.481 0-.907.221-1.196.555-.286.33-.428.749-.429 1.162V4H3a1 1 0 00-1 1v.702c0 1.398.42 2.823 1.16 3.923.657.976 1.64 1.792 2.882 1.96.202 1.548 1.117 2.832 2.141 3.728.838.733 1.858 1.3 2.817 1.55V20H8a1 1 0 100 2h8a1 1 0 100-2h-3v-3.137c.96-.25 1.98-.816 2.818-1.55 1.017-.89 1.926-2.163 2.136-3.699l.046.001c1.463 0 2.612-.895 3.349-1.99.74-1.1 1.16-2.525 1.16-3.923V5a1 1 0 00-1-1h-3.513v-.278c0-.414-.142-.835-.427-1.165zM4.008 6h1.995L6 9.524c-.412-.148-.82-.48-1.18-1.015C4.363 7.83 4.062 6.924 4.008 6zm13.988 0L18 9.615c.575 0 1.18-.35 1.69-1.106.455-.678.757-1.585.81-2.509h-2.503zM8.005 4.011l7.99-.01c.002 1.39.005 4.818.005 6.943 0 1.066-.6 2.078-1.499 2.864-.92.805-1.945 1.192-2.5 1.192-.555 0-1.58-.387-2.501-1.193C8.6 13.022 8 12.01 8 10.944l.003-3.606v-.002l.002-3.325z"
			/>
		</svg>
	);
}

const MemoSvgTopProducts = React.memo(SvgTopProducts);
export default MemoSvgTopProducts;
