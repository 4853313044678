import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgBasketfull({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.5 19a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm8 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM4.887 4a1 1 0 01.957.71l.028.117.206 1.172L18.999 6l.419.177.204.094c.195.098.25.163.29.283l.068.237.014.063.014.168v.075L20 7.273l-.034.386-.059.549-.116.955-.143 1.096-.149 1.063-.185 1.232-.119.722-.064.354-.057.283-.067.267-.013.044c-.027.083-.05.141-.087.214-.157.307-.341.52-.77.557L18 15l-10.337-.001L7.839 16H18a1 1 0 01.993.883L19 17a1 1 0 01-.883.993L18 18H7a1 1 0 01-.957-.71l-.028-.117L4.048 6H2a1 1 0 01-.993-.883L1 5a1 1 0 01.883-.993L2 4h2.887z"
			/>
			<path d="M16.106 8.8a.75.75 0 10-1.149-.965l-3.225 3.84-1.043-1.16a.75.75 0 10-1.115 1.004l1.62 1.8a.75.75 0 001.132-.02l3.78-4.5z" />
		</svg>
	);
}

const MemoSvgBasketfull = React.memo(SvgBasketfull);
export default MemoSvgBasketfull;
