import { useEntityFormContext } from "@app/modules/entity/hooks";
import type {
	ConstraintSeverity,
	FieldValueByName,
	FormControl,
} from "@app/modules/form/form";
import { useGetErrorMessage } from "@app/modules/form/hooks";
import { useQuery } from "@app/modules/graphql/queries";
import { NoopDocument } from "@app/modules/graphql/queries/noop.query";
import type { ValidationState } from "@app/modules/input-fields/types";
import type { ReactNode } from "react";
import { useEffect } from "react";
import type Rhf from "react-hook-form";
import type { FieldValues } from "react-hook-form";
import { useController as rhfUseController } from "react-hook-form";
import type { AnyVariables, DocumentInput } from "urql";

type ConstraintQueryDocument<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
> = DocumentInput<
	{
		result: ConstraintResult[];
	},
	ConstraintVariables
>;

export interface FormFieldConstraint<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Value,
> {
	query: ConstraintQueryDocument<ConstraintResult, ConstraintVariables>;
	getVariables: (value: Value) => ConstraintVariables;
	severity: ConstraintSeverity;
	hint?: ReactNode | ((data: ConstraintResult) => ReactNode);
	pause?: boolean;
}

export interface UseControllerProps<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Values extends Rhf.FieldValues = Rhf.FieldValues,
	Name extends Rhf.FieldPath<Values> = Rhf.FieldPath<Values>,
	Value = FieldValueByName<Values, Name>,
> extends Rhf.UseControllerProps<Values, Name> {
	constraint?: FormFieldConstraint<
		ConstraintResult,
		ConstraintVariables,
		Value
	>;
	control?: FormControl<Values>;
}

export function useController<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Values extends Rhf.FieldValues = Rhf.FieldValues,
	Name extends Rhf.FieldPath<Values> = Rhf.FieldPath<Values>,
	Value = FieldValueByName<Values, Name>,
>({
	constraint,
	control,
	...options
}: UseControllerProps<
	ConstraintResult,
	ConstraintVariables,
	Values,
	Name,
	Value
>) {
	const context = useEntityFormContext<Values>();
	const ctrl = control ?? context.control;
	const { field, fieldState, formState } = rhfUseController({
		...options,
		control: ctrl,
	});
	const getErrorMessage = useGetErrorMessage();

	const errorProps = fieldState.error
		? {
				state: "error" as ValidationState,
				hint: getErrorMessage(fieldState.error),
		  }
		: undefined;

	// TODO: Constraints should only run once the field value passes form schema validation.
	const constraintProps = useConstraint({
		name: options.name,
		constraint,
		value: field.value,
		control: ctrl,
	});

	return {
		field: {
			...field,
			...constraintProps,
			...errorProps,
		},
		fieldState,
		formState,
	};
}

interface UseConstraintOptions<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Value,
	Values extends FieldValues,
> {
	constraint:
		| FormFieldConstraint<ConstraintResult, ConstraintVariables, Value>
		| undefined;
	value: Value;
	control: FormControl<Values>;
	name: Rhf.FieldPath<Values>;
}

function useConstraint<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Value,
	Values extends FieldValues,
>({
	constraint,
	value,
	control,
	name,
}: UseConstraintOptions<ConstraintResult, ConstraintVariables, Value, Values>) {
	const { setFieldConstraintState } = control;

	const variables = constraint?.getVariables(value);

	const [{ data, fetching }] = useQuery({
		query: constraint?.query ?? NoopDocument,
		pause: !variables || !value || constraint?.pause,
		variables,
	});

	const result = (data as { result: ConstraintResult[] } | undefined)
		?.result[0];
	const isConstrained = constraint && value && result;

	const constraintState = (() => {
		if (fetching) {
			return "validating";
		}
		if (isConstrained) {
			return constraint.severity;
		}
		return "valid";
	})();

	useEffect(() => {
		setFieldConstraintState(name, constraintState);
	}, [constraintState, name, setFieldConstraintState]);

	if (!isConstrained) {
		return undefined;
	}

	const { hint } = constraint;
	return {
		state: constraint.severity as ValidationState,
		hint: typeof hint === "function" ? hint(result) : hint,
	};
}

export interface ControllerProps<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Values extends Rhf.FieldValues = Rhf.FieldValues,
	Name extends Rhf.FieldPath<Values> = Rhf.FieldPath<Values>,
	Value = FieldValueByName<Values, Name>,
> extends Rhf.ControllerProps<Values, Name> {
	constraint?: FormFieldConstraint<
		ConstraintResult,
		ConstraintVariables,
		Value
	>;
	control?: FormControl<Values>;
}

export function Controller<
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Values extends Rhf.FieldValues = Rhf.FieldValues,
	Name extends Rhf.FieldPath<Values> = Rhf.FieldPath<Values>,
>(props: ControllerProps<ConstraintResult, ConstraintVariables, Values, Name>) {
	return props.render(
		useController<ConstraintResult, ConstraintVariables, Values, Name>(props),
	);
}

export type ControlledProps<
	Values extends Rhf.FieldValues,
	Name extends Rhf.FieldPath<Values>,
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
	Value = string,
> = Value extends FieldValueByName<Values, Name>
	? Pick<
			ControllerProps<ConstraintResult, ConstraintVariables, Values, Name>,
			"control" | "name" | "defaultValue" | "constraint"
	  >
	: never;
