import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgFormat({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.45 2c.862 0 1.6.532 1.903 1.285h.197a2.941 2.941 0 012.945 2.757l.005.172v12.857a2.938 2.938 0 01-2.777 2.924L16.55 22h-9.1a2.941 2.941 0 01-2.945-2.756L4.5 19.07V6.214a2.938 2.938 0 012.777-2.923l.173-.005.197-.001A2.051 2.051 0 019.38 2.007L9.55 2h4.901zm2.023 3.285a2.05 2.05 0 01-1.864 1.709L14.45 7h-4.9a2.05 2.05 0 01-2.023-1.715H7.45c-.49 0-.89.361-.944.821l-.006.108v12.857c0 .474.365.869.839.923L7.45 20h9.1c.49 0 .89-.36.944-.82l.006-.109V6.214a.937.937 0 00-.839-.922l-.111-.006h-.077zM14.45 4h-4.9a.05.05 0 00-.043.025L9.5 4.05v.9c0 .018.01.035.025.043L9.55 5h4.9a.05.05 0 00.043-.025l.007-.025v-.9a.05.05 0 00-.025-.043L14.45 4z"
			/>
		</svg>
	);
}

const MemoSvgFormat = React.memo(SvgFormat);
export default MemoSvgFormat;
