import {
	AppStateContext,
	AppStateDispatchContext,
} from "@app/modules/app-state/context";
import { Languages_Enum } from "@app/modules/graphql-api-types.generated";
import type { ReactNode } from "react";
import React, { useContext, useReducer } from "react";
import { useLocation, useParams } from "react-router-dom";
import type { AppState } from "./app-state";
import { defaultAppState, reducer } from "./app-state";

const initialAppState: AppState = {
	...defaultAppState,
};

export interface AppStateProviderProps {
	children?: ReactNode;
}

export function AppStateProvider({ children }: AppStateProviderProps) {
	const customerOrderToken = useCustomerOrderToken();
	const { locale } = useParams();

	const [appState, dispatch] = useReducer(
		reducer,
		initialAppState,
		(state) => ({
			...state,
			locale: Object.values(Languages_Enum).includes(locale as Languages_Enum)
				? (locale as Languages_Enum)
				: Languages_Enum.DeCh,
			customerOrderToken: customerOrderToken || undefined,
		}),
	);

	return (
		<AppStateDispatchContext.Provider value={dispatch}>
			<AppStateContext.Provider value={appState}>
				{children}
			</AppStateContext.Provider>
		</AppStateDispatchContext.Provider>
	);
}

export function useAppState(): AppState {
	return useContext(AppStateContext);
}

function useCustomerOrderToken() {
	const { search } = useLocation();
	return new URLSearchParams(search).get("access_token");
}
