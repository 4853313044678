import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCash({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={20}
			height={16}
			viewBox="0 0 20 16"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.333 8a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0zm-1.666 0a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.505 1.405c-4.035-1.793-7-.88-9.72-.044l-.03.01c-2.688.826-5.133 1.568-8.583.034A.833.833 0 000 2.167v11.666c0 .33.194.628.495.762 3.93 1.747 6.86.728 9.483-.184l.295-.103c1.357-.47 2.607-.872 3.956-.931 1.323-.058 2.786.214 4.533 1.185A.833.833 0 0020 13.833V2.167a.833.833 0 00-.495-.762zM1.667 6.228V3.385c.836.28 1.624.445 2.375.522a3.34 3.34 0 01-2.375 2.321zm0 1.703v1.805a5.004 5.004 0 014.095 4.084c1.275-.153 2.475-.57 3.734-1.006l.231-.08c1.351-.468 2.81-.951 4.43-1.022.059-.003.119-.005.179-.006a5.006 5.006 0 013.997-3.637V6.264a5.004 5.004 0 01-4.113-4.201c-1.367.1-2.641.49-3.975.9l-.03.01c-1.39.427-2.843.874-4.47.97a5.005 5.005 0 01-4.078 3.988zm16.666-5.21v1.84a3.338 3.338 0 01-2.411-2.463c.755.09 1.552.286 2.411.623zm-2.286 9.103a3.34 3.34 0 012.286-2.052v2.727a10.07 10.07 0 00-2.286-.675zm-14.38-.386v1.84c.854.33 1.647.508 2.4.574a3.34 3.34 0 00-2.4-2.414z"
			/>
		</svg>
	);
}

const MemoSvgCash = React.memo(SvgCash);
export default MemoSvgCash;
