import type { ConfirmationDialogProps } from "@app/modules/confirmation-dialog/ConfirmationDialog";
import { ConfirmationDialog } from "@app/modules/confirmation-dialog/ConfirmationDialog";
import type { ReactNode } from "react";
import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

interface ConfirmationDialogContextValue {
	isOpen: boolean;
	dialogProps?: Omit<ConfirmationDialogProps, "isOpen">;
}

const Context = createContext<ConfirmationDialogContextValue>({
	isOpen: false,
});

interface ConfirmationDialogDispatchContextValue {
	requireConfirmation: (
		options: ConfirmationDialogContextValue["dialogProps"],
	) => () => void;
}

const DispatchContext = createContext<ConfirmationDialogDispatchContextValue>({
	requireConfirmation: () => () => {},
});

export interface ConfirmationDialogProviderProps {
	children?: ReactNode;
}

const closedState: ConfirmationDialogContextValue = { isOpen: false };

export function ConfirmationDialogProvider({
	children,
}: ConfirmationDialogProviderProps) {
	const [contextValue, setContextValue] =
		useState<ConfirmationDialogContextValue>(closedState);

	const dispatchValue: ConfirmationDialogDispatchContextValue = useMemo(
		() => ({
			requireConfirmation: (options) => () => {
				setContextValue({ isOpen: true, dialogProps: options });
			},
		}),
		[setContextValue],
	);

	const closeDialog = useCallback(() => {
		setContextValue((oldValue) => ({
			...oldValue,
			isOpen: false,
		}));
	}, [setContextValue]);

	const { dialogProps } = contextValue;

	return (
		<DispatchContext.Provider value={dispatchValue}>
			<Context.Provider value={contextValue}>{children}</Context.Provider>
			<ConfirmationDialog
				isOpen={contextValue.isOpen}
				{...dialogProps}
				onConfirm={(event) => {
					dialogProps?.onConfirm?.(event);
					closeDialog();
				}}
				onCancel={() => {
					dialogProps?.onCancel?.();
					closeDialog();
				}}
			/>
		</DispatchContext.Provider>
	);
}

export function useConfirmationDialog() {
	return useContext(DispatchContext);
}
