import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

export type NoopQueryVariables = any;

export type NoopQuery = { __typename: "query_root" };

export const NoopDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "Noop" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
				],
			},
		},
	],
} as unknown as DocumentNode<NoopQuery, NoopQueryVariables>;
