import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgDeleteNote({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M8.293 18.293a1 1 0 010-1.414l2.086-2.086-2.086-2.086a1 1 0 111.414-1.414l2.086 2.086 2.086-2.086a1 1 0 011.414 1.414l-2.086 2.086 2.086 2.086a1 1 0 01-1.414 1.414l-2.086-2.086-2.086 2.086a1 1 0 01-1.414 0z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.893 1.95A3.056 3.056 0 017.1 1h4.32c.65 0 1.266.27 1.712.736l6.18 6.452c.445.464.688 1.086.688 1.725v8.895c0 .833-.317 1.64-.893 2.242-.578.603-1.37.95-2.207.95H7.1c-.836 0-1.63-.347-2.207-.95A3.242 3.242 0 014 18.808V4.192c0-.833.317-1.64.893-2.242zM7.1 3c-.278 0-.553.115-.763.334-.211.22-.337.529-.337.858v14.616c0 .33.126.637.337.858.21.22.485.334.763.334h9.8c.277 0 .553-.115.763-.334.211-.22.337-.529.337-.858V10h-4.526A2.474 2.474 0 0111 7.526V3H7.1zM13 4.49L16.363 8h-2.89A.474.474 0 0113 7.526V4.49z"
			/>
		</svg>
	);
}

const MemoSvgDeleteNote = React.memo(SvgDeleteNote);
export default MemoSvgDeleteNote;
