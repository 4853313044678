import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgLoaderdark({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<g fillRule="evenodd">
				<path
					d="M10 17a7 7 0 100-14 7 7 0 000 14zm0 3c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
					opacity="0.3"
				/>
				<path
					fillRule="nonzero"
					d="M10 0a10 10 0 018.763 14.818l-2.611-1.436A7.022 7.022 0 0010 2.98V0z"
				/>
			</g>
		</svg>
	);
}

const MemoSvgLoaderdark = React.memo(SvgLoaderdark);
export default MemoSvgLoaderdark;
