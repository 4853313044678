import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgHouse({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.338 3.394a2.465 2.465 0 00-2.676 0L4.117 7.627A2.439 2.439 0 003 9.674v8.884A2.448 2.448 0 005.455 21h13.09A2.448 2.448 0 0021 18.558V9.674c0-.826-.42-1.597-1.117-2.047l-6.545-4.233zM11.554 4.76a.822.822 0 01.892 0l6.545 4.233c.233.15.373.407.373.682v8.884c0 .45-.367.814-.819.814h-3.272v-6.136c0-1.699-1.624-2.817-3.273-2.817s-3.273 1.118-3.273 2.817v6.136h.967-4.24a.816.816 0 01-.818-.814V9.674c0-.275.14-.532.373-.682l6.545-4.233zm-1.19 14.613h4.24-.968v-6.136c0-.514-.573-1.19-1.636-1.19-1.062 0-1.636.676-1.636 1.19v6.136z"
			/>
		</svg>
	);
}

const MemoSvgHouse = React.memo(SvgHouse);
export default MemoSvgHouse;
