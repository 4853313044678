import type { Customer_Groups } from "@app/modules/graphql-api-types.generated";

export const CUSTOMER_GROUP_INTERNAL = "INTERNAL";

export type CustomerGroupName = typeof CUSTOMER_GROUP_INTERNAL;

export function getIsInternalCustomer(
	customer?: Maybe<{
		customerGroup?: Maybe<Pick<Customer_Groups, "name">>;
	}>,
) {
	return customer?.customerGroup?.name === CUSTOMER_GROUP_INTERNAL;
}

export function getCustomerGroupName({
	internalName,
	name,
}: {
	internalName?: Maybe<string> | undefined;
	name: string;
}) {
	return internalName ?? name;
}
