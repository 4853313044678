import { useAppState } from "@app/modules/app-state/context";
import type { Languages_Enum } from "@app/modules/graphql-api-types.generated";
import {
	langToCode,
	useTenantLanguages,
} from "@app/modules/tenant/useTenantLanguages";
import { useCallback } from "react";

export function getI18nValue(
	i18n: Record<string, string> | undefined,
	lang: Languages_Enum,
	fallbackLang?: Languages_Enum,
) {
	return i18n?.[lang] ?? (fallbackLang && i18n?.[fallbackLang]) ?? "";
}

export function useI18nValueGetter() {
	const { locale } = useAppState();
	const { mainLanguage } = useTenantLanguages();

	return useCallback(
		(i18n?: Record<string, string>) => getI18nValue(i18n, locale, mainLanguage),
		[locale, mainLanguage],
	);
}

export function asISOLanguageAbbreviation(lang: Languages_Enum) {
	return langToCode(lang).toLocaleLowerCase();
}

export type I18nValueGetter = ReturnType<typeof useI18nValueGetter>;
