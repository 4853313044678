import { mergeRefs } from "@app/modules/form/form-utils";
import { BlockInputLabel } from "@app/modules/input-fields/commons";
import clsx from "clsx";
import type { ReactNode, Ref } from "react";
import { forwardRef, useEffect, useRef } from "react";
import type { InputProps } from "./types";

export interface RadioCheckboxProps extends InputProps {
	type: "radio" | "checkbox";
	isTouch?: boolean;
	indeterminate?: boolean;
	labelCls?: string;
}

export const RadioCheckbox = forwardRef<HTMLInputElement, RadioCheckboxProps>(
	function RadioCheckbox(
		{
			id,
			className,
			type,
			label,
			labelCls,
			error: _error,
			isTouch,
			indeterminate = false,
			...props
		},
		ref: Ref<HTMLInputElement>,
	) {
		const inputRef = useRef<HTMLInputElement>(null);

		useEffect(() => {
			if (inputRef.current) {
				inputRef.current.indeterminate = indeterminate;
			}
		}, [indeterminate]);

		const stringLabel =
			props["aria-label"] ?? (typeof label === "string" ? label : undefined);

		// todo: override style if _mixed is set to true
		return (
			// TODO as soon as error design is ready it should be implemented.
			<label
				htmlFor={id}
				className={clsx(className, "inline-flex items-center")}
				data-testid={`label-${props.name}-${stringLabel}`}
			>
				<input
					ref={mergeRefs(ref, inputRef)}
					type={type}
					{...props}
					className={clsx(
						type === "checkbox" ? "rounded" : "rounded-full",
						"border-grey-300 text-brand-700 focus:border-brand-700 focus:ring-0 focus:ring-transparent focus:ring-offset-transparent disabled:opacity-50 disabled:cursor-not-allowed",
						isTouch && "h-24 w-24",
					)}
					id={id}
				/>
				{label && (
					<span className={labelCls ?? "ml-8 text-body-14"}>{label}</span>
				)}
			</label>
		);
	},
);

export interface RadioCheckboxSpacerProps {
	className?: string;
}

/** Renders an element that takes the same amount as a RadioCheckbox and behaves the same.   */
export function RadioCheckboxSpacer({ className }: RadioCheckboxSpacerProps) {
	return <div className={clsx(className, "inline-flex mr-16 w-16 h-16")} />;
}

export interface RadioCheckboxGroupProps {
	className?: string;
	children?: ReactNode;
	label?: ReactNode;
}

export function RadioCheckboxGroup({
	label,
	className,
	children,
}: RadioCheckboxGroupProps) {
	return (
		<div className={className}>
			<BlockInputLabel className="mb-12">{label}</BlockInputLabel>
			<div className="flex gap-24">{children}</div>
		</div>
	);
}
