import type { EntityComboboxWrapperProps } from "@app/modules/entity/EntityCombobox";
import { EntityCombobox } from "@app/modules/entity/EntityCombobox";
import type { ControlledProps } from "@app/modules/form/controller";
import { useController } from "@app/modules/form/controller";
import { useI18nValueGetter } from "@app/modules/i18n/i18n-utils";
import type { ProductionSiteSuggestionsQuery } from "@app/modules/work-calendar/queries/production-site-suggestions.query.generated";
import { ProductionSiteSuggestionsDocument } from "@app/modules/work-calendar/queries/production-site-suggestions.query.generated";
import { forwardRef, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import type { AnyVariables } from "urql";

export interface ProductionSiteComboboxProps
	extends EntityComboboxWrapperProps {}

export const ProductionSiteCombobox = forwardRef<
	HTMLInputElement,
	ProductionSiteComboboxProps
>(function ProductionSiteCombobox(
	{ defaultInputValue = "", value, onChange, ...props },
	ref,
) {
	const [inputValue, setInputValue] = useState(defaultInputValue);

	const handleChange = (newValue: string | null) => {
		onChange?.(newValue);
	};
	const getI18nValue = useI18nValueGetter();

	return (
		<EntityCombobox
			ref={ref}
			inputValue={inputValue}
			value={value}
			onChange={handleChange}
			onInputValueChange={setInputValue}
			queryDocument={ProductionSiteSuggestionsDocument}
			getEntities={(data: ProductionSiteSuggestionsQuery) =>
				data.productionSites
			}
			asComboboxItem={({ id, i18n }) => ({
				id,
				label: getI18nValue(i18n?.name),
			})}
			{...props}
		/>
	);
});

export function ProductionSiteComboboxField<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
	ConstraintResult,
	ConstraintVariables extends AnyVariables,
>({
	control,
	name,
	defaultValue,
	constraint,
	...props
}: ProductionSiteComboboxProps &
	ControlledProps<Values, Name, ConstraintResult, ConstraintVariables>) {
	const { field } = useController({ control, name, defaultValue, constraint });
	return <ProductionSiteCombobox {...props} {...field} />;
}
