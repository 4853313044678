import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgCrown({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.444 5.17a1 1 0 011.166.038L9.168 9.49l1.942-3.798a1 1 0 011.78 0l1.943 3.798 5.557-4.282a1 1 0 011.575 1.055l-3.268 12a1 1 0 01-.965.737H6.268a1 1 0 01-.965-.737l-3.268-12a1 1 0 01.409-1.094zm2.303 3.438L7.032 17h9.936l2.285-8.392-4.153 3.2a1 1 0 01-1.5-.336L12 8.344l-1.6 3.128a1 1 0 01-1.5.337l-4.153-3.2z"
			/>
		</svg>
	);
}

const MemoSvgCrown = React.memo(SvgCrown);
export default MemoSvgCrown;
