import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgBestellungen({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.528 2.118a1 1 0 011.027.05l.945.63.945-.63a1 1 0 011.11 0l.945.63.945-.63a1 1 0 011.11 0l.945.63.945-.63a1 1 0 011.11 0l.945.63.945-.63A1 1 0 0122 3v14.5a4.5 4.5 0 01-4.5 4.5H6a4 4 0 01-4-4v-3a1 1 0 011-1h5V3a1 1 0 01.528-.882zM20 4.866V17.5a2.5 2.5 0 01-5 0V15a1 1 0 00-1-1h-4V4.866a1 1 0 001.055-.034l.945-.63.945.63a1 1 0 001.11 0l.945-.63.945.63a1 1 0 001.11 0l.945-.63.945.63A1 1 0 0020 4.866zM13.758 20A4.48 4.48 0 0113 17.5V16H4v2a2 2 0 002 2h7.758z"
			/>
		</svg>
	);
}

const MemoSvgBestellungen = React.memo(SvgBestellungen);
export default MemoSvgBestellungen;
