import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgInvoicing({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={19}
			height={15}
			viewBox="0 0 19 15"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.195.006A1.15 1.15 0 002.94.987l-.353 2.466H1.15A1.15 1.15 0 000 4.603v9c0 .635.515 1.15 1.15 1.15h13.5a1.15 1.15 0 001.15-1.15v-1.85h.902a1.15 1.15 0 001.143-1.023l.895-8.054a1.15 1.15 0 00-1.024-1.271L4.196.006zM14.65 3.453H3.9l.305-2.139 13.227 1.368-.864 7.771H15.8v-5.85a1.15 1.15 0 00-1.15-1.15zM1.3 5.953v-1.2h13.2v1.2H1.3zm0 7.5v-5.7h13.2v5.7H1.3zm9.85-4.5a1.15 1.15 0 00-1.15 1.15v1c0 .635.515 1.15 1.15 1.15h2a1.15 1.15 0 001.15-1.15v-1a1.15 1.15 0 00-1.15-1.15h-2zm.15 2v-.7H13v.7h-1.7z"
			/>
		</svg>
	);
}

const MemoSvgInvoicing = React.memo(SvgInvoicing);
export default MemoSvgInvoicing;
