import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgUsergroup({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M18.833 6.428c-.163 2.202-1.765 4.063-3.7 4.276.1-.381.167-.771.196-1.165.018-.24.021-.476.011-.709.873-.321 1.621-1.331 1.71-2.534.106-1.428-.888-2.509-2.288-2.509-.718 0-1.324.274-1.733.733a5.482 5.482 0 00-1.644-.792C12.105 2.67 13.32 2 14.762 2c2.443 0 4.253 1.967 4.071 4.428zM13.958 13.077a6.583 6.583 0 001.005-1.826c3.75.076 6.979 2.281 7.603 5.74.16.888-.396 1.748-1.317 1.821l-.128.005h-2.296a7.814 7.814 0 00-.793-1.787h2.715l-.03-.12c-.607-2.22-2.803-3.697-5.465-3.859l-.25-.011-.24-.003c-.272 0-.54.013-.804.04z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.566 19.992c-.635-3.521-3.968-5.743-7.804-5.743-3.835 0-7.168 2.222-7.803 5.743-.167.927.448 1.825 1.445 1.825H16.12l.128-.005c.921-.073 1.477-.933 1.317-1.82zM3.87 19.704c.725-2.236 3.097-3.668 5.892-3.668l.24.004.25.011c2.662.162 4.858 1.64 5.464 3.858l.03.12H3.778l.031-.12.062-.205zM13.833 9.428C14.015 6.968 12.205 5 9.763 5 7.31 5 5.51 6.94 5.691 9.427c.17 2.341 1.972 4.297 4.07 4.297 2.098 0 3.897-1.954 4.071-4.296zm-6.359-.13c-.105-1.453.875-2.51 2.288-2.51 1.4 0 2.394 1.08 2.288 2.508-.108 1.463-1.192 2.64-2.288 2.64-1.096 0-2.181-1.177-2.288-2.639z"
			/>
		</svg>
	);
}

const MemoSvgUsergroup = React.memo(SvgUsergroup);
export default MemoSvgUsergroup;
