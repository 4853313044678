import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgEyeclose({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.613 3.71l.094.083 16 16a1 1 0 01-1.32 1.497l-.095-.083-16-16a1 1 0 011.32-1.497zm-.1 4.13l1.423 1.425a14.747 14.747 0 00-2.832 2.912l-.233.321.009.008-.006.004.155.235c1.969 2.898 5.121 4.814 8.446 5.036l.322.016.323.006c.717 0 1.424-.082 2.114-.24l1.62 1.62c-1.194.404-2.448.62-3.734.62-4.375 0-8.534-2.463-10.96-6.26-.374-.58-.379-1.538.02-2.115A17.036 17.036 0 014.512 7.84zm7.607-2.866c4.202 0 8.188 2.574 10.72 6.485.365.566.365 1.516.002 2.08a14.425 14.425 0 01-3.318 3.657l-1.429-1.428c1.07-.789 2.03-1.786 2.829-2.954l.235-.355-.033.033-.162-.244c-2.058-3.016-5.09-5.02-8.234-5.251l-.305-.017-.305-.006c-.814 0-1.64.122-2.46.36L8.086 5.757a11.106 11.106 0 014.034-.784zm-3.566 6.909l4.063 4.063a3.5 3.5 0 01-4.063-4.063zM12 9a3.5 3.5 0 013.445 4.117l-4.063-4.063c.2-.035.407-.054.618-.054z"
			/>
		</svg>
	);
}

const MemoSvgEyeclose = React.memo(SvgEyeclose);
export default MemoSvgEyeclose;
