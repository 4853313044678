import { usePageTitle } from "@app/modules/hooks/usePageTitle";
import { autoHeightCls } from "@app/modules/layout/autoheight";
import { BannerNotification } from "@app/modules/notification/BannerNotification";
import clsx from "clsx";
import type { ReactNode } from "react";

export interface PageLayoutProps {
	className?: string;
	sidebar?: ReactNode;
	children?: ReactNode;
	title?: string;
}

export function PageLayout({
	className,
	children,
	sidebar,
	title,
}: PageLayoutProps) {
	usePageTitle(title);
	if (sidebar) {
		return (
			<div
				className={clsx(
					className,
					"grid gap-16 grid-cols-12 relative items-start",
				)}
			>
				{sidebar && (
					<div className="col-span-2 hidden lg:block sticky top-0">
						{sidebar}
					</div>
				)}
				<div
					className={clsx("col-span-12 lg:col-span-10", autoHeightCls(true))}
				>
					<BannerNotification />
					{children}
				</div>
			</div>
		);
	}
	return (
		<div className={autoHeightCls(true)}>
			<BannerNotification />
			{children}
		</div>
	);
}
