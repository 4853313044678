import { AlertSolid } from "@app/icons/components";
import { Icon } from "@app/icons/Icon";
import { useEntityForm } from "@app/modules/entity/hooks";
import { Customer_Order_States_Enum } from "@app/modules/graphql-api-types.generated";
import { useTranslate } from "@app/modules/i18n/context";
import { useI18nValueGetter } from "@app/modules/i18n/i18n-utils";
import { DateInputField } from "@app/modules/input-fields/date/DateInput";
import {
	getLocalizedDateString,
	LocalDate,
} from "@app/modules/localdate/localdate";
import { OfferActions } from "@app/modules/offer/public/OfferActions";
import { OfferItemList } from "@app/modules/offer/public/OfferItemList";
import type { OfferDetailsQuery } from "@app/modules/offer/queries/offer-details.query.generated";
import { offerSchema } from "@app/modules/offer/schema";
import clsx from "clsx";
import { FormProvider } from "react-hook-form";

interface OfferDetailProps {
	offerDetails: OfferDetailsQuery;
	refreshOffer: () => void;
}

export function OfferDetail({ offerDetails, refreshOffer }: OfferDetailProps) {
	const t = useTranslate("common");
	const getI18nValue = useI18nValueGetter();

	const offer = offerDetails.customerOrders[0];
	const customer = offer?.customer;
	const user = offer?.loggedActions?.[0]?.user;

	const { billingAddress, shippingAddress } = customer || {};
	const { tenantName, street1, street2, zip, city, i18n } =
		offerDetails.tenant[0] || {};

	const methods = useEntityForm({
		schema: offerSchema,
		defaultValues: {
			deliveryDate: offer?.deliveryDate ?? "",
			items: offer?.items.map((item) => ({
				_id: item.id,
				orderQuantity: item.orderQuantity,
				customerNote: item.customerNote ?? "",
				deleted: item.orderQuantity === "0",
			})),
		},
	});

	const { control } = methods;

	const deliveryDateIsEditable =
		offer?.isInternal && offer?.state === Customer_Order_States_Enum.OfferSent;

	const expiresAt = LocalDate.fromUtcString(
		offer?.offerExpiresAt ?? "",
	).formatDateTime({ includeDayOfWeek: true });

	if (!offer) {
		return null;
	}

	return (
		<FormProvider {...methods}>
			<div className="w-11/12 md:w-5/6 m-auto bg-white border-t-8 border-red-vibrant pb-120">
				<div className="grid grid-cols-4 gap-16 p-24 bg-grey-100">
					<div className="text-header-18 col-span-4 md:col-span-3 align-self-center">
						{t("public.offers.offer-from", {
							id: offer.domainId,
							date: getLocalizedDateString(offer.createdAt, {
								includeDayOfWeek: false,
							}),
						})}
					</div>

					<div className="flex-none col-span-4 md:col-span-1">
						<div className="text-caption-12 font-600">{tenantName}</div>
						<div className="text-caption-12 text-grey-700 font-500">
							{street1}
							<br />
							{street2 && (
								<>
									{street2}
									<br />
								</>
							)}
							{zip} {city}
						</div>
					</div>

					<div className="col-span-4 md:col-span-1">
						<div className="text-caption-12 text-grey-700 font-500">
							{t("public.offers.customer")}
						</div>
						<div className="text-body-14 font-600">
							{billingAddress?.name || customer?.name}
						</div>

						{billingAddress && (
							<div className="text-body-14">
								{billingAddress?.street1}
								{billingAddress?.street2}
								<br />
								{billingAddress?.zip} {billingAddress?.city}
							</div>
						)}
					</div>

					<div className="hidden md:block col-span-4 md:col-span-1">
						{shippingAddress && (
							<>
								<div className="text-caption-12 text-grey-700 font-500">
									{t("public.offers.delivered-to")}
								</div>
								<div className="text-body-14 font-600">
									{shippingAddress?.name}
								</div>
								<div className="text-body-14">
									{shippingAddress?.street1}
									{shippingAddress?.street2}
									<br />
									{shippingAddress?.zip} {shippingAddress?.city}
								</div>
							</>
						)}
					</div>

					<div className="flex flex-col gap-8">
						{deliveryDateIsEditable ? (
							<DateInputField
								control={control}
								name="deliveryDate"
								className="w-fit col-span-4 md:col-span-1"
								label={t("public.offers.delivery-date")}
							/>
						) : (
							<div>
								<div className="text-caption-12 text-grey-700 font-500">
									{t("public.offers.delivery-date")}
								</div>
								<div className="text-body-14">
									{offer?.deliveryDate &&
										LocalDate.fromUtcString(
											offer.deliveryDate,
										).toLocalizedDateString({ includeDayOfWeek: true })}
								</div>
							</div>
						)}
						<div>
							<div className="text-caption-12 text-grey-700 font-500">
								{t("public.offers.expires-at")}
							</div>
							<div
								className={clsx(
									"text-body-14 flex gap-8 items-center",
									offer.isOfferExpired && "font-500 text-orange-800",
								)}
							>
								{offer.isOfferExpired && <Icon size="16" icon={AlertSolid} />}
								{expiresAt}
							</div>
						</div>
					</div>

					{user && (
						<div className="col-span-4 md:col-span-1">
							<div className="text-caption-12 text-grey-700 font-500">
								{t("public.offers.sales-person")}
							</div>

							<div className="text-body-14 font-600">{user?.name}</div>
							<div className="text-body-14">
								{user?.phoneNumber}
								<br />
								{user?.email}
							</div>
						</div>
					)}
				</div>

				<OfferItemList offer={offer} refreshOffer={refreshOffer} />
				<div className="text-caption-10 pt-16 px-16 text-grey-700">
					{t("public.offers.conditions", {
						disclaimer: getI18nValue(i18n?.disclaimer),
						offerExpiresAt: LocalDate.fromUtcString(
							offer?.offerExpiresAt || "",
						).formatDateTime({ includeDayOfWeek: false }),
						jurisdiction: getI18nValue(i18n?.jurisdiction),
					})}
				</div>
			</div>

			<OfferActions offer={offer} refreshOffer={refreshOffer} />
		</FormProvider>
	);
}
