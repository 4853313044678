// THIS FILE IS GENERATED, DO NOT EDIT!
import type * as Types from '../../graphql-api-types.generated';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MyPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyPermissionsQuery = { __typename: 'query_root', rolePermissions: Array<{ __typename: 'role_permissions', action: string, fields?: string[] | null, subject: string, inverted?: boolean | null, conditions?: JsonObject | null }>, tenantPermissions: Array<{ __typename: 'tenant_permissions', id: string, action: string, fields?: string[] | null, subject: string, inverted?: boolean | null, conditions?: JsonObject | null }> };


export const MyPermissionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyPermissions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rolePermissions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"action"}},{"kind":"Field","name":{"kind":"Name","value":"fields"}},{"kind":"Field","name":{"kind":"Name","value":"subject"}},{"kind":"Field","name":{"kind":"Name","value":"inverted"}},{"kind":"Field","name":{"kind":"Name","value":"conditions"}}]}},{"kind":"Field","name":{"kind":"Name","value":"tenantPermissions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"action"}},{"kind":"Field","name":{"kind":"Name","value":"fields"}},{"kind":"Field","name":{"kind":"Name","value":"subject"}},{"kind":"Field","name":{"kind":"Name","value":"inverted"}},{"kind":"Field","name":{"kind":"Name","value":"conditions"}}]}}]}}]} as unknown as DocumentNode<MyPermissionsQuery, MyPermissionsQueryVariables>;