import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgStar({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2a1 1 0 01.953.696l1.867 5.842H21a1 1 0 01.572 1.82l-5.013 3.497 1.935 5.83a1 1 0 01-1.543 1.12l-4.95-3.65-4.952 3.65a1 1 0 01-1.543-1.12l1.935-5.83-5.013-3.496A1 1 0 013 8.539h6.18l1.867-5.843A1 1 0 0112 2zm0 4.285l-1.137 3.558a1 1 0 01-.952.696H6.182l3.015 2.102a1 1 0 01.377 1.136l-1.178 3.55 3.01-2.219a1 1 0 011.187 0l3.011 2.22-1.178-3.551a1 1 0 01.377-1.136l3.015-2.102H14.09a1 1 0 01-.952-.696L12 6.285z"
			/>
		</svg>
	);
}

const MemoSvgStar = React.memo(SvgStar);
export default MemoSvgStar;
