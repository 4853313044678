import { useCallback } from "react";

export function useClipboard() {
	const copyToClipboard = useCallback(async (text: string) => {
		if (!navigator?.clipboard?.writeText) {
			return { success: false };
		}

		await navigator.clipboard.writeText(text);
		return { success: true };
	}, []);

	return { copyToClipboard };
}
