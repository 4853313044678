import { useAppState } from "@app/modules/app-state/public/context";
import { keysWithoutId } from "@app/modules/graphql/UrqlClientProvider";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { createClient as createWSClient } from "graphql-ws";
import type { ReactNode } from "react";
import { useMemo } from "react";
import {
	createClient,
	fetchExchange,
	Provider,
	subscriptionExchange,
} from "urql";
import { updates } from "./updates";

const API_PATH = "/v1/graphql";

export interface UrqlClientProviderProps {
	children?: ReactNode;
}

export function UrqlClientProvider({ children }: UrqlClientProviderProps) {
	const { customerOrderToken } = useAppState();

	// INFO:
	// Creating a new client completely resets the cache.
	// As a result every query will automatically refetch.
	const client = useMemo(() => {
		if (!customerOrderToken) {
			return createClient({
				url: API_PATH,
				exchanges: [devtoolsExchange, fetchExchange],
			});
		}

		const headers = {
			"x-hasura-customer-order-token": customerOrderToken,
		};

		const { location } = window;
		const wsProtocol = location.protocol === "https:" ? "wss:" : "ws:";
		const wsUrl = `${wsProtocol}//${location.host}${API_PATH}`;

		const wsClient = createWSClient({
			url: wsUrl,
			connectionParams: { headers },
		});

		return createClient({
			url: API_PATH,
			requestPolicy: "cache-and-network",
			exchanges: [
				devtoolsExchange,
				cacheExchange({
					keys: keysWithoutId,
					updates,
				}),
				fetchExchange,
				subscriptionExchange({
					forwardSubscription: (request) => {
						const input = { ...request, query: request.query || "" };
						return {
							subscribe: (sink) => {
								const dispose = wsClient.subscribe(input, sink);
								return {
									unsubscribe: dispose,
								};
							},
						};
					},
				}),
			],
			fetchOptions: {
				headers,
			},
		});
	}, [customerOrderToken]);

	return <Provider value={client}>{children}</Provider>;
}
