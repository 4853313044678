import { useSearchParam } from "@app/modules/routes/search-params";
import type {
	LocalSearchFilter,
	LocalSearchFilterBase,
	LocalSearchFilterType,
	LocalSearchScopeKey,
} from "@app/modules/search/local/types";
import {
	anyFilterSchema,
	filterBaseSchema,
	rangeFilterSchema,
	valueFilterSchema,
} from "@app/modules/search/local/types";
import { makeQueryFiltersFromLocalSearchFilters } from "@app/modules/search/local/utils";
import { assertUnreachableDefaultCase } from "@app/modules/utils/assertions";
import { quickhash } from "@app/modules/utils/hash";
import { organizeByFn } from "@app/modules/utils/organizeBy";
import type { SetStateAction } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { Infer } from "superstruct";
import {
	array,
	assign,
	is,
	literal,
	object,
	optional,
	string,
	union,
} from "superstruct";

const EMPTY_FILTERS: LocalSearchFilter[] = [];

const searchParamsSchema = object({
	scope: string(),
	values: array(anyFilterSchema),
});

type SearchParams = Infer<typeof searchParamsSchema>;
export interface UseLocalSearchFiltersOptions {
	shouldUpdateHistoryOnChange: boolean;
}

export function useLocalSearchFilters(
	scopeKey: string | undefined,
	options: UseLocalSearchFiltersOptions,
) {
	const scopeKeyRef = useRef(scopeKey);
	scopeKeyRef.current = scopeKey;

	const [urlValue, setUrlValue] = useUrlFilters({
		shouldUpdateHistoryOnChange: options.shouldUpdateHistoryOnChange,
	});

	const fallback = useRef<Dictionary<string, SearchParams>>({});

	useEffect(
		function updateFallback() {
			if (!scopeKey) {
				return;
			}

			if (scopeKey !== urlValue?.scope) {
				return;
			}

			fallback.current[scopeKey] = urlValue;
		},
		[scopeKey, urlValue],
	);

	useEffect(
		function restoreFromFallback() {
			if (!scopeKey || !fallback.current[scopeKey]) {
				return;
			}
			setUrlValue(fallback.current[scopeKey]);
		},
		[scopeKey, setUrlValue],
	);

	const urlFilters =
		urlValue && urlValue.scope === scopeKey
			? urlValue.values ?? EMPTY_FILTERS
			: undefined;
	const fallbackFilters = scopeKey
		? fallback.current[scopeKey]?.values
		: undefined;
	const usedFilter = urlFilters ?? fallbackFilters ?? EMPTY_FILTERS;

	const queryFilters = useMemo(
		() => makeQueryFiltersFromLocalSearchFilters(usedFilter),
		[usedFilter],
	);

	const filtersByKey = useMemo(
		() => organizeByFn(usedFilter, getLocalSearchFilterKey),
		[usedFilter],
	);

	const removeFilterByKey = useCallback(
		(...keys: string[]) => {
			setUrlValue((base) => {
				if (!base || !base.values) {
					return base;
				}

				return {
					...base,
					values: base.values.filter(
						(f) => !keys.includes(getLocalSearchFilterKey(f)),
					),
				};
			});
		},
		[setUrlValue],
	);

	return {
		filters: usedFilter,
		queryFilters,
		setFilters: useCallback(
			(filters: LocalSearchFilter[]) => {
				const scope = scopeKeyRef.current;
				if (!scope) {
					return;
				}
				setUrlValue({
					scope,
					values: filters,
				});
			},
			[setUrlValue],
		),
		addFilters: useCallback(
			(filters: LocalSearchFilter[]) => {
				const scope = scopeKeyRef.current;
				if (!scope) {
					return;
				}
				setUrlValue((old) => {
					const base = old ?? { scope, values: [] };
					const newFilterKeys = filters.map(getLocalSearchFilterKey);
					const clean = base.values.filter(
						(f) => !newFilterKeys.includes(getLocalSearchFilterKey(f)),
					);
					return {
						...base,
						values: [...clean, ...filters],
					};
				});
			},
			[setUrlValue],
		),
		removeFilter: useCallback(
			(filter: LocalSearchFilter) => {
				removeFilterByKey(getLocalSearchFilterKey(filter));
			},
			[removeFilterByKey],
		),
		removeFilterByKey,
		hasFilter: useCallback(
			(filter: LocalSearchFilter) =>
				Boolean(filtersByKey[getLocalSearchFilterKey(filter)]),
			[filtersByKey],
		),
		findFilterByKey: useCallback(
			<T extends LocalSearchFilterType>(
				key: string,
				type: LocalSearchFilterType,
			): DiscriminateUnion<LocalSearchFilter, "type", T> | undefined => {
				const found = filtersByKey[key];
				if (found?.type === type) {
					return found as DiscriminateUnion<LocalSearchFilter, "type", T>;
				}
				return undefined;
			},
			[filtersByKey],
		),
	};
}

const SEARCH_PARAM_NAME = "filters";

interface UseUrlFiltersInput {
	shouldUpdateHistoryOnChange: boolean;
}

// TODO: fix `scope:[object+Object]` being saved to URL
function useUrlFilters({ shouldUpdateHistoryOnChange }: UseUrlFiltersInput) {
	const [urlValue, setUrlValue] = useSearchParam<
		CompressedSearchParams | undefined
	>(SEARCH_PARAM_NAME, undefined, {
		validate: (value: unknown) =>
			value === undefined || is(value, compressedSearchParamsSchema),
		shouldUpdateHistoryOnChange,
	});

	const value = useMemo(() => {
		if (!urlValue) {
			return undefined;
		}
		return decompressValue(urlValue);
	}, [urlValue]);

	const setValue = useCallback(
		(newVal: SetStateAction<SearchParams | undefined>) => {
			setUrlValue((oldVal) => {
				if (typeof newVal === "function") {
					const decompressedOld = oldVal ? decompressValue(oldVal) : undefined;
					const newValue = newVal(decompressedOld);
					return newValue ? compressValue(newValue) : undefined;
				}
				return newVal ? compressValue(newVal) : undefined;
			});
		},
		[setUrlValue],
	);

	return [value, setValue] as const;
}

const compressedFilterTypeMap = {
	Value: 1,
	Range: 2,
} as const satisfies Record<LocalSearchFilterType, number>;

const compressedFilterBaseSchema = object({
	k: filterBaseSchema.schema.key,
	path: filterBaseSchema.schema.path,
	l: filterBaseSchema.schema.label,
	i: filterBaseSchema.schema.icon,
	// To compress further we make these properties optional. This can only be done when default values exist. Check implementation of "compressFilter" & "decompressFilter" for details.
	date: optional(filterBaseSchema.schema.shouldDeactivateDateFilter),
	visible: optional(filterBaseSchema.schema.visible),
});

const compressedValueFilterSchema = assign(
	compressedFilterBaseSchema,
	object({
		// we keep the type optional to keep backwards compatibility
		t: optional(literal(compressedFilterTypeMap.Value)),
		op: valueFilterSchema.schema.operator,
		v: valueFilterSchema.schema.value,
	}),
);

const compressedRangeFilterSchema = assign(
	compressedFilterBaseSchema,
	object({
		t: literal(compressedFilterTypeMap.Range),
		f: rangeFilterSchema.schema.from,
		to: rangeFilterSchema.schema.to,
		fop: rangeFilterSchema.schema.fromOperator,
		top: rangeFilterSchema.schema.toOperator,
	}),
);

type CompressedFilterBase = Infer<typeof compressedFilterBaseSchema>;
type CompressedValueFilter = Infer<typeof compressedValueFilterSchema>;
type CompressedRangeFilter = Infer<typeof compressedRangeFilterSchema>;
type CompressedFilter = CompressedValueFilter | CompressedRangeFilter;

const compressedSearchParamsSchema = object({
	scope: string(),
	values: optional(
		array(union([compressedValueFilterSchema, compressedRangeFilterSchema])),
	),
});

type CompressedSearchParams = Infer<typeof compressedSearchParamsSchema>;

function compressValue(value: SearchParams): CompressedSearchParams {
	const values = value.values.map(compressFilter);
	return {
		scope: value.scope,
		// We cannot pass down an empty array as json url will turn it into an empty object as it will the url to be invalid.
		values: values.length ? values : undefined,
	};
}

function decompressValue(value: CompressedSearchParams): SearchParams {
	return {
		scope: value.scope,
		values: value.values?.map(decompressFilter) ?? [],
	};
}

function compressFilter(filter: LocalSearchFilter): CompressedFilter {
	const { type } = filter;
	const base: CompressedFilterBase = {
		k: filter.key,
		path: filter.path,
		l: filter.label,
		i: filter.icon,
		date: filter.shouldDeactivateDateFilter ? true : undefined,
		visible: filter.visible ? undefined : false,
	};
	switch (type) {
		case "Value":
			return {
				...base,
				op: filter.operator,
				v: filter.value,
			};
		case "Range":
			return {
				...base,
				t: compressedFilterTypeMap.Range,
				f: filter.from,
				to: filter.to,
				fop: filter.fromOperator,
				top: filter.toOperator,
			};
		default:
			return assertUnreachableDefaultCase(type);
	}
}

function decompressFilter(filter: CompressedFilter): LocalSearchFilter {
	const { t, k, path, l, i, date, visible } = filter;
	const base: LocalSearchFilterBase = {
		key: k,
		path,
		label: l,
		icon: i,
		shouldDeactivateDateFilter: date ?? false,
		visible: visible ?? true,
	};

	switch (t) {
		case undefined:
		case compressedFilterTypeMap.Value:
			return {
				...base,
				type: "Value",
				operator: filter.op,
				value: filter.v,
			};
		case compressedFilterTypeMap.Range:
			return {
				...base,
				type: "Range",
				from: filter.f,
				to: filter.to,
				fromOperator: filter.fop,
				toOperator: filter.top,
			};
		default:
			return assertUnreachableDefaultCase(t);
	}
}

export function getLocalSearchFilterKey(filter: LocalSearchFilter) {
	const { type, key, path } = filter;
	if (key) {
		return key;
	}
	switch (type) {
		case "Value":
			return `${path}:${filter.operator}:${filter.value}`;
		case "Range":
			return quickhash([
				type,
				path,
				filter.fromOperator,
				filter.from ?? "",
				filter.toOperator,
				filter.to ?? "",
			]);
		default:
			return assertUnreachableDefaultCase(type);
	}
}

export function makeLocalFiltersSearchObject(
	scopeKey: LocalSearchScopeKey,
	filters: LocalSearchFilter[],
) {
	return {
		[SEARCH_PARAM_NAME]: compressValue({ scope: scopeKey, values: filters }),
	};
}
