import { DataTableCell } from "@app/modules/data-table/DataTable";
import type { BigNumber } from "@app/modules/number/big-number";
import clsx from "clsx";

export interface PercentageCellProps {
	factor: BigNumber | undefined;
	className?: string;
}

export function PercentageCell({ className, factor }: PercentageCellProps) {
	const percentageString =
		factor && !factor.isNaN() ? `${factor.times(100).toFixed(1)} %` : "- %";
	return (
		<DataTableCell className={clsx(className, "whitespace-nowrap")}>
			{percentageString}
		</DataTableCell>
	);
}
