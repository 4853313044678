import { LargeDropdownClosed } from "@app/icons/components/action";
import { Icon } from "@app/icons/Icon";
import { useAbility } from "@app/modules/authorization/AuthorizationProvider";
import type { CardProps } from "@app/modules/cards/card-props";
import type { CardState } from "@app/modules/cards/cards-shared-state";
import { useSharedCardsState } from "@app/modules/cards/cards-shared-state";
import { CustomerCard } from "@app/modules/cards/CustomerCard";
import { CustomerProductHistoryCard } from "@app/modules/cards/CustomerProductHistoryCard";
import { CustomerProductContainersCard } from "@app/modules/cards/CustomerProductContainersCard";
import { CustomerProductsCard } from "@app/modules/cards/CustomerProductsCard";
import { SupplierCard } from "@app/modules/cards/SupplierCard";
import { SupplierOrderRelatedProductsCard } from "@app/modules/cards/SupplierOrderRelatedProductsCard";
import { SupplierSubOrdersCard } from "@app/modules/cards/SupplierSubOrdersCard";
import { useLocalStorageState } from "@app/modules/utils/local-storage";
import clsx from "clsx";
import type { ComponentType } from "react";
import { boolean, is } from "superstruct";

export interface CardDrawerProps {
	className?: string;
}
export function CardDrawer({ className }: CardDrawerProps) {
	const [{ cards }] = useSharedCardsState();

	const [isOpen, setIsOpen] = useLocalStorageState("card-drawer", false, {
		validate: (value): value is boolean => is(value, boolean()),
	});
	const { can } = useAbility();
	const toggleOpen = () => setIsOpen((oldVal) => !oldVal);

	if (!can("use", "CardDrawer")) {
		return null;
	}

	return (
		<div
			data-label="card-drawer"
			className={clsx(
				className,
				"relative bg-grey-200 transition-width duration-500 overflow-y-scroll",
				isOpen ? "w-464" : "w-32",
			)}
		>
			<div className="overflow-hidden mt-8 flex text-grey-900">
				<button
					tabIndex={-1}
					type="button"
					onClick={toggleOpen}
					className="p-8"
				>
					<Icon
						icon={LargeDropdownClosed}
						size="16"
						className={clsx(
							"transform-gpu",
							isOpen ? "-rotate-90" : "rotate-90",
						)}
					/>
				</button>
			</div>
			{isOpen && (
				<div className="m-16 flex flex-col gap-16">
					{cards.map((props) => (
						<MappedCard key={props.cardId} {...props} />
					))}
				</div>
			)}
		</div>
	);
}

const mapping = {
	customer: CustomerCard,
	supplier: SupplierCard,
	"customer-products": CustomerProductsCard,
	"customer-product-history": CustomerProductHistoryCard,
	"customer-order-containers": CustomerProductContainersCard,
	"supplier-sub-orders": SupplierSubOrdersCard,
	"supplier-order-related-products": SupplierOrderRelatedProductsCard,
} as const;

function MappedCard({ type, cardId, payload }: CardState) {
	// It's a bit hard to tell TS that the component we get is the correct
	// component for the type attribute. Yes, this allows for uncaught type issues.
	// At this point, the effort does not warrant the proper implementation.
	const Component = mapping[type] as ComponentType<CardProps>;
	return <Component cardId={cardId} {...payload} />;
}
