import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgClose({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M5.844 16.884a1 1 0 101.414 1.414l4.813-4.813 4.806 4.806a1 1 0 001.414-1.414l-4.806-4.806 4.95-4.95a1 1 0 10-1.414-1.414l-4.95 4.95-4.95-4.95a1 1 0 10-1.414 1.414l4.95 4.95-4.813 4.813z" />
		</svg>
	);
}

const MemoSvgClose = React.memo(SvgClose);
export default MemoSvgClose;
