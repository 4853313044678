import {
	useAppState,
	useAppStateDispatch,
} from "@app/modules/app-state/context";
import type { IdTokenClaims } from "@app/modules/auth0/utils";
import { hasAuthParams, parseHasuraClaims } from "@app/modules/auth0/utils";
import { useNavigate } from "@app/modules/routes/routes";
import { Auth0Client } from "@auth0/auth0-spa-js";
import type { ReactNode } from "react";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

interface Auth0ContextValue {
	login: () => void;
	logout: () => void;
}

const Auth0Context = createContext<Auth0ContextValue>({
	login: () => {},
	logout: () => {},
});

export interface Auth0ProviderProps {
	children?: ReactNode;
}

function Auth0Provider({ children }: Auth0ProviderProps) {
	const dispatch = useAppStateDispatch();
	const navigate = useNavigate();
	const { tenantDoc, tenant } = useAppState();
	const [auth0Client, setAuth0Client] = useState<Auth0Client | undefined>();

	useEffect(() => {
		if (tenant && tenantDoc) {
			setAuth0Client(
				new Auth0Client({
					domain: tenantDoc.auth0Domain,
					client_id: tenantDoc.auth0ClientId,
					redirect_uri: window.location.origin,
					audience: tenant,
				}),
			);
		}
	}, [tenant, tenantDoc]);

	const login = useCallback(async () => {
		auth0Client?.loginWithRedirect({
			connection: tenant,
			appState: {
				targetPath: window.location.pathname + window.location.search,
			},
		});
	}, [auth0Client, tenant]);

	const logout = useCallback(() => {
		auth0Client?.logout({ returnTo: window.location.origin });
	}, [auth0Client]);

	const handleAuthenticated = async () => {
		if (auth0Client) {
			const isAuth = await auth0Client.isAuthenticated();
			if (!isAuth) {
				dispatch({ type: "UNAUTHENTICATED" });
				return;
			}

			const accessToken = await auth0Client.getTokenSilently();
			const { userId, allowedRoles, allowedTenants } = parseHasuraClaims(
				(await auth0Client.getIdTokenClaims()) as IdTokenClaims,
			);
			if (userId && allowedRoles && allowedTenants) {
				dispatch({
					type: "AUTHENTICATED",
					accessToken,
					allowedRoles,
					allowedTenants,
					userId,
				});
			} else {
				dispatch({ type: "UNAUTHENTICATED" });
				logout();
			}
		}
	};

	// Restore session as soon as client becomes available
	useEffect(() => {
		if (auth0Client) {
			(async () => {
				if (hasAuthParams(window.location.search)) {
					const { appState } = await auth0Client.handleRedirectCallback();
					const targetPath = appState?.targetPath || "/";
					navigate(targetPath, { replace: true });
				} else {
					await auth0Client.checkSession();
				}
				await handleAuthenticated();
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth0Client]);

	const value = useMemo(
		() => ({
			login,
			logout,
		}),
		[login, logout],
	);

	return (
		<Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
	);
}

function useAuth0() {
	return useContext(Auth0Context);
}

export { Auth0Provider, useAuth0 };
