import { useCallback, useState } from "react";

export function usePaginatedArray<T>(
	entries: T[],
	{ limit = 10, initialOffset = 0 },
) {
	const [offset, setOffset] = useState(initialOffset);

	const goNext = useCallback(() => {
		setOffset((oldOffset) => oldOffset + limit);
	}, [limit]);

	const goPrev = useCallback(() => {
		setOffset((oldOffset) => Math.max(oldOffset - limit, 0));
	}, [limit]);

	const getHasNext = (count: number) => offset + limit < count;

	const resetOffset = useCallback(() => {
		setOffset(0);
	}, []);

	return {
		visibleEntries: entries.slice(offset, offset + limit),
		limit,
		offset,
		resetOffset,
		goNext,
		goPrev,
		hasPrev: offset !== 0,
		hasNext: getHasNext(entries.length),
	};
}
