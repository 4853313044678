import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgClock({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12.3 0C18.8.1 24 5.4 24 12s-5.4 12-12 12S0 18.6 0 11.8a12 12 0 013.1-7.8 1.1 1.1 0 011.6 1.5A9.8 9.8 0 002.3 12c0 5.4 4.3 9.9 9.8 9.9s9.8-4.3 9.8-9.8a9.8 9.8 0 00-8.4-9.8h-.3v3.9a1.1 1.1 0 01-.9 1.1 1.1 1.1 0 01-1.1-.9v-5.1A1.4 1.4 0 0112.3 0ZM7.2 6.6l6.2 4.3a1.8 1.8 0 11-2.4 2.5L6.6 7.2a.5.5 0 01.6-.6Z"
			/>
		</svg>
	);
}

const MemoSvgClock = React.memo(SvgClock);
export default MemoSvgClock;
