import murmurhash from "murmurhash";

/**
 * Generates a 32 bit non-cryptographic hash from a string.
 * Useful for generating short ids like the `key` prop of React components.
 */
export function quickhash(
	input: string | (string | number | null | undefined)[],
): string {
	const toHash = typeof input === "string" ? input : input.join("-");

	// We use a very fast and simple hash without seed.
	// This is not a cryptographic hash!
	return murmurhash.v3(toHash).toString();
}
