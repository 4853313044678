import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgInvoice({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path d="M8 7a1 1 0 000 2h8a1 1 0 100-2H8zM8 11a1 1 0 100 2h8a1 1 0 100-2H8zM8 15a1 1 0 100 2h4a1 1 0 100-2H8z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 3h3a3 3 0 116 0h3a3 3 0 013 3v14a3 3 0 01-3 3H6a3 3 0 01-3-3V6a3 3 0 013-3zm6-1a1 1 0 00-1 1h2a1 1 0 00-1-1zM6 5a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1H6z"
			/>
		</svg>
	);
}

const MemoSvgInvoice = React.memo(SvgInvoice);
export default MemoSvgInvoice;
