import * as React from "react";

interface SVGRProps {
	title?: string;
	titleId?: string;
}

function SvgUser({
	title,
	titleId,
	...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.762 13.349c4.291 0 8.02 2.486 8.732 6.426.179.992-.443 1.955-1.474 2.037l-.143.005H4.647c-1.115 0-1.804-1.005-1.616-2.042.71-3.94 4.44-6.426 8.73-6.426zm0 2c-3.127 0-5.782 1.602-6.593 4.103l-.069.23-.035.135h13.393l-.034-.135c-.679-2.482-3.135-4.135-6.114-4.316l-.28-.013-.268-.004zm0-12.349c2.733 0 4.759 2.201 4.555 4.955-.195 2.62-2.208 4.807-4.555 4.807-2.348 0-4.364-2.189-4.555-4.809C7.005 5.171 9.017 3 11.762 3zm0 2c-1.581 0-2.678 1.184-2.56 2.808.119 1.635 1.333 2.954 2.56 2.954 1.226 0 2.439-1.318 2.56-2.955C14.441 6.209 13.328 5 11.762 5z"
			/>
		</svg>
	);
}

const MemoSvgUser = React.memo(SvgUser);
export default MemoSvgUser;
