import type { MutableRefObject, RefCallback } from "react";
import { useCallback, useRef, useState } from "react";
import type {
	Control,
	FieldPath,
	FieldPathValue,
	FieldValues,
	UnpackNestedValue,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";

/** @deprecated use ControlledProps instead */
export interface ControlledFieldProps<
	Values extends FieldValues,
	Name extends FieldPath<Values>,
> {
	control: Control<Values>;
	name: Name;
	defaultValue?: UnpackNestedValue<FieldPathValue<Values, Name>>;
}

// With v7 of react-hook-form the type checking has been tightened up.
// Types starting with `Untyped` can be used as an escape hatch for cases
// where fixing types is not yet possible or too complex.
// Important: adding these types to existing components does not reduce type safety.
// Important: don't use these types for newly created components.

// TODO: Remove these types with v8. It will give us proper support for reusable components.
// RFC: https://github.com/react-hook-form/react-hook-form/discussions/7354
/** @deprecated */
export type UntypedControl = Control<any>;

/** @deprecated */
export type UntypedSetValue = UseFormSetValue<any>;

/** @deprecated */
export type UntypedRegister = UseFormRegister<any>;

type Id = string;
/**
 * Given two arrays of form values with an `_id` field,
 * this function returns all ids that are present in the first argument,
 * but not in the second.
 * */
export function findRemovedIds<
	IV extends { [key in T]?: Id },
	V extends { [key in T]?: Id },
	T extends string = "_id",
>(initialValues: IV[], values: V[], idFieldName = "_id" as T): Id[] {
	const initialIds = initialValues
		.map((val) => val[idFieldName])
		.filter(Boolean) as Id[];
	const ids = values.map((val) => val[idFieldName]).filter((id) => id) as Id[];
	const removedIds = initialIds.filter((id) => !ids.includes(id));
	return removedIds;
}

export function findAddedIds<
	V extends { [key in T]?: Id },
	T extends string = "_id",
>(initialValues: V[], values: V[], idFieldName = "_id" as T): Id[] {
	return findRemovedIds(values, initialValues, idFieldName);
}

export function arraySubtract(a: string[], b: string[]) {
	const setA = new Set(a);
	const setB = new Set(b);
	return [...setA].filter((value) => !setB.has(value));
}

/**
 * Reverts an object's `_id` field to an `id` field.
 */
export function revertFormId<T extends { _id?: Id }>(
	values: T,
): Omit<T, "_id"> & { id?: Id } {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { _id, ...rest } = values;
	return {
		id: _id,
		...rest,
	};
}

/**
 * Recursively replaces any empty strings (after trimming) `""` with `null` in the provided field values.
 * This is especially useful before submitting the values to the server.
 * Careful! Empty values of not null DB columns will also be changed to null.
 */
// There are some challenges that we still need to solve before this becomes a general-purpose function.
// Some columns in the DB are not nullable but have a default value. Replacing
// these values with null will cause the DB to throw an error.
// This issue can be solved by replacing the empty string with `undefined`.
// However, this function does not know for which field it should set `null` or
// `undefined`.
// Also we have a problem with NOT NULL columns without a default value. Here I
// would argue that these values should not reach this function. If they do,
// there is a bug with the validation or DB schema as the 2 should be in sync.
export function fixEmptyStringValues<T extends unknown>(values: T) {
	return fixEmptyStringValuesRecursively(values) as T;
}

// TODO: Write a test for this as soon as the test setup is fixed.
function fixEmptyStringValuesRecursively(value: unknown): unknown {
	switch (typeof value) {
		case "string": {
			const trimmed = value.trim();
			return trimmed === "" ? null : trimmed;
		}
		case "object": {
			// Yes, null is considered an object in JS :)
			if (value === null) {
				return value;
			}
			if (Array.isArray(value)) {
				return value.map(fixEmptyStringValuesRecursively);
			}
			return Object.entries(value).reduce((acc, [key, val]) => {
				const fixedValue = fixEmptyStringValuesRecursively(val);
				acc[key] = fixedValue;
				return acc;
			}, {} as FieldValues);
		}
		default: {
			return value;
		}
	}
}

// based on https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
export function mergeRefs<T>(
	...refs: (MutableRefObject<T | null> | RefCallback<T> | undefined | null)[]
): RefCallback<T> {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === "function") {
				ref(value);
			} else if (ref) {
				// eslint-disable-next-line no-param-reassign
				ref.current = value;
			}
		});
	};
}

export function useVersionCounter(initialVersion: string) {
	const version = useRef(Number(initialVersion));

	const incrementVersion = useCallback(() => {
		version.current += 1;
	}, []);

	const decrementVersion = useCallback(() => {
		version.current -= 1;
	}, []);

	return {
		version,
		incrementVersion,
		decrementVersion,
	};
}

// Dirty workaround because reset/setValue during onSubmit handling does not always work as expected.
// Properly fixing the behavior requires further investigation.
// Based on https://react-hook-form.com/v6/api/ the reset should happen in an effect which is super awful to use.
// We could create a custom hook that makes this functionality less painful to use. Maybe even integrate with the EntityForm.
// Though the usage has changed slightly with v7: https://react-hook-form.com/api/useform/reset
// TODO: revisit this logic when we upgrade to v7 of react-hook-form
export function onFormSubmitted(fn: () => void) {
	setTimeout(fn, 0);
}

/**
 * Returns a stable and unique id used for form inputs.
 * Inspired by `useId` from react 18 but without SSR support.
 */
export function useInputId(defaultId?: string) {
	const [id] = useState(() => defaultId ?? generateNextId());
	return id;
}

const idState = {
	id: 0,
};

function generateNextId() {
	idState.id += 1;
	return `input-${idState.id}`;
}
