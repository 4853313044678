import type { RefObject } from "react";
import { useCallback, useEffect } from "react";

interface UseClickOutsideOptions<T extends HTMLElement> {
	isActive?: boolean;
	onClickOutside: (() => void) | undefined;
	ref: RefObject<T>;
}

export function useClickOutsideEffect<T extends HTMLElement>({
	isActive = true,
	onClickOutside,
	ref,
}: UseClickOutsideOptions<T>) {
	const handleClickOutside = useCallback(
		(e: MouseEvent) => {
			if (
				onClickOutside &&
				ref?.current &&
				!ref.current.contains(e.target as Node)
			) {
				onClickOutside();
			}
		},
		[onClickOutside, ref],
	);
	useEffect(() => {
		if (isActive) {
			document.addEventListener("click", handleClickOutside);
			return () => {
				document.removeEventListener("click", handleClickOutside);
			};
		}
		return undefined;
	}, [handleClickOutside, isActive]);
}
