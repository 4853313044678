import type { SuggestionTagItem } from "@app/modules/search/suggestion-utils";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import type { Infer } from "superstruct";
import {
	array,
	assign,
	boolean,
	enums,
	literal,
	object,
	optional,
	record,
	string,
	union,
	unknown,
} from "superstruct";
import type { DocumentInput } from "urql";

export const localSearchScopeKeys = [
	"customers-table",
	"suppliers-table",
	"supplier-orders-table",
	"customer-orders-table",
	"internal-customer-orders-table",
	"customer-prices-table",
	"picking-list",
	"goods-income-overview.customer-orders",
	"goods-income-overview.supplier-orders",
	"goods-income-generic",
	"products-table",
	"temporal-products-table",
	"price-lists-table",
	"price-list-products-table",
	"invoices-table",
	"purchasing-list",
	"inventory-list",
	"container-inventory-list",
	"stock-transaction-list",
	"stock-locations-table",
	"price-labelling",
	"credit-notes-table",
	"rolling-inventory",
	"sales-teams-table",
	"work-calendar",
	"supplier-order-returns",
	"framework-agreements-table",
	"customer-returns-table",
	"supplier-returns-table",
	"product-groups-table",
	"customer-groups-table",
	"cutting-pattern-product-groups-table",
	"cutting-patterns-details",
	"dismantling-pricing-products-table",
	"dismantling-pricing-product-groups-table",
	"demand-planning",
	"hierarchical-labels-table",
	"hardware-device-list",
	"exchange-rate-list",
	"journal-entry-list",
	"notifications-table",
	"dismantling-printing",
] as const;

export type LocalSearchScopeKey = (typeof localSearchScopeKeys)[number];

export type LocalSearchFilterBase = Infer<typeof filterBaseSchema>;
export type LocalSearchValueFilter = Infer<typeof valueFilterSchema>;
export type LocalSearchRangeFilter = Infer<typeof rangeFilterSchema>;
export type LocalSearchFilter = LocalSearchRangeFilter | LocalSearchValueFilter;

export type LocalSearchFilterValue =
	| string
	| string[]
	| boolean
	| Record<string, unknown>;

export interface LocalSearchScope<
	QueryDocument extends DocumentInput = DocumentInput,
> {
	scopeKey: LocalSearchScopeKey;
	scopeKeySuffix?: string;
	queryDocument: QueryDocument | null;
	queryVariables?: (searchInput: string) => VariablesOf<QueryDocument>;
	fields?: {
		key: string;
		getSuggestions: (
			data: ResultOf<QueryDocument>,
			inputValue: string,
		) => LocalSearchSuggestion[];
	}[];
}

export type LocalSearchFilters = Dictionary<
	LocalSearchScopeKey,
	{ [k: string]: LocalSearchFilter }
>;

const filterTypes = {
	Value: "Value",
	Range: "Range",
} as const;
export type LocalSearchFilterType = Values<typeof filterTypes>;

const operators = [
	"_eq",
	"_in",
	"_ilike",
	"_gte",
	"_lte",
	"_is_null",
	"_contains",
] as const;
const operatorSchema = enums(operators);
export type ValueOperator = Infer<typeof operatorSchema>;

const rangeFromOperatorsSchema = enums(["_gt", "_gte"]);
const rangeToOperatorsSchema = enums(["_lt", "_lte"]);
export type RangeFromOperator = Infer<typeof rangeFromOperatorsSchema>;
export type RangeToOperator = Infer<typeof rangeToOperatorsSchema>;

export const filterBaseSchema = object({
	key: optional(string()),
	path: string(),
	label: optional(string()),
	icon: optional(string()),
	shouldDeactivateDateFilter: optional(boolean()),
	visible: boolean(),
});

export const valueFilterSchema = assign(
	filterBaseSchema,
	object({
		type: literal(filterTypes.Value),
		operator: operatorSchema,
		value: union([
			string(),
			array(string()),
			boolean(),
			record(string(), unknown()),
		]),
	}),
);

export const rangeFilterSchema = assign(
	filterBaseSchema,
	object({
		type: literal(filterTypes.Range),
		from: optional(string()),
		to: optional(string()),
		fromOperator: rangeFromOperatorsSchema,
		toOperator: rangeToOperatorsSchema,
	}),
);

export const anyFilterSchema = union([valueFilterSchema, rangeFilterSchema]);

export type LocalSearchSuggestion = {
	key: string;
	label: string;
	icon?: string;
	domainId?: string;
	tenant?: string;
	filter: LocalSearchFilter;
	tags?: SuggestionTagItem[];
};
