import { useTranslate } from "@app/modules/i18n/context";
import { getLocalTimeZone, today } from "@internationalized/date";
import clsx from "clsx";
import React from "react";
import type { CalendarState, RangeCalendarState } from "react-stately";

interface ShortcutConfig {
	label: string;
	onClick: () => void;
}

export function CalendarShortcuts({
	state,
	className,
}: {
	state: CalendarState;
	className?: string;
}) {
	const shortcuts = useCalendarShortcuts(state);

	return (
		<Shortcuts className={clsx(className)}>
			{shortcuts.map(({ onClick, label }) => (
				<Shortcut onClick={onClick} key={label}>
					<div className="text-body-14 font-500">{label}</div>
				</Shortcut>
			))}
		</Shortcuts>
	);
}

export function useCalendarShortcuts(state: CalendarState): ShortcutConfig[] {
	const t = useTranslate("common");
	const currentDate = today(getLocalTimeZone());

	return [
		{
			label: t("datetime.calendar.shortcuts.yesterday"),
			onClick: () => state.setValue(currentDate),
		},
		{
			label: t("datetime.calendar.shortcuts.today"),
			onClick: () => state.setValue(currentDate.subtract({ days: 1 })),
		},
		{
			label: t("datetime.calendar.shortcuts.tomorrow"),
			onClick: () => state.setValue(currentDate.add({ days: 1 })),
		},
	];
}

export function RangeCalendarShortcuts({
	className,
	state,
}: {
	className?: string;
	state: RangeCalendarState;
}) {
	const shortcuts = useRangeCalendarShortcuts(state);

	return (
		<Shortcuts className={className}>
			{shortcuts.map(({ onClick, label }) => (
				<Shortcut onClick={onClick} key={label}>
					<div className="text-body-14 font-500">{label}</div>
				</Shortcut>
			))}
		</Shortcuts>
	);
}

export function useRangeCalendarShortcuts(
	state: RangeCalendarState,
): ShortcutConfig[] {
	const t = useTranslate("common");
	const currentDate = today(getLocalTimeZone());

	return [
		{
			label: t("datetime.calendar.shortcuts.yesterday"),
			onClick: () =>
				state.setValue({
					start: currentDate.subtract({ days: 1 }),
					end: currentDate.subtract({ days: 1 }),
				}),
		},
		{
			label: t("datetime.calendar.shortcuts.today"),
			onClick: () => state.setValue({ start: currentDate, end: currentDate }),
		},
		{
			label: t("datetime.calendar.shortcuts.tomorrow"),
			onClick: () =>
				state.setValue({
					start: currentDate.add({ days: 1 }),
					end: currentDate.add({ days: 1 }),
				}),
		},
		{
			label: t("datetime.calendar.shortcuts.next-week"),
			onClick: () =>
				state.setValue({
					start: currentDate,
					end: currentDate.add({ days: 7 }),
				}),
		},
		{
			label: t("datetime.calendar.shortcuts.last-week"),
			onClick: () =>
				state.setValue({
					start: currentDate.subtract({ days: 7 }),
					end: currentDate,
				}),
		},
	];
}

function Shortcut({
	children,
	onClick,
}: {
	children?: React.ReactNode;
	onClick?: () => void;
}) {
	return (
		<button
			onClick={onClick}
			className="text-left hover:bg-grey-200 py-4 px-8 rounded-r"
			type="button"
		>
			{children}
		</button>
	);
}

function Shortcuts({
	children,
	className,
}: {
	children?: React.ReactNode;
	className?: string;
}) {
	return (
		<div
			className={clsx(
				className,
				"whitespace-nowrap text-body-14 flex flex-col gap-8",
			)}
		>
			{children}
		</div>
	);
}
